define('jason-frontend/components/patient-chip-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    selectedMode: 'new',
    isPatient: true,
    currentView: 'overview',
    actions: {
      registerAnimalData: function registerAnimalData() {
        this.set('currentView', 'registerAnimalData');
        var self = this;
        $.ajax({
          url: "/api/patients/" + this.get('patient').id + "/animalDataForm",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('model', data.chipRegistrationAnimalForm);
        });
      },
      submitAnimalData: function submitAnimalData() {

        var self = this;

        var model = this.get('model');

        if (!model.animalName || model.animalName.length === 0) {
          jason.notifiction.error('Fehler', 'Bitte Name des Tieres angeben');
          return;
        }
        if (!model.animalGender || model.animalGender.length === 0) {
          jason.notifiction.error('Fehler', 'Bitte Geschlecht des Tieres angeben');
          return;
        }
        if (!model.animalCategory || model.animalCategory.length === 0) {
          jason.notifiction.error('Fehler', 'Bitte Spezies des Tieres angeben');
          return;
        }
        if (!model.animalRace || model.animalRace.length === 0) {
          jason.notifiction.error('Fehler', 'Bitte Rasse des Tieres angeben');
          return;
        }
        if (!model.animalRace || model.animalRace.length === 0) {
          jason.notifiction.error('Fehler', 'Bitte Farbe des Tieres angeben');
          return;
        }
        if (!model.password || model.password.length === 0) {
          jason.notifiction.error('Fehler', 'Bitte ein Passwort angeben');
          return;
        }
        if (!model.customerFirstname || model.customerFirstname.length === 0) {
          jason.notifiction.error('Fehler', 'Bitte Vorname angeben');
          return;
        }
        if (!model.customerLastname || model.customerLastname.length === 0) {
          jason.notifiction.error('Fehler', 'Bitte Nachname angeben');
          return;
        }
        if (!model.customerPostalCode || model.customerPostalCode.length === 0) {
          jason.notifiction.error('Fehler', 'Bitte PLZ angeben');
          return;
        }
        if (!model.customerTown || model.customerTown.length === 0) {
          jason.notifiction.error('Fehler', 'Bitte Stadt/Ort angeben');
          return;
        }
        if (!model.customerStreet || model.customerStreet.length === 0) {
          jason.notifiction.error('Fehler', 'Bitte Adresse angeben');
          return;
        }
        if (!model.customerCountry || model.customerCountry.length === 0) {
          jason.notifiction.error('Fehler', 'Bitte Land angeben');
          return;
        }
        if (!model.customerGender || model.customerGender.length === 0) {
          jason.notifiction.error('Fehler', 'Bitte Anrede angeben');
          return;
        }
        if (!model.customerBirthday || model.customerBirthday.length === 0) {
          jason.notifiction.error('Fehler', 'Bitte Geburtsdatum angeben');
          return;
        }
        if (!model.customerPhone || model.customerPhone.length === 0) {
          jason.notifiction.error('Fehler', 'Bitte Telefonnummer angeben');
          return;
        }
        if (!model.animalBirthday || model.animalBirthday.length === 0) {
          jason.notifiction.error('Fehler', 'Bitte Geburtstag/Alter des Tieres angeben');
          return;
        }
        if (model.officialOrder && model.officialOrder === true) {
          if (!model.ownerDocumentType || model.ownerDocumentType.length === 0) {
            jason.notifiction.error('Fehler', 'Bitte Art des Lichtbildausweise angeben');
            return;
          }
          if (!model.ownerDocumentId || model.ownerDocumentId.length === 0) {
            jason.notifiction.error('Fehler', 'Bitte Ausweisnummer angeben');
            return;
          }
          if (!model.animalCountry || model.animalCountry.length === 0) {
            jason.notifiction.error('Fehler', 'Bitte Herkunftsland des Tieres angeben');
            return;
          }
          if (!model.animalStartdate || model.animalStartdate.length === 0) {
            jason.notifiction.error('Fehler', 'Bitte Haltungsbeginn des Tieres angeben');
            return;
          }
        }

        this.set('currentView', 'overview');

        $.ajax({
          url: "/api/patients/" + this.get('patient').id + "/animalDataForm",
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(this.get('model')),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {

          $.ajax({
            url: "/api/patients/" + self.get('patient').id + "/chipStatus",
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.set('chipStatus', data.chipRegistration);
          });
        });
      },
      confirm: function confirm() {
        if (this.get('selectedMode') === 'composite' && !this.get('selectedInvoice')) {
          jason.notifiction.error('Fehler', 'Bitte Sammelrechnung auswählen');
          return;
        }
        this.sendAction('confirm', this.get('tpId'), this.get('patientId'), this.get('selectedMode'), this.get('selectedInvoice'), this.get('selectedPatient'));
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      load: function load(patient, reference) {
        this.set('patient', patient);
        this.set('currentView', 'overview');
        var self = this;

        this.set('customerGenders', reference.store.find('gender'));

        $.ajax({
          url: "/api/patients/" + patient.id + "/chipStatus",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('chipStatus', data.chipRegistration);
        });
      }
    }
  });

});