define('jason-frontend/components/powersearch-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actionList: [
    // {id: "openHelp", name: "Hilfe durchsuchen", type: "help"},
    {
      id: "addtreatmentPosition",
      name: "Position in aktuelle Behandlung hinzufügen",
      description: "Produkte, Leistungen, Pakete oder Vorlagen hinzufügen",
      type: "search",
      icon: "product",
      onlyVisibleInRoute: "practicemgmt.treatment-details"
    }, {
      id: "openCustomer",
      name: "Kunde suchen",
      description: "und Detailansicht öffnen",
      type: "search",
      icon: "customer"
    }, {
      id: "openPatient",
      name: "Tier suchen",
      description: "und Detailansicht öffnen",
      type: "search",
      icon: "animal"
    }, {
      id: "openProduct",
      name: "Produkt suchen",
      description: "und Detailansicht öffnen",
      type: "search",
      icon: "product"
    }, {
      id: "openService",
      name: "Leistung suchen",
      description: "und Detailansicht öffnen",
      type: "search",
      icon: "service"
    }, {
      id: "openPackage", name: "Paket suchen", description: "und Detailansicht öffnen", type: "search", icon: "package" }, {
      id: "gotoCalendar",
      name: "Kalender öffnen",
      description: "Kalender Übersicht öffnen",
      type: "link",
      target: 'appointments.calendar',
      icon: "calendar"
    }, {
      id: "gotoAdmission",
      name: "Praxis Übersicht öffnen",
      description: "",
      type: "link",
      target: 'practicemgmt.admission'
    },
    // {id: "createCustomer", name: "Neuen Kunden anlegen (WIP)", type: "wizard"},
    {
      id: "admission",
      name: "Tier aufnehmen",
      description: "Neues Tier in Behandlung aufnehmen",
      type: "link",
      target: 'practicemgmt.admission-select-customer'
    }],
    filteredItems: null,
    subContext: null,
    loading: false,
    searchIcon: "search",
    placeholder: "",
    init: function init() {
      this._super.apply(this, arguments);
      this.boundHandleKeyDownPower = null;
    },
    close: function close() {
      $.magnificPopup.close();
    },
    removeKeydownListener: function removeKeydownListener() {
      if (this.boundHandleKeyDownPower) {
        Ember['default'].$(document).off('keydown', this.boundHandleKeyDownPower);
      }
    },
    addKeydownListener: function addKeydownListener() {
      var _this = this;

      this.removeKeydownListener(); // Remove any existing listener first
      this.boundHandleKeyDownPower = function (event) {
        return _this.handleKeyDownPower(event, _this);
      };
      Ember['default'].$(document).on('keydown', this.boundHandleKeyDownPower);
    },
    // initHelp: function () {
    //   this.set('filteredItems', this.get('helpIndex'));
    //   this.set('selectedIndex', 0);
    //   this.updateSelected();
    // },
    prepareSubContext: function prepareSubContext(id, placeholder, icon) {
      var self = this;
      this.set('powerSearch', null);
      this.set('filteredItems', null);
      this.set('placeholder', placeholder);
      this.set('subContext', id);
      this.set('searchIcon', icon);
      setTimeout(function () {
        $('#powerSearchInput').focus();
        self.set('powerSearch', ' ');
        self.set('powerSearch', '');
      }, 200);
    },
    resetSubContext: function resetSubContext() {
      this.set('subContext', null);
      this.set('powerSearch', null);
      this.set('selectedItemId', null);
      this.set('searchIcon', 'search');
      this.set('placeholder', 'Suchen...');
      this.updateSearch();
    },
    debouncedCustomerApiCall: function debouncedCustomerApiCall(searchTerm) {
      var self = this;
      $.ajax({
        url: "/api/customers?filterName=" + searchTerm,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.set('filteredItems', data.customer);
        self.set('selectedIndex', 0);
        self.set('loading', false);
        self.updateSelected();
      });
    },
    debouncedPatientApiCall: function debouncedPatientApiCall(searchTerm) {
      var self = this;
      $.ajax({
        url: "/api/patients?filterName=" + searchTerm,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.set('filteredItems', data.patient);
        self.set('selectedIndex', 0);
        self.set('loading', false);
        self.updateSelected();
      });
    },
    debouncedArticleApiCall: function debouncedArticleApiCall(searchTerm) {
      var self = this;
      $.ajax({
        url: "/api/articles/powersearch?searchTerm=" + searchTerm,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.set('filteredItems', data.article);
        self.set('selectedIndex', 0);
        self.set('loading', false);
        self.updateSelected();
      });
    },
    debouncedProductApiCall: function debouncedProductApiCall(searchTerm) {
      var self = this;
      $.ajax({
        url: "/api/products?searchTerm=" + searchTerm,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.set('filteredItems', data.product);
        self.set('selectedIndex', 0);
        self.set('loading', false);
        self.updateSelected();
      });
    },
    debouncedServiceApiCall: function debouncedServiceApiCall(searchTerm) {
      var self = this;
      $.ajax({
        url: "/api/services?searchTerm=" + searchTerm,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.set('filteredItems', data.service);
        self.set('selectedIndex', 0);
        self.set('loading', false);
        self.updateSelected();
      });
    },
    debouncedPackageApiCall: function debouncedPackageApiCall(searchTerm) {
      var self = this;
      $.ajax({
        url: "/api/compositeArticles?filterName=" + searchTerm,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.set('filteredItems', data.compositeArticle);
        self.set('selectedIndex', 0);
        self.set('loading', false);
        self.updateSelected();
      });
    },
    updateSearch: function updateSearch(value) {
      var _this2 = this;

      var self = this;
      var searchTerm = (this.get('powerSearch') || '').toLowerCase();

      if (this.get('subContext') && this.get('subContext').length) {
        if (this.get('subContext') === 'helpSearch') {
          var filtered = this.get('helpIndex').filter(function (item) {
            return item.name.toLowerCase().indexOf(searchTerm) >= 0 || item.keyWords && item.keyWords.some(function (keyword) {
              return keyword.toLowerCase().indexOf(searchTerm) >= 0;
            });
          });
          this.set('filteredItems', filtered);
          this.set('selectedIndex', 0);
          this.updateSelected();
        } else {
          if (searchTerm.length > 2) {
            if (this.get('subContext') === 'customerSearch') {
              this.set('loading', true);
              Ember['default'].run.debounce(this, this.debouncedCustomerApiCall, searchTerm, 300);
            } else if (this.get('subContext') === 'patientSearch') {
              this.set('loading', true);
              Ember['default'].run.debounce(this, this.debouncedPatientApiCall, searchTerm, 300);
            } else if (this.get('subContext') === 'productSearch') {
              this.set('loading', true);
              Ember['default'].run.debounce(this, this.debouncedProductApiCall, searchTerm, 300);
            } else if (this.get('subContext') === 'treatmentAddPosition') {
              this.set('loading', true);
              Ember['default'].run.debounce(this, this.debouncedArticleApiCall, searchTerm, 300);
            } else if (this.get('subContext') === 'serviceSearch') {
              this.set('loading', true);
              Ember['default'].run.debounce(this, this.debouncedServiceApiCall, searchTerm, 300);
            } else if (this.get('subContext') === 'packageSearch') {
              this.set('loading', true);
              Ember['default'].run.debounce(this, this.debouncedPackageApiCall, searchTerm, 300);
            }
          }
        }
      } else {
        var filtered = this.get('actionList').filter(function (item) {
          return item.name.toLowerCase().indexOf(searchTerm) >= 0 && (!item.onlyVisibleInRoute || item.onlyVisibleInRoute && item.onlyVisibleInRoute === _this2.get('route'));
        });
        this.set('filteredItems', filtered);
        this.set('selectedIndex', 0);
        this.updateSelected();
      }
    },
    updateSelected: function updateSelected() {
      var filtered = this.get('filteredItems');
      var selectedIndex = this.get('selectedIndex');
      if (filtered) {
        filtered.forEach(function (item, index) {
          Ember['default'].set(item, 'selected', index === selectedIndex);
        });
      }
    },
    moveUp: function moveUp(event) {
      var filteredItems = this.get('filteredItems');
      var selectedIndex = this.get('selectedIndex');
      selectedIndex = (selectedIndex - 1 + filteredItems.length) % filteredItems.length;
      this.set('selectedIndex', selectedIndex);
      this.updateSelected();
    },
    moveDown: function moveDown(event) {
      var filteredItems = this.get('filteredItems');
      var selectedIndex = this.get('selectedIndex');
      selectedIndex = (selectedIndex + 1) % filteredItems.length;
      this.set('selectedIndex', selectedIndex);
      this.updateSelected();
    },
    handleKeyDownPower: function handleKeyDownPower(event, self) {
      if (event.keyCode === 40) {
        this.moveDown();
      }
      if (event.keyCode === 38) {
        this.moveUp();
      }
      if (event.key === 'Enter') {
        this.trigger(self.get('filteredItems').find(function (item) {
          return item.selected;
        }));
      }
      if (event.key === 'Backspace' && this.get('powerSearch') && this.get('powerSearch').length === 0) {
        if (self.get('subContext') && self.get('subContext').length > 0) {
          if (self.get('subContext') === 'treatmentAddProduct') {
            self.set('selectedItemId', null);
            self.prepareSubContext('treatmentAddPosition', 'Position hinzufügen', 'product');
          } else {
            self.resetSubContext();
          }
        }
      }
      if (event.key === 'Escape') {
        if (self.get('subContext') && self.get('subContext').length > 0) {
          if (self.get('subContext') === 'treatmentAddProduct') {
            self.set('selectedItemId', null);
            self.prepareSubContext('treatmentAddPosition', 'Position hinzufügen', 'product');
          } else {
            self.resetSubContext();
          }
        } else {
          $.magnificPopup.close();
        }
      }
    },
    trigger: function trigger(item) {
      if (this.get('subContext') && this.get('subContext').length > 0 && this.get('subContext') === 'customerSearch' && item && item.id) {
        this.gotoCustomerEdit(item.id);
      } else if (this.get('subContext') && this.get('subContext').length > 0 && this.get('subContext') === 'patientSearch' && item && item.id) {
        this.gotoPatientEdit(item.id);
      } else if (this.get('subContext') && this.get('subContext').length > 0 && this.get('subContext') === 'productSearch' && item && item.id) {
        this.gotoProductEdit(item.id);
      } else if (this.get('subContext') && this.get('subContext').length > 0 && this.get('subContext') === 'serviceSearch' && item && item.id) {
        this.gotoServiceEdit(item.id);
      } else if (this.get('subContext') && this.get('subContext').length > 0 && this.get('subContext') === 'treatmentAddPosition' && item && item.id) {
        this.addArticleToTreatment(item);
      } else if (this.get('subContext') && this.get('subContext').length > 0 && this.get('subContext') === 'treatmentAddProduct' && item && item.id) {
        this.addProductToTreatment(item);
      } else if (this.get('subContext') && this.get('subContext').length > 0 && this.get('subContext') === 'packageSearch' && item && item.id) {
        this.gotoPackageEdit(item.id);
      } else if (this.get('subContext') && this.get('subContext').length > 0 && this.get('subContext') === 'helpSearch' && item && item.id && item.link) {
        // this.openHelpPage(item);
      } else {
          if (item && item.id) {
            if (item.type === 'link') {
              this.get('externalContext').transitionTo(item.target);
              $.magnificPopup.close();
            } else if (item.type === 'search') {
              if (item.id === 'openCustomer') {
                this.prepareSubContext('customerSearch', 'Kund:in suchen', item.icon);
              } else if (item.id === 'openPatient') {
                this.prepareSubContext('patientSearch', 'Tier suchen', item.icon);
              } else if (item.id === 'openProduct') {
                this.prepareSubContext('productSearch', 'Produkt suchen', item.icon);
              } else if (item.id === 'openService') {
                this.prepareSubContext('serviceSearch', 'Leistung suchen', item.icon);
              } else if (item.id === 'openPackage') {
                this.prepareSubContext('packageSearch', 'Paket suchen', item.icon);
              } else if (item.id === 'addtreatmentPosition') {
                this.prepareSubContext('treatmentAddPosition', 'Position hinzufügen', 'product');
              }
              // } else if (item.id === 'openHelp') {
              //   this.prepareSubContext('helpSearch', 'Hilfe durchsuchen');
              //   this.initHelp();
            }
          }
        }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.removeKeydownListener();
    },
    gotoCustomerEdit: function gotoCustomerEdit(id) {
      this.get('externalContext').transitionTo('customers.edit', id);
      this.close();
    },
    gotoPatientEdit: function gotoPatientEdit(id) {
      this.get('externalContext').transitionTo('patients.edit', id);
      this.close();
    },
    gotoProductEdit: function gotoProductEdit(id) {
      this.get('externalContext').transitionTo('products.edit', id);
      this.close();
    },
    gotoServiceEdit: function gotoServiceEdit(id) {
      this.get('externalContext').transitionTo('services.edit', id);
      this.close();
    },
    gotoPackageEdit: function gotoPackageEdit(id) {
      this.get('externalContext').transitionTo('compositeproducts.edit', id);
      this.close();
    },
    // openHelpPage: function (item) {
    //   window.open(item.link, '_blank');
    // },
    addProductToTreatment: function addProductToTreatment(vpe) {
      var vpeId = vpe.id;
      if (vpeId === -1) {
        vpeId = 0;
      }
      var self = this;
      var deferred = jQuery.ajax({
        url: "/api/treatmentOffers/" + this.get('subContextId') + "/add/product/" + this.get('selectedItemId') + "/1/" + vpeId + "/0",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.sendAction('reload');
        jason.notifiction.notifiy('Produkt hinzugefügt', '');
      });
    },
    addArticleToTreatment: function addArticleToTreatment(item) {
      var _this3 = this;

      if (item.type === 'service') {
        var deferred;

        (function () {
          var self = _this3;
          deferred = jQuery.ajax({
            url: "/api/treatmentOffers/" + _this3.get('subContextId') + "/add/service/" + item.id + "/1/0",
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.sendAction('reload');
            jason.notifiction.notifiy('Leistung hinzugefügt', '');
          });
        })();
      } else if (item.type === 'package') {
        var deferred;

        (function () {
          var self = _this3;
          deferred = jQuery.ajax({
            url: "/api/treatmentOffers/" + _this3.get('subContextId') + "/add/composite/" + item.id + "/1/0",
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.sendAction('reload');
            jason.notifiction.notifiy('Paket hinzugefügt', '');
          });
        })();
      } else if (item.type === 'product') {
        var deferred;
        var deferred;

        (function () {
          var self = _this3;
          if (item.pricesCount === 0) {
            deferred = jQuery.ajax({
              url: "/api/treatmentOffers/" + _this3.get('subContextId') + "/add/product/" + item.id + "/1/0/0",
              method: "GET",
              contentType: "application/json",
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function (data) {
              self.sendAction('reload');
              jason.notifiction.notifiy('Produkt hinzugefügt', '');
            });
          } else {
            _this3.prepareSubContext('treatmentAddProduct', 'VPE auswählen', 'product');
            _this3.set('selectedItemId', item.id);
            deferred = jQuery.ajax({
              url: "/api/products/" + item.id,
              method: "GET",
              contentType: "application/json",
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function (data) {
              var filtered = [];

              filtered.push({
                id: -1,
                name: data.product.quantity + " " + data.product.unit.name,
                price: data.product.priceExVat,
                favorite: data.product.favorite,
                tax: data.product.tax.percentage
              });

              data.product.prices.forEach(function (price) {
                filtered.push({
                  id: price.id,
                  name: price.quantity + " " + price.unit.name,
                  price: price.price,
                  favorite: price.favorite,
                  tax: data.product.tax.percentage,
                  applied: price.applied
                });
              });
              self.set('filteredItems', filtered);
              self.set('selectedIndex', 0);
              self.updateSelected();
            });
          }
        })();
      } else if (item.type === 'template') {
        var deferred;

        (function () {
          var self = _this3;
          deferred = jQuery.ajax({
            url: "/api/treatmentOffers/" + _this3.get('subContextId') + "/add/text/" + item.id + "/1/0",
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.sendAction('reload');
            jason.notifiction.notifiy('Vorlage hinzugefügt', '');
          });
        })();
      }
    },
    actions: {
      addArticleToTreatment: function addArticleToTreatment(id) {
        this.addArticleToTreatment(id);
      },
      addProductToTreatment: function addProductToTreatment(id) {
        this.addProductToTreatment(id);
      },
      // openHelpPage: function (item) {
      //   this.openHelpPage(item);
      // },
      gotoCustomerEdit: function gotoCustomerEdit(id) {
        this.gotoCustomerEdit(id);
      },
      gotoPatientEdit: function gotoPatientEdit(id) {
        this.gotoPatientEdit(id);
      },
      gotoProductEdit: function gotoProductEdit(id) {
        this.gotoProductEdit();
      },
      gotoServiceEdit: function gotoServiceEdit(id) {
        this.gotoServiceEdit();
      },
      gotoPackageEdit: function gotoPackageEdit(id) {
        this.gotoPackageEdit();
      },
      destroy: function destroy() {
        this.removeKeydownListener();
      },
      trigger: function trigger(item) {
        this.trigger(item);
      },
      load: function load(externalContext, route, id) {
        var self = this;
        this.set('loading', false);
        this.set('route', route);
        this.set('subContextId', id);
        this.set('selectedItemId', null);
        this.set('subContext', null);
        this.set('placeholder', 'Suchen...');
        this.set('selectedIndex', 0);
        this.set('externalContext', externalContext);
        this.addKeydownListener();
        this.set('powerSearch', null);
        setTimeout(function () {
          $('#powerSearchInput').focus();
          self.set('powerSearch', ' ');
          self.set('powerSearch', '');
        }, 500);
        if (route === 'practicemgmt.treatment-details') {
          this.prepareSubContext('treatmentAddPosition', 'Position hinzufügen', 'product');
        }

        this.updateSearch();
      }
    },
    watchSearch: (function () {
      this.updateSearch();
    }).observes('powerSearch')
  });

});