define('jason-frontend/controllers/customers/index', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Kunde auswählen",
    breadCrumbPath: "customers.index",
    selectedGender: 'female',
    showFilters: true,
    queryParams: ["page", "perPage", "filterName", "filterLastname", "filterPostalCode", "filterTown", "filterStreet", "sortBy", "sortDir"],
    filterFirstname: "",
    filterLastname: "",
    filterPostalcode: "",
    filterPatient: "",
    filterTown: "",
    filterStreet: "",
    filterChip: "",
    filterPhone: "",
    filterAddress: "",
    sortBy: null,
    sortDir: null,
    addPatient: false,

    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",

    page: 1,
    perPage: 10,
    watchName: (function () {
      this.set('page', 1);
    }).observes('filterName'),
    watchLastname: (function () {
      this.set('page', 1);
    }).observes('filterLastname'),
    watchPostalcode: (function () {
      this.set('page', 1);
    }).observes('filterPostalcode'),
    watchTown: (function () {
      this.set('page', 1);
    }).observes('filterTown'),
    watchStreet: (function () {
      this.set('page', 1);
    }).observes('filterStreet'),
    actions: {
      selectCustomer: function selectCustomer(customerId, patients) {
        var self = this;
        if (patients.length == 0) {
          jason.notifiction.error('Fehler', 'Bitte wählen Sie zumindest einen Patienten aus');
          return;
        }

        this.get('startTreatmentPanel').send('load', customerId, patients);

        Ember['default'].$.ajax({
          url: "/api/patients/latestInfos?patientIds=" + patients,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('startTreatmentPanel').send('loadTreatment', data);
        }, function (data) {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-start-treatment-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      addPatient: function addPatient(customerId) {
        $('#createPatientForm').attr('data-customer-id', customerId);
        this.get('createPatientPanel').send('reset');

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-create-patient-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openCreateCustomer: function openCreateCustomer() {

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-create-customer-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });

        this.get('createCustomerPanel').send('load');
      },
      setCustomerContr: function setCustomerContr(customerId) {
        var patients = Array();
        $('#patientsForCustomer' + customerId + ' input.patient:checked').each(function (e) {
          var id = $(this).attr('data-id');
          patients.push(id);
        });
        this.send('setCustomer', customerId, patients);
      }
    }
  });

});