define('jason-frontend/routes/dashboard', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller, model, params) {
      $('body').addClass('theme-' + API['default'].getTheme());
      Ember['default'].$(document).on('keydown', this.handleKeyDown.bind(this));
    },
    handleKeyDown: function handleKeyDown(event) {
      // Check if CTRL+K (or Command+K on Mac) is pressed
      if ((event.ctrlKey || event.metaKey) && event.keyCode === 75) {
        event.preventDefault();
        this.send('openPowerSearch');
      }
    },
    actions: {
      reloadFromPowersearch: function reloadFromPowersearch() {
        this.send('reloadTreatment');
      },
      openPowerSearch: function openPowerSearch() {
        var self = this;
        $.magnificPopup.open({
          removalDelay: 0,
          closeOnBgClick: true,
          showCloseBtn: false,
          alignTop: true,
          enableEscapeKey: false,
          items: {
            src: '#modal-powersearch'
          },
          callbacks: {
            close: function close() {
              self.get('controller').get('powerSearch').send('destroy');
            },
            beforeOpen: function beforeOpen() {
              this.st.mainClass = "mfp-move-from-top";
            }
          },
          midClick: true
        });
        var currentRouteName = this.get('router.currentRouteName');
        var currentParams = this.paramsFor(currentRouteName);
        var currentParamId = currentParams.id;
        this.get('controller').get('powerSearch').send('load', this, currentRouteName, currentParamId);
      },
      openHome: function openHome() {
        var landingPage = API['default'].getLandingPage();
        if (landingPage) {
          if (landingPage === 'dashboard') {
            this.transitionTo("/dashboard/welcome");
          } else if (landingPage === 'overview') {
            this.transitionTo("/dashboard/practicemgmt/admission");
          } else if (landingPage === 'calendar') {
            this.transitionTo("/dashboard/appointments/calendar");
          } else if (landingPage === 'promotions' && API['default'].getOperatingCountry() === 'AT') {
            this.transitionTo("/dashboard/purchases/promotions");
          }
        } else {
          this.transitionTo("/dashboard/welcome");
        }
      },
      updateServerState: function updateServerState(state) {
        var controller = this.get('controller');
        if (state === 'down') {
          controller.set('showBreakdown', true);
        } else if (state === 'timeout') {
          controller.set('showTimeout', true);
        } else {
          if (controller.get('showBreakdown')) {
            controller.set('showBreakdown', false);
          }
          if (controller.get('showTimeout')) {
            controller.set('showTimeout', false);
          }
        }
      },
      openPortal: function openPortal() {
        window.location.href = 'https://portal.vetnative.com';
      },
      openScanEtigaQr: function openScanEtigaQr() {

        this.get('controller').get('quickScanEtiga').send('load');

        $.magnificPopup.open({
          removalDelay: 200,
          closeOnBgClick: false,
          items: {
            src: '#modal-etiga-quick-scan-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      scannedEtigaNumber: function scannedEtigaNumber(id) {
        var self = this;

        $.ajax({
          url: "/api/patients/" + id + "/getClientFromEtiga",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          timeout: 3000,
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          if (data.found) {

            jason.notifiction.notifiy('Erfolgreich', 'eltiga Patient erfolgreich erkannt');

            if (API['default'].getMandantType() === 'basic' || API['default'].getMandantType() === 'essential') {

              var treatment = {
                customer: {
                  id: data.customerId
                }
              };

              var selected = new Array();

              selected[0] = {
                patientId: data.patientIds[0],
                putDirectlyInTreatment: true
              };

              treatment.selectedPatients = selected;
              treatment.appointment = false;
              treatment.time = moment().format("HH:mm");

              $.ajax({
                url: "/api/treatments",
                method: "PUT",
                data: JSON.stringify(treatment),
                contentType: "application/json",
                dataType: "json",
                headers: {
                  'X-AUTH-TOKEN': API['default'].getToken()
                }
              }).then(function (id) {
                self.transitionTo('practicemgmt.treatment-details', id, { queryParams: { entry: "true" } });
              }, function (error) {
                return { status: error.statusText, message: error.responseText };
              });
            } else {
              self.transitionTo('patients.edit', data.patientIds[0], {
                queryParams: {
                  selectTab: "history",
                  action: "admission"
                }
              }); //?selectTab=history
            }
          } else {
              self.transitionTo('patients.etiga', id, { queryParams: { referer: "scan" } });
            }
        }, function (error) {});
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      Ember['default'].$(document).off('keydown', this.handleKeyDown);
    }
  });

});