define('jason-frontend/templates/customers/edit', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 6,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[4,30],[4,40]]]]],[],[]],"placeHolder","Dokument suchen","search","searchDocument"],["loc",[null,[4,6],[4,96]]]],
          ["inline","filter-box",[],["showFilters",["subexpr","@mut",[["get","showFilters",["loc",[null,[5,31],[5,42]]]]],[],[]]],["loc",[null,[5,6],[5,44]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 4
            },
            "end": {
              "line": 26,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context mr10");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Kund:in zusammenführen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("form");
          dom.setAttribute(el1,"id","customersVcardExportForm");
          dom.setAttribute(el1,"method","post");
          dom.setAttribute(el1,"action","api/customers/exportVcard");
          dom.setAttribute(el1,"target","_blank");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2,"type","hidden");
          dom.setAttribute(el2,"name","mandant_id");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2,"type","hidden");
          dom.setAttribute(el2,"name","token");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2,"type","hidden");
          dom.setAttribute(el2,"name","filterId");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"class","with-icon button context");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("vCard herunterladen");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context ml10");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Umsatzauswertung");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element24 = dom.childAt(fragment, [1]);
          var element25 = dom.childAt(fragment, [3]);
          var element26 = dom.childAt(element25, [1]);
          var element27 = dom.childAt(element25, [5]);
          var element28 = dom.childAt(element25, [7]);
          var element29 = dom.childAt(fragment, [5]);
          var morphs = new Array(8);
          morphs[0] = dom.createElementMorph(element24);
          morphs[1] = dom.createMorphAt(element24,1,1);
          morphs[2] = dom.createAttrMorph(element26, 'value');
          morphs[3] = dom.createAttrMorph(element27, 'value');
          morphs[4] = dom.createElementMorph(element28);
          morphs[5] = dom.createMorphAt(element28,1,1);
          morphs[6] = dom.createElementMorph(element29);
          morphs[7] = dom.createMorphAt(element29,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["openMergeCustomer"],[],["loc",[null,[10,9],[10,39]]]],
          ["inline","button-merge",[],["content","","color","#FFF","size","16"],["loc",[null,[11,8],[11,58]]]],
          ["attribute","value",["concat",[["get","mandantId",["loc",[null,[15,56],[15,65]]]]]]],
          ["attribute","value",["concat",[["get","model.id",["loc",[null,[17,54],[17,62]]]]]]],
          ["element","action",["checkForm","customersVcardExportForm"],[],["loc",[null,[18,49],[18,98]]]],
          ["inline","button-download2",[],["content","vCard herunterladen","color","#FFF","size","16"],["loc",[null,[19,10],[19,83]]]],
          ["element","action",["openRevenue",["get","model.id",["loc",[null,[23,32],[23,40]]]]],[],["loc",[null,[23,9],[23,42]]]],
          ["inline","icon-cash",[],["color","#fff","size","20","showStroke",false],["loc",[null,[24,8],[24,61]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 6
              },
              "end": {
                "line": 33,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","with-icon button context");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("Ordnerinhalt herunterladen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element22 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element22);
            morphs[1] = dom.createMorphAt(element22,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["downloadFolder"],[],["loc",[null,[29,11],[29,38]]]],
            ["inline","button-download2",[],["content","vCard herunterladen","color","#FFF","size","16"],["loc",[null,[30,10],[30,83]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 4
            },
            "end": {
              "line": 37,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context primary");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neues Dokument");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element23 = dom.childAt(fragment, [2]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createElementMorph(element23);
          morphs[2] = dom.createMorphAt(element23,1,1);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","not",[["get","filterName",["loc",[null,[28,17],[28,27]]]]],[],["loc",[null,[28,12],[28,28]]]]],[],0,null,["loc",[null,[28,6],[33,13]]]],
          ["element","action",["openDocumentUpload"],[],["loc",[null,[34,9],[34,40]]]],
          ["inline","button-fileupload",[],["color","#fff","size","16","showStroke",false],["loc",[null,[35,8],[35,69]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 38,
              "column": 4
            },
            "end": {
              "line": 42,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context primary");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/add-entry.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neues Tier");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element21 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element21);
          return morphs;
        },
        statements: [
          ["element","action",["showCreatePatient"],[],["loc",[null,[39,9],[39,39]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 4
            },
            "end": {
              "line": 47,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context primary");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/add-entry.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neuer Termin");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element20 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element20);
          return morphs;
        },
        statements: [
          ["element","action",["openNewAppointment"],[],["loc",[null,[44,9],[44,40]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 53,
                "column": 6
              },
              "end": {
                "line": 58,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","with-icon button context primary");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("Guthaben ausbezahlen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element18 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element18);
            morphs[1] = dom.createMorphAt(element18,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["payoutDeposit",["get","model",["loc",[null,[54,36],[54,41]]]]],[],["loc",[null,[54,11],[54,43]]]],
            ["inline","button-cash",[],["color","#fff","size","32","showStroke",false],["loc",[null,[55,10],[55,65]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 48,
              "column": 4
            },
            "end": {
              "line": 59,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Saldoübersicht drucken");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element19 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createElementMorph(element19);
          morphs[1] = dom.createMorphAt(element19,1,1);
          morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["element","action",["print",["get","model",["loc",[null,[49,26],[49,31]]]]],[],["loc",[null,[49,9],[49,33]]]],
          ["inline","button-print",[],["color","#fff","size","32","showStroke",false],["loc",[null,[50,8],[50,64]]]],
          ["block","if",[["subexpr","gt",[["get","depositSum",["loc",[null,[53,16],[53,26]]]],0],[],["loc",[null,[53,12],[53,29]]]]],[],0,null,["loc",[null,[53,6],[58,13]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 60,
              "column": 4
            },
            "end": {
              "line": 64,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context primary");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Erstellen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element17 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element17);
          morphs[1] = dom.createMorphAt(element17,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["showCreateConsent"],[],["loc",[null,[61,9],[61,39]]]],
          ["inline","button-fileupload",[],["color","#fff","size","16","showStroke",false],["loc",[null,[62,8],[62,69]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 69,
              "column": 60
            },
            "end": {
              "line": 69,
              "column": 204
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","button-ical",[],["size",48,"classNames","inline-block vertical-bottom","color","#00AAC6","content","eltiga App BenutzerIn"],["loc",[null,[69,91],[69,204]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child8 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 69,
                "column": 204
              },
              "end": {
                "line": 69,
                "column": 362
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","button-ical",[],["size",48,"classNames","inline-block vertical-bottom","color","#797979","content","eltiga Tier QR Code verschickt"],["loc",[null,[69,240],[69,362]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 69,
              "column": 204
            },
            "end": {
              "line": 69,
              "column": 362
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","model.etigaCodeRequested",["loc",[null,[69,214],[69,238]]]]],[],0,null,["loc",[null,[69,204],[69,362]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child9 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 73,
                "column": 6
              },
              "end": {
                "line": 75,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","clear title title--big");
            var el2 = dom.createTextNode("Guthaben: ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]),1,1);
            return morphs;
          },
          statements: [
            ["inline","format-currency",[],["value",["subexpr","@mut",[["get","depositSum",["loc",[null,[74,84],[74,94]]]]],[],[]]],["loc",[null,[74,60],[74,96]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 70,
              "column": 2
            },
            "end": {
              "line": 77,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","invoice__saldo");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Saldo: ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element15 = dom.childAt(fragment, [1]);
          var element16 = dom.childAt(element15, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element16, 'class');
          morphs[1] = dom.createMorphAt(element16,1,1);
          morphs[2] = dom.createMorphAt(element15,3,3);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["title title--big ",["subexpr","css-bool-evaluator",[["subexpr","lt",[["get","balanceSum",["loc",[null,[72,63],[72,73]]]],0],[],["loc",[null,[72,59],[72,76]]]],"is-negative",""],[],["loc",[null,[72,38],[72,95]]]]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","balanceSum",["loc",[null,[72,128],[72,138]]]]],[],[]]],["loc",[null,[72,104],[72,140]]]],
          ["block","if",[["subexpr","gt",[["get","depositSum",["loc",[null,[73,16],[73,26]]]],0],[],["loc",[null,[73,12],[73,29]]]]],[],0,null,["loc",[null,[73,6],[75,13]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 85,
              "column": 14
            },
            "end": {
              "line": 96,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-accounting");
          var el3 = dom.createTextNode("Verrechnung");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-warning");
          var el3 = dom.createTextNode("Mahnwesen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"class","js-tabs__label tabs__label title");
          dom.setAttribute(el2,"href","#tab-saldo");
          var el3 = dom.createTextNode("Saldo\n                        Informationen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element12 = dom.childAt(fragment, [1, 1]);
          var element13 = dom.childAt(fragment, [3, 1]);
          var element14 = dom.childAt(fragment, [5, 1]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element12, 'class');
          morphs[1] = dom.createElementMorph(element12);
          morphs[2] = dom.createAttrMorph(element13, 'class');
          morphs[3] = dom.createElementMorph(element13);
          morphs[4] = dom.createElementMorph(element14);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[87,88],[87,97]]]],"accounting"],[],["loc",[null,[87,84],[87,111]]]],"is-active",""],[],["loc",[null,[87,63],[87,128]]]]]]],
          ["element","action",["selectTab","accounting"],[],["loc",[null,[87,153],[87,188]]]],
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[90,88],[90,97]]]],"warning"],[],["loc",[null,[90,84],[90,108]]]],"is-active",""],[],["loc",[null,[90,63],[90,125]]]]]]],
          ["element","action",["selectTab","warning"],[],["loc",[null,[90,147],[90,179]]]],
          ["element","action",["selectTab","saldo"],[],["loc",[null,[93,82],[93,112]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child11 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 106,
              "column": 0
            },
            "end": {
              "line": 110,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-appointments");
          var el3 = dom.createTextNode("Termine");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element11 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element11, 'class');
          morphs[1] = dom.createElementMorph(element11);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[108,84],[108,93]]]],"appointments"],[],["loc",[null,[108,80],[108,109]]]],"is-active",""],[],["loc",[null,[108,59],[108,126]]]]]]],
          ["element","action",["selectTab","appointments"],[],["loc",[null,[108,153],[108,190]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child12 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 279,
                "column": 28
              },
              "end": {
                "line": 291,
                "column": 28
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"for","user-category");
            dom.setAttribute(el1,"class","field-label");
            var el2 = dom.createTextNode("Produktkategorie");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"for","user-category");
            dom.setAttribute(el1,"class","field select");
            var el2 = dom.createTextNode("\n                                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            dom.setAttribute(el2,"class","arrow");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
            return morphs;
          },
          statements: [
            ["inline","view",[["get","Ember.Select",["loc",[null,[282,41],[282,53]]]]],["content",["subexpr","@mut",[["get","productCategories",["loc",[null,[283,42],[283,59]]]]],[],[]],"optionValuePath","content.id","optionLabelPath","content.name","selection",["subexpr","@mut",[["get","reduction.productCategory",["loc",[null,[286,44],[286,69]]]]],[],[]],"prompt","Alle"],["loc",[null,[282,34],[288,36]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 292,
                "column": 28
              },
              "end": {
                "line": 304,
                "column": 28
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"for","user-category");
            dom.setAttribute(el1,"class","field-label");
            var el2 = dom.createTextNode("Leistungskategorie");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"for","user-category");
            dom.setAttribute(el1,"class","field select");
            var el2 = dom.createTextNode("\n                                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            dom.setAttribute(el2,"class","arrow");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
            return morphs;
          },
          statements: [
            ["inline","view",[["get","Ember.Select",["loc",[null,[295,41],[295,53]]]]],["content",["subexpr","@mut",[["get","serviceCategories",["loc",[null,[296,42],[296,59]]]]],[],[]],"optionValuePath","content.id","optionLabelPath","content.name","selection",["subexpr","@mut",[["get","reduction.serviceCategory",["loc",[null,[299,44],[299,69]]]]],[],[]],"prompt","Alle"],["loc",[null,[295,34],[301,36]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 315,
                "column": 28
              },
              "end": {
                "line": 319,
                "column": 20
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square");
            var el2 = dom.createTextNode("\n                              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element8);
            morphs[1] = dom.createMorphAt(element8,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["addReduction",["get","reduction",["loc",[null,[316,55],[316,64]]]]],[],["loc",[null,[316,31],[316,66]]]],
            ["inline","button-add-list",[],["size","32","color","#fff","showStroke",false],["loc",[null,[317,30],[317,89]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 320,
                "column": 28
              },
              "end": {
                "line": 324,
                "column": 28
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","icon-button icon-button--delete list-action-square");
            var el2 = dom.createTextNode("\n                                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element7);
            morphs[1] = dom.createMorphAt(element7,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["removeReduction",["get","reduction",["loc",[null,[321,62],[321,71]]]]],[],["loc",[null,[321,35],[321,73]]]],
            ["inline","button-delete-list",[],["size","32","color","#fff","showStroke",false],["loc",[null,[322,34],[322,96]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 246,
              "column": 18
            },
            "end": {
              "line": 327,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-6/24 gutter");
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","field");
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","patient");
          dom.setAttribute(el3,"class","field-label");
          var el4 = dom.createTextNode("Patient(en)");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","user-title");
          dom.setAttribute(el3,"class","field select");
          var el4 = dom.createTextNode("\n                                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          dom.setAttribute(el4,"class","arrow");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-6/24 gutter");
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","field");
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","patient");
          dom.setAttribute(el3,"class","field-label");
          var el4 = dom.createTextNode("Typ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","user-title");
          dom.setAttribute(el3,"class","field select");
          var el4 = dom.createTextNode("\n                                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          dom.setAttribute(el4,"class","arrow");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-5/24 gutter");
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","field");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-4/24 gutter");
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","field");
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","user-phone");
          dom.setAttribute(el3,"class","field-label");
          var el4 = dom.createTextNode("Rabatt in %");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-2/24");
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","line-action-buttons");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [5, 1]);
          var element10 = dom.childAt(fragment, [9, 1]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3, 1, 3]),1,1);
          morphs[2] = dom.createMorphAt(element9,1,1);
          morphs[3] = dom.createMorphAt(element9,2,2);
          morphs[4] = dom.createMorphAt(dom.childAt(fragment, [7, 1]),3,3);
          morphs[5] = dom.createMorphAt(element10,1,1);
          morphs[6] = dom.createMorphAt(element10,2,2);
          return morphs;
        },
        statements: [
          ["inline","view",[["get","Ember.Select",["loc",[null,[251,39],[251,51]]]]],["content",["subexpr","@mut",[["get","model.patients",["loc",[null,[252,40],[252,54]]]]],[],[]],"optionValuePath","content.id","optionLabelPath","content.name","prompt","Alle","selection",["subexpr","@mut",[["get","reduction.patient",["loc",[null,[256,42],[256,59]]]]],[],[]]],["loc",[null,[251,32],[257,34]]]],
          ["inline","view",[["get","Ember.Select",["loc",[null,[266,39],[266,51]]]]],["content",["subexpr","@mut",[["get","customerReductionTypes",["loc",[null,[267,40],[267,62]]]]],[],[]],"optionValuePath","content.id","optionLabelPath","content.name","prompt","Alle","selection",["subexpr","@mut",[["get","reduction.type",["loc",[null,[271,42],[271,56]]]]],[],[]]],["loc",[null,[266,32],[272,34]]]],
          ["block","if",[["subexpr","eq",[["get","reduction.type.id",["loc",[null,[279,38],[279,55]]]],"product"],[],["loc",[null,[279,34],[279,66]]]]],[],0,null,["loc",[null,[279,28],[291,35]]]],
          ["block","if",[["subexpr","eq",[["get","reduction.type.id",["loc",[null,[292,38],[292,55]]]],"service"],[],["loc",[null,[292,34],[292,66]]]]],[],1,null,["loc",[null,[292,28],[304,35]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","reduction.reduction",["loc",[null,[310,42],[310,61]]]]],[],[]],"class","gui-input"],["loc",[null,[310,28],[310,81]]]],
          ["block","if",[["subexpr","eq",[["get","reduction",["loc",[null,[315,38],[315,47]]]],["get","model.reductions.lastObject",["loc",[null,[315,48],[315,75]]]]],[],["loc",[null,[315,34],[315,76]]]]],[],2,null,["loc",[null,[315,28],[319,27]]]],
          ["block","if",[["subexpr","not",[["subexpr","eq",[["get","reduction",["loc",[null,[320,43],[320,52]]]],["get","model.reductions.firstObject",["loc",[null,[320,53],[320,81]]]]],[],["loc",[null,[320,39],[320,82]]]]],[],["loc",[null,[320,34],[320,83]]]]],[],3,null,["loc",[null,[320,28],[324,35]]]]
        ],
        locals: ["reduction"],
        templates: [child0, child1, child2, child3]
      };
    }());
    var child13 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 418,
                "column": 20
              },
              "end": {
                "line": 418,
                "column": 240
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("label");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [0]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element6, 'class');
            morphs[1] = dom.createAttrMorph(element6, 'style');
            morphs[2] = dom.createAttrMorph(element6, 'data-id');
            morphs[3] = dom.createElementMorph(element6);
            morphs[4] = dom.createMorphAt(element6,0,0);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","tag.selected",["loc",[null,[418,96],[418,108]]]],"selected",""],[],["loc",[null,[418,75],[418,124]]]]]]],
            ["attribute","style",["concat",["background-color: ",["get","tag.color",["loc",[null,[418,153],[418,162]]]]]]],
            ["attribute","data-id",["concat",[["get","tag.id",["loc",[null,[418,177],[418,183]]]]]]],
            ["element","action",["selectSearchTag",["get","tag",["loc",[null,[418,214],[418,217]]]]],[],["loc",[null,[418,187],[418,219]]]],
            ["content","tag.name",["loc",[null,[418,220],[418,232]]]]
          ],
          locals: ["tag"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 415,
              "column": 16
            },
            "end": {
              "line": 420,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","quick-filter ml20 mb20");
          dom.setAttribute(el1,"id","tagFilters");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","title");
          var el3 = dom.createTextNode("Tags");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
          return morphs;
        },
        statements: [
          ["block","each",[["get","tags",["loc",[null,[418,28],[418,32]]]]],[],0,null,["loc",[null,[418,20],[418,249]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child14 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 454,
                  "column": 34
                },
                "end": {
                  "line": 454,
                  "column": 219
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","animal-icon",[],["evil",["subexpr","@mut",[["get","patient.evil",["loc",[null,[454,77],[454,89]]]]],[],[]],"classNames","inline-icon","animal-id",["subexpr","@mut",[["get","patient.category.id",["loc",[null,[454,125],[454,144]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patient.digitalRace.icon",["loc",[null,[454,153],[454,177]]]]],[],[]],"size","32","content",["subexpr","@mut",[["get","patient.category.name",["loc",[null,[454,196],[454,217]]]]],[],[]]],["loc",[null,[454,58],[454,219]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 459,
                    "column": 49
                  },
                  "end": {
                    "line": 461,
                    "column": 32
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                                ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","icon-etiga",[],["classNames","inline-icon","size","20","width",60,"color","00AAC6","connected",true],["loc",[null,[460,32],[460,120]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 461,
                    "column": 40
                  },
                  "end": {
                    "line": 461,
                    "column": 158
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","button-died",[],["classNames","ml10","size","21","useConfirm",false,"showStroke",false,"content","verstorben"],["loc",[null,[461,60],[461,158]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 458,
                  "column": 30
                },
                "end": {
                  "line": 462,
                  "column": 30
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
              return morphs;
            },
            statements: [
              ["content","patient.name",["loc",[null,[459,32],[459,48]]]],
              ["block","if",[["get","patient.etigaActive",["loc",[null,[459,55],[459,74]]]]],[],0,null,["loc",[null,[459,49],[461,39]]]],
              ["block","if",[["get","patient.dead",["loc",[null,[461,46],[461,58]]]]],[],1,null,["loc",[null,[461,40],[461,165]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child2 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 463,
                    "column": 32
                  },
                  "end": {
                    "line": 463,
                    "column": 87
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","patient.name",["loc",[null,[463,71],[463,87]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 463,
                    "column": 101
                  },
                  "end": {
                    "line": 465,
                    "column": 30
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                              ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","icon-etiga",[],["classNames","inline-icon","size","20","width",60,"color","00AAC6","connected",true],["loc",[null,[464,32],[464,120]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 465,
                    "column": 38
                  },
                  "end": {
                    "line": 465,
                    "column": 156
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","button-died",[],["classNames","ml10","size","21","useConfirm",false,"showStroke",false,"content","verstorben"],["loc",[null,[465,58],[465,156]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 462,
                  "column": 30
                },
                "end": {
                  "line": 466,
                  "column": 30
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
              return morphs;
            },
            statements: [
              ["block","link-to",["patients.edit",["get","patient.id",["loc",[null,[463,59],[463,69]]]]],[],0,null,["loc",[null,[463,32],[463,99]]]],
              ["block","if",[["get","patient.etigaActive",["loc",[null,[463,107],[463,126]]]]],[],1,null,["loc",[null,[463,101],[465,37]]]],
              ["block","if",[["get","patient.dead",["loc",[null,[465,44],[465,56]]]]],[],2,null,["loc",[null,[465,38],[465,163]]]]
            ],
            locals: [],
            templates: [child0, child1, child2]
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 473,
                  "column": 28
                },
                "end": {
                  "line": 473,
                  "column": 83
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","patient.digitalRace.name",["loc",[null,[473,55],[473,83]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child4 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 473,
                  "column": 83
                },
                "end": {
                  "line": 473,
                  "column": 107
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","patient.race",["loc",[null,[473,91],[473,107]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child5 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 479,
                  "column": 28
                },
                "end": {
                  "line": 483,
                  "column": 28
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","exp icon-button icon-button--success list-action-square");
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("wiederherstellen");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element4);
              morphs[1] = dom.createMorphAt(element4,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["undeletePatient",["get","patient.id",["loc",[null,[480,124],[480,134]]]]],[],["loc",[null,[480,97],[480,136]]]],
              ["inline","button-checked",[],["content","Gelöschten Datensatz wiederherstellen","color","#fff","showStroke",false],["loc",[null,[481,32],[481,128]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child6 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 489,
                    "column": 30
                  },
                  "end": {
                    "line": 491,
                    "column": 30
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                var el2 = dom.createTextNode("Bearbeiten");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","button-edit",[],["size","32","color","#fff","showStroke",false],["loc",[null,[490,32],[490,87]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 483,
                  "column": 28
                },
                "end": {
                  "line": 495,
                  "column": 28
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","exp icon-button icon-button--delete list-action-square");
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("                              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square");
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("QR-Code herunterladen");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var element3 = dom.childAt(fragment, [5]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(element2,1,1);
              morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]),0,0);
              morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[3] = dom.createElementMorph(element3);
              morphs[4] = dom.createMorphAt(element3,1,1);
              return morphs;
            },
            statements: [
              ["inline","button-delete-with-confirm-id",[],["color","#fff","showStroke",false,"itemId",["subexpr","@mut",[["get","patient.id",["loc",[null,[485,101],[485,111]]]]],[],[]],"confirmed","deletePatient","text","Wollen Sie dieses Tier wirklich löschen?"],["loc",[null,[485,32],[486,113]]]],
              ["inline","text-with-confirm-id",[],["itemId",["subexpr","@mut",[["get","patient.id",["loc",[null,[487,68],[487,78]]]]],[],[]],"confirmed","deletePatient","textToShow","Löschen","text","Wollen Sie dieses Tier wirklich löschen?"],["loc",[null,[487,38],[487,175]]]],
              ["block","link-to",["patients.edit",["get","patient.id",["loc",[null,[489,57],[489,67]]]]],["classNames","exp icon-button icon-button--default list-action-square"],0,null,["loc",[null,[489,30],[491,42]]]],
              ["element","action",["selectBarcode",["get","patient",["loc",[null,[492,58],[492,65]]]]],[],["loc",[null,[492,33],[492,67]]]],
              ["inline","button-barcode",[],["color","#fff","showStroke",false,"classNames","mr10"],["loc",[null,[493,32],[493,99]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 448,
                "column": 26
              },
              "end": {
                "line": 498,
                "column": 26
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-6/24 l-6/24@tablet l-1/24@desk");
            dom.setAttribute(el2,"data-label","Geschlecht");
            var el3 = dom.createTextNode("\n                              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-6/24 l-6/24@tablet l-1/24@desk");
            dom.setAttribute(el2,"data-label","Art");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--name l-12/24 l-12/24@tablet l-5/24@desk");
            dom.setAttribute(el2,"data-label","Name");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","patient-view");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-6/24 l-6/24@tablet l-4/24@desk");
            dom.setAttribute(el2,"data-label","Spezies");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n                          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-12/24 l-6/24@tablet l-5/24@desk");
            dom.setAttribute(el2,"data-label","Rasse");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-6/24 l-6/24@tablet l-4/24@desk");
            dom.setAttribute(el2,"data-label","Farbe");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell  table__cell--action l-4/24@desk buttons-3");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(7);
            morphs[0] = dom.createMorphAt(dom.childAt(element5, [1, 1]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element5, [3, 1]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element5, [5, 1]),1,1);
            morphs[3] = dom.createMorphAt(dom.childAt(element5, [7]),1,1);
            morphs[4] = dom.createMorphAt(dom.childAt(element5, [9]),1,1);
            morphs[5] = dom.createMorphAt(dom.childAt(element5, [11]),1,1);
            morphs[6] = dom.createMorphAt(dom.childAt(element5, [13]),1,1);
            return morphs;
          },
          statements: [
            ["inline","icon-gender",[],["gender",["subexpr","@mut",[["get","patient.gender.id",["loc",[null,[451,57],[451,74]]]]],[],[]],"castrated",["subexpr","@mut",[["get","patient.castrated",["loc",[null,[451,85],[451,102]]]]],[],[]],"content",["subexpr","@mut",[["get","patient.genderDetails",["loc",[null,[451,111],[451,132]]]]],[],[]],"size","32","color","#4A4A4A"],["loc",[null,[451,36],[451,160]]]],
            ["block","if",[["get","patient.category",["loc",[null,[454,40],[454,56]]]]],[],0,null,["loc",[null,[454,34],[454,226]]]],
            ["block","if",[["get","patient.deleted",["loc",[null,[458,36],[458,51]]]]],[],1,2,["loc",[null,[458,30],[466,37]]]],
            ["content","patient.category.name",["loc",[null,[470,28],[470,53]]]],
            ["block","if",[["get","patient.digitalRace",["loc",[null,[473,34],[473,53]]]]],[],3,4,["loc",[null,[473,28],[473,114]]]],
            ["content","patient.color",["loc",[null,[476,28],[476,45]]]],
            ["block","if",[["get","patient.deleted",["loc",[null,[479,34],[479,49]]]]],[],5,6,["loc",[null,[479,28],[495,35]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 447,
              "column": 24
            },
            "end": {
              "line": 499,
              "column": 24
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","or",[["subexpr","not",[["get","patient.dead",["loc",[null,[448,41],[448,53]]]]],[],["loc",[null,[448,36],[448,54]]]],["subexpr","and",[["subexpr","or",[["subexpr","eq",[["get","quickFilter",["loc",[null,[448,68],[448,79]]]],"all"],[],["loc",[null,[448,64],[448,86]]]],["subexpr","eq",[["get","quickFilter",["loc",[null,[448,91],[448,102]]]],"deleted"],[],["loc",[null,[448,87],[448,113]]]]],[],["loc",[null,[448,60],[448,114]]]],["get","patient.dead",["loc",[null,[448,115],[448,127]]]]],[],["loc",[null,[448,55],[448,128]]]]],[],["loc",[null,[448,32],[448,129]]]]],[],0,null,["loc",[null,[448,26],[498,33]]]]
        ],
        locals: ["patient"],
        templates: [child0]
      };
    }());
    var child15 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 527,
              "column": 18
            },
            "end": {
              "line": 538,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","field");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"for","patient-consent");
          dom.setAttribute(el2,"class","field-label title required");
          var el3 = dom.createTextNode("Status aus vorherigem Praxismanagementsystem");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","checkbox-custom checkbox-primary field-checkbox");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","patient-consent");
          var el4 = dom.createTextNode("DSGVO akzeptiert");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
          return morphs;
        },
        statements: [
          ["inline","input",[],["disabled",true,"type","checkbox","name","patient.consent","id","patient-consent","checked",true],["loc",[null,[532,24],[532,120]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child16 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 550,
              "column": 6
            },
            "end": {
              "line": 554,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(element1,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["showUploadForm"],[],["loc",[null,[551,11],[551,38]]]],
          ["inline","button-upload",[],["size","52","showStroke",false,"color","#ffffff","content","Neues Dokument hochladen"],["loc",[null,[552,10],[552,105]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child17 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 555,
              "column": 6
            },
            "end": {
              "line": 559,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button--cancel");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","button-delete-with-confirm",[],["item",["subexpr","@mut",[["get","customer",["loc",[null,[557,44],[557,52]]]]],[],[]],"confirmed","delete","size",52,"showStroke",false,"color","#fff","text","Wollen Sie diese/n Kunde/in wirklich löschen?"],["loc",[null,[557,10],[557,164]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child18 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 560,
              "column": 6
            },
            "end": {
              "line": 564,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["triggerSave"],[],["loc",[null,[561,13],[561,37]]]],
          ["inline","button-save",[],["color","#ffffff","size","70","content","Speichern"],["loc",[null,[562,12],[562,73]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child19 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 587,
              "column": 0
            },
            "end": {
              "line": 589,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","document-forward-panel",[],["actionReceiver",["subexpr","@mut",[["get","forwardDocumentPanel",["loc",[null,[588,42],[588,62]]]]],[],[]],"confirm","sendDocumentMail"],["loc",[null,[588,2],[588,91]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 633,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/customers/edit.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        dom.setAttribute(el1,"class","context-bar grid");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-8/24");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        dom.setAttribute(el2,"class","mb10");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","js-tabs tabs");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","tabs__head");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4,"class","u-mb0 clearfix");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-master-data");
        var el7 = dom.createTextNode("Stammdaten");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-reminder");
        var el7 = dom.createTextNode("Erinnerungen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-documents");
        var el7 = dom.createTextNode("Dokumente");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-patients");
        var el7 = dom.createTextNode("Tiere");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","js-tabs__label tabs__label title");
        dom.setAttribute(el6,"href","#tab-correspondence");
        var el7 = dom.createTextNode("Korrespondenz");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","js-tabs__label tabs__label title");
        dom.setAttribute(el6,"href","#tab-consents");
        var el7 = dom.createTextNode("Einverständniserklärungen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3,"class","tabs__body mt20");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4,"id","customerEdit");
        dom.setAttribute(el4,"class","admin-form");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-master-data");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-accounting");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mt0 mb20");
        var el7 = dom.createTextNode("Zahlungsinformationen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-payment-moral");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Zahlungsmoral");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-payment-moral");
        dom.setAttribute(el8,"class","field select");
        var el9 = dom.createTextNode("\n                              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("i");
        dom.setAttribute(el9,"class","arrow");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-11/24 gutter");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-sued");
        dom.setAttribute(el8,"class","field-label title required");
        var el9 = dom.createTextNode("Gesperrt weil Nichtzahler");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","checkbox-custom checkbox-primary field-checkbox");
        var el9 = dom.createTextNode("\n                              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-sued");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mb20");
        var el7 = dom.createTextNode("Rechnungsadresse");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-addition");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Briefzusatz");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-firstname");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Vorname");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-street");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Straße/Hausnummer");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-postalcode");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("PLZ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-uid");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("UID");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-11/24 gutter");
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-different-invoice");
        dom.setAttribute(el8,"class","field-label title required");
        var el9 = dom.createTextNode("Alternative\n                          Rechnungsadresse?");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","checkbox-custom checkbox-primary field-checkbox");
        var el9 = dom.createTextNode("\n                          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-different-invoice");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-lastname");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Nachname");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-title");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Titel");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-title");
        dom.setAttribute(el8,"class","field select");
        var el9 = dom.createTextNode("\n                          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("i");
        dom.setAttribute(el9,"class","arrow");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-town");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Ort");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mb20");
        var el7 = dom.createTextNode("Rechnungskonditionen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-conditions");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Konditionen");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-11/24 gutter");
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-reductionToPurchasePrice");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Rabatt zum Einkaufspreis");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","checkbox-custom checkbox-primary field-checkbox");
        var el9 = dom.createTextNode("\n                          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-reductionToPurchasePrice");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mb20");
        var el7 = dom.createTextNode("Bankverbindung");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-iban");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("IBAN");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-11/24 gutter");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-bic");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("BIC");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mb20");
        var el7 = dom.createTextNode("Rabattinformationen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mb20");
        var el7 = dom.createTextNode("Anfahrtsadresse");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-driveStreet");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Straße");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-drivePostalcode");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("PLZ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-distanceKm");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Entfernung in km");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-11/24 gutter");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-driveNotice");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Bezeichnung");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-driveTown");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Ort");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-distanceMinutes");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Entfernung in Minuten");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-warning");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mb20 mt0");
        var el7 = dom.createTextNode("Kundenspezifische Mahnzeiten");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field number-input");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-days-till-warning1");
        dom.setAttribute(el8,"class","field-label title");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        var el9 = dom.createTextNode("Tage bis 1. Mahnung verschickt wird. (Allgemeine Einstellung: ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("b");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode(" Tage");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(")");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field number-input");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-days-till-warning2");
        dom.setAttribute(el8,"class","field-label title");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        var el9 = dom.createTextNode("Tage bis 2. Mahnung verschickt wird. (Allgemeine Einstellung: ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("b");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode(" Tage");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(")");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field number-input");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-days-till-nopayer");
        dom.setAttribute(el8,"class","field-label title");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        var el9 = dom.createTextNode("Tage bis Kunde als Nichtzahler markiert wird (nach dem Verschicken der 2. Mahnung).  (Allgemeine Einstellung: ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("b");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode(" Tage");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(")");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","inline-icon");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","is-highlighted");
        var el7 = dom.createElement("b");
        var el8 = dom.createTextNode("Tipp:");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" Um das Mahnwesen für diesen Kunden zu deaktivieren muss in den entsprechenden Feldern ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("b");
        var el8 = dom.createTextNode("0");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" eingetragen werden.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-reminder");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","patient-category");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Übermittlungseinstellung");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","app-internal-person2");
        dom.setAttribute(el8,"class","field select");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("i");
        dom.setAttribute(el9,"class","arrow");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    Standardeinstellung: ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("b");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"class","tabs__content");
        dom.setAttribute(el5,"data-for","#tab-saldo");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table-wrapper mt20");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-medias");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-documents");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"class","tabs__content");
        dom.setAttribute(el5,"data-for","#tab-patients");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table-wrapper");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","quick-filter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","title");
        var el9 = dom.createTextNode("Schnellfilter");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        var el9 = dom.createTextNode("Alle anzeigen");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        var el9 = dom.createTextNode("Verstorbene ausblenden");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        var el9 = dom.createTextNode("Nur gelöschte");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table grid table--large");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__head");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-2/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-5/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Name");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-4/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Spezies");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-5/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Rasse");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-4/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Farbe");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-4/24");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__body");
        var el9 = dom.createTextNode("\n");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-appointments");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-6/24@desk mb30");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"for","filterUser");
        dom.setAttribute(el7,"class","input-element");
        dom.setAttribute(el7,"style","width: 100%");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","title");
        var el9 = dom.createTextNode("Kategorie");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"class","tabs__content");
        dom.setAttribute(el5,"data-for","#tab-correspondence");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"class","tabs__content");
        dom.setAttribute(el5,"data-for","#tab-consents");
        var el6 = dom.createTextNode("\n\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","action-icons");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","icon-button icon-button--small");
        dom.setAttribute(el3,"onclick","history.back()");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n    $(function() {\n        $('.datetimepicker').on('apply.daterangepicker', function(ev, picker) {\n            $(this).val(picker.startDate.format('DD. MM. YYYY'));\n            $(this).focus();\n        });\n        $('.datetimepicker').daterangepicker({\n            singleDatePicker: true,\n            autoUpdateInput: false,\n            \"locale\": {\n                \"format\": \"DD. MM. YYYY\",\n                \"daysOfWeek\": [\n                    \"So\",\n                    \"Mo\",\n                    \"Di\",\n                    \"Mi\",\n                    \"Do\",\n                    \"Fr\",\n                    \"Sa\"\n                ],\n                \"monthNames\": [\n                    \"Jänner\",\n                    \"Februar\",\n                    \"März\",\n                    \"April\",\n                    \"Mai\",\n                    \"Juni\",\n                    \"Juli\",\n                    \"August\",\n                    \"September\",\n                    \"Oktober\",\n                    \"November\",\n                    \"Dezember\"\n                ],\n                \"firstDay\": 1\n            },\n        });\n    });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element30 = dom.childAt(fragment, [0]);
        var element31 = dom.childAt(element30, [3]);
        var element32 = dom.childAt(fragment, [2]);
        var element33 = dom.childAt(element32, [1]);
        var element34 = dom.childAt(element32, [5]);
        var element35 = dom.childAt(element34, [1, 1]);
        var element36 = dom.childAt(element35, [1, 1]);
        var element37 = dom.childAt(element35, [5, 1]);
        var element38 = dom.childAt(element35, [7, 1]);
        var element39 = dom.childAt(element35, [9, 1]);
        var element40 = dom.childAt(element35, [13, 1]);
        var element41 = dom.childAt(element35, [15, 1]);
        var element42 = dom.childAt(element34, [3, 1]);
        var element43 = dom.childAt(element42, [1]);
        var element44 = dom.childAt(element42, [3]);
        var element45 = dom.childAt(element44, [9]);
        var element46 = dom.childAt(element45, [3]);
        var element47 = dom.childAt(element45, [5]);
        var element48 = dom.childAt(element45, [7]);
        var element49 = dom.childAt(element45, [9]);
        var element50 = dom.childAt(element44, [11]);
        var element51 = dom.childAt(element50, [3]);
        var element52 = dom.childAt(element50, [5]);
        var element53 = dom.childAt(element50, [7]);
        var element54 = dom.childAt(element44, [31]);
        var element55 = dom.childAt(element44, [33]);
        var element56 = dom.childAt(element42, [5]);
        var element57 = dom.childAt(element56, [3, 1]);
        var element58 = dom.childAt(element56, [5, 1]);
        var element59 = dom.childAt(element56, [7, 1]);
        var element60 = dom.childAt(element42, [7]);
        var element61 = dom.childAt(element60, [1]);
        var element62 = dom.childAt(element42, [11]);
        var element63 = dom.childAt(element42, [13]);
        var element64 = dom.childAt(element42, [15, 1]);
        var element65 = dom.childAt(element64, [1]);
        var element66 = dom.childAt(element65, [3]);
        var element67 = dom.childAt(element65, [5]);
        var element68 = dom.childAt(element65, [7]);
        var element69 = dom.childAt(element42, [17]);
        var element70 = dom.childAt(element42, [21]);
        var element71 = dom.childAt(element32, [7]);
        var morphs = new Array(103);
        morphs[0] = dom.createMorphAt(dom.childAt(element30, [1]),1,1);
        morphs[1] = dom.createAttrMorph(element31, 'class');
        morphs[2] = dom.createMorphAt(element31,1,1);
        morphs[3] = dom.createMorphAt(element31,2,2);
        morphs[4] = dom.createMorphAt(element31,3,3);
        morphs[5] = dom.createMorphAt(element31,4,4);
        morphs[6] = dom.createMorphAt(element31,5,5);
        morphs[7] = dom.createMorphAt(element31,6,6);
        morphs[8] = dom.createAttrMorph(element32, 'class');
        morphs[9] = dom.createMorphAt(element33,0,0);
        morphs[10] = dom.createMorphAt(element33,2,2);
        morphs[11] = dom.createMorphAt(element33,4,4);
        morphs[12] = dom.createMorphAt(element32,3,3);
        morphs[13] = dom.createAttrMorph(element36, 'class');
        morphs[14] = dom.createElementMorph(element36);
        morphs[15] = dom.createMorphAt(element35,3,3);
        morphs[16] = dom.createAttrMorph(element37, 'class');
        morphs[17] = dom.createElementMorph(element37);
        morphs[18] = dom.createAttrMorph(element38, 'class');
        morphs[19] = dom.createElementMorph(element38);
        morphs[20] = dom.createAttrMorph(element39, 'class');
        morphs[21] = dom.createElementMorph(element39);
        morphs[22] = dom.createMorphAt(element35,11,11);
        morphs[23] = dom.createElementMorph(element40);
        morphs[24] = dom.createElementMorph(element41);
        morphs[25] = dom.createElementMorph(element42);
        morphs[26] = dom.createAttrMorph(element43, 'class');
        morphs[27] = dom.createMorphAt(element43,1,1);
        morphs[28] = dom.createAttrMorph(element44, 'class');
        morphs[29] = dom.createMorphAt(dom.childAt(element44, [3, 1, 3]),1,1);
        morphs[30] = dom.createMorphAt(dom.childAt(element44, [5, 1, 3]),1,1);
        morphs[31] = dom.createMorphAt(dom.childAt(element45, [1]),3,3);
        morphs[32] = dom.createAttrMorph(element46, 'class');
        morphs[33] = dom.createMorphAt(element46,3,3);
        morphs[34] = dom.createAttrMorph(element47, 'class');
        morphs[35] = dom.createMorphAt(element47,3,3);
        morphs[36] = dom.createAttrMorph(element48, 'class');
        morphs[37] = dom.createMorphAt(element48,3,3);
        morphs[38] = dom.createAttrMorph(element49, 'class');
        morphs[39] = dom.createMorphAt(element49,3,3);
        morphs[40] = dom.createMorphAt(dom.childAt(element50, [1, 3]),1,1);
        morphs[41] = dom.createAttrMorph(element51, 'class');
        morphs[42] = dom.createMorphAt(element51,3,3);
        morphs[43] = dom.createAttrMorph(element52, 'class');
        morphs[44] = dom.createMorphAt(dom.childAt(element52, [3]),1,1);
        morphs[45] = dom.createAttrMorph(element53, 'class');
        morphs[46] = dom.createMorphAt(element53,3,3);
        morphs[47] = dom.createMorphAt(dom.childAt(element44, [15, 1]),3,3);
        morphs[48] = dom.createMorphAt(dom.childAt(element44, [17, 1, 3]),1,1);
        morphs[49] = dom.createMorphAt(dom.childAt(element44, [21, 1]),3,3);
        morphs[50] = dom.createMorphAt(dom.childAt(element44, [23, 1]),3,3);
        morphs[51] = dom.createMorphAt(element44,27,27);
        morphs[52] = dom.createMorphAt(dom.childAt(element54, [1]),3,3);
        morphs[53] = dom.createMorphAt(dom.childAt(element54, [3]),3,3);
        morphs[54] = dom.createMorphAt(dom.childAt(element54, [5]),3,3);
        morphs[55] = dom.createMorphAt(dom.childAt(element55, [1]),3,3);
        morphs[56] = dom.createMorphAt(dom.childAt(element55, [3]),3,3);
        morphs[57] = dom.createMorphAt(dom.childAt(element55, [5]),3,3);
        morphs[58] = dom.createAttrMorph(element56, 'class');
        morphs[59] = dom.createMorphAt(element57,3,3);
        morphs[60] = dom.createMorphAt(dom.childAt(element57, [5, 1]),0,0);
        morphs[61] = dom.createMorphAt(element58,3,3);
        morphs[62] = dom.createMorphAt(dom.childAt(element58, [5, 1]),0,0);
        morphs[63] = dom.createMorphAt(element59,3,3);
        morphs[64] = dom.createMorphAt(dom.childAt(element59, [5, 1]),0,0);
        morphs[65] = dom.createMorphAt(dom.childAt(element56, [9]),0,0);
        morphs[66] = dom.createAttrMorph(element60, 'class');
        morphs[67] = dom.createMorphAt(dom.childAt(element61, [1, 3]),1,1);
        morphs[68] = dom.createMorphAt(dom.childAt(element61, [3]),0,0);
        morphs[69] = dom.createMorphAt(dom.childAt(element42, [9, 1]),1,1);
        morphs[70] = dom.createAttrMorph(element62, 'class');
        morphs[71] = dom.createMorphAt(element62,1,1);
        morphs[72] = dom.createAttrMorph(element63, 'class');
        morphs[73] = dom.createMorphAt(element63,1,1);
        morphs[74] = dom.createMorphAt(element63,3,3);
        morphs[75] = dom.createAttrMorph(element66, 'class');
        morphs[76] = dom.createElementMorph(element66);
        morphs[77] = dom.createAttrMorph(element67, 'class');
        morphs[78] = dom.createElementMorph(element67);
        morphs[79] = dom.createAttrMorph(element68, 'class');
        morphs[80] = dom.createElementMorph(element68);
        morphs[81] = dom.createMorphAt(dom.childAt(element64, [3, 3]),1,1);
        morphs[82] = dom.createAttrMorph(element69, 'class');
        morphs[83] = dom.createMorphAt(dom.childAt(element69, [1, 1]),3,3);
        morphs[84] = dom.createMorphAt(element69,3,3);
        morphs[85] = dom.createMorphAt(dom.childAt(element42, [19]),1,1);
        morphs[86] = dom.createMorphAt(element70,1,1);
        morphs[87] = dom.createMorphAt(element70,3,3);
        morphs[88] = dom.createMorphAt(dom.childAt(element71, [1]),1,1);
        morphs[89] = dom.createMorphAt(element71,3,3);
        morphs[90] = dom.createMorphAt(element71,4,4);
        morphs[91] = dom.createMorphAt(element71,5,5);
        morphs[92] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[93] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[94] = dom.createMorphAt(fragment,9,9,contextualElement);
        morphs[95] = dom.createMorphAt(fragment,11,11,contextualElement);
        morphs[96] = dom.createMorphAt(fragment,13,13,contextualElement);
        morphs[97] = dom.createMorphAt(fragment,15,15,contextualElement);
        morphs[98] = dom.createMorphAt(fragment,17,17,contextualElement);
        morphs[99] = dom.createMorphAt(fragment,19,19,contextualElement);
        morphs[100] = dom.createMorphAt(fragment,21,21,contextualElement);
        morphs[101] = dom.createMorphAt(fragment,23,23,contextualElement);
        morphs[102] = dom.createMorphAt(fragment,25,25,contextualElement);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[3,14],[3,23]]]],"documents"],[],["loc",[null,[3,10],[3,36]]]]],[],0,null,["loc",[null,[3,4],[6,11]]]],
        ["attribute","class",["concat",["buttons-box col l-",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[8,53],[8,66]]]],"10","16"],[],["loc",[null,[8,32],[8,78]]]],"/24"]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[9,14],[9,23]]]],"masterdata"],[],["loc",[null,[9,10],[9,37]]]]],[],1,null,["loc",[null,[9,4],[26,11]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[27,14],[27,23]]]],"documents"],[],["loc",[null,[27,10],[27,36]]]]],[],2,null,["loc",[null,[27,4],[37,11]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[38,14],[38,23]]]],"patients"],[],["loc",[null,[38,10],[38,35]]]]],[],3,null,["loc",[null,[38,4],[42,11]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[43,14],[43,23]]]],"appointments"],[],["loc",[null,[43,10],[43,39]]]]],[],4,null,["loc",[null,[43,4],[47,11]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[48,14],[48,23]]]],"saldo"],[],["loc",[null,[48,10],[48,32]]]]],[],5,null,["loc",[null,[48,4],[59,11]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[60,14],[60,23]]]],"consents"],[],["loc",[null,[60,10],[60,35]]]]],[],6,null,["loc",[null,[60,4],[64,11]]]],
        ["attribute","class",["concat",["site-content status status-",["subexpr","get-payment-moral",[["get","model",["loc",[null,[68,63],[68,68]]]],true],[],["loc",[null,[68,43],[68,75]]]]," card transaction-filter with-context-bar "]]],
        ["content","model.firstname",["loc",[null,[69,21],[69,40]]]],
        ["content","model.lastname",["loc",[null,[69,41],[69,59]]]],
        ["block","if",[["get","model.etigaAppConnected",["loc",[null,[69,66],[69,89]]]]],[],7,8,["loc",[null,[69,60],[69,369]]]],
        ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[70,8],[70,21]]]]],[],9,null,["loc",[null,[70,2],[77,9]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[83,88],[83,97]]]],"masterdata"],[],["loc",[null,[83,84],[83,111]]]],"is-active",""],[],["loc",[null,[83,63],[83,128]]]]]]],
        ["element","action",["selectTab","masterdata"],[],["loc",[null,[83,154],[83,189]]]],
        ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[85,20],[85,33]]]]],[],10,null,["loc",[null,[85,14],[96,21]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[98,84],[98,93]]]],"reminder"],[],["loc",[null,[98,80],[98,105]]]],"is-active",""],[],["loc",[null,[98,59],[98,122]]]]]]],
        ["element","action",["selectTab","reminder"],[],["loc",[null,[98,145],[98,178]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[101,86],[101,95]]]],"documents"],[],["loc",[null,[101,82],[101,108]]]],"is-active",""],[],["loc",[null,[101,61],[101,125]]]]]]],
        ["element","action",["selectTab","documents"],[],["loc",[null,[101,149],[101,183]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[104,88],[104,97]]]],"patients"],[],["loc",[null,[104,84],[104,109]]]],"is-active",""],[],["loc",[null,[104,63],[104,126]]]]]]],
        ["element","action",["selectTab","patients"],[],["loc",[null,[104,149],[104,182]]]],
        ["block","if",[["subexpr","has-access",["calendar"],[],["loc",[null,[106,6],[106,29]]]]],[],11,null,["loc",[null,[106,0],[110,7]]]],
        ["element","action",["selectTab","correspondance"],[],["loc",[null,[112,91],[112,130]]]],
        ["element","action",["selectTab","consents"],[],["loc",[null,[115,85],[115,118]]]],
        ["element","action",["save",["get","referer",["loc",[null,[120,71],[120,78]]]],["get","refererInvoiceId",["loc",[null,[120,79],[120,95]]]]],["on","submit"],["loc",[null,[120,55],[120,109]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[121,66],[121,75]]]],"masterdata"],[],["loc",[null,[121,62],[121,89]]]],"is-active",""],[],["loc",[null,[121,41],[121,106]]]]]]],
        ["inline","customers-edit-form",[],["edit",true,"model",["subexpr","@mut",[["get","model",["loc",[null,[122,56],[122,61]]]]],[],[]],"genders",["subexpr","@mut",[["get","genders",["loc",[null,[122,70],[122,77]]]]],[],[]],"customerSalutations",["subexpr","@mut",[["get","customerSalutations",["loc",[null,[122,98],[122,117]]]]],[],[]],"countries",["subexpr","@mut",[["get","countries",["loc",[null,[122,128],[122,137]]]]],[],[]],"titles",["subexpr","@mut",[["get","titles",["loc",[null,[122,145],[122,151]]]]],[],[]],"setInputDirty","setInputDirty","addEmail","addEmail","removeEmail","removeEmail","addPhone","addPhone","removePhone","removePhone","addFax","addFax","removeFax","removeFax"],["loc",[null,[122,18],[122,313]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[124,66],[124,75]]]],"accounting"],[],["loc",[null,[124,62],[124,89]]]],"is-active",""],[],["loc",[null,[124,41],[124,106]]]]]]],
        ["inline","view",[["get","Ember.Select",["loc",[null,[130,37],[130,49]]]]],["content",["subexpr","@mut",[["get","paymentMorals",["loc",[null,[131,38],[131,51]]]]],[],[]],"optionValuePath","content.id","optionLabelPath","content.name","prompt","Bitte wählen","name","user-payment-moral","selection",["subexpr","@mut",[["get","selectedPaymentMoral",["loc",[null,[136,40],[136,60]]]]],[],[]]],["loc",[null,[130,30],[137,32]]]],
        ["inline","input",[],["type","checkbox","name","user.sued","id","user-sued","checked",["subexpr","@mut",[["get","model.sued",["loc",[null,[146,94],[146,104]]]]],[],[]]],["loc",[null,[146,30],[146,106]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.invoiceAddition",["loc",[null,[158,38],[158,59]]]]],[],[]],"class","gui-input","name","user-invoice-addition"],["loc",[null,[158,24],[158,108]]]],
        ["attribute","class",["concat",["field ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[160,63],[160,85]]]],"","hidden"],[],["loc",[null,[160,42],[160,100]]]]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.invoiceFirstname",["loc",[null,[162,40],[162,62]]]]],[],[]],"class","gui-input","name","user-invoice-firstname"],["loc",[null,[162,26],[162,112]]]],
        ["attribute","class",["concat",["field ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[164,63],[164,85]]]],"","hidden"],[],["loc",[null,[164,42],[164,100]]]]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.invoiceStreet",["loc",[null,[166,40],[166,59]]]]],[],[]],"class","gui-input","name","user-invoice-street"],["loc",[null,[166,26],[166,106]]]],
        ["attribute","class",["concat",["field ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[168,63],[168,85]]]],"","hidden"],[],["loc",[null,[168,42],[168,100]]]]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.invoicePostalcode",["loc",[null,[170,40],[170,63]]]]],[],[]],"class","gui-input","name","user-invoice-postalcode"],["loc",[null,[170,26],[170,114]]]],
        ["attribute","class",["concat",["field ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[172,63],[172,85]]]],"","hidden"],[],["loc",[null,[172,42],[172,100]]]]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.invoiceUid",["loc",[null,[174,40],[174,56]]]]],[],[]],"class","gui-input","name","user-invoice-uid"],["loc",[null,[174,26],[174,100]]]],
        ["inline","input",[],["type","checkbox","name","user-different-invoice","id","user-different-invoice","checked",["subexpr","@mut",[["get","model.differentInvoice",["loc",[null,[182,116],[182,138]]]]],[],[]]],["loc",[null,[182,26],[182,140]]]],
        ["attribute","class",["concat",["field ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[186,63],[186,85]]]],"","hidden"],[],["loc",[null,[186,42],[186,100]]]]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.invoiceLastname",["loc",[null,[188,40],[188,61]]]]],[],[]],"class","gui-input","name","user-invoice-lastname"],["loc",[null,[188,26],[188,110]]]],
        ["attribute","class",["concat",["field ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[190,61],[190,83]]]],"","hidden"],[],["loc",[null,[190,40],[190,98]]]]]]],
        ["inline","view",[["get","Ember.Select",["loc",[null,[193,33],[193,45]]]]],["content",["subexpr","@mut",[["get","titles",["loc",[null,[194,41],[194,47]]]]],[],[]],"optionValuePath","content.id","optionLabelPath","content.name","prompt","Bitte wählen","name","user-invoice-title","selection",["subexpr","@mut",[["get","model.invoiceTitle",["loc",[null,[199,43],[199,61]]]]],[],[]]],["loc",[null,[193,26],[200,28]]]],
        ["attribute","class",["concat",["field ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[204,63],[204,85]]]],"","hidden"],[],["loc",[null,[204,42],[204,100]]]]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.invoiceTown",["loc",[null,[206,40],[206,57]]]]],[],[]],"class","gui-input","name","user-invoice-town"],["loc",[null,[206,26],[206,102]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.invoiceConditions",["loc",[null,[215,40],[215,63]]]]],[],[]],"class","gui-input","name","user-invoice-conditions"],["loc",[null,[215,26],[215,114]]]],
        ["inline","input",[],["type","checkbox","name","user.reductionToPurchasePrice","id","user-reductionToPurchasePrice","checked",["subexpr","@mut",[["get","model.reductionToPurchasePrice",["loc",[null,[223,130],[223,160]]]]],[],[]]],["loc",[null,[223,26],[223,162]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.iban",["loc",[null,[234,40],[234,50]]]]],[],[]],"class","gui-input","name","user-iban","id","user-iban"],["loc",[null,[234,26],[234,102]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.bic",["loc",[null,[240,40],[240,49]]]]],[],[]],"class","gui-input","name","user-bic","id","user-bic"],["loc",[null,[240,26],[240,99]]]],
        ["block","each",[["get","model.reductions",["loc",[null,[246,26],[246,42]]]]],[],12,null,["loc",[null,[246,18],[327,27]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.driveStreet",["loc",[null,[333,40],[333,57]]]]],[],[]],"class","gui-input","name","user-driveStreet","id","user-driveStreet"],["loc",[null,[333,26],[333,123]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.drivePostalcode",["loc",[null,[337,40],[337,61]]]]],[],[]],"class","gui-input","name","user-drive-postalcode","title","Bitte geben Sie eine gültige PLZ an"],["loc",[null,[337,26],[337,154]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.distanceKm",["loc",[null,[341,40],[341,56]]]]],[],[]],"class","gui-input","name","user-drive-distanceKm"],["loc",[null,[341,26],[341,105]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.driveNotice",["loc",[null,[347,40],[347,57]]]]],[],[]],"class","gui-input","name","user-driveNotice","id","user-driveNotice"],["loc",[null,[347,26],[347,123]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.driveTown",["loc",[null,[351,40],[351,55]]]]],[],[]],"class","gui-input","name","user-driveTown","id","user-driveTown"],["loc",[null,[351,26],[351,117]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.distanceMinutes",["loc",[null,[355,40],[355,61]]]]],[],[]],"class","gui-input","name","user-drive-distanceMinutes"],["loc",[null,[355,26],[355,115]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[359,66],[359,75]]]],"warning"],[],["loc",[null,[359,62],[359,86]]]],"is-active",""],[],["loc",[null,[359,41],[359,103]]]]]]],
        ["inline","input",[],["type","number","min",0,"step",1,"max",60,"value",["subexpr","@mut",[["get","model.daysTillWarning1",["loc",[null,[365,70],[365,92]]]]],[],[]],"autofocus",true,"class","gui-input newStyle","title","","name","mandant-days-till-warning1"],["loc",[null,[365,22],[365,179]]]],
        ["content","model.defaultDaysTillWarning1",["loc",[null,[365,251],[365,284]]]],
        ["inline","input",[],["type","number","min",0,"step",1,"max",60,"value",["subexpr","@mut",[["get","model.daysTillWarning2",["loc",[null,[371,70],[371,92]]]]],[],[]],"autofocus",true,"class","gui-input newStyle","title","","name","mandant-days-till-warning2"],["loc",[null,[371,22],[371,179]]]],
        ["content","model.defaultDaysTillWarning2",["loc",[null,[371,251],[371,284]]]],
        ["inline","input",[],["type","number","min",0,"step",1,"max",60,"value",["subexpr","@mut",[["get","model.daysTillNopayer",["loc",[null,[377,70],[377,91]]]]],[],[]],"autofocus",true,"class","gui-input newStyle","title","","name","mandant-days-till-nopayer"],["loc",[null,[377,22],[377,177]]]],
        ["content","model.defaultDaysTillNopayer",["loc",[null,[377,297],[377,329]]]],
        ["inline","icon-info",[],["color","#008EC6","size","24"],["loc",[null,[381,44],[381,83]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[384,66],[384,75]]]],"reminder"],[],["loc",[null,[384,62],[384,87]]]],"is-active",""],[],["loc",[null,[384,41],[384,104]]]]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","deliveryOptions",["loc",[null,[390,34],[390,49]]]]],[],[]],"value",["subexpr","@mut",[["get","model.reminderSetting",["loc",[null,[391,32],[391,53]]]]],[],[]],"cssClass","custom-select2 newStyle","optionLabelPath","value","optionValuePath","id","allowClear","true","placeholder","Standardeinstellungen beibehalten","name","customer.reminderSetting"],["loc",[null,[389,24],[398,26]]]],
        ["content","model.defaultReminderSetting",["loc",[null,[402,44],[402,76]]]],
        ["inline","customer-saldo",[],["deposits",["subexpr","@mut",[["get","deposits",["loc",[null,[408,50],[408,58]]]]],[],[]],"balanceSum",["subexpr","@mut",[["get","balanceSum",["loc",[null,[408,70],[408,80]]]]],[],[]],"depositSum",["subexpr","@mut",[["get","depositSum",["loc",[null,[408,92],[408,102]]]]],[],[]],"banktransferEnabled",["subexpr","@mut",[["get","banktransferEnabled",["loc",[null,[408,123],[408,142]]]]],[],[]]],["loc",[null,[408,24],[408,144]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[411,64],[411,73]]]],"medias"],[],["loc",[null,[411,60],[411,83]]]],"is-active",""],[],["loc",[null,[411,39],[411,100]]]]]]],
        ["inline","patient-medias",[],["items",["subexpr","@mut",[["get","medias",["loc",[null,[412,41],[412,47]]]]],[],[]],"deleteMedia","deleteMedia","editable",true,"forwardEmail","openDocumentForwardPanel","reloadMedias","reloadMedias"],["loc",[null,[412,18],[412,158]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[414,64],[414,73]]]],"documents"],[],["loc",[null,[414,60],[414,86]]]],"is-active",""],[],["loc",[null,[414,39],[414,103]]]]]]],
        ["block","if",[["get","showFilters",["loc",[null,[415,22],[415,33]]]]],[],13,null,["loc",[null,[415,16],[420,23]]]],
        ["inline","documents-panel",[],["showSearch",false,"showBreadcrumbs",true,"updateSearch","updateSearch","forwardLab","openEmailForwardPanel","actionReceiver",["subexpr","@mut",[["get","documentsPanel",["loc",[null,[426,33],[426,47]]]]],[],[]]],["loc",[null,[421,16],[426,49]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[432,77],[432,88]]]],"all"],[],["loc",[null,[432,73],[432,95]]]],"selected",""],[],["loc",[null,[432,52],[432,112]]]]]]],
        ["element","action",["toggleQuickFilter","all"],[],["loc",[null,[432,114],[432,150]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[433,77],[433,88]]]],"hideDead"],[],["loc",[null,[433,73],[433,100]]]],"selected",""],[],["loc",[null,[433,52],[433,117]]]]]]],
        ["element","action",["toggleQuickFilter","hideDead"],[],["loc",[null,[433,119],[433,160]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[434,77],[434,88]]]],"deleted"],[],["loc",[null,[434,73],[434,99]]]],"selected",""],[],["loc",[null,[434,52],[434,116]]]]]]],
        ["element","action",["toggleQuickFilter","deleted"],[],["loc",[null,[434,118],[434,158]]]],
        ["block","each",[["get","patientList",["loc",[null,[447,32],[447,43]]]]],[],14,null,["loc",[null,[447,24],[499,33]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[504,64],[504,73]]]],"appointments"],[],["loc",[null,[504,60],[504,89]]]],"is-active",""],[],["loc",[null,[504,39],[504,106]]]]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","patientAppointmentCategories",["loc",[null,[509,30],[509,58]]]]],[],[]],"value",["subexpr","@mut",[["get","filterCategory",["loc",[null,[510,28],[510,42]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","optionValuePath","id","allowClear",true,"placeholder","Alle"],["loc",[null,[508,20],[516,22]]]],
        ["inline","patient-appointments",[],["startTreatment","startTreatment","referer","patient","delete","deleteAppointment","edit","openEditAppointment","showAppointment","showAppointment","stickyHeader",false,"items",["subexpr","@mut",[["get","appointments",["loc",[null,[520,203],[520,215]]]]],[],[]]],["loc",[null,[520,16],[520,217]]]],
        ["inline","customer-correspondance",[],["items",["subexpr","@mut",[["get","logbookEntries",["loc",[null,[523,50],[523,64]]]]],[],[]],"content",["subexpr","@mut",[["get","content",["loc",[null,[523,73],[523,80]]]]],[],[]]],["loc",[null,[523,18],[523,82]]]],
        ["block","if",[["get","model.consent",["loc",[null,[527,24],[527,37]]]]],[],15,null,["loc",[null,[527,18],[538,25]]]],
        ["inline","customer-consents",[],["actionReceiver",["subexpr","@mut",[["get","customerConsentsComponent",["loc",[null,[540,53],[540,78]]]]],[],[]]],["loc",[null,[540,18],[540,80]]]],
        ["content","button-prev",["loc",[null,[547,10],[547,25]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[550,16],[550,25]]]],"medias"],[],["loc",[null,[550,12],[550,35]]]]],[],16,null,["loc",[null,[550,6],[554,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[555,16],[555,25]]]],"masterdata"],[],["loc",[null,[555,12],[555,39]]]]],[],17,null,["loc",[null,[555,6],[559,13]]]],
        ["block","if",[["subexpr","or",[["subexpr","eq",[["get","selectTab",["loc",[null,[560,20],[560,29]]]],"masterdata"],[],["loc",[null,[560,16],[560,43]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[560,48],[560,57]]]],"warning"],[],["loc",[null,[560,44],[560,68]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[560,73],[560,82]]]],"accounting"],[],["loc",[null,[560,69],[560,96]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[560,101],[560,110]]]],"reminder"],[],["loc",[null,[560,97],[560,122]]]]],[],["loc",[null,[560,12],[560,123]]]]],[],18,null,["loc",[null,[560,6],[564,13]]]],
        ["inline","email-invoice-panel",[],["actionReceiver",["subexpr","@mut",[["get","forwardEmailPanel",["loc",[null,[568,37],[568,54]]]]],[],[]],"confirm","sendEmail"],["loc",[null,[568,0],[568,76]]]],
        ["inline","customer-payout-deposit-panel",[],["actionReceiver",["subexpr","@mut",[["get","depositPanel",["loc",[null,[571,17],[571,29]]]]],[],[]],"totalDeposit",["subexpr","@mut",[["get","depositSum",["loc",[null,[572,15],[572,25]]]]],[],[]],"payout","payout","actionReceiver",["subexpr","@mut",[["get","customerPayoutDepositPanel",["loc",[null,[574,17],[574,43]]]]],[],[]]],["loc",[null,[570,0],[574,45]]]],
        ["inline","patient-select-barcode-panel",[],["download","downloadBarcode","actionReceiver",["subexpr","@mut",[["get","patientSelectBarcodePanel",["loc",[null,[578,73],[578,98]]]]],[],[]]],["loc",[null,[578,0],[578,100]]]],
        ["inline","show-appointment-panel",[],["actionReceiver",["subexpr","@mut",[["get","showAppointmentPanel",["loc",[null,[579,40],[579,60]]]]],[],[]]],["loc",[null,[579,0],[579,62]]]],
        ["inline","customer-revenue-panel",[],["actionReceiver",["subexpr","@mut",[["get","customerRevenuePanel",["loc",[null,[580,40],[580,60]]]]],[],[]]],["loc",[null,[580,0],[580,62]]]],
        ["inline","consent-upload-panel",[],["uploadDone","consentUploadDone","actionReceiver",["subexpr","@mut",[["get","consentUploadPanel",["loc",[null,[581,69],[581,87]]]]],[],[]]],["loc",[null,[581,0],[581,89]]]],
        ["inline","add-appointment-panel",[],["doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[583,32],[583,39]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[583,51],[583,61]]]]],[],[]],"resources",["subexpr","@mut",[["get","resources",["loc",[null,[583,72],[583,81]]]]],[],[]],"rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[583,88],[583,93]]]]],[],[]],"stations",["subexpr","@mut",[["get","stations",["loc",[null,[583,103],[583,111]]]]],[],[]],"users",["subexpr","@mut",[["get","users",["loc",[null,[583,118],[583,123]]]]],[],[]],"create","createAppointment","actionReceiver",["subexpr","@mut",[["get","addAppointmentPanel",["loc",[null,[583,166],[583,185]]]]],[],[]]],["loc",[null,[583,0],[583,187]]]],
        ["inline","media-upload-panel",[],["actionReceiver",["subexpr","@mut",[["get","mediaPanel",["loc",[null,[585,36],[585,46]]]]],[],[]],"customer",["subexpr","@mut",[["get","model",["loc",[null,[585,56],[585,61]]]]],[],[]],"mediaCategories",["subexpr","@mut",[["get","mediaCategories",["loc",[null,[585,78],[585,93]]]]],[],[]],"reloadMedias","reloadMedias"],["loc",[null,[585,0],[585,123]]]],
        ["block","if",[["subexpr","not",[["get","newMode",["loc",[null,[587,11],[587,18]]]]],[],["loc",[null,[587,6],[587,19]]]]],[],19,null,["loc",[null,[587,0],[589,7]]]],
        ["inline","create-patient-panel",[],["patientCategories",["subexpr","@mut",[["get","patientCategories",["loc",[null,[591,41],[591,58]]]]],[],[]],"patientGenders",["subexpr","@mut",[["get","patientGenders",["loc",[null,[591,74],[591,88]]]]],[],[]],"createPatient","createPatient","actionReceiver",["subexpr","@mut",[["get","createPatientPanel",["loc",[null,[591,134],[591,152]]]]],[],[]]],["loc",[null,[591,0],[591,154]]]],
        ["inline","merge-customer-panel",[],["gotoCustomer","gotoCustomer","actionReceiver",["subexpr","@mut",[["get","mergeCustomerPanel",["loc",[null,[592,66],[592,84]]]]],[],[]]],["loc",[null,[592,0],[592,86]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18, child19]
    };
  }()));

});