define('jason-frontend/components/invoice-entry', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    classNames: ['table__row'],
    doctor: null,
    assistant: null,
    invoiceId: null,
    day: null,
    entry: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.initTooltips();
    },

    initTooltips: function initTooltips() {
      Ember['default'].run.scheduleOnce('afterRender', this, function () {
        this.$('.tooltipstered').tooltipster({
          theme: 'tooltipster-punk',
          delay: 0,
          side: 'top',
          touchDevices: false
        });
      });
    },
    actions: {
      moveUp: function moveUp(entryId) {
        this.sendAction('moveUp', entryId);
      },
      moveDown: function moveDown(entryId) {
        this.sendAction('moveDown', entryId);
      },
      openInventoryStockPanel: function openInventoryStockPanel(item) {
        this.sendAction('openInventoryStockPanel', item, true);
      },
      downloadSlopingBarcode: function downloadSlopingBarcode(id) {
        this.sendAction('downloadSlopingBarcode', id);
      },
      downloadBarcode: function downloadBarcode(id) {
        this.sendAction('downloadBarcode', id);
      },
      openSlopingInfos: function openSlopingInfos(item) {
        this.sendAction('openSlopingInfos', item);
      },
      openTemplatePanel: function openTemplatePanel(entryId, instanceId, readOnly, patient) {
        var patientId = 0;
        if (patient) {
          patientId = patient.id;
        }
        this.sendAction('openTemplateEdit', entryId, instanceId, readOnly, patientId);
      },
      toggle: function toggle(entry) {
        var secondRow = $("div.table__second-row[data-id=" + entry.id + "]");
        if (secondRow.hasClass('hidden')) {
          secondRow.removeClass('hidden');
        } else {
          secondRow.addClass('hidden');
        }
      },
      changeText: function changeText(entry, invoiceId, productName, oldText) {
        var text = this.$().find("input[data-article-text-entry-id='" + entry.id + "']").val();
        if (oldText !== text) {
          this.sendAction('textChanged', text, entry.id, invoiceId, productName);
        }
      },
      changeUser: function changeUser(entry, invoiceId, typeId) {
        var userId = 0;
        if (typeId === 'doctor') {
          userId = this.$().find("input[data-doctor-entry-id='" + entry.id + "']").val();
        } else if (typeId === 'assistant') {
          userId = this.$().find("input[data-assistant-entry-id='" + entry.id + "']").val();
        }
        if (userId > 0 && typeId) {
          this.sendAction('changeUser', invoiceId, entry, userId, typeId);
        }
      },
      increaseQuantity: function increaseQuantity(entry, invoiceId, productName) {
        var qty = parseFloat(this.$().find("input[data-qty-entry-id='" + entry.id + "']").val());
        this.sendAction('quantityChanged', qty + 1, entry.id, invoiceId, productName);
      },
      decreaseQuantity: function decreaseQuantity(entry, invoiceId, productName) {
        var qty = parseFloat(this.$().find("input[data-qty-entry-id='" + entry.id + "']").val());
        this.sendAction('quantityChanged', qty - 1, entry.id, invoiceId, productName);
      },
      changePrice: function changePrice(entry, invoiceId, productName) {
        var price = this.$().find("input[data-price-entry-id='" + entry.id + "']").val();
        price = price.replace(',', '.');
        if (price > 0) {
          this.sendAction('priceChanged', price, entry.id, invoiceId, productName);
        } else {
          jason.notifiction.error('Fehleingabe', 'Preis muss größer/gleich 0 sein');
        }
      },
      changeQuantity: function changeQuantity(entry, invoiceId, productName, oldQty) {
        var qty = this.$().find("input[data-qty-entry-id='" + entry.id + "']").val();
        if (oldQty != qty) {
          qty = String(qty).replace(',', '.');
          if (qty > 0) {
            this.sendAction('quantityChanged', qty, entry.id, invoiceId, productName);
          } else {
            jason.notifiction.error('Fehleingabe', 'Menge muss größer/gleich 0 sein');
          }
        }
      },
      changeGrossPrice: function changeGrossPrice(entry, percentage, invoiceId, productName) {
        var grossPrice = String(this.$().find("input[data-gross-price-entry-id='" + entry.id + "']").val()).replace(',', '.');
        var netPrice = this.$().find("input[data-price-entry-id='" + entry.id + "']"),
            newNetPrice = Math.round(grossPrice / (100 + percentage) * 100 * 1000) / 1000;

        if (newNetPrice > 0) {
          netPrice.val(String(newNetPrice).replace('.', ','));
          this.sendAction('priceChanged', newNetPrice, entry.id, invoiceId, productName);
        } else {
          jason.notifiction.error('Fehleingabe', 'Preis muss größer/gleich 0 sein');
        }
      },
      changeApplied: function changeApplied(entry, invoiceId, productName) {
        var applied = this.$().find("input[data-applied-entry-id='" + entry.id + "']").val();
        if (applied === 'false') {
          applied = "true";
        } else {
          applied = "false";
        }
        this.sendAction('appliedChanged', applied, entry.id, invoiceId, productName);
      },
      changeDiscount: function changeDiscount(entry, invoiceId, absolute, productName, oldDiscount) {
        var discount = this.$().find("input[data-discount-entry-id='" + entry.id + "']").val();
        if (oldDiscount != discount) {
          if (discount.replace(',', '.') >= 0) {
            this.sendAction('discountChanged', discount, absolute, entry.id, invoiceId, productName);
          }
        }
      },
      changeBankadvice: function changeBankadvice(entry, invoiceId, productName) {
        var advice = this.$().find("input[data-bankadvice-entry-id='" + entry.id + "']").val();
        if (advice === 'false') {
          advice = "true";
        } else {
          advice = "false";
        }
        this.sendAction('bankadviceChanged', advice, entry.id, invoiceId, productName);
      },
      setRelativeDiscount: function setRelativeDiscount(entry, invoiceId, discount, absolute, productName) {
        if (absolute) {
          this.sendAction('relativeDiscountChanged', entry.id, discount, invoiceId, productName);
        }
      },
      setAbsoluteDiscount: function setAbsoluteDiscount(entry, invoiceId, discount, absolute, productName) {
        if (!absolute) {
          this.sendAction('absoluteDiscountChanged', entry.id, discount, invoiceId, productName);
        }
      },
      'delete': function _delete(entryId, invoiceId, productName) {
        this.sendAction('deleted', entryId, invoiceId, productName);
      },
      changeDay: function changeDay(entry, invoiceId) {
        var day = this.$().find("input[data-day-entry-id='" + entry.id + "']").val();
        if (day !== entry.get('day')) {
          this.sendAction('changeDay', invoiceId, day, entry.id);
        }
      }
    },
    doctorOberserver: (function () {
      var self = $(this);

      self.removeClass('unchanged');

      var data = {
        userId: this.get('doctor') ? this.get('doctor').id : null,
        type: 'doctor'
      };

      $.ajax({
        url: "/api/invoices/" + this.get('invoiceId') + "/changeUser/" + this.get('entry').get('id'),
        method: "POST",
        contentType: "application/json",
        data: JSON.stringify(data),
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {}, function (error) {});
    }).observes('doctor'),
    assistantOberserver: (function () {
      var self = $(this);

      self.removeClass('unchanged');

      var data = {
        userId: this.get('assistant') ? this.get('assistant').id : null,
        type: 'assistant'
      };

      $.ajax({
        url: "/api/invoices/" + this.get('invoiceId') + "/changeUser/" + this.get('entry').get('id'),
        method: "POST",
        contentType: "application/json",
        data: JSON.stringify(data),
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {}, function (error) {});
    }).observes('assistant')

  });

});