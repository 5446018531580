define('jason-frontend/routes/resourcemgmt/index', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    save: function save() {
      var controller = this.get('controller');
      var data = controller.get('model');
      var self = this;
      $.ajax({
        url: "/api/resourceWeeks?year=" + controller.get('year') + "&weekNumber=" + controller.get('weekNumber'),
        method: "POST",
        contentType: "application/json",
        data: JSON.stringify(data),
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        jason.notifiction.notifiy('Erfolgreich', 'Gespeichert');
        self.applyFilter();
      }, function (data) {});
    },
    setupController: function setupController(controller, model, params) {
      var year = new Date().getFullYear();
      var all = new Array();

      var weekNumber = moment().weeksInYear();
      for (var i = 1; i <= weekNumber; i++) {
        all.push({
          id: i,
          name: i
        });
      }

      controller.set('weeks', all);

      controller.set('userRoles', this.store.find('userRole'));

      this.applyFilter();
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller');
      controller.set('loading', true);
      var self = this;
      $.ajax({
        url: "/api/resourceWeeks?year=" + controller.get('year') + "&weekNumber=" + controller.get('weekNumber'),
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('model', data.resourceWeek);
        controller.set('loading', false);
        setTimeout(function () {
          self.roleUpdated();
        }, 1000);
      }, function (data) {
        controller.set('loading', false);
      });
    },
    roleUpdated: function roleUpdated() {
      var role = this.get('controller').get('role');
      if (role) {
        $('.table__row').addClass('hidden');
        $('.table__row.' + role).removeClass('hidden');
      } else {
        $('.table__row').removeClass('hidden');
      }
    },
    actions: {
      gotoDate: function gotoDate($date) {
        var momentDate = moment($date);
        var targetWeek = momentDate.isoWeek().toString();
        this.get('controller').set('weekNumber', targetWeek);
      },
      openMiniCalendar: function openMiniCalendar() {
        $('#miniCalendarTrigger').click();
      },
      roleUpdated: function roleUpdated() {
        this.roleUpdated();
      },
      save: function save() {
        this.save();
      },
      openEdit: function openEdit(row) {
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-resource-day-panel'
          },
          callbacks: {
            close: function close() {},
            open: function open() {
              $('html').css('overflow', 'auto').css('margin-right', '0');
            },
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });

        this.get('controller').get('editResourceDayPanel').send('load', row);
      },
      openCopyPanel: function openCopyPanel(user) {
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-resource-panel'
          },
          callbacks: {
            close: function close() {},
            open: function open() {
              $('html').css('overflow', 'auto').css('margin-right', '0');
            },
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
        this.get('controller').get('copyResourcePanel').send('init', user);
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      "delete": function _delete(id) {}
    }
  });

});