define('jason-frontend/models/patient', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    foreignId: DS['default'].attr('string'),
    chipId: DS['default'].attr('string'),
    race: DS['default'].attr('string'),
    optimizedRace: DS['default'].attr(''),
    birthday: DS['default'].attr('date'),
    deathday: DS['default'].attr('date'),
    castrated: DS['default'].attr('boolean'),
    dead: DS['default'].attr('boolean'),
    sold: DS['default'].attr('boolean'),
    insurer: DS['default'].attr(''),
    outdoor: DS['default'].attr(''),
    deleted: DS['default'].attr(''),
    insuranceNumber: DS['default'].attr(''),
    etigaDeclarationMissing: DS['default'].attr('boolean'),
    color: DS['default'].attr('string'),
    homeanimalNumberDate: DS['default'].attr('date'),
    soldDate: DS['default'].attr('date'),
    homeanimalNumber: DS['default'].attr('string'),
    allergies: DS['default'].attr('string'),
    propertiesChangedByApp: DS['default'].attr(''),
    horsePassNumber: DS['default'].attr('string'),
    currentWeight: DS['default'].attr('number'),
    normalWeightUpper: DS['default'].attr('number'),
    normalWeightLower: DS['default'].attr('number'),
    giveawayNotice: DS['default'].attr('string'),
    version: DS['default'].attr('number'),
    etigaActive: DS['default'].attr(''),
    etigaOwner: DS['default'].attr('boolean'),
    etigaMaster: DS['default'].attr('boolean'),
    etigaMasterId: DS['default'].attr(''),
    etigaConnected: DS['default'].attr(''),
    etigaQr: DS['default'].attr(''),
    etigaCustomerFirstname: DS['default'].attr('string'),
    etigaCustomerLastname: DS['default'].attr('string'),
    etigaCustomerGender: DS['default'].attr('string'),
    customerId: DS['default'].attr('number'),
    uploadedPicture: DS['default'].attr('string'),
    customer: DS['default'].belongsTo('customer', { async: true }),
    customerName: DS['default'].attr('string'),
    gender: DS['default'].belongsTo('patient-gender', { async: true }),
    category: DS['default'].belongsTo('patient-category', { async: true }),
    chipRegistered: DS['default'].attr('boolean'),
    bornCountry: DS['default'].attr('string'),
    evil: DS['default'].attr('boolean'),
    digitalRace: DS['default'].attr(''),
    evilDetails: DS['default'].attr('string'),
    medicalIncompatibility: DS['default'].attr('boolean'),
    medicalIncompatibilityNotice: DS['default'].attr('string'),
    picture: DS['default'].belongsTo('media'),
    chipDate: DS['default'].attr('date'),
    insured: DS['default'].attr('boolean'),
    chipLocation: DS['default'].attr('string'),
    slaughter: DS['default'].attr('boolean'),
    notice: DS['default'].attr('string'),
    fullName: DS['default'].attr(''),
    genderDetails: DS['default'].attr('string'),
    handoverDocument: DS['default'].attr('boolean'),
    horsePass: DS['default'].attr('boolean'),
    horseLivenumber: DS['default'].attr('string'),
    breedNumber: DS['default'].attr(''),
    horseBurningMark: DS['default'].attr('boolean'),
    horseBurningMarkLocation: DS['default'].attr('string'),
    horseTatoo: DS['default'].attr('boolean'),
    etigaCodeRequested: DS['default'].attr(''),
    etigaAppRequested: DS['default'].attr(''),
    horseTatooLocation: DS['default'].attr('string'),
    stableAddress: DS['default'].attr('string'),
    transponder: DS['default'].attr('string'),
    ageShort: DS['default'].attr('string'),
    ageShortUnit: DS['default'].attr('string'),
    insuranceProtection: DS['default'].attr('')
  });

});