define('jason-frontend/templates/patients/edit', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 6,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[4,30],[4,40]]]]],[],[]],"placeHolder","Dokument suchen","search","searchDocument"],["loc",[null,[4,6],[4,96]]]],
          ["inline","filter-box",[],["showFilters",["subexpr","@mut",[["get","showFilters",["loc",[null,[5,31],[5,42]]]]],[],[]]],["loc",[null,[5,6],[5,44]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 10
            },
            "end": {
              "line": 21,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/picture-primary.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Tierbild ändern");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/add-entry-primary.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Notiz erstellen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element104 = dom.childAt(fragment, [1]);
          var element105 = dom.childAt(fragment, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element104);
          morphs[1] = dom.createElementMorph(element105);
          return morphs;
        },
        statements: [
          ["element","action",["showImagePanel"],[],["loc",[null,[15,13],[15,40]]]],
          ["element","action",["addNewMemo"],[],["loc",[null,[18,13],[18,36]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 22,
              "column": 8
            },
            "end": {
              "line": 26,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/download-primary.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Ordner herunterladen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element103 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element103);
          return morphs;
        },
        statements: [
          ["element","action",["downloadFolder"],[],["loc",[null,[23,13],[23,40]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 10
            },
            "end": {
              "line": 31,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/download-primary.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Als CSV herunterladen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element102 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element102);
          return morphs;
        },
        statements: [
          ["element","action",["downloadAppointmentCsv",["get","model.id",["loc",[null,[28,49],[28,57]]]]],[],["loc",[null,[28,15],[28,59]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 32,
              "column": 10
            },
            "end": {
              "line": 36,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/download-primary.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Alle Laborwerte anzeigen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element101 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element101);
          return morphs;
        },
        statements: [
          ["element","action",["openAllLabParamsPanel",["get","model.id",["loc",[null,[33,49],[33,57]]]]],[],["loc",[null,[33,15],[33,59]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 37,
              "column": 10
            },
            "end": {
              "line": 47,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/notification-primary.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Erinnerung planen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/appointment-primary.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Termin planen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/more-primary.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Zukunft planen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element98 = dom.childAt(fragment, [1]);
          var element99 = dom.childAt(fragment, [3]);
          var element100 = dom.childAt(fragment, [5]);
          var morphs = new Array(3);
          morphs[0] = dom.createElementMorph(element98);
          morphs[1] = dom.createElementMorph(element99);
          morphs[2] = dom.createElementMorph(element100);
          return morphs;
        },
        statements: [
          ["element","action",["openReminderPanel"],[],["loc",[null,[38,15],[38,45]]]],
          ["element","action",["openAppointmentPanel"],[],["loc",[null,[41,15],[41,48]]]],
          ["element","action",["addFutureAction",["get","model.id",["loc",[null,[44,42],[44,50]]]]],[],["loc",[null,[44,15],[44,52]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 53,
              "column": 6
            },
            "end": {
              "line": 58,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neues Dokument");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element97 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element97);
          morphs[1] = dom.createMorphAt(element97,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["openDocumentUpload"],[],["loc",[null,[54,11],[54,42]]]],
          ["inline","button-fileupload",[],["color","#fff","size","16","showStroke",false],["loc",[null,[55,10],[55,71]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 59,
              "column": 6
            },
            "end": {
              "line": 63,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/add-entry.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neue Aufgabe");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element96 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element96);
          return morphs;
        },
        statements: [
          ["element","action",["openNew"],[],["loc",[null,[60,11],[60,31]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 64,
              "column": 6
            },
            "end": {
              "line": 67,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context");
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/add-entry.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neuer Termin");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element95 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element95);
          return morphs;
        },
        statements: [
          ["element","action",["openAppointmentPanel"],[],["loc",[null,[65,11],[65,44]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child9 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 68,
              "column": 6
            },
            "end": {
              "line": 71,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("eltiga Historie");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element94 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element94);
          morphs[1] = dom.createMorphAt(element94,0,0);
          return morphs;
        },
        statements: [
          ["element","action",["openLogbook"],[],["loc",[null,[69,11],[69,35]]]],
          ["inline","button-history",[],["color","#fff","size","30","showStroke",false,"content",""],["loc",[null,[69,69],[69,138]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 72,
              "column": 6
            },
            "end": {
              "line": 76,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("CSV herunterladen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element93 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element93);
          morphs[1] = dom.createMorphAt(element93,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["downloadHistoryCsv",["get","model.id",["loc",[null,[73,41],[73,49]]]]],[],["loc",[null,[73,11],[73,51]]]],
          ["inline","button-download2",[],["color","#fff","size","16","content",""],["loc",[null,[74,10],[74,64]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child11 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 77,
              "column": 6
            },
            "end": {
              "line": 81,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("eltiga Nachricht");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element92 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element92);
          morphs[1] = dom.createMorphAt(element92,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["openDirectMessage"],[],["loc",[null,[78,11],[78,41]]]],
          ["inline","button-chat",[],["content","","color","#ffffff","size","32","showStroke",false],["loc",[null,[79,10],[79,79]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child12 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 96,
              "column": 14
            },
            "end": {
              "line": 100,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-activities");
          var el3 = dom.createTextNode("Journal");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element91 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element91, 'class');
          morphs[1] = dom.createElementMorph(element91);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[98,86],[98,95]]]],"activities"],[],["loc",[null,[98,82],[98,109]]]],"is-active",""],[],["loc",[null,[98,61],[98,126]]]]]]],
          ["element","action",["selectTab","activities"],[],["loc",[null,[98,151],[98,186]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child13 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 101,
              "column": 14
            },
            "end": {
              "line": 105,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-history");
          var el3 = dom.createTextNode("Historie");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element90 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element90, 'class');
          morphs[1] = dom.createElementMorph(element90);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[103,88],[103,97]]]],"history"],[],["loc",[null,[103,84],[103,108]]]],"is-active",""],[],["loc",[null,[103,63],[103,125]]]]]]],
          ["element","action",["selectTab","history"],[],["loc",[null,[103,147],[103,179]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child14 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 115,
              "column": 16
            },
            "end": {
              "line": 119,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-correspondance");
          var el3 = dom.createTextNode("Korrespondenz");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element89 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element89, 'class');
          morphs[1] = dom.createElementMorph(element89);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[117,90],[117,99]]]],"correspondance"],[],["loc",[null,[117,86],[117,117]]]],"is-active",""],[],["loc",[null,[117,65],[117,134]]]]]]],
          ["element","action",["selectTab","correspondance"],[],["loc",[null,[117,163],[117,202]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child15 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 123,
              "column": 14
            },
            "end": {
              "line": 127,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-todo");
          var el3 = dom.createTextNode("TO-DO");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element88 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element88, 'class');
          morphs[1] = dom.createElementMorph(element88);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[125,86],[125,95]]]],"todo"],[],["loc",[null,[125,82],[125,103]]]],"is-active",""],[],["loc",[null,[125,61],[125,120]]]]]]],
          ["element","action",["selectTab","todo"],[],["loc",[null,[125,139],[125,168]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child16 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 128,
              "column": 14
            },
            "end": {
              "line": 132,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-appointments");
          var el3 = dom.createTextNode("Termine");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element87 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element87, 'class');
          morphs[1] = dom.createElementMorph(element87);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[130,88],[130,97]]]],"appointments"],[],["loc",[null,[130,84],[130,113]]]],"is-active",""],[],["loc",[null,[130,63],[130,130]]]]]]],
          ["element","action",["selectTab","appointments"],[],["loc",[null,[130,157],[130,194]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child17 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 142,
              "column": 20
            },
            "end": {
              "line": 145,
              "column": 20
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          var el2 = dom.createTextNode("Pferd");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","patients-edit-additional-form",[],["etigaPermissions",["subexpr","@mut",[["get","etigaPermissions",["loc",[null,[144,73],[144,89]]]]],[],[]],"edit",true,"openLogbook","openEtigaMasterdataLogbook","model",["subexpr","@mut",[["get","model",["loc",[null,[144,147],[144,152]]]]],[],[]],"customer",["subexpr","@mut",[["get","customer",["loc",[null,[144,162],[144,170]]]]],[],[]]],["loc",[null,[144,24],[144,172]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child18 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 149,
              "column": 16
            },
            "end": {
              "line": 151,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          var el2 = dom.createTextNode("Gewichtsentwicklung");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child19 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 255,
                "column": 20
              },
              "end": {
                "line": 255,
                "column": 240
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("label");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element86 = dom.childAt(fragment, [0]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element86, 'class');
            morphs[1] = dom.createAttrMorph(element86, 'style');
            morphs[2] = dom.createAttrMorph(element86, 'data-id');
            morphs[3] = dom.createElementMorph(element86);
            morphs[4] = dom.createMorphAt(element86,0,0);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","tag.selected",["loc",[null,[255,96],[255,108]]]],"selected",""],[],["loc",[null,[255,75],[255,124]]]]]]],
            ["attribute","style",["concat",["background-color: ",["get","tag.color",["loc",[null,[255,153],[255,162]]]]]]],
            ["attribute","data-id",["concat",[["get","tag.id",["loc",[null,[255,177],[255,183]]]]]]],
            ["element","action",["selectSearchTag",["get","tag",["loc",[null,[255,214],[255,217]]]]],[],["loc",[null,[255,187],[255,219]]]],
            ["content","tag.name",["loc",[null,[255,220],[255,232]]]]
          ],
          locals: ["tag"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 252,
              "column": 16
            },
            "end": {
              "line": 257,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","quick-filter box");
          dom.setAttribute(el1,"id","tagFilters");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","title");
          var el3 = dom.createTextNode("Tags");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
          return morphs;
        },
        statements: [
          ["block","each",[["get","tags",["loc",[null,[255,28],[255,32]]]]],[],0,null,["loc",[null,[255,20],[255,249]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child20 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 308,
                "column": 22
              },
              "end": {
                "line": 310,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        Alle ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" um ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Uhr, ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Wiederholungen\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
            morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
            morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
            return morphs;
          },
          statements: [
            ["content","item.dueTimeUnitInterval",["loc",[null,[309,29],[309,57]]]],
            ["content","item.dueTimeUnit.name",["loc",[null,[309,58],[309,83]]]],
            ["content","item.time",["loc",[null,[309,87],[309,100]]]],
            ["content","item.dueTimeUnitIterations",["loc",[null,[309,106],[309,136]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 310,
                "column": 22
              },
              "end": {
                "line": 312,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","time-format",[["get","item.dueDate",["loc",[null,[311,38],[311,50]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[311,24],[311,73]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 319,
                "column": 28
              },
              "end": {
                "line": 319,
                "column": 102
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge success");
            var el2 = dom.createTextNode("erledigt");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 319,
                "column": 102
              },
              "end": {
                "line": 319,
                "column": 149
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge");
            var el2 = dom.createTextNode("offen");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 304,
              "column": 20
            },
            "end": {
              "line": 331,
              "column": 20
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 l-5/24@desk");
          dom.setAttribute(el2,"data-label","Fälligkeit");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 l-10/24@desk");
          dom.setAttribute(el2,"data-label","Titel");
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 l-4/24@desk");
          dom.setAttribute(el2,"data-label","Status");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell buttons-2 table__cell--action l-6/24 l-5/24@desk buttons-2");
          dom.setAttribute(el2,"data-label","");
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","icon-button icon-button--delete list-action-square exp");
          var el4 = dom.createTextNode("\n                            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","icon-button icon-button--default list-action-square exp");
          var el4 = dom.createTextNode("\n                            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          var el5 = dom.createTextNode("Bearbeiten");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element82 = dom.childAt(fragment, [1]);
          var element83 = dom.childAt(element82, [7]);
          var element84 = dom.childAt(element83, [1]);
          var element85 = dom.childAt(element83, [3]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(dom.childAt(element82, [1, 1]),1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(element82, [3, 1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element82, [5, 1]),0,0);
          morphs[3] = dom.createMorphAt(element84,1,1);
          morphs[4] = dom.createMorphAt(dom.childAt(element84, [3]),0,0);
          morphs[5] = dom.createElementMorph(element85);
          morphs[6] = dom.createMorphAt(element85,1,1);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[["get","item.repeat",["loc",[null,[308,32],[308,43]]]],"repeat"],[],["loc",[null,[308,28],[308,53]]]]],[],0,1,["loc",[null,[308,22],[312,29]]]],
          ["content","item.name",["loc",[null,[316,32],[316,45]]]],
          ["block","if",[["get","item.checkedDate",["loc",[null,[319,34],[319,50]]]]],[],2,3,["loc",[null,[319,28],[319,156]]]],
          ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"classNames","","item",["subexpr","@mut",[["get","item",["loc",[null,[323,106],[323,110]]]]],[],[]],"confirmed","deleteTodo","text","Wollen Sie diesen Eintrag wirklich löschen?"],["loc",[null,[323,28],[323,186]]]],
          ["inline","text-with-confirm",[],["item",["subexpr","@mut",[["get","item",["loc",[null,[324,59],[324,63]]]]],[],[]],"confirmed","deleteTodo","textToShow","Löschen","text","Wollen Sie diesen Eintrag wirklich löschen?"],["loc",[null,[324,34],[324,160]]]],
          ["element","action",["editTodo",["get","item",["loc",[null,[326,49],[326,53]]]]],[],["loc",[null,[326,29],[326,55]]]],
          ["inline","button-edit",[],["color","#fff","showStroke",false,"size","36","content",""],["loc",[null,[327,28],[327,94]]]]
        ],
        locals: ["item"],
        templates: [child0, child1, child2, child3]
      };
    }());
    var child21 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 358,
              "column": 14
            },
            "end": {
              "line": 375,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","customer-etiga",[],["patient",["subexpr","@mut",[["get","model",["loc",[null,[359,39],[359,44]]]]],[],[]],"etigaPermissions",["subexpr","@mut",[["get","etigaPermissions",["loc",[null,[360,48],[360,64]]]]],[],[]],"activateEtiga","activateEtiga","uploadDeclaration","uploadDeclaration","removeShare","removeEtigaShare","sendEtigaSave","sendEtigaSave","downloadEtigaQr","downloadEtigaQr","hasEtigaPermissions",["subexpr","has-permission",["etiga"],[],["loc",[null,[366,51],[366,75]]]],"addEtigaShare","addEtigaShare","disconnectEtiga","disconnectEtiga","selectDocs","selectDocs","submitCredentials","sendCredentials","savePermission","saveEtigaPermission","editEtigaPermissions","editEtigaPermissions","classNames","etigaPermissionPanel patients","actionReceiver",["subexpr","@mut",[["get","etigaPanel",["loc",[null,[374,46],[374,56]]]]],[],[]]],["loc",[null,[359,14],[374,58]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child22 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 375,
              "column": 14
            },
            "end": {
              "line": 377,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                Derzeit in Arbeit\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child23 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 386,
              "column": 6
            },
            "end": {
              "line": 390,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element81 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element81);
          morphs[1] = dom.createMorphAt(element81,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["triggerSave"],[],["loc",[null,[387,13],[387,37]]]],
          ["inline","button-save",[],["color","#ffffff","size","80","content","Speichern"],["loc",[null,[388,12],[388,73]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child24 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 391,
              "column": 6
            },
            "end": {
              "line": 395,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element80 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element80);
          morphs[1] = dom.createMorphAt(element80,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["sendEtigaSave"],[],["loc",[null,[392,11],[392,37]]]],
          ["inline","button-save",[],["color","#ffffff","size","80","content","Freigaben speichern"],["loc",[null,[393,10],[393,81]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child25 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 396,
              "column": 6
            },
            "end": {
              "line": 403,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element78 = dom.childAt(fragment, [1]);
          var element79 = dom.childAt(fragment, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createElementMorph(element78);
          morphs[1] = dom.createMorphAt(element78,1,1);
          morphs[2] = dom.createElementMorph(element79);
          morphs[3] = dom.createMorphAt(element79,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["downloadWeightCsv",["get","model.id",["loc",[null,[397,40],[397,48]]]]],[],["loc",[null,[397,11],[397,50]]]],
          ["inline","button-download-csv",[],["color","#ffffff","size","40","content","Gewichtsverlauf als CSV herunterladen"],["loc",[null,[398,10],[398,107]]]],
          ["element","action",["openWeight",null,["get","model.id",["loc",[null,[400,38],[400,46]]]]],[],["loc",[null,[400,11],[400,48]]]],
          ["inline","button-add-entry",[],["content","Neues Gewicht eintragen","color","#FFFFFF","size","52","showStroke",false],["loc",[null,[401,10],[401,108]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child26 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 404,
              "column": 6
            },
            "end": {
              "line": 408,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element77 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element77);
          morphs[1] = dom.createMorphAt(element77,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["showUploadForm"],[],["loc",[null,[405,13],[405,40]]]],
          ["inline","button-upload",[],["size","52","showStroke",false,"color","#ffffff","content","Neues Dokument hochladen"],["loc",[null,[406,12],[406,107]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child27 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 422,
                "column": 12
              },
              "end": {
                "line": 425,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","animal-icon",[],["useFilter",false,"classNames","inline-block vertical-middle","color","#fff","inverted",["subexpr","eq",["female",["get","model.gender.id",["loc",[null,[423,121],[423,136]]]]],[],["loc",[null,[423,108],[423,137]]]],"animal-id",["subexpr","@mut",[["get","model.category.id",["loc",[null,[424,38],[424,55]]]]],[],[]],"race-id",["subexpr","@mut",[["get","model.digitalRace.icon",["loc",[null,[424,64],[424,86]]]]],[],[]],"size","60","content",""],["loc",[null,[423,14],[424,109]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 425,
                "column": 12
              },
              "end": {
                "line": 427,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"src","assets/images/eltiga-poe.png");
            dom.setAttribute(el1,"style","width: 55px;padding-top: 11px;padding-left: 6px;");
            dom.setAttribute(el1,"class","inline-block vertical-middle");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 431,
                "column": 12
              },
              "end": {
                "line": 431,
                "column": 95
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"class","dead-icon");
            dom.setAttribute(el1,"src","assets/images/icons/died-black.svg");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 432,
                "column": 55
              },
              "end": {
                "line": 432,
                "column": 108
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("- ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","model.digitalRace.name",["loc",[null,[432,82],[432,108]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 432,
                "column": 108
              },
              "end": {
                "line": 432,
                "column": 132
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("- ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","model.race",["loc",[null,[432,118],[432,132]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 433,
                "column": 8
              },
              "end": {
                "line": 433,
                "column": 71
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","subName small");
            var el2 = dom.createTextNode("Freigänger:in");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child6 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 436,
                  "column": 10
                },
                "end": {
                  "line": 438,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","cursor info-badge");
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/icons/sun.svg");
              dom.setAttribute(el2,"style","width: 20px");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 438,
                  "column": 10
                },
                "end": {
                  "line": 440,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","cursor info-badge");
              var el2 = dom.createTextNode("CAVE");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 434,
                "column": 8
              },
              "end": {
                "line": 442,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"id","caveLabel");
            dom.setAttribute(el1,"class","cursor");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["subexpr","get-current-mandant-id",[],[],["loc",[null,[436,20],[436,44]]]],"153"],[],["loc",[null,[436,16],[436,51]]]]],[],0,1,["loc",[null,[436,10],[440,17]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child7 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 445,
                "column": 10
              },
              "end": {
                "line": 449,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","insured");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","textBelow");
            var el3 = dom.createElement("img");
            dom.setAttribute(el3,"src","assets/images/icons/insured.svg");
            dom.setAttribute(el3,"style","width: 20px");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" Versicherung");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child8 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 449,
                "column": 10
              },
              "end": {
                "line": 453,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","not-insured");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","textBelow");
            var el3 = dom.createElement("img");
            dom.setAttribute(el3,"src","assets/images/icons/no.svg");
            dom.setAttribute(el3,"style","width: 20px;padding-bottom: 3px");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" Versicherung");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child9 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 458,
                  "column": 12
                },
                "end": {
                  "line": 460,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("               ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1,"src","assets/images/eltiga-poe.png");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element18 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element18);
              return morphs;
            },
            statements: [
              ["element","action",["openEmailEtigaPanel",["get","model",["loc",[null,[459,86],[459,91]]]]],[],["loc",[null,[459,55],[459,93]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 460,
                  "column": 12
                },
                "end": {
                  "line": 462,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","etigaicon-with-confirm",[],["small",true,"color","00AAC6","item",["subexpr","@mut",[["get","model",["loc",[null,[461,72],[461,77]]]]],[],[]],"confirmed","activateEtiga","textToShow","etiga","text","eltiga für Tier aktivieren?","classNames","inline-block"],["loc",[null,[461,16],[461,185]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 456,
                "column": 8
              },
              "end": {
                "line": 464,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"id","etigaLabel");
            dom.setAttribute(el1,"class","cursor");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["get","model.etigaActive",["loc",[null,[458,18],[458,35]]]]],[],0,1,["loc",[null,[458,12],[462,19]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child10 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 469,
                "column": 14
              },
              "end": {
                "line": 471,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"style","width: 27px;");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element17 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element17, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",["assets/images/icons/",["get","model.gender.id",["loc",[null,[470,69],[470,84]]]],".svg"]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child11 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 471,
                "column": 14
              },
              "end": {
                "line": 473,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","infoText");
            var el2 = dom.createElement("em");
            var el3 = dom.createTextNode("N/A");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child12 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 474,
                "column": 38
              },
              "end": {
                "line": 474,
                "column": 70
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Kastriert");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child13 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 474,
                "column": 70
              },
              "end": {
                "line": 474,
                "column": 88
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Geschlecht");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child14 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 479,
                "column": 14
              },
              "end": {
                "line": 481,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","infoText");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("em");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element16 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element16,0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element16, [2]),0,0);
            return morphs;
          },
          statements: [
            ["content","model.ageShort",["loc",[null,[480,39],[480,57]]]],
            ["content","model.ageShortUnit",["loc",[null,[480,62],[480,84]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child15 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 481,
                "column": 14
              },
              "end": {
                "line": 483,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","infoText");
            var el2 = dom.createElement("em");
            var el3 = dom.createTextNode("N/A");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child16 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 484,
                "column": 38
              },
              "end": {
                "line": 484,
                "column": 105
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","time-format",[["get","model.birthday",["loc",[null,[484,74],[484,88]]]],"DD. MM. YYYY"],[],["loc",[null,[484,60],[484,105]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child17 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 484,
                "column": 105
              },
              "end": {
                "line": 484,
                "column": 118
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Alter");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child18 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 489,
                "column": 14
              },
              "end": {
                "line": 491,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","infoText");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("em");
            var el3 = dom.createTextNode("kg");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["inline","format-number-german",[["get","model.currentWeight",["loc",[null,[490,62],[490,81]]]]],[],["loc",[null,[490,39],[490,83]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child19 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 491,
                "column": 14
              },
              "end": {
                "line": 493,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","infoText");
            var el2 = dom.createElement("em");
            var el3 = dom.createTextNode("N/A");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child20 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 517,
                  "column": 16
                },
                "end": {
                  "line": 522,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","btn tiny");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/eltiga-poe.png");
              dom.setAttribute(el2,"style","width: 17px;");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    eltiga\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element15 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element15);
              return morphs;
            },
            statements: [
              ["element","action",["openEmailEtigaPanel",["get","model",["loc",[null,[518,69],[518,74]]]]],[],["loc",[null,[518,38],[518,76]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 522,
                  "column": 16
                },
                "end": {
                  "line": 525,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","activate-eltiga-with-confirm",[],["item",["subexpr","@mut",[["get","model",["loc",[null,[523,56],[523,61]]]]],[],[]],"confirmed","activateEtiga","textToShow","eltiga","text","eltiga für Tier aktivieren?","classNames","inline-block"],["loc",[null,[523,20],[524,102]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 516,
                "column": 14
              },
              "end": {
                "line": 526,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","model.etigaActive",["loc",[null,[517,22],[517,39]]]]],[],0,1,["loc",[null,[517,16],[525,23]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child21 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 536,
                "column": 14
              },
              "end": {
                "line": 541,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","btn tiny");
            dom.setAttribute(el1,"style","padding-left:2px");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/archive.svg");
            dom.setAttribute(el2,"style","width: 15px;");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  Archivieren\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element14 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element14);
            return morphs;
          },
          statements: [
            ["element","action",["setDeathDate","dead"],[],["loc",[null,[537,61],[537,93]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child22 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 551,
                "column": 10
              },
              "end": {
                "line": 553,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"href","#tab-activity");
            var el2 = dom.createTextNode("Aktivitäten");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element13 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element13, 'class');
            morphs[1] = dom.createElementMorph(element13);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","healthTab",["loc",[null,[552,67],[552,76]]]],"activity"],[],["loc",[null,[552,63],[552,88]]]],"health","deselected"],[],["loc",[null,[552,42],[552,112]]]]]]],
            ["element","action",["selectHealthTab","activity"],[],["loc",[null,[552,135],[552,174]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child23 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 559,
                    "column": 98
                  },
                  "end": {
                    "line": 559,
                    "column": 156
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["inline","get-html",[["get","model.allergies",["loc",[null,[559,133],[559,148]]]]],[],["loc",[null,[559,121],[559,151]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 559,
                  "column": 35
                },
                "end": {
                  "line": 559,
                  "column": 213
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createUnsafeMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","model.allergies",["loc",[null,[559,104],[559,119]]]]],[],0,null,["loc",[null,[559,98],[559,163]]]],
              ["inline","get-html",[["get","model.medicalIncompatibilityNotice",["loc",[null,[559,176],[559,210]]]]],[],["loc",[null,[559,164],[559,213]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 559,
                  "column": 213
                },
                "end": {
                  "line": 559,
                  "column": 241
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Dosierungen einsehen");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 558,
                "column": 12
              },
              "end": {
                "line": 560,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","pb10 pt10");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","or",[["get","model.medicalIncompatibilityNotice",["loc",[null,[559,45],[559,79]]]],["get","model.allergies",["loc",[null,[559,80],[559,95]]]]],[],["loc",[null,[559,41],[559,96]]]]],[],0,1,["loc",[null,[559,35],[559,248]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child24 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 560,
                "column": 12
              },
              "end": {
                "line": 562,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","pb10 pt10");
            var el2 = dom.createTextNode("Derzeit keine Erkrankungen / Dosierungen hinterlegt.");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child25 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 585,
                "column": 10
              },
              "end": {
                "line": 587,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"href","#tab-documents");
            var el2 = dom.createTextNode("Docs");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element12 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element12, 'class');
            morphs[1] = dom.createElementMorph(element12);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","patientTab",["loc",[null,[586,67],[586,77]]]],"documents"],[],["loc",[null,[586,63],[586,90]]]],"masterdata","deselected"],[],["loc",[null,[586,42],[586,118]]]]]]],
            ["element","action",["selectPatientTab","documents"],[],["loc",[null,[586,143],[586,184]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child26 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 596,
                "column": 16
              },
              "end": {
                "line": 599,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Farbe");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
            return morphs;
          },
          statements: [
            ["content","model.color",["loc",[null,[598,21],[598,36]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child27 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 600,
                "column": 16
              },
              "end": {
                "line": 603,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Geburtstag");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
            return morphs;
          },
          statements: [
            ["inline","time-format",[["get","model.birthday",["loc",[null,[602,35],[602,49]]]],"DD. MM. YYYY"],[],["loc",[null,[602,21],[602,66]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child28 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 604,
                "column": 16
              },
              "end": {
                "line": 607,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Todestag");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","cursor");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element11 = dom.childAt(fragment, [3]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element11);
            morphs[1] = dom.createMorphAt(element11,0,0);
            return morphs;
          },
          statements: [
            ["element","action",["setDeathDate","dead"],[],["loc",[null,[606,36],[606,68]]]],
            ["inline","time-format",[["get","model.deathday",["loc",[null,[606,83],[606,97]]]],"DD. MM. YYYY"],[],["loc",[null,[606,69],[606,114]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child29 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 608,
                "column": 16
              },
              "end": {
                "line": 611,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Archiviert");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","cursor");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element10 = dom.childAt(fragment, [3]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element10);
            morphs[1] = dom.createMorphAt(element10,0,0);
            return morphs;
          },
          statements: [
            ["element","action",["setDeathDate","giveaway"],[],["loc",[null,[610,36],[610,72]]]],
            ["inline","time-format",[["get","model.soldDate",["loc",[null,[610,87],[610,101]]]],"DD. MM. YYYY"],[],["loc",[null,[610,73],[610,118]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child30 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 616,
                "column": 14
              },
              "end": {
                "line": 619,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Chip-Nr");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","cursor flex-center");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"class","ml5");
            dom.setAttribute(el2,"src","assets/images/icons/external-link.svg");
            dom.setAttribute(el2,"style","max-width: 18px;");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element9 = dom.childAt(fragment, [3]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element9);
            morphs[1] = dom.createMorphAt(element9,0,0);
            return morphs;
          },
          statements: [
            ["element","action",["openChipPanel",["get","model",["loc",[null,[618,46],[618,51]]]]],[],["loc",[null,[618,21],[618,53]]]],
            ["content","model.chipId",["loc",[null,[618,81],[618,97]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child31 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 620,
                "column": 14
              },
              "end": {
                "line": 623,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Heimtier-Nr");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
            return morphs;
          },
          statements: [
            ["content","model.homeanimalNumber",["loc",[null,[622,19],[622,45]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child32 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 624,
                "column": 14
              },
              "end": {
                "line": 629,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Versicherung");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Versicherungs-Nr");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [7]),0,0);
            return morphs;
          },
          statements: [
            ["content","model.insurer.name",["loc",[null,[626,28],[626,50]]]],
            ["content","model.insuranceNumber",["loc",[null,[628,28],[628,53]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child33 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 639,
                "column": 10
              },
              "end": {
                "line": 644,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"style","padding-top: 5px;padding-left: 4px;");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["inline","gender-icon",[],["classNames","owner-gender","useFilter",false,"size","55","color","#fff","gender",["subexpr","@mut",[["get","model.customer.gender.id",["loc",[null,[642,35],[642,59]]]]],[],[]],"content",""],["loc",[null,[641,14],[642,72]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child34 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 644,
                "column": 10
              },
              "end": {
                "line": 649,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/jason-poe-grey.svg");
            dom.setAttribute(el2,"style","width: 55px;padding-top: 11px;padding-left: 6px;");
            dom.setAttribute(el2,"class","inline-block vertical-middle");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child35 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 653,
                "column": 27
              },
              "end": {
                "line": 654,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          , ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [
            ["content","model.customer.street",["loc",[null,[653,56],[653,81]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child36 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 661,
                "column": 8
              },
              "end": {
                "line": 665,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"id","appLabel");
            dom.setAttribute(el1,"class","cursor");
            var el2 = dom.createTextNode("\n             ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/eltiga-poe.png");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child37 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 665,
                  "column": 8
                },
                "end": {
                  "line": 669,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"id","appLabel");
              dom.setAttribute(el1,"class","cursor");
              var el2 = dom.createTextNode("\n             ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/eltiga-poe-dark.png");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 665,
                "column": 8
              },
              "end": {
                "line": 669,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","model.customer.etigaCodeRequested",["loc",[null,[665,18],[665,51]]]]],[],0,null,["loc",[null,[665,8],[669,8]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child38 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 687,
                  "column": 16
                },
                "end": {
                  "line": 691,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1,"src","assets/images/icons/car.svg");
              dom.setAttribute(el1,"style","width: 22px;margin-top: -3px;margin-bottom: -3px;");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                  Anfahrt\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 686,
                "column": 14
              },
              "end": {
                "line": 692,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","link-to",["customers.edit",["get","model.customer.id",["loc",[null,[687,44],[687,61]]]],["subexpr","query-params",[],["selectTab","accounting"],["loc",[null,[687,62],[687,99]]]]],["class","btn tiny"],0,null,["loc",[null,[687,16],[691,28]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child39 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 692,
                "column": 14
              },
              "end": {
                "line": 697,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","btn tiny");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/car.svg");
            dom.setAttribute(el2,"style","width: 22px;margin-top: -3px;margin-bottom: -3px;");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  Anfahrt\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element8);
            return morphs;
          },
          statements: [
            ["element","action",["addDistance"],[],["loc",[null,[693,36],[693,60]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child40 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 719,
                    "column": 152
                  },
                  "end": {
                    "line": 719,
                    "column": 193
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode(" (");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(")");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["content","entry.comment",["loc",[null,[719,175],[719,192]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 718,
                  "column": 14
                },
                "end": {
                  "line": 720,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/icons/phone.svg");
              dom.setAttribute(el2,"style","width: 15px;");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","is-highlighted");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [1, 2]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element7, 'href');
              morphs[1] = dom.createMorphAt(element7,0,0);
              morphs[2] = dom.createMorphAt(element7,1,1);
              return morphs;
            },
            statements: [
              ["attribute","href",["concat",["tel:",["get","entry.value",["loc",[null,[719,122],[719,133]]]]]]],
              ["content","entry.value",["loc",[null,[719,137],[719,152]]]],
              ["block","if",[["get","entry.comment",["loc",[null,[719,158],[719,171]]]]],[],0,null,["loc",[null,[719,152],[719,200]]]]
            ],
            locals: ["entry"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 715,
                "column": 10
              },
              "end": {
                "line": 722,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Telefon");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("ul");
            dom.setAttribute(el1,"class","mb10");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","model.customer.phones",["loc",[null,[718,22],[718,43]]]]],[],0,null,["loc",[null,[718,14],[720,23]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child41 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 727,
                    "column": 156
                  },
                  "end": {
                    "line": 727,
                    "column": 197
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode(" (");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(")");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["content","entry.comment",["loc",[null,[727,179],[727,196]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 726,
                  "column": 14
                },
                "end": {
                  "line": 728,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/icons/email.svg");
              dom.setAttribute(el2,"style","width: 15px;");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","is-highlighted");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1, 2]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element6, 'href');
              morphs[1] = dom.createMorphAt(element6,0,0);
              morphs[2] = dom.createMorphAt(element6,1,1);
              return morphs;
            },
            statements: [
              ["attribute","href",["concat",["mailto:",["get","entry.value",["loc",[null,[727,126],[727,137]]]]]]],
              ["content","entry.value",["loc",[null,[727,141],[727,156]]]],
              ["block","if",[["get","entry.comment",["loc",[null,[727,162],[727,175]]]]],[],0,null,["loc",[null,[727,156],[727,204]]]]
            ],
            locals: ["entry"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 723,
                "column": 10
              },
              "end": {
                "line": 730,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Email");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("ul");
            dom.setAttribute(el1,"class","mb10");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","model.customer.emails",["loc",[null,[726,22],[726,43]]]]],[],0,null,["loc",[null,[726,14],[728,23]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child42 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 731,
                "column": 10
              },
              "end": {
                "line": 734,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Guthaben");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
            return morphs;
          },
          statements: [
            ["inline","format-currency",[],["value",["subexpr","@mut",[["get","depositSum",["loc",[null,[733,39],[733,49]]]]],[],[]]],["loc",[null,[733,15],[733,51]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child43 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 750,
                    "column": 46
                  },
                  "end": {
                    "line": 750,
                    "column": 76
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("ganztägig");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 750,
                      "column": 89
                    },
                    "end": {
                      "line": 750,
                      "column": 145
                    }
                  },
                  "moduleName": "jason-frontend/templates/patients/edit.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" - ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.dateFrom",["loc",[null,[750,110],[750,127]]]],
                  ["content","item.dateTo",["loc",[null,[750,130],[750,145]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 750,
                    "column": 76
                  },
                  "end": {
                    "line": 750,
                    "column": 152
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.dateFrom",["loc",[null,[750,95],[750,108]]]]],[],0,null,["loc",[null,[750,89],[750,152]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 750,
                    "column": 159
                  },
                  "end": {
                    "line": 750,
                    "column": 245
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("bis ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.dateEnd",["loc",[null,[750,229],[750,245]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 747,
                  "column": 20
                },
                "end": {
                  "line": 756,
                  "column": 20
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-12/24@phone l-8/24@tablet l-12/24@desk");
              dom.setAttribute(el2,"data-label","Datum");
              var el3 = dom.createTextNode("\n                          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-12/24@phone l-16/24@tablet l-12/24@desk");
              dom.setAttribute(el2,"data-label","TitelKategorie");
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createElement("a");
              dom.setAttribute(el4,"class","cursor is-highlighted");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("br");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var element3 = dom.childAt(element2, [1, 1]);
              var element4 = dom.childAt(element2, [3, 1]);
              var element5 = dom.childAt(element4, [0]);
              var morphs = new Array(6);
              morphs[0] = dom.createMorphAt(element3,0,0);
              morphs[1] = dom.createMorphAt(element3,2,2);
              morphs[2] = dom.createMorphAt(element3,3,3);
              morphs[3] = dom.createElementMorph(element5);
              morphs[4] = dom.createMorphAt(element5,0,0);
              morphs[5] = dom.createMorphAt(element4,2,2);
              return morphs;
            },
            statements: [
              ["content","item.date",["loc",[null,[750,32],[750,45]]]],
              ["block","if",[["get","item.wholeDay",["loc",[null,[750,52],[750,65]]]]],[],0,1,["loc",[null,[750,46],[750,159]]]],
              ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","item.dateEnd",["loc",[null,[750,179],[750,191]]]],["get","item.date",["loc",[null,[750,192],[750,201]]]]],[],["loc",[null,[750,175],[750,202]]]]],[],["loc",[null,[750,170],[750,203]]]],["get","item.multiDay",["loc",[null,[750,204],[750,217]]]]],[],["loc",[null,[750,165],[750,218]]]]],[],2,null,["loc",[null,[750,159],[750,252]]]],
              ["element","action",["showAppointment",["get","item",["loc",[null,[753,90],[753,94]]]]],[],["loc",[null,[753,63],[753,96]]]],
              ["content","item.title",["loc",[null,[753,97],[753,111]]]],
              ["content","item.category.name",["loc",[null,[753,120],[753,142]]]]
            ],
            locals: ["item"],
            templates: [child0, child1, child2]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 739,
                "column": 12
              },
              "end": {
                "line": 760,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table-wrapper table--small box no-box@phone mb0 pl0 pr0");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table no-box@phone");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__head");
            var el4 = dom.createComment("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__cell l-12/24");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","table__title title");
            var el6 = dom.createTextNode("Datum");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__cell l-12/24");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","table__title title");
            var el6 = dom.createTextNode("Titel/Kategorie");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__body");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","customerAppointments",["loc",[null,[747,28],[747,48]]]]],[],0,null,["loc",[null,[747,20],[756,29]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child44 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 760,
                "column": 12
              },
              "end": {
                "line": 764,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","ml10");
            var el2 = dom.createTextNode("\n                Derzeit keine Termine hinterlegt\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child45 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 785,
                      "column": 224
                    },
                    "end": {
                      "line": 785,
                      "column": 273
                    }
                  },
                  "moduleName": "jason-frontend/templates/patients/edit.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.name",["loc",[null,[785,260],[785,273]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 782,
                    "column": 22
                  },
                  "end": {
                    "line": 794,
                    "column": 22
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__row");
                var el2 = dom.createTextNode("\n                          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-8/24");
                dom.setAttribute(el2,"data-label","Name");
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","is-highlighted");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                          ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-8/24");
                dom.setAttribute(el2,"data-label","Spezies");
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                          ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-8/24");
                dom.setAttribute(el2,"data-label","Rasse");
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                          ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var element1 = dom.childAt(element0, [1, 1]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(element1,0,0);
                morphs[1] = dom.createMorphAt(element1,2,2);
                morphs[2] = dom.createMorphAt(dom.childAt(element0, [3, 1]),0,0);
                morphs[3] = dom.createMorphAt(dom.childAt(element0, [5, 1]),0,0);
                return morphs;
              },
              statements: [
                ["inline","animal-icon",[],["classNames","inline-block vertical-middle","evil",["subexpr","@mut",[["get","item.evil",["loc",[null,[785,118],[785,127]]]]],[],[]],"animal-id",["subexpr","@mut",[["get","item.category.id",["loc",[null,[785,138],[785,154]]]]],[],[]],"race-id",["subexpr","@mut",[["get","item.digitalRace.icon",["loc",[null,[785,163],[785,184]]]]],[],[]],"size","22","content","","color","#4A4A4A"],["loc",[null,[785,57],[785,223]]]],
                ["block","link-to",["patients.edit",["get","item.id",["loc",[null,[785,251],[785,258]]]]],[],0,null,["loc",[null,[785,224],[785,285]]]],
                ["content","item.category.name",["loc",[null,[788,34],[788,56]]]],
                ["content","item.race",["loc",[null,[791,34],[791,47]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 781,
                  "column": 20
                },
                "end": {
                  "line": 795,
                  "column": 20
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","and",[["subexpr","not",[["get","item.dead",["loc",[null,[782,38],[782,47]]]]],[],["loc",[null,[782,33],[782,48]]]],["subexpr","not",[["get","item.sold",["loc",[null,[782,54],[782,63]]]]],[],["loc",[null,[782,49],[782,64]]]]],[],["loc",[null,[782,28],[782,65]]]]],[],0,null,["loc",[null,[782,22],[794,29]]]]
            ],
            locals: ["item"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 772,
                "column": 12
              },
              "end": {
                "line": 799,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table-wrapper table--small box no-box@phone grid mt20 mb20");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table no-box@phone no-hover");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__head");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__cell l-8/24");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","table__title title");
            var el6 = dom.createTextNode("Name");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__cell l-8/24");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","table__title title");
            var el6 = dom.createTextNode("Spezies");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__cell l-8/24");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","table__title title");
            var el6 = dom.createTextNode("Rasse");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__body");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","customerPatients",["loc",[null,[781,28],[781,44]]]]],[],0,null,["loc",[null,[781,20],[795,29]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child46 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 799,
                "column": 12
              },
              "end": {
                "line": 803,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","mt20 ");
            var el2 = dom.createTextNode("\n                Derzeit keine Tiere hinterlegt\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 411,
              "column": 3
            },
            "end": {
              "line": 810,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("aside");
          dom.setAttribute(el1,"class","side-bar card col l-6/24@desk l-24/24@phone l-24/24@tablet patientSidebar");
          dom.setAttribute(el1,"id","patientEditSidebar");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"type","button");
          dom.setAttribute(el2,"class","mfp-close");
          var el3 = dom.createTextNode("X");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","picture");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("figure");
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","sidebar-card");
          dom.setAttribute(el3,"id","summary");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("h1");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("p");
          dom.setAttribute(el4,"class","subName mb0");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"id","insuredLabel");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","titleBlock mt20");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","cellContainer");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"class","textBelow");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","cellContainer middle");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"class","textBelow");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","cellContainer cursor");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"class","textBelow");
          var el8 = dom.createTextNode("Gewicht");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","titleBlock");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","sidebar-button-group");
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"style","width: 100%;padding-left:0");
          var el7 = dom.createTextNode("\n                ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("a");
          dom.setAttribute(el7,"class","btn tiny");
          var el8 = dom.createTextNode("\n                  ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("img");
          dom.setAttribute(el8,"src","assets/images/icons/assistant.svg");
          dom.setAttribute(el8,"style","width: 19px;margin-right: 5px;");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                  Digitaler\n                  Assistent\n                ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","titleBlock clearfix");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","sidebar-button-group");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","middle");
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("a");
          dom.setAttribute(el7,"class","btn tiny");
          var el8 = dom.createTextNode("\n                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("img");
          dom.setAttribute(el8,"src","assets/images/icons/barcode.svg");
          dom.setAttribute(el8,"style","width: 17px;");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                Etiketten\n              ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","sidebar-card");
          dom.setAttribute(el3,"id","health");
          dom.setAttribute(el3,"style","margin-top: 25px;");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","category-tab-bar");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-health");
          var el6 = dom.createTextNode("Gesundheit");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-vacc");
          var el6 = dom.createTextNode("Impfpass");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-memos");
          var el6 = dom.createTextNode("Notizen");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("ul");
          dom.setAttribute(el4,"class","tabs__body pl0 pr0 mb0");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-health");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"style","clear: both");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","showMoreBottom");
          var el7 = dom.createElement("img");
          dom.setAttribute(el7,"src","assets/images/icons/arrow-right.svg");
          dom.setAttribute(el7,"style","width: 15px;");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Details");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-vacc");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-activity");
          var el6 = dom.createTextNode("\n            In Entwicklung\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-memos");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"style","clear: both");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","showMoreBottom");
          var el7 = dom.createElement("img");
          dom.setAttribute(el7,"src","assets/images/icons/arrow-right.svg");
          dom.setAttribute(el7,"style","width: 15px;");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Notiz erstellen");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","sidebar-card");
          dom.setAttribute(el3,"id","masterdata");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","category-tab-bar");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-patientmasterdata");
          var el6 = dom.createTextNode("Profil");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-lab");
          var el6 = dom.createTextNode("Labor");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-patientxray");
          var el6 = dom.createTextNode("Röntgen");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("ul");
          dom.setAttribute(el4,"class","tabs__body pl0 pr0 mb0");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-patientmasterdata");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","col l-12/24");
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"class","dataList");
          var el8 = dom.createTextNode("\n                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("label");
          var el9 = dom.createTextNode("Nr");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("p");
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("              ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("\n        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","col l-12/24 mt10");
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"class","dataList");
          var el8 = dom.createTextNode("\n");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("            ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n          ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","sidebar-card");
          dom.setAttribute(el3,"id","customerSummary");
          var el4 = dom.createTextNode("\n\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("h1");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("p");
          dom.setAttribute(el4,"class","subName");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"id","saldoLabel");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","textBelow");
          var el7 = dom.createElement("b");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Saldo");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","titleBlock clearfix");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","sidebar-button-group mt0");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("a");
          dom.setAttribute(el7,"class","btn tiny");
          var el8 = dom.createTextNode("\n                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("img");
          dom.setAttribute(el8,"src","assets/images/icons/revenue.svg");
          dom.setAttribute(el8,"style","width: 15px;");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                Umsatz\n              ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","middle");
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("a");
          dom.setAttribute(el7,"class","btn tiny");
          var el8 = dom.createTextNode("\n                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("img");
          dom.setAttribute(el8,"src","assets/images/icons/discount.svg");
          dom.setAttribute(el8,"style","width: 15px;");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                Rabatt\n              ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","sidebar-card");
          dom.setAttribute(el3,"id","customerdata");
          dom.setAttribute(el3,"style","margin-bottom: 120px");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","category-tab-bar");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-customermasterdata");
          var el6 = dom.createTextNode("Profil");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-customerapps");
          var el6 = dom.createTextNode("Termine");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-customerpatients");
          var el6 = dom.createTextNode("Tiere");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-customerdocs");
          var el6 = dom.createTextNode("Docs");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("ul");
          dom.setAttribute(el4,"class","tabs__body pl0 pr0 mb0");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-customermasterdata");
          var el6 = dom.createTextNode("\n\n          ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","dataList ");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("        ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","showMoreBottom");
          var el7 = dom.createElement("img");
          dom.setAttribute(el7,"src","assets/images/icons/arrow-right.svg");
          dom.setAttribute(el7,"style","width: 15px;");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Details");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#customerapps");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","showMoreBottom");
          var el7 = dom.createElement("img");
          dom.setAttribute(el7,"src","assets/images/icons/arrow-right.svg");
          dom.setAttribute(el7,"style","width: 15px;");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Alle Termine");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-customerdocs");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","showMoreBottom");
          var el7 = dom.createElement("img");
          dom.setAttribute(el7,"src","assets/images/icons/arrow-right.svg");
          dom.setAttribute(el7,"style","width: 15px;");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Alle Dokumente");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-customerpatients");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","showMoreBottom");
          var el7 = dom.createElement("img");
          dom.setAttribute(el7,"src","assets/images/icons/arrow-right.svg");
          dom.setAttribute(el7,"style","width: 15px;");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Alle Tiere");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element19 = dom.childAt(fragment, [0]);
          var element20 = dom.childAt(element19, [1]);
          var element21 = dom.childAt(element19, [3]);
          var element22 = dom.childAt(element21, [1, 1]);
          var element23 = dom.childAt(element21, [3]);
          var element24 = dom.childAt(element23, [1]);
          var element25 = dom.childAt(element23, [3]);
          var element26 = dom.childAt(element23, [5]);
          var element27 = dom.childAt(element23, [15]);
          var element28 = dom.childAt(element27, [1, 1]);
          var element29 = dom.childAt(element27, [3, 1]);
          var element30 = dom.childAt(element27, [5]);
          var element31 = dom.childAt(element23, [17, 1, 1, 1]);
          var element32 = dom.childAt(element23, [19, 1]);
          var element33 = dom.childAt(element32, [3, 1]);
          var element34 = dom.childAt(element21, [5]);
          var element35 = dom.childAt(element34, [1]);
          var element36 = dom.childAt(element35, [1]);
          var element37 = dom.childAt(element35, [3]);
          var element38 = dom.childAt(element35, [7]);
          var element39 = dom.childAt(element34, [3]);
          var element40 = dom.childAt(element39, [1]);
          var element41 = dom.childAt(element39, [3]);
          var element42 = dom.childAt(element39, [5]);
          var element43 = dom.childAt(element39, [7]);
          var element44 = dom.childAt(element43, [5]);
          var element45 = dom.childAt(element21, [7]);
          var element46 = dom.childAt(element45, [1]);
          var element47 = dom.childAt(element46, [1]);
          var element48 = dom.childAt(element46, [3]);
          var element49 = dom.childAt(element46, [5]);
          var element50 = dom.childAt(element45, [3, 1]);
          var element51 = dom.childAt(element50, [1, 1]);
          var element52 = dom.childAt(element50, [3, 1]);
          var element53 = dom.childAt(element21, [9]);
          var element54 = dom.childAt(element53, [1]);
          var element55 = dom.childAt(element53, [3]);
          var element56 = dom.childAt(element53, [5]);
          var element57 = dom.childAt(element53, [7, 1]);
          var element58 = dom.childAt(element53, [11, 1]);
          var element59 = dom.childAt(element58, [1, 1]);
          var element60 = dom.childAt(element58, [3, 1]);
          var element61 = dom.childAt(element21, [11]);
          var element62 = dom.childAt(element61, [1]);
          var element63 = dom.childAt(element62, [1]);
          var element64 = dom.childAt(element62, [3]);
          var element65 = dom.childAt(element62, [5]);
          var element66 = dom.childAt(element62, [7]);
          var element67 = dom.childAt(element61, [3]);
          var element68 = dom.childAt(element67, [1]);
          var element69 = dom.childAt(element68, [1]);
          var element70 = dom.childAt(element68, [3]);
          var element71 = dom.childAt(element67, [3]);
          var element72 = dom.childAt(element71, [3]);
          var element73 = dom.childAt(element67, [5]);
          var element74 = dom.childAt(element73, [3]);
          var element75 = dom.childAt(element67, [7]);
          var element76 = dom.childAt(element75, [3]);
          var morphs = new Array(93);
          morphs[0] = dom.createElementMorph(element20);
          morphs[1] = dom.createAttrMorph(element22, 'class');
          morphs[2] = dom.createAttrMorph(element22, 'style');
          morphs[3] = dom.createElementMorph(element22);
          morphs[4] = dom.createAttrMorph(element24, 'class');
          morphs[5] = dom.createMorphAt(dom.childAt(element24, [1]),1,1);
          morphs[6] = dom.createMorphAt(element25,0,0);
          morphs[7] = dom.createMorphAt(element25,2,2);
          morphs[8] = dom.createMorphAt(element26,0,0);
          morphs[9] = dom.createMorphAt(element26,2,2);
          morphs[10] = dom.createMorphAt(element23,7,7);
          morphs[11] = dom.createMorphAt(element23,9,9);
          morphs[12] = dom.createMorphAt(dom.childAt(element23, [11]),1,1);
          morphs[13] = dom.createMorphAt(element23,13,13);
          morphs[14] = dom.createMorphAt(element28,1,1);
          morphs[15] = dom.createMorphAt(dom.childAt(element28, [3]),0,0);
          morphs[16] = dom.createMorphAt(element29,1,1);
          morphs[17] = dom.createMorphAt(dom.childAt(element29, [3]),0,0);
          morphs[18] = dom.createElementMorph(element30);
          morphs[19] = dom.createMorphAt(dom.childAt(element30, [1]),1,1);
          morphs[20] = dom.createElementMorph(element31);
          morphs[21] = dom.createMorphAt(dom.childAt(element32, [1]),1,1);
          morphs[22] = dom.createElementMorph(element33);
          morphs[23] = dom.createMorphAt(dom.childAt(element32, [5]),1,1);
          morphs[24] = dom.createAttrMorph(element36, 'class');
          morphs[25] = dom.createElementMorph(element36);
          morphs[26] = dom.createAttrMorph(element37, 'class');
          morphs[27] = dom.createElementMorph(element37);
          morphs[28] = dom.createMorphAt(element35,5,5);
          morphs[29] = dom.createAttrMorph(element38, 'class');
          morphs[30] = dom.createElementMorph(element38);
          morphs[31] = dom.createAttrMorph(element40, 'class');
          morphs[32] = dom.createElementMorph(element40);
          morphs[33] = dom.createMorphAt(element40,1,1);
          morphs[34] = dom.createAttrMorph(element41, 'class');
          morphs[35] = dom.createMorphAt(element41,1,1);
          morphs[36] = dom.createAttrMorph(element42, 'class');
          morphs[37] = dom.createAttrMorph(element43, 'class');
          morphs[38] = dom.createMorphAt(element43,1,1);
          morphs[39] = dom.createElementMorph(element44);
          morphs[40] = dom.createAttrMorph(element47, 'class');
          morphs[41] = dom.createElementMorph(element47);
          morphs[42] = dom.createAttrMorph(element48, 'class');
          morphs[43] = dom.createElementMorph(element48);
          morphs[44] = dom.createAttrMorph(element49, 'class');
          morphs[45] = dom.createElementMorph(element49);
          morphs[46] = dom.createMorphAt(element46,7,7);
          morphs[47] = dom.createAttrMorph(element50, 'class');
          morphs[48] = dom.createMorphAt(dom.childAt(element51, [3]),0,0);
          morphs[49] = dom.createMorphAt(element51,5,5);
          morphs[50] = dom.createMorphAt(element51,6,6);
          morphs[51] = dom.createMorphAt(element51,7,7);
          morphs[52] = dom.createMorphAt(element51,8,8);
          morphs[53] = dom.createMorphAt(element52,1,1);
          morphs[54] = dom.createMorphAt(element52,2,2);
          morphs[55] = dom.createMorphAt(element52,3,3);
          morphs[56] = dom.createElementMorph(element53);
          morphs[57] = dom.createAttrMorph(element54, 'class');
          morphs[58] = dom.createMorphAt(element54,1,1);
          morphs[59] = dom.createMorphAt(element55,0,0);
          morphs[60] = dom.createMorphAt(element55,2,2);
          morphs[61] = dom.createMorphAt(element55,4,4);
          morphs[62] = dom.createMorphAt(element56,0,0);
          morphs[63] = dom.createMorphAt(element56,1,1);
          morphs[64] = dom.createMorphAt(element56,3,3);
          morphs[65] = dom.createAttrMorph(element57, 'class');
          morphs[66] = dom.createMorphAt(dom.childAt(element57, [1, 0]),0,0);
          morphs[67] = dom.createMorphAt(element53,9,9);
          morphs[68] = dom.createElementMorph(element59);
          morphs[69] = dom.createElementMorph(element60);
          morphs[70] = dom.createMorphAt(dom.childAt(element58, [5]),1,1);
          morphs[71] = dom.createAttrMorph(element63, 'class');
          morphs[72] = dom.createElementMorph(element63);
          morphs[73] = dom.createAttrMorph(element64, 'class');
          morphs[74] = dom.createElementMorph(element64);
          morphs[75] = dom.createAttrMorph(element65, 'class');
          morphs[76] = dom.createElementMorph(element65);
          morphs[77] = dom.createAttrMorph(element66, 'class');
          morphs[78] = dom.createElementMorph(element66);
          morphs[79] = dom.createAttrMorph(element68, 'class');
          morphs[80] = dom.createMorphAt(element69,1,1);
          morphs[81] = dom.createMorphAt(element69,2,2);
          morphs[82] = dom.createMorphAt(element69,3,3);
          morphs[83] = dom.createElementMorph(element70);
          morphs[84] = dom.createAttrMorph(element71, 'class');
          morphs[85] = dom.createMorphAt(element71,1,1);
          morphs[86] = dom.createElementMorph(element72);
          morphs[87] = dom.createAttrMorph(element73, 'class');
          morphs[88] = dom.createMorphAt(element73,1,1);
          morphs[89] = dom.createElementMorph(element74);
          morphs[90] = dom.createAttrMorph(element75, 'class');
          morphs[91] = dom.createMorphAt(element75,1,1);
          morphs[92] = dom.createElementMorph(element76);
          return morphs;
        },
        statements: [
          ["element","action",["toggleSidebar",true],[],["loc",[null,[412,42],[412,73]]]],
          ["attribute","class",["concat",["cursor frame ",["subexpr","css-bool-evaluator",[["subexpr","or",[["get","model.sold",["loc",[null,[415,61],[415,71]]]],["get","model.dead",["loc",[null,[415,72],[415,82]]]]],[],["loc",[null,[415,57],[415,83]]]],"dead",""],[],["loc",[null,[415,36],[415,95]]]]]]],
          ["attribute","style",["concat",["background-image: url(",["subexpr","get-avatar",[["get","model.picture",["loc",[null,[415,139],[415,152]]]]],[],["loc",[null,[415,126],[415,154]]]],");"]]],
          ["element","action",["gotoPatient",["get","model.id",["loc",[null,[415,181],[415,189]]]]],[],["loc",[null,[415,158],[415,191]]]],
          ["attribute","class",["concat",["avatar ",["get","model.gender.id",["loc",[null,[420,31],[420,46]]]]]]],
          ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","model.category.id",["loc",[null,[422,32],[422,49]]]],"misc"],[],["loc",[null,[422,28],[422,57]]]]],[],["loc",[null,[422,23],[422,58]]]],["get","model.category",["loc",[null,[422,59],[422,73]]]]],[],["loc",[null,[422,18],[422,74]]]]],[],0,1,["loc",[null,[422,12],[427,19]]]],
          ["block","if",[["get","model.dead",["loc",[null,[431,18],[431,28]]]]],[],2,null,["loc",[null,[431,12],[431,102]]]],
          ["content","model.name",["loc",[null,[431,103],[431,117]]]],
          ["content","model.category.name",["loc",[null,[432,31],[432,54]]]],
          ["block","if",[["get","model.digitalRace",["loc",[null,[432,61],[432,78]]]]],[],3,4,["loc",[null,[432,55],[432,139]]]],
          ["block","if",[["get","model.outdoor",["loc",[null,[433,14],[433,27]]]]],[],5,null,["loc",[null,[433,8],[433,78]]]],
          ["block","if",[["get","model.evil",["loc",[null,[434,14],[434,24]]]]],[],6,null,["loc",[null,[434,8],[442,15]]]],
          ["block","if",[["get","model.insured",["loc",[null,[445,16],[445,29]]]]],[],7,8,["loc",[null,[445,10],[453,17]]]],
          ["block","if",[["subexpr","is-etiga-active",[],[],["loc",[null,[456,14],[456,31]]]]],[],9,null,["loc",[null,[456,8],[464,15]]]],
          ["block","if",[["get","model.gender",["loc",[null,[469,20],[469,32]]]]],[],10,11,["loc",[null,[469,14],[473,21]]]],
          ["block","if",[["get","model.castrated",["loc",[null,[474,44],[474,59]]]]],[],12,13,["loc",[null,[474,38],[474,95]]]],
          ["block","if",[["get","model.ageShort",["loc",[null,[479,20],[479,34]]]]],[],14,15,["loc",[null,[479,14],[483,21]]]],
          ["block","if",[["get","model.birthday",["loc",[null,[484,44],[484,58]]]]],[],16,17,["loc",[null,[484,38],[484,125]]]],
          ["element","action",["openWeightPanel"],[],["loc",[null,[487,44],[487,72]]]],
          ["block","if",[["get","model.currentWeight",["loc",[null,[489,20],[489,39]]]]],[],18,19,["loc",[null,[489,14],[493,21]]]],
          ["element","action",["openDigitalHelperPanel",["get","model.id",["loc",[null,[503,70],[503,78]]]]],[],["loc",[null,[503,36],[503,80]]]],
          ["block","if",[["subexpr","is-etiga-active",[],[],["loc",[null,[516,20],[516,37]]]]],[],20,null,["loc",[null,[516,14],[526,21]]]],
          ["element","action",["selectBarcode",["get","model",["loc",[null,[530,59],[530,64]]]]],[],["loc",[null,[530,34],[530,66]]]],
          ["block","if",[["subexpr","and",[["subexpr","not",[["get","model.dead",["loc",[null,[536,30],[536,40]]]]],[],["loc",[null,[536,25],[536,41]]]],["subexpr","not",[["get","model.sold",["loc",[null,[536,47],[536,57]]]]],[],["loc",[null,[536,42],[536,58]]]]],[],["loc",[null,[536,20],[536,59]]]]],[],21,null,["loc",[null,[536,14],[541,21]]]],
          ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","healthTab",["loc",[null,[549,65],[549,74]]]],"health"],[],["loc",[null,[549,61],[549,84]]]],"health","deselected"],[],["loc",[null,[549,40],[549,108]]]]]]],
          ["element","action",["selectHealthTab","health"],[],["loc",[null,[549,129],[549,166]]]],
          ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","healthTab",["loc",[null,[550,65],[550,74]]]],"vacc"],[],["loc",[null,[550,61],[550,82]]]],"health","deselected"],[],["loc",[null,[550,40],[550,106]]]]]]],
          ["element","action",["selectHealthTab","vacc"],[],["loc",[null,[550,125],[550,160]]]],
          ["block","if",[["subexpr","is-test-mandant",[],[],["loc",[null,[551,16],[551,33]]]]],[],22,null,["loc",[null,[551,10],[553,17]]]],
          ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","healthTab",["loc",[null,[554,65],[554,74]]]],"memos"],[],["loc",[null,[554,61],[554,83]]]],"health","deselected"],[],["loc",[null,[554,40],[554,107]]]]]]],
          ["element","action",["selectHealthTab","memos"],[],["loc",[null,[554,127],[554,163]]]],
          ["attribute","class",["concat",["pl0 pb0 pt10 pr0 tabs__content content cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","healthTab",["loc",[null,[557,92],[557,101]]]],"health"],[],["loc",[null,[557,88],[557,111]]]],"is-active",""],[],["loc",[null,[557,67],[557,128]]]]]]],
          ["element","action",["openMedicalProblemsPanel",["get","model.id",["loc",[null,[557,189],[557,197]]]],"infinity"],[],["loc",[null,[557,153],[557,210]]]],
          ["block","if",[["subexpr","or",[["get","model.medicalIncompatibilityNotice",["loc",[null,[558,22],[558,56]]]],["get","model.allergies",["loc",[null,[558,57],[558,72]]]]],[],["loc",[null,[558,18],[558,73]]]]],[],23,24,["loc",[null,[558,12],[562,19]]]],
          ["attribute","class",["concat",["tabs__content content pl0 pr0 pb0  ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","healthTab",["loc",[null,[566,81],[566,90]]]],"vacc"],[],["loc",[null,[566,77],[566,98]]]],"is-active",""],[],["loc",[null,[566,56],[566,115]]]]]]],
          ["inline","patient-vaccinations",[],["gotoService","gotoService","gotoProduct","gotoProduct","items",["subexpr","@mut",[["get","vaccinations",["loc",[null,[567,93],[567,105]]]]],[],[]]],["loc",[null,[567,12],[567,107]]]],
          ["attribute","class",["concat",["tabs__content content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","healthTab",["loc",[null,[569,68],[569,77]]]],"activity"],[],["loc",[null,[569,64],[569,89]]]],"is-active",""],[],["loc",[null,[569,43],[569,106]]]]]]],
          ["attribute","class",["concat",["tabs__content content pl0 pr0 pb0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","healthTab",["loc",[null,[572,80],[572,89]]]],"memos"],[],["loc",[null,[572,76],[572,98]]]],"is-active",""],[],["loc",[null,[572,55],[572,115]]]]]]],
          ["inline","patient-memos",[],["patientId",["subexpr","@mut",[["get","model.id",["loc",[null,[573,38],[573,46]]]]],[],[]],"items",["subexpr","@mut",[["get","memos",["loc",[null,[573,53],[573,58]]]]],[],[]],"reload","reloadMemos","actionReceiver",["subexpr","@mut",[["get","patientMemoPanel",["loc",[null,[573,96],[573,112]]]]],[],[]]],["loc",[null,[573,12],[573,114]]]],
          ["element","action",["addNewMemo"],[],["loc",[null,[575,40],[575,63]]]],
          ["attribute","class",["concat",["category cursor masterdata ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","patientTab",["loc",[null,[582,76],[582,86]]]],"masterdata"],[],["loc",[null,[582,72],[582,100]]]],"masterdata","deselected"],[],["loc",[null,[582,51],[582,128]]]]]]],
          ["element","action",["selectPatientTab","masterdata"],[],["loc",[null,[582,160],[582,202]]]],
          ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","patientTab",["loc",[null,[583,65],[583,75]]]],"lab"],[],["loc",[null,[583,61],[583,82]]]],"masterdata","deselected"],[],["loc",[null,[583,40],[583,110]]]]]]],
          ["element","action",["selectPatientTab","lab"],[],["loc",[null,[583,128],[583,163]]]],
          ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","patientTab",["loc",[null,[584,65],[584,75]]]],"xray"],[],["loc",[null,[584,61],[584,83]]]],"masterdata","deselected"],[],["loc",[null,[584,40],[584,111]]]]]]],
          ["element","action",["selectPatientTab","xray"],[],["loc",[null,[584,137],[584,173]]]],
          ["block","if",[["subexpr","and",[["subexpr","not",[["get","model.patient.dead",["loc",[null,[585,26],[585,44]]]]],[],["loc",[null,[585,21],[585,45]]]],["subexpr","not",[["get","model.patient.sold",["loc",[null,[585,51],[585,69]]]]],[],["loc",[null,[585,46],[585,70]]]]],[],["loc",[null,[585,16],[585,71]]]]],[],25,null,["loc",[null,[585,10],[587,17]]]],
          ["attribute","class",["concat",["pl0 pr0 pb0 tabs__content content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","patientTab",["loc",[null,[591,80],[591,90]]]],"masterdata"],[],["loc",[null,[591,76],[591,104]]]],"is-active",""],[],["loc",[null,[591,55],[591,121]]]]]]],
          ["content","model.id",["loc",[null,[595,19],[595,31]]]],
          ["block","if",[["get","model.color",["loc",[null,[596,22],[596,33]]]]],[],26,null,["loc",[null,[596,16],[599,23]]]],
          ["block","if",[["get","model.birthday",["loc",[null,[600,22],[600,36]]]]],[],27,null,["loc",[null,[600,16],[603,23]]]],
          ["block","if",[["get","model.dead",["loc",[null,[604,22],[604,32]]]]],[],28,null,["loc",[null,[604,16],[607,23]]]],
          ["block","if",[["get","model.sold",["loc",[null,[608,22],[608,32]]]]],[],29,null,["loc",[null,[608,16],[611,23]]]],
          ["block","if",[["get","model.chipId",["loc",[null,[616,20],[616,32]]]]],[],30,null,["loc",[null,[616,14],[619,21]]]],
          ["block","if",[["get","model.homeanimalNumber",["loc",[null,[620,20],[620,42]]]]],[],31,null,["loc",[null,[620,14],[623,21]]]],
          ["block","if",[["get","model.insurer",["loc",[null,[624,20],[624,33]]]]],[],32,null,["loc",[null,[624,14],[629,21]]]],
          ["element","action",["gotoCustomer",["get","model.customer.id",["loc",[null,[636,78],[636,95]]]],""],[],["loc",[null,[636,54],[636,100]]]],
          ["attribute","class",["concat",["avatar ",["get","model.customer.gender.id",["loc",[null,[638,29],[638,53]]]]]]],
          ["block","if",[["subexpr","and",[["get","model.customer.gender",["loc",[null,[639,21],[639,42]]]],["subexpr","not",[["subexpr","eq",[["get","model.customer.gender.id",["loc",[null,[639,52],[639,76]]]],"male/female/company"],[],["loc",[null,[639,48],[639,99]]]]],[],["loc",[null,[639,43],[639,100]]]]],[],["loc",[null,[639,16],[639,101]]]]],[],33,34,["loc",[null,[639,10],[649,17]]]],
          ["content","model.customer.title.name",["loc",[null,[652,12],[652,41]]]],
          ["content","model.customer.firstname",["loc",[null,[652,42],[652,70]]]],
          ["content","model.customer.lastname",["loc",[null,[652,71],[652,98]]]],
          ["block","if",[["get","model.customer.street",["loc",[null,[653,33],[653,54]]]]],[],35,null,["loc",[null,[653,27],[654,19]]]],
          ["content","model.customer.postalCode",["loc",[null,[654,19],[654,48]]]],
          ["content","model.customer.town",["loc",[null,[654,49],[654,72]]]],
          ["attribute","class",["concat",["insured ",["subexpr","css-bool-evaluator",[["subexpr","lt",[["get","balanceSum",["loc",[null,[656,57],[656,67]]]],0],[],["loc",[null,[656,53],[656,70]]]],"is-negative",""],[],["loc",[null,[656,32],[656,89]]]]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","balanceSum",["loc",[null,[657,65],[657,75]]]]],[],[]],"classNames","inline-block"],["loc",[null,[657,41],[657,103]]]],
          ["block","if",[["get","model.customer.etigaAppConnected",["loc",[null,[661,14],[661,46]]]]],[],36,37,["loc",[null,[661,8],[669,15]]]],
          ["element","action",["openRevenue",["get","model.customer.id",["loc",[null,[674,57],[674,74]]]]],["bubbles",false],["loc",[null,[674,34],[674,90]]]],
          ["element","action",["openCustomerReductions",["get","model.customer.id",["loc",[null,[680,68],[680,85]]]]],["bubbles",false],["loc",[null,[680,34],[680,101]]]],
          ["block","if",[["subexpr","not",[["get","model.customer.driveAddressAvailable",["loc",[null,[686,25],[686,61]]]]],[],["loc",[null,[686,20],[686,62]]]]],[],38,39,["loc",[null,[686,14],[697,21]]]],
          ["attribute","class",["concat",["category cursor masterdata ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[705,76],[705,87]]]],"masterdata"],[],["loc",[null,[705,72],[705,101]]]],"customerdata","deselected"],[],["loc",[null,[705,51],[705,131]]]]]]],
          ["element","action",["selectCustomerTab","masterdata"],[],["loc",[null,[705,164],[705,207]]]],
          ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[706,65],[706,76]]]],"appointments"],[],["loc",[null,[706,61],[706,92]]]],"customerdata","deselected"],[],["loc",[null,[706,40],[706,122]]]]]]],
          ["element","action",["selectCustomerTab","appointments"],[],["loc",[null,[706,149],[706,194]]]],
          ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[707,65],[707,76]]]],"patients"],[],["loc",[null,[707,61],[707,88]]]],"customerdata","deselected"],[],["loc",[null,[707,40],[707,118]]]]]]],
          ["element","action",["selectCustomerTab","patients"],[],["loc",[null,[707,149],[707,190]]]],
          ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[708,65],[708,76]]]],"documents"],[],["loc",[null,[708,61],[708,89]]]],"customerdata","deselected"],[],["loc",[null,[708,40],[708,119]]]]]]],
          ["element","action",["selectCustomerTab","documents"],[],["loc",[null,[708,147],[708,189]]]],
          ["attribute","class",["concat",["tabs__content pl0 pr0 pb0 content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[712,80],[712,91]]]],"masterdata"],[],["loc",[null,[712,76],[712,105]]]],"is-active",""],[],["loc",[null,[712,55],[712,122]]]]]]],
          ["block","if",[["get","model.customer.phones",["loc",[null,[715,16],[715,37]]]]],[],40,null,["loc",[null,[715,10],[722,17]]]],
          ["block","if",[["get","model.customer.emails",["loc",[null,[723,16],[723,37]]]]],[],41,null,["loc",[null,[723,10],[730,17]]]],
          ["block","if",[["subexpr","gt",[["get","depositSum",["loc",[null,[731,20],[731,30]]]],0],[],["loc",[null,[731,16],[731,33]]]]],[],42,null,["loc",[null,[731,10],[734,17]]]],
          ["element","action",["gotoCustomer",["get","model.customer.id",["loc",[null,[736,60],[736,77]]]],""],[],["loc",[null,[736,36],[736,82]]]],
          ["attribute","class",["concat",["tabs__content pl0 pt0 pb0 pr0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[738,76],[738,87]]]],"appointments"],[],["loc",[null,[738,72],[738,103]]]],"is-active",""],[],["loc",[null,[738,51],[738,120]]]]]]],
          ["block","if",[["get","customerAppointments",["loc",[null,[739,18],[739,38]]]]],[],43,44,["loc",[null,[739,12],[764,19]]]],
          ["element","action",["gotoCustomer",["get","model.customer.id",["loc",[null,[765,64],[765,81]]]],"appointments"],[],["loc",[null,[765,40],[765,98]]]],
          ["attribute","class",["concat",["tabs__content pb0 pt0 pl0 pr0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[767,76],[767,87]]]],"documents"],[],["loc",[null,[767,72],[767,100]]]],"is-active",""],[],["loc",[null,[767,51],[767,117]]]]]]],
          ["inline","documents-panel",[],["prefix","cdocs","loadPanels",false,"smallView",true,"showSearch",false,"useHover",false,"showBreadcrumbs",false,"actionReceiver",["subexpr","@mut",[["get","customerDocumentsPanel",["loc",[null,[768,146],[768,168]]]]],[],[]]],["loc",[null,[768,12],[768,170]]]],
          ["element","action",["gotoCustomer",["get","model.customer.id",["loc",[null,[769,64],[769,81]]]],"documents"],[],["loc",[null,[769,40],[769,95]]]],
          ["attribute","class",["concat",["tabs__content pb0 pt0 pl0 pr0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[771,76],[771,87]]]],"patients"],[],["loc",[null,[771,72],[771,99]]]],"is-active",""],[],["loc",[null,[771,51],[771,116]]]]]]],
          ["block","if",[["subexpr","and",[["subexpr","not",[["get","loading",["loc",[null,[772,28],[772,35]]]]],[],["loc",[null,[772,23],[772,36]]]],["get","customerPatients",["loc",[null,[772,37],[772,53]]]]],[],["loc",[null,[772,18],[772,54]]]]],[],45,46,["loc",[null,[772,12],[803,19]]]],
          ["element","action",["gotoCustomer",["get","model.customer.id",["loc",[null,[804,64],[804,81]]]],"patients"],[],["loc",[null,[804,40],[804,94]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18, child19, child20, child21, child22, child23, child24, child25, child26, child27, child28, child29, child30, child31, child32, child33, child34, child35, child36, child37, child38, child39, child40, child41, child42, child43, child44, child45, child46]
      };
    }());
    var child28 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 810,
              "column": 0
            },
            "end": {
              "line": 814,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("aside");
          dom.setAttribute(el1,"class","side-bar-hidden");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"type","button");
          dom.setAttribute(el2,"class","mfp-close");
          dom.setAttribute(el2,"id","showSidebar");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),0,0);
          return morphs;
        },
        statements: [
          ["inline","button-toggle-sidebar",[],["size",34,"color","#fff","toggle","toggleSidebar","strokeWidth",1,"classNames","","active",false,"content","Ein/Ausblenden"],["loc",[null,[812,61],[812,196]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 975,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/patients/edit.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-8/24");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","dropdown");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","button context mr10 dropdown d-flex align-items-center");
        dom.setAttribute(el4,"data-toggle","dropdown");
        dom.setAttribute(el4,"aria-haspopup","true");
        dom.setAttribute(el4,"aria-expanded","false");
        dom.setAttribute(el4,"style","line-height: 20px");
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("Weitere Aktionen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","dropdown-menu larger");
        dom.setAttribute(el4,"aria-labelledby","dropdownMenuButton");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"class","d-flex align-items-center dropdown-item");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","assets/images/icons/merge-primary.svg");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" Tier zusammenführen\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","with-icon button context primary");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createTextNode("Check-In");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"id","patientEditPage");
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","js-tabs tabs");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","tabs__head");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4,"class","u-mb0 clearfix");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-master-data");
        var el7 = dom.createTextNode("Stammdaten");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-future");
        var el7 = dom.createTextNode("Zukunft");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-lab");
        var el7 = dom.createTextNode("Labor");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-documents");
        var el7 = dom.createTextNode("Dokumente");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-tasks");
        var el7 = dom.createTextNode("Aufgaben");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-etiga");
        var el7 = dom.createTextNode("eltiga");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3,"class","tabs__body");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4,"id","patientEdit");
        dom.setAttribute(el4,"class","mb0 admin-form");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-master-data");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-weight");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                  ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        var el6 = dom.createTextNode("Gewicht");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-activities");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-history");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","mb30 mt30");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","quick-filter standalone col l-12/24");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","title");
        var el8 = dom.createTextNode("Schnellfilter");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createTextNode("Alle anzeigen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createTextNode("Nur eingeblendete");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createTextNode("Nur gelöschte");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","quick-filter col l-12/24");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","title");
        var el8 = dom.createTextNode("Tags");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createTextNode("Ernährung");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createTextNode("Leistungen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createTextNode("Produkte");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createTextNode("Labor");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-future");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-lab");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-medias");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        var el6 = dom.createTextNode("Dokumente");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-documents");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-correspondance");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-tasks");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-todo");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","mt30 mb30 col l-6/24@desk");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","u-mb0 field select full-width");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","title");
        var el8 = dom.createTextNode("Status");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table-wrapper box no-box@phone");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table grid table--large box no-box@phone");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__head");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-5/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("Fällig");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-10/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("Titel");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-4/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("Status");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-5/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__body");
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","pagination-wrapper is-fixed");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-appointments");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-6/24@desk mt30 mb30");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"for","filterUser");
        dom.setAttribute(el6,"class","input-element");
        dom.setAttribute(el6,"style","width: 100%");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","title");
        var el8 = dom.createTextNode("Kategorie");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-etiga");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","action-icons");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","icon-button icon-button--small");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("    ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n    $(function() {\n        $( window ).resize(function() {\n          $('aside.side-bar ').css('min-height', $(window).height()-375);\n          $('aside.side-bar div.scrollable').css('max-height', $(window).height()-375);\n        });\n        $('aside.side-bar').css('min-height', $(window).height()-375);\n        $('aside.side-bar div.scrollable').css('max-height', $(window).height()-375);\n        $('.datetimepicker').on('apply.daterangepicker', function(ev, picker) {\n            $(this).val(picker.startDate.format('DD. MM. YYYY'));\n            $(this).focus();\n        });\n        $('.datetimepicker').daterangepicker({\n            singleDatePicker: true,\n            autoUpdateInput: false,\n            \"locale\": {\n                \"format\": \"DD. MM. YYYY\",\n                \"daysOfWeek\": [\n                    \"So\",\n                    \"Mo\",\n                    \"Di\",\n                    \"Mi\",\n                    \"Do\",\n                    \"Fr\",\n                    \"Sa\"\n                ],\n                \"monthNames\": [\n                    \"Jänner\",\n                    \"Februar\",\n                    \"März\",\n                    \"April\",\n                    \"Mai\",\n                    \"Juni\",\n                    \"Juli\",\n                    \"August\",\n                    \"September\",\n                    \"Oktober\",\n                    \"November\",\n                    \"Dezember\"\n                ],\n                \"firstDay\": 1\n            },\n        });\n    });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element106 = dom.childAt(fragment, [1]);
        var element107 = dom.childAt(element106, [3]);
        var element108 = dom.childAt(element107, [1, 3]);
        var element109 = dom.childAt(element108, [7]);
        var element110 = dom.childAt(element107, [10]);
        var element111 = dom.childAt(fragment, [3]);
        var element112 = dom.childAt(element111, [1]);
        var element113 = dom.childAt(element112, [1, 1]);
        var element114 = dom.childAt(element113, [1, 1]);
        var element115 = dom.childAt(element113, [6, 1]);
        var element116 = dom.childAt(element113, [8, 1]);
        var element117 = dom.childAt(element113, [10, 1]);
        var element118 = dom.childAt(element113, [14, 1]);
        var element119 = dom.childAt(element113, [19, 1]);
        var element120 = dom.childAt(element112, [3]);
        var element121 = dom.childAt(element120, [1]);
        var element122 = dom.childAt(element121, [1]);
        var element123 = dom.childAt(element120, [3]);
        var element124 = dom.childAt(element120, [5]);
        var element125 = dom.childAt(element120, [7]);
        var element126 = dom.childAt(element125, [1]);
        var element127 = dom.childAt(element126, [1]);
        var element128 = dom.childAt(element127, [3]);
        var element129 = dom.childAt(element127, [5]);
        var element130 = dom.childAt(element127, [7]);
        var element131 = dom.childAt(element126, [3]);
        var element132 = dom.childAt(element131, [3]);
        var element133 = dom.childAt(element131, [5]);
        var element134 = dom.childAt(element131, [7]);
        var element135 = dom.childAt(element131, [9]);
        var element136 = dom.childAt(element120, [9]);
        var element137 = dom.childAt(element120, [11]);
        var element138 = dom.childAt(element120, [13]);
        var element139 = dom.childAt(element120, [15]);
        var element140 = dom.childAt(element120, [17]);
        var element141 = dom.childAt(element120, [19]);
        var element142 = dom.childAt(element120, [21]);
        var element143 = dom.childAt(element142, [3]);
        var element144 = dom.childAt(element120, [23]);
        var element145 = dom.childAt(element120, [25]);
        var element146 = dom.childAt(element111, [3]);
        var element147 = dom.childAt(element146, [1]);
        var morphs = new Array(131);
        morphs[0] = dom.createAttrMorph(element106, 'class');
        morphs[1] = dom.createMorphAt(dom.childAt(element106, [1]),1,1);
        morphs[2] = dom.createAttrMorph(element107, 'class');
        morphs[3] = dom.createMorphAt(element108,1,1);
        morphs[4] = dom.createMorphAt(element108,2,2);
        morphs[5] = dom.createMorphAt(element108,3,3);
        morphs[6] = dom.createMorphAt(element108,4,4);
        morphs[7] = dom.createMorphAt(element108,5,5);
        morphs[8] = dom.createElementMorph(element109);
        morphs[9] = dom.createMorphAt(element107,3,3);
        morphs[10] = dom.createMorphAt(element107,4,4);
        morphs[11] = dom.createMorphAt(element107,5,5);
        morphs[12] = dom.createMorphAt(element107,6,6);
        morphs[13] = dom.createMorphAt(element107,7,7);
        morphs[14] = dom.createMorphAt(element107,8,8);
        morphs[15] = dom.createElementMorph(element110);
        morphs[16] = dom.createMorphAt(element110,1,1);
        morphs[17] = dom.createAttrMorph(element111, 'class');
        morphs[18] = dom.createAttrMorph(element114, 'class');
        morphs[19] = dom.createElementMorph(element114);
        morphs[20] = dom.createMorphAt(element113,3,3);
        morphs[21] = dom.createMorphAt(element113,4,4);
        morphs[22] = dom.createAttrMorph(element115, 'class');
        morphs[23] = dom.createElementMorph(element115);
        morphs[24] = dom.createAttrMorph(element116, 'class');
        morphs[25] = dom.createElementMorph(element116);
        morphs[26] = dom.createAttrMorph(element117, 'class');
        morphs[27] = dom.createElementMorph(element117);
        morphs[28] = dom.createMorphAt(element113,12,12);
        morphs[29] = dom.createAttrMorph(element118, 'class');
        morphs[30] = dom.createElementMorph(element118);
        morphs[31] = dom.createMorphAt(element113,16,16);
        morphs[32] = dom.createMorphAt(element113,17,17);
        morphs[33] = dom.createAttrMorph(element119, 'class');
        morphs[34] = dom.createElementMorph(element119);
        morphs[35] = dom.createElementMorph(element121);
        morphs[36] = dom.createAttrMorph(element122, 'class');
        morphs[37] = dom.createMorphAt(element122,1,1);
        morphs[38] = dom.createMorphAt(element122,3,3);
        morphs[39] = dom.createAttrMorph(element123, 'class');
        morphs[40] = dom.createMorphAt(element123,1,1);
        morphs[41] = dom.createMorphAt(element123,3,3);
        morphs[42] = dom.createMorphAt(element123,7,7);
        morphs[43] = dom.createAttrMorph(element124, 'class');
        morphs[44] = dom.createMorphAt(element124,1,1);
        morphs[45] = dom.createAttrMorph(element125, 'class');
        morphs[46] = dom.createAttrMorph(element128, 'class');
        morphs[47] = dom.createElementMorph(element128);
        morphs[48] = dom.createAttrMorph(element129, 'class');
        morphs[49] = dom.createElementMorph(element129);
        morphs[50] = dom.createAttrMorph(element130, 'class');
        morphs[51] = dom.createElementMorph(element130);
        morphs[52] = dom.createAttrMorph(element132, 'class');
        morphs[53] = dom.createElementMorph(element132);
        morphs[54] = dom.createAttrMorph(element133, 'class');
        morphs[55] = dom.createElementMorph(element133);
        morphs[56] = dom.createAttrMorph(element134, 'class');
        morphs[57] = dom.createElementMorph(element134);
        morphs[58] = dom.createAttrMorph(element135, 'class');
        morphs[59] = dom.createElementMorph(element135);
        morphs[60] = dom.createMorphAt(element125,3,3);
        morphs[61] = dom.createAttrMorph(element136, 'class');
        morphs[62] = dom.createMorphAt(element136,1,1);
        morphs[63] = dom.createAttrMorph(element137, 'class');
        morphs[64] = dom.createMorphAt(element137,1,1);
        morphs[65] = dom.createAttrMorph(element138, 'class');
        morphs[66] = dom.createMorphAt(element138,3,3);
        morphs[67] = dom.createAttrMorph(element139, 'class');
        morphs[68] = dom.createMorphAt(element139,1,1);
        morphs[69] = dom.createMorphAt(element139,3,3);
        morphs[70] = dom.createAttrMorph(element140, 'class');
        morphs[71] = dom.createMorphAt(element140,1,1);
        morphs[72] = dom.createAttrMorph(element141, 'class');
        morphs[73] = dom.createMorphAt(element141,1,1);
        morphs[74] = dom.createAttrMorph(element142, 'class');
        morphs[75] = dom.createMorphAt(dom.childAt(element142, [1, 1]),3,3);
        morphs[76] = dom.createMorphAt(dom.childAt(element143, [1, 3]),1,1);
        morphs[77] = dom.createMorphAt(dom.childAt(element143, [3]),1,1);
        morphs[78] = dom.createAttrMorph(element144, 'class');
        morphs[79] = dom.createMorphAt(dom.childAt(element144, [1, 1]),3,3);
        morphs[80] = dom.createMorphAt(element144,3,3);
        morphs[81] = dom.createAttrMorph(element145, 'class');
        morphs[82] = dom.createMorphAt(element145,1,1);
        morphs[83] = dom.createElementMorph(element147);
        morphs[84] = dom.createMorphAt(element147,1,1);
        morphs[85] = dom.createMorphAt(element146,3,3);
        morphs[86] = dom.createMorphAt(element146,4,4);
        morphs[87] = dom.createMorphAt(element146,5,5);
        morphs[88] = dom.createMorphAt(element146,6,6);
        morphs[89] = dom.createMorphAt(fragment,5,5,contextualElement);
        morphs[90] = dom.createMorphAt(fragment,7,7,contextualElement);
        morphs[91] = dom.createMorphAt(fragment,9,9,contextualElement);
        morphs[92] = dom.createMorphAt(fragment,11,11,contextualElement);
        morphs[93] = dom.createMorphAt(fragment,14,14,contextualElement);
        morphs[94] = dom.createMorphAt(fragment,16,16,contextualElement);
        morphs[95] = dom.createMorphAt(fragment,18,18,contextualElement);
        morphs[96] = dom.createMorphAt(fragment,21,21,contextualElement);
        morphs[97] = dom.createMorphAt(fragment,23,23,contextualElement);
        morphs[98] = dom.createMorphAt(fragment,25,25,contextualElement);
        morphs[99] = dom.createMorphAt(fragment,27,27,contextualElement);
        morphs[100] = dom.createMorphAt(fragment,29,29,contextualElement);
        morphs[101] = dom.createMorphAt(fragment,31,31,contextualElement);
        morphs[102] = dom.createMorphAt(fragment,33,33,contextualElement);
        morphs[103] = dom.createMorphAt(fragment,35,35,contextualElement);
        morphs[104] = dom.createMorphAt(fragment,37,37,contextualElement);
        morphs[105] = dom.createMorphAt(fragment,39,39,contextualElement);
        morphs[106] = dom.createMorphAt(fragment,41,41,contextualElement);
        morphs[107] = dom.createMorphAt(fragment,43,43,contextualElement);
        morphs[108] = dom.createMorphAt(fragment,45,45,contextualElement);
        morphs[109] = dom.createMorphAt(fragment,47,47,contextualElement);
        morphs[110] = dom.createMorphAt(fragment,49,49,contextualElement);
        morphs[111] = dom.createMorphAt(fragment,51,51,contextualElement);
        morphs[112] = dom.createMorphAt(fragment,53,53,contextualElement);
        morphs[113] = dom.createMorphAt(fragment,55,55,contextualElement);
        morphs[114] = dom.createMorphAt(fragment,57,57,contextualElement);
        morphs[115] = dom.createMorphAt(fragment,59,59,contextualElement);
        morphs[116] = dom.createMorphAt(fragment,61,61,contextualElement);
        morphs[117] = dom.createMorphAt(fragment,63,63,contextualElement);
        morphs[118] = dom.createMorphAt(fragment,65,65,contextualElement);
        morphs[119] = dom.createMorphAt(fragment,67,67,contextualElement);
        morphs[120] = dom.createMorphAt(fragment,69,69,contextualElement);
        morphs[121] = dom.createMorphAt(fragment,71,71,contextualElement);
        morphs[122] = dom.createMorphAt(fragment,73,73,contextualElement);
        morphs[123] = dom.createMorphAt(fragment,75,75,contextualElement);
        morphs[124] = dom.createMorphAt(fragment,77,77,contextualElement);
        morphs[125] = dom.createMorphAt(fragment,79,79,contextualElement);
        morphs[126] = dom.createMorphAt(fragment,81,81,contextualElement);
        morphs[127] = dom.createMorphAt(fragment,83,83,contextualElement);
        morphs[128] = dom.createMorphAt(fragment,85,85,contextualElement);
        morphs[129] = dom.createMorphAt(fragment,87,87,contextualElement);
        morphs[130] = dom.createMorphAt(fragment,89,89,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["context-bar grid ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[1,52],[1,65]]]],"sidebar","sidebar-inactive"],[],["loc",[null,[1,31],[1,96]]]]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[3,14],[3,23]]]],"documents"],[],["loc",[null,[3,10],[3,36]]]]],[],0,null,["loc",[null,[3,4],[6,11]]]],
        ["attribute","class",["concat",["buttons-box col l-",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[8,55],[8,68]]]],"10","16"],[],["loc",[null,[8,34],[8,80]]]],"/24"]]],
        ["block","if",[["subexpr","is-etiga-editable",["masterData",["get","etigaPermissions",["loc",[null,[14,48],[14,64]]]],["get","model",["loc",[null,[14,65],[14,70]]]]],[],["loc",[null,[14,16],[14,71]]]]],[],1,null,["loc",[null,[14,10],[21,17]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[22,18],[22,27]]]],"documents"],[],["loc",[null,[22,14],[22,40]]]]],[],2,null,["loc",[null,[22,8],[26,15]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[27,20],[27,29]]]],"appointments"],[],["loc",[null,[27,16],[27,45]]]]],[],3,null,["loc",[null,[27,10],[31,15]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[32,20],[32,29]]]],"lab"],[],["loc",[null,[32,16],[32,36]]]]],[],4,null,["loc",[null,[32,10],[36,15]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[37,20],[37,29]]]],"future"],[],["loc",[null,[37,16],[37,39]]]]],[],5,null,["loc",[null,[37,10],[47,17]]]],
        ["element","action",["openMergePatient"],[],["loc",[null,[48,13],[48,42]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[53,16],[53,25]]]],"documents"],[],["loc",[null,[53,12],[53,38]]]]],[],6,null,["loc",[null,[53,6],[58,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[59,16],[59,25]]]],"tasks"],[],["loc",[null,[59,12],[59,34]]]]],[],7,null,["loc",[null,[59,6],[63,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[64,16],[64,25]]]],"appointments"],[],["loc",[null,[64,12],[64,41]]]]],[],8,null,["loc",[null,[64,6],[67,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[68,16],[68,25]]]],"etiga"],[],["loc",[null,[68,12],[68,34]]]]],[],9,null,["loc",[null,[68,6],[71,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[72,16],[72,25]]]],"history"],[],["loc",[null,[72,12],[72,36]]]]],[],10,null,["loc",[null,[72,6],[76,13]]]],
        ["block","if",[["get","model.etigaActive",["loc",[null,[77,12],[77,29]]]]],[],11,null,["loc",[null,[77,6],[81,13]]]],
        ["element","action",["admissionPatient",["get","model.customerId",["loc",[null,[82,37],[82,53]]]],["get","model.id",["loc",[null,[82,54],[82,62]]]]],[],["loc",[null,[82,9],[82,65]]]],
        ["inline","button-admission",[],["color","#ffffff","size","32","showStroke",false,"content","Patient aufnehmen"],["loc",[null,[83,8],[83,99]]]],
        ["attribute","class",["concat",["col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[88,48],[88,61]]]]],[],["loc",[null,[88,43],[88,62]]]],"24","18"],[],["loc",[null,[88,22],[88,74]]]],"/24@desk with-context-bar l-24/24@phone l-24/24@tablet ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[88,150],[88,163]]]],"sidebar-active",""],[],["loc",[null,[88,129],[88,185]]]]," card site-content fadeIn"]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[94,88],[94,97]]]],"masterdata"],[],["loc",[null,[94,84],[94,111]]]],"is-active",""],[],["loc",[null,[94,63],[94,128]]]]]]],
        ["element","action",["selectTab","masterdata"],[],["loc",[null,[94,154],[94,189]]]],
        ["block","if",[["subexpr","or",[["subexpr","is-journal-enabled",[],[],["loc",[null,[96,24],[96,44]]]],["subexpr","is-debug-user",[],[],["loc",[null,[96,45],[96,60]]]]],[],["loc",[null,[96,20],[96,61]]]]],[],12,null,["loc",[null,[96,14],[100,21]]]],
        ["block","if",[["subexpr","or",[["subexpr","is-test-mandant",[],[],["loc",[null,[101,24],[101,41]]]],["subexpr","not",[["subexpr","is-journal-enabled",[],[],["loc",[null,[101,47],[101,67]]]]],[],["loc",[null,[101,42],[101,68]]]],["subexpr","is-debug-user",[],[],["loc",[null,[101,69],[101,84]]]]],[],["loc",[null,[101,20],[101,85]]]]],[],13,null,["loc",[null,[101,14],[105,21]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[107,88],[107,97]]]],"future"],[],["loc",[null,[107,84],[107,107]]]],"is-active",""],[],["loc",[null,[107,63],[107,124]]]]]]],
        ["element","action",["selectTab","future"],[],["loc",[null,[107,145],[107,176]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[110,88],[110,97]]]],"lab"],[],["loc",[null,[110,84],[110,104]]]],"is-active",""],[],["loc",[null,[110,63],[110,121]]]]]]],
        ["element","action",["selectTab","lab"],[],["loc",[null,[110,139],[110,167]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[113,88],[113,97]]]],"documents"],[],["loc",[null,[113,84],[113,110]]]],"is-active",""],[],["loc",[null,[113,63],[113,127]]]]]]],
        ["element","action",["selectTab","documents"],[],["loc",[null,[113,151],[113,185]]]],
        ["block","if",[["subexpr","or",[["subexpr","not",[["subexpr","is-journal-enabled",[],[],["loc",[null,[115,31],[115,51]]]]],[],["loc",[null,[115,26],[115,52]]]],["subexpr","is-debug-user",[],[],["loc",[null,[115,53],[115,68]]]]],[],["loc",[null,[115,22],[115,69]]]]],[],14,null,["loc",[null,[115,16],[119,23]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[121,88],[121,97]]]],"tasks"],[],["loc",[null,[121,84],[121,106]]]],"is-active",""],[],["loc",[null,[121,63],[121,123]]]]]]],
        ["element","action",["selectTab","tasks"],[],["loc",[null,[121,143],[121,173]]]],
        ["block","if",[["subexpr","is-test-mandant",[],[],["loc",[null,[123,20],[123,37]]]]],[],15,null,["loc",[null,[123,14],[127,21]]]],
        ["block","if",[["subexpr","has-access",["calendar"],[],["loc",[null,[128,20],[128,43]]]]],[],16,null,["loc",[null,[128,14],[132,21]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[134,88],[134,97]]]],"etiga"],[],["loc",[null,[134,84],[134,106]]]],"is-active",""],[],["loc",[null,[134,63],[134,123]]]]]]],
        ["element","action",["selectTab","etiga"],[],["loc",[null,[134,143],[134,173]]]],
        ["element","action",["save"],["on","submit"],["loc",[null,[139,58],[139,87]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[140,66],[140,75]]]],"masterdata"],[],["loc",[null,[140,62],[140,89]]]],"is-active",""],[],["loc",[null,[140,41],[140,106]]]]," mt30"]]],
        ["inline","patients-edit-form",[],["edit",true,"digitalRaceEnabled",true,"customerOverride",["subexpr","@mut",[["get","customerOverride",["loc",[null,[141,92],[141,108]]]]],[],[]],"model",["subexpr","@mut",[["get","model",["loc",[null,[141,115],[141,120]]]]],[],[]],"openLogbook","openEtigaMasterdataLogbook","changeUser","changeUser","etigaPermissions",["subexpr","@mut",[["get","etigaPermissions",["loc",[null,[141,203],[141,219]]]]],[],[]],"genders",["subexpr","@mut",[["get","genders",["loc",[null,[141,228],[141,235]]]]],[],[]],"castrationOptions",["subexpr","@mut",[["get","castrationOptions",["loc",[null,[141,254],[141,271]]]]],[],[]],"categories",["subexpr","@mut",[["get","categories",["loc",[null,[141,283],[141,293]]]]],[],[]],"customer",["subexpr","@mut",[["get","customer",["loc",[null,[141,303],[141,311]]]]],[],[]],"insurers",["subexpr","@mut",[["get","insurers",["loc",[null,[141,321],[141,329]]]]],[],[]],"patientImage",["subexpr","@mut",[["get","patientImage",["loc",[null,[141,343],[141,355]]]]],[],[]],"imageChanged","changeImage","actionReceiver",["subexpr","@mut",[["get","patientEditForm",["loc",[null,[141,398],[141,413]]]]],[],[]]],["loc",[null,[141,20],[141,415]]]],
        ["block","if",[["subexpr","eq",[["get","model.category.id",["loc",[null,[142,30],[142,47]]]],"pferd"],[],["loc",[null,[142,26],[142,56]]]]],[],17,null,["loc",[null,[142,20],[145,27]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[148,62],[148,71]]]],"weight"],[],["loc",[null,[148,58],[148,81]]]],"is-active",""],[],["loc",[null,[148,37],[148,98]]]]]]],
        ["block","if",[["get","weightInfos",["loc",[null,[149,22],[149,33]]]]],[],18,null,["loc",[null,[149,16],[151,23]]]],
        ["inline","patient-weight-chart",[],["patientId",["subexpr","@mut",[["get","model.id",["loc",[null,[152,49],[152,57]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","weightChart",["loc",[null,[152,73],[152,84]]]]],[],[]]],["loc",[null,[152,16],[152,86]]]],
        ["inline","patient-weight",[],["weightInfos",["subexpr","@mut",[["get","weightInfos",["loc",[null,[154,45],[154,56]]]]],[],[]],"edit","openWeight","delete","deleteWeight","editable",["subexpr","is-etiga-editable",["addWeight",["get","etigaPermissions",["loc",[null,[154,137],[154,153]]]],["get","model",["loc",[null,[154,154],[154,159]]]]],[],["loc",[null,[154,106],[154,160]]]],"patientId",["subexpr","@mut",[["get","model.id",["loc",[null,[154,171],[154,179]]]]],[],[]]],["loc",[null,[154,16],[154,181]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[156,60],[156,69]]]],"activities"],[],["loc",[null,[156,56],[156,83]]]],"is-active",""],[],["loc",[null,[156,35],[156,100]]]]]]],
        ["inline","patient-activities",[],["editNote","editMemo","editTreatment","editFullTreatment","openReportChooser","openReportChooser","openDocument","openDocument","gotoReport","gotoReport","gotoTemplate","gotoTemplate","openLab","openLab","openApp","openEditAppointment","actionReceiver",["subexpr","@mut",[["get","patientActivities",["loc",[null,[166,31],[166,48]]]]],[],[]]],["loc",[null,[157,14],[166,50]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[168,60],[168,69]]]],"history"],[],["loc",[null,[168,56],[168,80]]]],"is-active",""],[],["loc",[null,[168,35],[168,97]]]]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[172,73],[172,84]]]],"all"],[],["loc",[null,[172,69],[172,91]]]],"selected",""],[],["loc",[null,[172,48],[172,108]]]]]]],
        ["element","action",["toggleQuickFilter","all"],[],["loc",[null,[172,110],[172,146]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[173,73],[173,84]]]],"visible"],[],["loc",[null,[173,69],[173,95]]]],"selected",""],[],["loc",[null,[173,48],[173,112]]]]]]],
        ["element","action",["toggleQuickFilter","visible"],[],["loc",[null,[173,114],[173,154]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[174,73],[174,84]]]],"deleted"],[],["loc",[null,[174,69],[174,95]]]],"selected",""],[],["loc",[null,[174,48],[174,112]]]]]]],
        ["element","action",["toggleQuickFilter","deleted"],[],["loc",[null,[174,114],[174,154]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","filterFood",["loc",[null,[178,69],[178,79]]]],"selected",""],[],["loc",[null,[178,48],[178,95]]]]]]],
        ["element","action",["toggleTabFilter","filterFood"],[],["loc",[null,[178,97],[178,138]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","filterServices",["loc",[null,[179,69],[179,83]]]],"selected",""],[],["loc",[null,[179,48],[179,99]]]]]]],
        ["element","action",["toggleTabFilter","filterServices"],[],["loc",[null,[179,101],[179,146]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","filterProducts",["loc",[null,[180,69],[180,83]]]],"selected",""],[],["loc",[null,[180,48],[180,99]]]]]]],
        ["element","action",["toggleTabFilter","filterProducts"],[],["loc",[null,[180,101],[180,146]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","filterLab",["loc",[null,[181,69],[181,78]]]],"selected",""],[],["loc",[null,[181,48],[181,94]]]]]]],
        ["element","action",["toggleTabFilter","filterLab"],[],["loc",[null,[181,96],[181,136]]]],
        ["inline","treatment-history",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[186,22],[186,27]]]]],[],[]],"showCopy",false,"showEdit",true,"showHide",true,"showHideApp",true,"showFilter",false,"users",["subexpr","@mut",[["get","users",["loc",[null,[192,22],[192,27]]]]],[],[]],"etigaPermissions",["subexpr","@mut",[["get","etigaPermissions",["loc",[null,[193,33],[193,49]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[194,24],[194,31]]]]],[],[]],"reload","reloadHistory","gotoReport","gotoReport","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[197,22],[197,27]]]]],[],[]],"showPager",true,"reload","reloadHistory","assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[200,27],[200,37]]]]],[],[]],"openDigitalForm","openDigitalForm","employeesSaved","employeesSaved","openSlopingInfos","openSlopingInfos","roomSaved","roomSaved","downloadSlopingBarcode","selectSlopingBarcodeForInvoice","deleteTreatment","deleteTreatment","editTreatmentPanel","editTreatmentPanel","treatmentSaved","saveTreatment","openTemplateEdit","openTemplateEdit","forwardToBilling","forwardToBilling","openLaboklinChoosePanel","openLaboklinChoosePanel","items",["subexpr","@mut",[["get","historicTreatments",["loc",[null,[212,22],[212,40]]]]],[],[]]],["loc",[null,[185,14],[212,42]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[214,62],[214,71]]]],"future"],[],["loc",[null,[214,58],[214,81]]]],"is-active",""],[],["loc",[null,[214,37],[214,98]]]]]]],
        ["inline","treatment-future",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[216,22],[216,27]]]]],[],[]],"items",["subexpr","@mut",[["get","futureItems",["loc",[null,[217,22],[217,33]]]]],[],[]],"crud",false,"editFuture","editFuture","reload","reloadFuture","moveFuture","moveFuture","addDirectNotification","openAddDirectNotification","addDirectAppNotification","openAddAppDirectNotification","addFutureAction","addFutureAction","deleteFutureEntry","deleteFuture","actionReceiver",["subexpr","@mut",[["get","treatmentFuturePanel",["loc",[null,[226,29],[226,49]]]]],[],[]]],["loc",[null,[215,14],[226,51]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[228,62],[228,71]]]],"lab"],[],["loc",[null,[228,58],[228,78]]]],"is-active",""],[],["loc",[null,[228,37],[228,95]]]]]]],
        ["inline","laboklin-historic-forms",[],["actionReceiver",["subexpr","@mut",[["get","historicItems",["loc",[null,[229,57],[229,70]]]]],[],[]],"downloadBarcode","selectLaboklinBarcode","editable",["subexpr","is-etiga-editable",["addLabor",["get","etigaPermissions",["loc",[null,[231,81],[231,97]]]],["get","model",["loc",[null,[231,98],[231,103]]]]],[],["loc",[null,[231,51],[231,104]]]],"showSendAll",false,"showEdit",true,"size",40,"downloadMedia","downloadMedia","editLaboklinForm","editLaboklinForm","downloadVetconnectResult","downloadVetconnectResult","openResult","openResult","downloadResult","downloadResult","downloadPdf","downloadPdf","forwardEmail","openEmailForwardPanel","deleteReport","deleteReport","submitLaboklin","submitLaboklin","reload","reloadLaboklin","labItems",["subexpr","@mut",[["get","labItems",["loc",[null,[245,51],[245,59]]]]],[],[]]],["loc",[null,[229,16],[245,61]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[247,62],[247,71]]]],"medias"],[],["loc",[null,[247,58],[247,81]]]],"is-active",""],[],["loc",[null,[247,37],[247,98]]]]]]],
        ["inline","patient-medias",[],["items",["subexpr","@mut",[["get","medias",["loc",[null,[249,39],[249,45]]]]],[],[]],"deleteMedia","deleteMedia","editable",["subexpr","is-etiga-editable",["addDocs",["get","etigaPermissions",["loc",[null,[249,110],[249,126]]]],["get","model",["loc",[null,[249,127],[249,132]]]]],[],["loc",[null,[249,81],[249,133]]]],"forwardEmail","openDocumentForwardPanel","reloadMedias","reloadMedias"],["loc",[null,[249,16],[249,204]]]],
        ["attribute","class",["concat",["pl0 pr0 tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[251,70],[251,79]]]],"documents"],[],["loc",[null,[251,66],[251,92]]]],"is-active",""],[],["loc",[null,[251,45],[251,109]]]]]]],
        ["block","if",[["get","showFilters",["loc",[null,[252,22],[252,33]]]]],[],19,null,["loc",[null,[252,16],[257,23]]]],
        ["inline","documents-panel",[],["showSearch",false,"showBreadcrumbs",true,"updateSearch","updateSearch","forwardLab","openEmailForwardPanel","actionReceiver",["subexpr","@mut",[["get","documentsPanel",["loc",[null,[263,31],[263,45]]]]],[],[]]],["loc",[null,[258,14],[263,47]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[265,62],[265,71]]]],"correspondance"],[],["loc",[null,[265,58],[265,89]]]],"is-active",""],[],["loc",[null,[265,37],[265,106]]]]," mt30"]]],
        ["inline","customer-correspondance",[],["items",["subexpr","@mut",[["get","logbookEntries",["loc",[null,[266,48],[266,62]]]]],[],[]]],["loc",[null,[266,16],[266,64]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[268,62],[268,71]]]],"tasks"],[],["loc",[null,[268,58],[268,80]]]],"is-active",""],[],["loc",[null,[268,37],[268,97]]]]," mt30"]]],
        ["inline","patient-tasks",[],["items",["subexpr","@mut",[["get","tasks",["loc",[null,[269,36],[269,41]]]]],[],[]],"startTask","startTask","createNotification","addMessage","showNotification","showNotification","finishTask","finishTask","showTask","showTask","startTask","startTask","changeOwner","changeOwner","setSortDir","setSortDir"],["loc",[null,[269,14],[277,55]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[279,60],[279,69]]]],"todo"],[],["loc",[null,[279,56],[279,77]]]],"is-active",""],[],["loc",[null,[279,35],[279,94]]]]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","todoStatus",["loc",[null,[284,34],[284,44]]]]],[],[]],"value",["subexpr","@mut",[["get","filterTodoStatus",["loc",[null,[285,32],[285,48]]]]],[],[]],"optionLabelPath","value","optionValuePath","id","allowClear",true,"placeholder","Alle","cssClass","input--dropdown"],["loc",[null,[283,18],[291,20]]]],
        ["block","each",[["get","todos",["loc",[null,[304,28],[304,33]]]]],[],20,null,["loc",[null,[304,20],[331,29]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","todos",["loc",[null,[335,41],[335,46]]]]],[],[]],"numPagesToShow",7],["loc",[null,[335,18],[335,65]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[339,62],[339,71]]]],"appointments"],[],["loc",[null,[339,58],[339,87]]]],"is-active",""],[],["loc",[null,[339,37],[339,104]]]]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","patientAppointmentCategories",["loc",[null,[344,28],[344,56]]]]],[],[]],"value",["subexpr","@mut",[["get","filterCategory",["loc",[null,[345,26],[345,40]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","optionValuePath","id","allowClear",true,"placeholder","Alle"],["loc",[null,[343,18],[351,20]]]],
        ["inline","patient-appointments",[],["startTreatment","admissionPatient","referer","patient","delete","deleteAppointment","edit","openEditAppointment","showAppointment","showAppointment","stickyHeader",false,"items",["subexpr","@mut",[["get","appointments",["loc",[null,[355,202],[355,214]]]]],[],[]]],["loc",[null,[355,14],[355,216]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[357,62],[357,71]]]],"etiga"],[],["loc",[null,[357,58],[357,80]]]],"is-active",""],[],["loc",[null,[357,37],[357,97]]]]]]],
        ["block","if",[["subexpr","is-etiga-active",[],[],["loc",[null,[358,20],[358,37]]]]],[],21,22,["loc",[null,[358,14],[377,21]]]],
        ["element","action",["back"],[],["loc",[null,[383,48],[383,65]]]],
        ["content","button-prev",["loc",[null,[384,8],[384,23]]]],
        ["block","if",[["subexpr","and",[["subexpr","eq",[["get","selectTab",["loc",[null,[386,21],[386,30]]]],"masterdata"],[],["loc",[null,[386,17],[386,44]]]],["subexpr","is-etiga-editable",["masterData",["get","etigaPermissions",["loc",[null,[386,77],[386,93]]]],["get","model",["loc",[null,[386,94],[386,99]]]]],[],["loc",[null,[386,45],[386,100]]]]],[],["loc",[null,[386,12],[386,101]]]]],[],23,null,["loc",[null,[386,6],[390,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[391,16],[391,25]]]],"etiga"],[],["loc",[null,[391,12],[391,34]]]]],[],24,null,["loc",[null,[391,6],[395,13]]]],
        ["block","if",[["subexpr","and",[["subexpr","is-etiga-editable",["addWeight",["get","etigaPermissions",["loc",[null,[396,48],[396,64]]]],["get","model",["loc",[null,[396,65],[396,70]]]]],[],["loc",[null,[396,17],[396,71]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[396,76],[396,85]]]],"weight"],[],["loc",[null,[396,72],[396,95]]]]],[],["loc",[null,[396,12],[396,96]]]]],[],25,null,["loc",[null,[396,6],[403,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[404,16],[404,25]]]],"medias"],[],["loc",[null,[404,12],[404,35]]]]],[],26,null,["loc",[null,[404,6],[408,13]]]],
        ["block","if",[["get","sidebarActive",["loc",[null,[411,9],[411,22]]]]],[],27,28,["loc",[null,[411,3],[814,7]]]],
        ["inline","customer-revenue-panel",[],["actionReceiver",["subexpr","@mut",[["get","customerRevenuePanel",["loc",[null,[816,40],[816,60]]]]],[],[]]],["loc",[null,[816,0],[816,62]]]],
        ["inline","media-upload-panel",[],["actionReceiver",["subexpr","@mut",[["get","mediaPanel",["loc",[null,[817,36],[817,46]]]]],[],[]],"patient",["subexpr","@mut",[["get","model",["loc",[null,[817,55],[817,60]]]]],[],[]],"mediaCategories",["subexpr","@mut",[["get","mediaCategories",["loc",[null,[817,77],[817,92]]]]],[],[]],"reloadMedias","reloadMedias"],["loc",[null,[817,0],[817,122]]]],
        ["inline","customer-reduction-panel",[],["actionReceiver",["subexpr","@mut",[["get","setCustomer",["loc",[null,[818,42],[818,53]]]]],[],[]]],["loc",[null,[818,0],[818,55]]]],
        ["inline","email-invoice-panel",[],["actionReceiver",["subexpr","@mut",[["get","forwardEmailPanel",["loc",[null,[821,37],[821,54]]]]],[],[]],"confirm","sendEmail"],["loc",[null,[821,0],[821,76]]]],
        ["inline","finish-task-panel",[],["confirm","finishTaskSend","actionReceiver",["subexpr","@mut",[["get","finishTaskPanel",["loc",[null,[823,60],[823,75]]]]],[],[]]],["loc",[null,[823,0],[823,77]]]],
        ["inline","change-task-owner-panel",[],["confirm","changedTaskOwner","users",["subexpr","@mut",[["get","users",["loc",[null,[824,59],[824,64]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","changeTaskOwnerPanel",["loc",[null,[824,80],[824,100]]]]],[],[]]],["loc",[null,[824,0],[824,102]]]],
        ["inline","add-appointment-panel",[],["users",["subexpr","@mut",[["get","users",["loc",[null,[828,34],[828,39]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[828,48],[828,55]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[828,67],[828,77]]]]],[],[]],"resources",["subexpr","@mut",[["get","resources",["loc",[null,[828,88],[828,97]]]]],[],[]],"rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[828,104],[828,109]]]]],[],[]],"stations",["subexpr","@mut",[["get","stations",["loc",[null,[828,119],[828,127]]]]],[],[]],"create","createAppointment","actionReceiver",["subexpr","@mut",[["get","addAppointmentPanel",["loc",[null,[828,170],[828,189]]]]],[],[]]],["loc",[null,[828,4],[828,191]]]],
        ["inline","add-task-panel",[],["users",["subexpr","@mut",[["get","users",["loc",[null,[830,23],[830,28]]]]],[],[]],"create","create","reloadPatients","reloadPatients","actionReceiver",["subexpr","@mut",[["get","addTaskPanel",["loc",[null,[830,92],[830,104]]]]],[],[]]],["loc",[null,[830,0],[830,106]]]],
        ["inline","show-task-panel",[],["actionReceiver",["subexpr","@mut",[["get","showTaskPanel",["loc",[null,[831,33],[831,46]]]]],[],[]],"linkTo","linkTo","goto","goto","create","createNotification","addReply","addReply","startTask","startTask","finishTask","finishTask","addMessage","addMessage","changeOwner","changeOwner"],["loc",[null,[831,0],[831,220]]]],
        ["inline","show-appointment-panel",[],["actionReceiver",["subexpr","@mut",[["get","showAppointmentPanel",["loc",[null,[832,40],[832,60]]]]],[],[]]],["loc",[null,[832,0],[832,62]]]],
        ["inline","show-notification-panel",[],["actionReceiver",["subexpr","@mut",[["get","showNotificationPanel",["loc",[null,[834,41],[834,62]]]]],[],[]]],["loc",[null,[834,0],[834,64]]]],
        ["inline","add-notification-panel",[],["users",["subexpr","@mut",[["get","users",["loc",[null,[836,31],[836,36]]]]],[],[]],"mandants",["subexpr","@mut",[["get","mandants",["loc",[null,[836,46],[836,54]]]]],[],[]],"mandant",["subexpr","@mut",[["get","mandant",["loc",[null,[836,63],[836,70]]]]],[],[]],"create","createNotification","forward","sendForward","actionReceiver",["subexpr","@mut",[["get","addNotificationPanel",["loc",[null,[836,136],[836,156]]]]],[],[]]],["loc",[null,[836,0],[836,158]]]],
        ["inline","etiga-logbook-panel",[],["actionReceiver",["subexpr","@mut",[["get","etigaLogbookPanel",["loc",[null,[837,37],[837,54]]]]],[],[]]],["loc",[null,[837,0],[837,56]]]],
        ["inline","patient-select-barcode-panel",[],["download","downloadPatientBarcode","actionReceiver",["subexpr","@mut",[["get","patientSelectBarcodePanel",["loc",[null,[839,80],[839,105]]]]],[],[]]],["loc",[null,[839,0],[839,107]]]],
        ["inline","laboklin-select-barcode-panel",[],["download","downloadLaboklinBarcode","actionReceiver",["subexpr","@mut",[["get","laboklinSelectBarcodePanel",["loc",[null,[840,82],[840,108]]]]],[],[]]],["loc",[null,[840,0],[840,110]]]],
        ["inline","choose-existing-etiga-panel",[],["createNew","createNew","select","connectToExistingEtigaCustomer","actionReceiver",["subexpr","@mut",[["get","existingEtigaPanel",["loc",[null,[841,107],[841,125]]]]],[],[]]],["loc",[null,[841,0],[841,127]]]],
        ["inline","sloping-info-panel",[],["save","saveSlopingInfo","gotoProduct","gotoProduct","gotoService","gotoService","selectBarcode","downloadSlopingBarcode","actionReceiver",["subexpr","@mut",[["get","slopingInfoPanel",["loc",[null,[842,150],[842,166]]]]],[],[]]],["loc",[null,[842,0],[842,168]]]],
        ["inline","edit-medical-problems-panel",[],["confirm","saveMedicalProblemsInfo","openEditDosing","openEditDosing","refresh","refreshPastDosings","actionReceiver",["subexpr","@mut",[["get","medicalProblemsPanel",["loc",[null,[843,140],[843,160]]]]],[],[]]],["loc",[null,[843,0],[843,162]]]],
        ["inline","edit-past-actions-panel",[],["save","savePastAction","actionReceiver",["subexpr","@mut",[["get","editPastActionsPanel",["loc",[null,[844,63],[844,83]]]]],[],[]]],["loc",[null,[844,0],[844,85]]]],
        ["inline","edit-lab-date-panel",[],["confirm","changeLabDate","actionReceiver",["subexpr","@mut",[["get","changeLabDatePanel",["loc",[null,[846,61],[846,79]]]]],[],[]]],["loc",[null,[846,0],[846,81]]]],
        ["inline","digital-form-panel",[],["confirm","updateDigitalForm","actionReceiver",["subexpr","@mut",[["get","digitalFormPanel",["loc",[null,[850,25],[850,41]]]]],[],[]]],["loc",[null,[848,0],[850,43]]]],
        ["inline","add-etiga-share",[],["etigaShareInfos",["subexpr","@mut",[["get","etigaShareInfos",["loc",[null,[853,18],[853,33]]]]],[],[]],"select","addEtigaPermisison","remove","removeEtigaShare","actionReceiver",["subexpr","@mut",[["get","addEtigaShare",["loc",[null,[856,17],[856,30]]]]],[],[]]],["loc",[null,[852,0],[856,32]]]],
        ["inline","digital-helper-qr-panel",[],["actionReceiver",["subexpr","@mut",[["get","digitalHelperPanel",["loc",[null,[858,41],[858,59]]]]],[],[]]],["loc",[null,[858,0],[858,61]]]],
        ["inline","laboklin-treatment-panel",[],["treatment",true,"allowUnlinking",false,"removeEntry","unlinkLaboklin","editLab","editLab","delete","deleteLab","unlink","unlinkLaboklin","submitLaboklin","submitLaboklin","downloadMedia","downloadMedia","downloadBarcode","downloadBarcode","downloadPdf","downloadPdf","actionReceiver",["subexpr","@mut",[["get","laboklinTreatmentPanel",["loc",[null,[871,17],[871,39]]]]],[],[]]],["loc",[null,[860,0],[871,41]]]],
        ["inline","private-treatment-panel",[],["treatment",true,"allowUnlinking",false,"removeEntry","unlinkLaboklin","editLab","editLab","delete","deleteLab","unlink","unlinkLaboklin","downloadResult","downloadResult","openLabResult","openLabResult","submitLaboklin","submitLaboklin","downloadMedia","downloadMedia","downloadBarcode","downloadBarcode","downloadPdf","downloadPdf","actionReceiver",["subexpr","@mut",[["get","privateTreatmentPanel",["loc",[null,[886,17],[886,38]]]]],[],[]]],["loc",[null,[873,0],[886,40]]]],
        ["inline","lab-result-panel",[],["changeParamValue","changeParamValue","downloadResult","downloadResult","openChart","openChart","selectBarcode","selectBarcode","dateChanged","openChangeDate","save","saveLabResult","actionReceiver",["subexpr","@mut",[["get","labResultPanel",["loc",[null,[894,17],[894,31]]]]],[],[]]],["loc",[null,[887,0],[894,33]]]],
        ["inline","all-lab-result-panel",[],["openChart","openChart","actionReceiver",["subexpr","@mut",[["get","allLabResultPanel",["loc",[null,[897,17],[897,34]]]]],[],[]]],["loc",[null,[895,0],[897,36]]]],
        ["inline","lab-result-chart-panel",[],["back","openResult","actionReceiver",["subexpr","@mut",[["get","labResultChartPanel",["loc",[null,[900,17],[900,36]]]]],[],[]]],["loc",[null,[898,0],[900,38]]]],
        ["inline","etiga-masterdata-logbook-panel",[],["actionReceiver",["subexpr","@mut",[["get","etigaMasterdataLogbookPanel",["loc",[null,[902,17],[902,44]]]]],[],[]]],["loc",[null,[901,0],[902,46]]]],
        ["inline","patient-chip-panel",[],["genders",["subexpr","@mut",[["get","genders",["loc",[null,[905,12],[905,19]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","patientChipPanel",["loc",[null,[906,19],[906,35]]]]],[],[]]],["loc",[null,[904,2],[906,37]]]],
        ["inline","start-treatment-panel",[],["confirm","selectCustomerConfirm","gotoCustomer","gotoCustomer","gotoPatient","gotoPatient","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[912,8],[912,13]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[913,10],[913,17]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[914,13],[914,23]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","startTreatmentPanel",["loc",[null,[915,17],[915,36]]]]],[],[]]],["loc",[null,[908,2],[915,38]]]],
        ["inline","set-death-day-panel",[],["confirm","updateDeathDate","remove","removeDeathDate","actionReceiver",["subexpr","@mut",[["get","deathDayPanel",["loc",[null,[917,88],[917,101]]]]],[],[]]],["loc",[null,[917,0],[917,103]]]],
        ["inline","treatment-weight-panel",[],["weightInfos",["subexpr","@mut",[["get","weightInfos",["loc",[null,[918,37],[918,48]]]]],[],[]],"patient",["subexpr","@mut",[["get","model",["loc",[null,[918,57],[918,62]]]]],[],[]],"reloadWeightData","reloadWeightData","reloadPatient","reloadPatient","reopen","openWeightPanel","actionReceiver",["subexpr","@mut",[["get","weightChart",["loc",[null,[918,169],[918,180]]]]],[],[]]],["loc",[null,[918,0],[918,182]]]],
        ["inline","add-direct-app-notification-panel",[],["showTitle",false,"reload","reload","create","addDirectAppNotification","actionReceiver",["subexpr","@mut",[["get","addDirectAppNotification",["loc",[null,[919,117],[919,141]]]]],[],[]]],["loc",[null,[919,0],[919,143]]]],
        ["inline","add-direct-notification-panel",[],["reload","reload","create","addDirectNotification","users",["subexpr","@mut",[["get","users",["loc",[null,[920,85],[920,90]]]]],[],[]],"mandants",["subexpr","@mut",[["get","mandants",["loc",[null,[920,100],[920,108]]]]],[],[]],"mandant",["subexpr","@mut",[["get","mandant",["loc",[null,[920,117],[920,124]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","addDirectNotification",["loc",[null,[920,140],[920,161]]]]],[],[]]],["loc",[null,[920,0],[920,163]]]],
        ["inline","treatment-info-form-clone",[],["treatmentSaved","saveTreatment","actionReceiver",["subexpr","@mut",[["get","getTreatmentInfoForm",["loc",[null,[921,74],[921,94]]]]],[],[]]],["loc",[null,[921,0],[921,96]]]],
        ["inline","template-edit-panel",[],["download","downloadTemplate","reloadTemplate","reloadTemplate","actionReceiver",["subexpr","@mut",[["get","templateEditPanel",["loc",[null,[922,97],[922,114]]]]],[],[]]],["loc",[null,[922,0],[922,116]]]],
        ["inline","select-customer-panel",[],["select","changePatientOwner","actionReceiver",["subexpr","@mut",[["get","selectCustomerPanel",["loc",[null,[923,67],[923,86]]]]],[],[]]],["loc",[null,[923,0],[923,88]]]],
        ["inline","edit-todo-panel",[],["confirm","saveTodoInstance","actionReceiver",["subexpr","@mut",[["get","editTodoPanel",["loc",[null,[924,60],[924,73]]]]],[],[]]],["loc",[null,[924,0],[924,75]]]],
        ["inline","email-etiga-panel",[],["confirm","sendEtigaMail","openAddAppDirectNotification","openDirectMessage","qrDownload","downloadEtigaQr","actionReceiver",["subexpr","@mut",[["get","emailEtigaPanel",["loc",[null,[926,137],[926,152]]]]],[],[]]],["loc",[null,[926,0],[926,154]]]],
        ["inline","merge-patient-panel",[],["gotoPatient","gotoPatient","actionReceiver",["subexpr","@mut",[["get","mergePatientPanel",["loc",[null,[928,63],[928,80]]]]],[],[]]],["loc",[null,[928,0],[928,82]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18, child19, child20, child21, child22, child23, child24, child25, child26, child27, child28]
    };
  }()));

});