define('jason-frontend/routes/users/index', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    perPage: 10,
    queryParams: {
      filterName: {
        refreshModel: true
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      $.ajax({
        url: "/api/users/meta/contigent",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('contingent', data.mandantUserContingent);
      });
    },
    model: function model(params) {
      params.filterMandant = true;
      params.includeInactive = true;
      return this.findPaged('user', params);
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          filterName = controller.get('filterName'),
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy');

      $.ajax({
        url: "/api/users/meta/contigent",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('contingent', data.mandantUserContingent);
      });

      this.findPaged('user', {
        filterName: filterName,
        includeInactive: true
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    actions: {
      openUpgradePanel: function openUpgradePanel(type) {
        this.get('controller').get('upgradePanel').send('load');

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-upgrade-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      'delete': function _delete(id) {
        var controller = this.get('controller');
        this.get('store').find('user', id).then(function (rec) {
          rec.destroyRecord();

          setTimeout(function () {
            $.ajax({
              url: "/api/users/meta/contigent",
              method: "GET",
              contentType: "application/json",
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function (data) {
              controller.set('contingent', data.mandantUserContingent);
            });
          }, 1000);
        });
      }
    }
  });

});