define('jason-frontend/routes/stables/index', ['exports', 'ember', 'jason-frontend/helpers/is-journal-enabled'], function (exports, Ember, is_journal_enabled) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      return this.store.find('stable');
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model.sortBy('name'));
    },
    actions: {
      gotoHistory: function gotoHistory(patient) {
        var tab = 'history';
        if (is_journal_enabled.isJournalEnabled()) {
          tab = 'activities';
        }
        this.transitionTo('patients.edit', patient.id, { queryParams: { tab: tab } });
      }
    }
  });
  // delete: function (id) {
  //   var controller = this.get('controller'),
  //     self = this;
  //   this.get('store').find('productCategory', id).then(function(rec){
  //     rec.destroyRecord();
  //   });
  //
  //   setTimeout(function () {
  //     self.store.find('productCategory').then(function(data) {
  //       controller.set('model', data.sortBy('fullName'));
  //     });
  //   }, 1000);
  // }

});