define('jason-frontend/components/sloping-info-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    showSloping: true,
    showMySloping: false,
    actions: {
      refresh: function refresh() {
        $.magnificPopup.close();
      },
      gotoProduct: function gotoProduct() {

        try {
          if (this.get('product').get('type') === 'service') {
            this.sendAction('gotoService', this.get('product').id);
          } else {
            this.sendAction('gotoProduct', this.get('product').id);
          }
        } catch (e) {
          if (this.product.type === 'service') {
            this.sendAction('gotoService', this.product.id);
          } else {
            this.sendAction('gotoProduct', this.product.id);
          }
        }
        $.magnificPopup.close();
      },
      selectBarcode: function selectBarcode() {
        var self = this;

        var data;
        try {
          data = {
            slopingNotice: this.get('entry').get('slopingInfo'),
            infinitySloping: this.get('entry').get('infinitySloping')
          };
        } catch (e) {
          data = {
            slopingNotice: this.entry.slopingInfo,
            infinitySloping: this.entry.infinitySloping
          };
        }

        $.magnificPopup.close();
        setTimeout(function (e) {
          self.sendAction('selectBarcode', self.get('entry').id, data);
        }, 500);
      },
      confirm: function confirm() {
        var data;

        try {
          data = {
            slopingNotice: this.get('entry').get('slopingInfo'),
            infinitySloping: this.get('entry').get('infinitySloping'),
            offerId: this.get('entry').get('offerId')
          };
        } catch (e) {
          data = {
            slopingNotice: this.entry.slopingInfo,
            infinitySloping: this.entry.infinitySloping,
            offerId: this.entry.offerId
          };
        }

        this.sendAction('save', data, this.get('entry').id);
        $.magnificPopup.close();
      },
      load: function load(items, product, entry) {
        this.set('items', items);
        this.set('entry', entry);
        this.set('product', product);

        this.set('showSloping', true);
        this.set('showMySloping', false);
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    }
  });

});