define('jason-frontend/templates/components/simple-select', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 12
            },
            "end": {
              "line": 27,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/components/simple-select.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 cursor");
          dom.setAttribute(el2,"data-label","Name");
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element0, 'data-id');
          morphs[1] = dom.createElementMorph(element0);
          morphs[2] = dom.createUnsafeMorphAt(dom.childAt(element0, [1, 1]),0,0);
          return morphs;
        },
        statements: [
          ["attribute","data-id",["concat",[["get","item.id",["loc",[null,[22,30],[22,37]]]]]]],
          ["element","action",["select",["get","item",["loc",[null,[22,78],[22,82]]]]],[],["loc",[null,[22,60],[22,84]]]],
          ["content","item.name",["loc",[null,[24,24],[24,39]]]]
        ],
        locals: ["item"],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 34,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/simple-select.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","simple-select-panel");
        dom.setAttribute(el1,"class","popup-basic admin-form mfp-with-anim mfp-hide small--heading");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-heading");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","panel-title");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-body");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","col l-24/24 mb30");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"for","search");
        dom.setAttribute(el5,"class","search-box");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("button");
        dom.setAttribute(el6,"class","input search__button");
        dom.setAttribute(el6,"type","submit");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el7 = dom.createElement("svg");
        dom.setAttribute(el7,"width","22");
        dom.setAttribute(el7,"height","22");
        dom.setAttribute(el7,"viewBox","0 0 26 26");
        dom.setAttribute(el7,"xmlns","http://www.w3.org/2000/svg");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("path");
        dom.setAttribute(el8,"d","M24.53 24.53c-.208.21-.52.21-.73 0l-4.59-4.59c.26-.262.52-.47.73-.73l4.59 4.59c.21.157.21.522 0 .73zm-13.095-2.66C5.695 21.87 1 17.174 1 11.435 1 5.695 5.696 1 11.435 1c5.74 0 10.435 4.696 10.435 10.435 0 5.74-4.696 10.435-10.435 10.435zm0-19.827c-5.165 0-9.392 4.227-9.392 9.392 0 5.165 4.227 9.39 9.392 9.39 5.165 0 9.39-4.225 9.39-9.39 0-5.165-4.225-9.392-9.39-9.392z");
        dom.setAttribute(el8,"stroke","#4A4A4A");
        dom.setAttribute(el8,"fill","#4A4A4A");
        dom.setAttribute(el8,"fill-rule","evenodd");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.setNamespace(null);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","scrollable");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table size-fix table--small no-box@phone no-hover");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__body");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0, 1]);
        var element2 = dom.childAt(element1, [3]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element1, [1, 1]),1,1);
        morphs[1] = dom.createMorphAt(dom.childAt(element2, [1, 1]),3,3);
        morphs[2] = dom.createMorphAt(dom.childAt(element2, [3, 1, 1]),1,1);
        return morphs;
      },
      statements: [
        ["content","title",["loc",[null,[5,10],[5,19]]]],
        ["inline","input",[],["name","filterName","value",["subexpr","@mut",[["get","filterName",["loc",[null,[15,42],[15,52]]]]],[],[]],"class","gui-input input input--search search__input","placeholder","Suchen","autocomplete","off"],["loc",[null,[15,10],[15,146]]]],
        ["block","each",[["get","values",["loc",[null,[21,20],[21,26]]]]],[],0,null,["loc",[null,[21,12],[27,21]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});