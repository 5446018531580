define('jason-frontend/components/eltiga-sharenotallowed-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    type: null,
    actions: {
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    }
  });

});