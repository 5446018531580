define('jason-frontend/templates/practicemgmt/admission', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 26,
                      "column": 12
                    },
                    "end": {
                      "line": 31,
                      "column": 12
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","symbol-label");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n              ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element53 = dom.childAt(fragment, [1]);
                  var morphs = new Array(5);
                  morphs[0] = dom.createAttrMorph(element53, 'title');
                  morphs[1] = dom.createAttrMorph(element53, 'class');
                  morphs[2] = dom.createAttrMorph(element53, 'style');
                  morphs[3] = dom.createElementMorph(element53);
                  morphs[4] = dom.createMorphAt(dom.childAt(element53, [1]),0,0);
                  return morphs;
                },
                statements: [
                  ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[27,28],[27,42]]]]]]],
                  ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[27,121],[27,134]]]],"selected",""],[],["loc",[null,[27,100],[27,150]]]]," symbol symbol-circle symbol-40px"]]],
                  ["attribute","style",["concat",["background-color: ",["get","user.color",["loc",[null,[27,212],[27,222]]]]," !important;"]]],
                  ["element","action",["toggleResource",["get","user",["loc",[null,[27,72],[27,76]]]]],[],["loc",[null,[27,46],[27,78]]]],
                  ["content","user.capitals",["loc",[null,[28,42],[28,59]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 33,
                        "column": 16
                      },
                      "end": {
                        "line": 37,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","symbol-label");
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("img");
                    dom.setAttribute(el2,"src","assets/images/icons/notassigned.svg");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 37,
                        "column": 16
                      },
                      "end": {
                        "line": 40,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","symbol-label");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["content","user.capitals",["loc",[null,[38,44],[38,61]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 31,
                      "column": 12
                    },
                    "end": {
                      "line": 42,
                      "column": 12
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("              ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element52 = dom.childAt(fragment, [1]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createAttrMorph(element52, 'title');
                  morphs[1] = dom.createAttrMorph(element52, 'class');
                  morphs[2] = dom.createElementMorph(element52);
                  morphs[3] = dom.createMorphAt(element52,1,1);
                  return morphs;
                },
                statements: [
                  ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[32,28],[32,42]]]]]]],
                  ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[32,121],[32,134]]]],"selected",""],[],["loc",[null,[32,100],[32,150]]]]," symbol symbol-circle symbol-40px ",["get","user.color",["loc",[null,[32,186],[32,196]]]]]]],
                  ["element","action",["toggleResource",["get","user",["loc",[null,[32,72],[32,76]]]]],[],["loc",[null,[32,46],[32,78]]]],
                  ["block","if",[["subexpr","eq",[["get","user.id",["loc",[null,[33,26],[33,33]]]],"9998"],[],["loc",[null,[33,22],[33,41]]]]],[],0,1,["loc",[null,[33,16],[40,23]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 25,
                    "column": 12
                  },
                  "end": {
                    "line": 43,
                    "column": 12
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","user.colorHex",["loc",[null,[26,18],[26,31]]]]],[],0,1,["loc",[null,[26,12],[42,19]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 24,
                  "column": 10
                },
                "end": {
                  "line": 44,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","user.active",["loc",[null,[25,18],[25,29]]]]],[],0,null,["loc",[null,[25,12],[43,19]]]]
            ],
            locals: ["user"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 4
              },
              "end": {
                "line": 47,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","quick-filter mb10 mtm10 col l-24/24");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","title");
            var el3 = dom.createTextNode("Mitarbeiter:innen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","symbol-group symbol-hover flex-shrink-0 me-2");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","users",["loc",[null,[24,18],[24,23]]]]],[],0,null,["loc",[null,[24,10],[44,19]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 69,
                    "column": 24
                  },
                  "end": {
                    "line": 70,
                    "column": 118
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.customer.firstname",["loc",[null,[70,64],[70,91]]]],
                ["content","item.customer.lastname",["loc",[null,[70,92],[70,118]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 73,
                      "column": 24
                    },
                    "end": {
                      "line": 73,
                      "column": 256
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("i");
                  dom.setAttribute(el1,"class","animal-icon");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                  return morphs;
                },
                statements: [
                  ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[73,115],[73,146]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[73,155],[73,191]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[73,200],[73,233]]]]],[],[]],"classNames","mr5"],["loc",[null,[73,83],[73,252]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 74,
                      "column": 22
                    },
                    "end": {
                      "line": 74,
                      "column": 149
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[74,92],[74,116]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[74,125],[74,147]]]]],[],[]]],["loc",[null,[74,73],[74,149]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 75,
                      "column": 22
                    },
                    "end": {
                      "line": 75,
                      "column": 190
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[75,61],[75,190]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child3 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 81,
                          "column": 28
                        },
                        "end": {
                          "line": 86,
                          "column": 28
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                      var el2 = dom.createTextNode("\n                                  ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","symbol-label");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                                  ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                                ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element40 = dom.childAt(fragment, [1]);
                      var morphs = new Array(3);
                      morphs[0] = dom.createAttrMorph(element40, 'title');
                      morphs[1] = dom.createAttrMorph(element40, 'style');
                      morphs[2] = dom.createMorphAt(dom.childAt(element40, [1]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[82,44],[82,72]]]]]]],
                      ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[82,162],[82,186]]]]," !important;"]]],
                      ["content","patientInfo.doctor.capitals",["loc",[null,[83,60],[83,91]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 86,
                          "column": 28
                        },
                        "end": {
                          "line": 91,
                          "column": 28
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      var el2 = dom.createTextNode("\n                                  ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","symbol-label");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                                  ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                                ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element39 = dom.childAt(fragment, [1]);
                      var morphs = new Array(3);
                      morphs[0] = dom.createAttrMorph(element39, 'title');
                      morphs[1] = dom.createAttrMorph(element39, 'class');
                      morphs[2] = dom.createMorphAt(dom.childAt(element39, [1]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[87,44],[87,72]]]]]]],
                      ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.doctor.selected",["loc",[null,[87,122],[87,149]]]],"selected",""],[],["loc",[null,[87,101],[87,165]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[87,201],[87,225]]]]]]],
                      ["content","patientInfo.doctor.capitals",["loc",[null,[88,60],[88,91]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 80,
                        "column": 26
                      },
                      "end": {
                        "line": 92,
                        "column": 26
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[81,34],[81,61]]]]],[],0,1,["loc",[null,[81,28],[91,35]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 94,
                          "column": 28
                        },
                        "end": {
                          "line": 99,
                          "column": 28
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                      var el2 = dom.createTextNode("\n                                    ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","symbol-label");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                                    ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                                  ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element38 = dom.childAt(fragment, [1]);
                      var morphs = new Array(3);
                      morphs[0] = dom.createAttrMorph(element38, 'title');
                      morphs[1] = dom.createAttrMorph(element38, 'style');
                      morphs[2] = dom.createMorphAt(dom.childAt(element38, [1]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[95,44],[95,75]]]]]]],
                      ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[95,165],[95,192]]]]," !important;"]]],
                      ["content","patientInfo.assistant.capitals",["loc",[null,[96,62],[96,96]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 99,
                          "column": 28
                        },
                        "end": {
                          "line": 104,
                          "column": 28
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      var el2 = dom.createTextNode("\n                                    ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","symbol-label");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                                    ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                                  ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element37 = dom.childAt(fragment, [1]);
                      var morphs = new Array(3);
                      morphs[0] = dom.createAttrMorph(element37, 'title');
                      morphs[1] = dom.createAttrMorph(element37, 'class');
                      morphs[2] = dom.createMorphAt(dom.childAt(element37, [1]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[100,44],[100,75]]]]]]],
                      ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.assistant.selected",["loc",[null,[100,125],[100,155]]]],"selected",""],[],["loc",[null,[100,104],[100,171]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[100,207],[100,234]]]]]]],
                      ["content","patientInfo.assistant.capitals",["loc",[null,[101,62],[101,96]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 93,
                        "column": 26
                      },
                      "end": {
                        "line": 105,
                        "column": 26
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[94,34],[94,64]]]]],[],0,1,["loc",[null,[94,28],[104,35]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 78,
                      "column": 24
                    },
                    "end": {
                      "line": 106,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","patientInfo.doctor",["loc",[null,[80,32],[80,50]]]]],[],0,null,["loc",[null,[80,26],[92,33]]]],
                  ["block","if",[["get","patientInfo.assistant",["loc",[null,[93,32],[93,53]]]]],[],1,null,["loc",[null,[93,26],[105,33]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 71,
                    "column": 18
                  },
                  "end": {
                    "line": 113,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","flex-center mb5 mt5");
                dom.setAttribute(el1,"style","flex-direction: row");
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","symbol-group");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                      ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("a");
                dom.setAttribute(el2,"class","ember-view icon-button icon-button--success list-action-square mr0 ml10");
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element41 = dom.childAt(fragment, [1]);
                var element42 = dom.childAt(element41, [9]);
                var morphs = new Array(6);
                morphs[0] = dom.createMorphAt(element41,1,1);
                morphs[1] = dom.createMorphAt(element41,3,3);
                morphs[2] = dom.createMorphAt(element41,5,5);
                morphs[3] = dom.createMorphAt(dom.childAt(element41, [7]),1,1);
                morphs[4] = dom.createElementMorph(element42);
                morphs[5] = dom.createMorphAt(element42,1,1);
                return morphs;
              },
              statements: [
                ["block","if",[["get","patientInfo.patient.category",["loc",[null,[73,30],[73,58]]]]],[],0,null,["loc",[null,[73,24],[73,263]]]],
                ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[74,49],[74,71]]]]],[],1,null,["loc",[null,[74,22],[74,161]]]],
                ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[75,28],[75,59]]]]],[],2,null,["loc",[null,[75,22],[75,197]]]],
                ["block","if",[["subexpr","or",[["get","patientInfo.assistant",["loc",[null,[78,34],[78,55]]]],["get","patientInfo.doctor",["loc",[null,[78,56],[78,74]]]]],[],["loc",[null,[78,30],[78,75]]]]],[],3,null,["loc",[null,[78,24],[106,31]]]],
                ["element","action",["admissionPatient",["get","item.customer.id",["loc",[null,[109,133],[109,149]]]],["get","patientInfo.patient.id",["loc",[null,[109,150],[109,172]]]],["get","item.appointmentId",["loc",[null,[109,173],[109,191]]]]],[],["loc",[null,[109,105],[109,193]]]],
                ["inline","button-admission",[],["size","32","color","#fff","showStroke",false,"content","Check-In"],["loc",[null,[110,24],[110,103]]]]
              ],
              locals: ["patientInfo"],
              templates: [child0, child1, child2, child3]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 62,
                  "column": 12
                },
                "end": {
                  "line": 116,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24");
              dom.setAttribute(el2,"data-label","Termin");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","sub");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" Uhr");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell  l-16/24");
              dom.setAttribute(el2,"data-label","Kund:in");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element43 = dom.childAt(fragment, [1]);
              var element44 = dom.childAt(element43, [1]);
              var element45 = dom.childAt(element43, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(element44, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element44, [3]),0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element45, [1]),0,0);
              morphs[3] = dom.createMorphAt(element45,3,3);
              return morphs;
            },
            statements: [
              ["inline","time-format",[["get","item.created",["loc",[null,[65,97],[65,109]]]],"DD. MM. YYYY"],[],["loc",[null,[65,83],[65,126]]]],
              ["inline","time-format",[["get","item.created",["loc",[null,[66,50],[66,62]]]],"HH:mm"],[],["loc",[null,[66,36],[66,72]]]],
              ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[69,52],[69,68]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[69,24],[70,130]]]],
              ["block","each",[["get","item.treatmentPatients",["loc",[null,[71,26],[71,48]]]]],[],1,null,["loc",[null,[71,18],[113,27]]]]
            ],
            locals: ["item"],
            templates: [child0, child1]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 139,
                      "column": 22
                    },
                    "end": {
                      "line": 146,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","sub block mt5");
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2,"class","sub");
                  dom.setAttribute(el2,"style","display: inline");
                  var el3 = dom.createTextNode("Termin: ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("label");
                  dom.setAttribute(el2,"for","time");
                  dom.setAttribute(el2,"class","input-element");
                  var el3 = dom.createTextNode("\n                             ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("input");
                  dom.setAttribute(el3,"style","font-size: 14px");
                  dom.setAttribute(el3,"autocomplete","false");
                  dom.setAttribute(el3,"type","text");
                  dom.setAttribute(el3,"placeholder","");
                  dom.setAttribute(el3,"class","input date-picker__input time time-small");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element33 = dom.childAt(fragment, [1, 3, 1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createAttrMorph(element33, 'id');
                  morphs[1] = dom.createAttrMorph(element33, 'value');
                  morphs[2] = dom.createAttrMorph(element33, 'onclick');
                  return morphs;
                },
                statements: [
                  ["attribute","id",["concat",["timeTile_",["get","item.id",["loc",[null,[143,108],[143,115]]]]]]],
                  ["attribute","value",["concat",[["get","item.time",["loc",[null,[143,143],[143,152]]]]]]],
                  ["attribute","onclick",["subexpr","action",["openTime",["get","item",["loc",[null,[143,233],[143,237]]]]],[],["loc",[null,[143,213],[143,239]]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 149,
                      "column": 28
                    },
                    "end": {
                      "line": 150,
                      "column": 118
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.customer.firstname",["loc",[null,[150,64],[150,91]]]],
                  ["content","item.customer.lastname",["loc",[null,[150,92],[150,118]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 154,
                          "column": 24
                        },
                        "end": {
                          "line": 158,
                          "column": 85
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                          ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("i");
                      dom.setAttribute(el1,"class","animal-icon");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[155,81],[155,112]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[156,71],[156,107]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[157,71],[157,104]]]]],[],[]],"classNames","mr5"],["loc",[null,[155,49],[158,81]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 159,
                          "column": 28
                        },
                        "end": {
                          "line": 160,
                          "column": 92
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[160,35],[160,59]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[160,68],[160,90]]]]],[],[]]],["loc",[null,[159,79],[160,92]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child2 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 161,
                          "column": 28
                        },
                        "end": {
                          "line": 163,
                          "column": 63
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[161,67],[163,63]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child3 = (function() {
                  var child0 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 168,
                              "column": 28
                            },
                            "end": {
                              "line": 173,
                              "column": 28
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element30 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element30, 'title');
                          morphs[1] = dom.createAttrMorph(element30, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element30, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[169,44],[169,72]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[169,162],[169,186]]]]," !important;"]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[170,60],[170,91]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 173,
                              "column": 28
                            },
                            "end": {
                              "line": 178,
                              "column": 28
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element29 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element29, 'title');
                          morphs[1] = dom.createAttrMorph(element29, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element29, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[174,44],[174,72]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.doctor.selected",["loc",[null,[174,122],[174,149]]]],"selected",""],[],["loc",[null,[174,101],[174,165]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[174,201],[174,225]]]]]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[175,60],[175,91]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 167,
                            "column": 26
                          },
                          "end": {
                            "line": 179,
                            "column": 26
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[168,34],[168,61]]]]],[],0,1,["loc",[null,[168,28],[178,35]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 181,
                              "column": 34
                            },
                            "end": {
                              "line": 186,
                              "column": 34
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                    ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element28 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element28, 'title');
                          morphs[1] = dom.createAttrMorph(element28, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element28, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[182,50],[182,81]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[182,171],[182,198]]]]," !important;"]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[183,62],[183,96]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 186,
                              "column": 34
                            },
                            "end": {
                              "line": 191,
                              "column": 34
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                    ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element27 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element27, 'title');
                          morphs[1] = dom.createAttrMorph(element27, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element27, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[187,50],[187,81]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.assistant.selected",["loc",[null,[187,131],[187,161]]]],"selected",""],[],["loc",[null,[187,110],[187,177]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[187,213],[187,240]]]]]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[188,62],[188,96]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 180,
                            "column": 32
                          },
                          "end": {
                            "line": 192,
                            "column": 32
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[181,40],[181,70]]]]],[],0,1,["loc",[null,[181,34],[191,41]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 165,
                          "column": 28
                        },
                        "end": {
                          "line": 193,
                          "column": 28
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(2);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","patientInfo.doctor",["loc",[null,[167,32],[167,50]]]]],[],0,null,["loc",[null,[167,26],[179,33]]]],
                      ["block","if",[["get","patientInfo.assistant",["loc",[null,[180,38],[180,59]]]]],[],1,null,["loc",[null,[180,32],[192,39]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 152,
                        "column": 24
                      },
                      "end": {
                        "line": 200,
                        "column": 24
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","flex-center mb5 mt5");
                    dom.setAttribute(el1,"style","flex-direction: row");
                    var el2 = dom.createTextNode("\n");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-group");
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("                          ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("a");
                    dom.setAttribute(el2,"class","ember-view icon-button icon-button--success list-action-square mr0 ml10");
                    var el3 = dom.createTextNode("\n                              ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                            ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element31 = dom.childAt(fragment, [1]);
                    var element32 = dom.childAt(element31, [9]);
                    var morphs = new Array(6);
                    morphs[0] = dom.createMorphAt(element31,1,1);
                    morphs[1] = dom.createMorphAt(element31,3,3);
                    morphs[2] = dom.createMorphAt(element31,5,5);
                    morphs[3] = dom.createMorphAt(dom.childAt(element31, [7]),1,1);
                    morphs[4] = dom.createElementMorph(element32);
                    morphs[5] = dom.createMorphAt(element32,1,1);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","patientInfo.patient.category",["loc",[null,[154,30],[154,58]]]]],[],0,null,["loc",[null,[154,24],[158,92]]]],
                    ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[159,55],[159,77]]]]],[],1,null,["loc",[null,[159,28],[160,104]]]],
                    ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[161,34],[161,65]]]]],[],2,null,["loc",[null,[161,28],[163,70]]]],
                    ["block","if",[["subexpr","or",[["get","patientInfo.assistant",["loc",[null,[165,38],[165,59]]]],["get","patientInfo.doctor",["loc",[null,[165,60],[165,78]]]]],[],["loc",[null,[165,34],[165,79]]]]],[],3,null,["loc",[null,[165,28],[193,35]]]],
                    ["element","action",["movePatientToTreatmentRoom",["get","patientInfo.id",["loc",[null,[196,149],[196,163]]]]],[],["loc",[null,[196,111],[196,165]]]],
                    ["inline","button-in-treatment",[],["size","32","color","#fff","showStroke",false,"content","Behandeln"],["loc",[null,[197,30],[197,113]]]]
                  ],
                  locals: [],
                  templates: [child0, child1, child2, child3]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 151,
                      "column": 22
                    },
                    "end": {
                      "line": 201,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","show-treatment-patient-tile",[["get","patientInfo",["loc",[null,[152,59],[152,70]]]],["get","selectedUsers",["loc",[null,[152,71],[152,84]]]],["get","changeTrigger",["loc",[null,[152,85],[152,98]]]]],[],["loc",[null,[152,30],[152,99]]]]],[],0,null,["loc",[null,[152,24],[200,31]]]]
                ],
                locals: ["patientInfo"],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 134,
                    "column": 14
                  },
                  "end": {
                    "line": 205,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__row");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24");
                dom.setAttribute(el2,"data-label","Check-In");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","sub");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" Uhr");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell  l-16/24");
                dom.setAttribute(el2,"data-label","Kund:in");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element34 = dom.childAt(fragment, [1]);
                var element35 = dom.childAt(element34, [1]);
                var element36 = dom.childAt(element34, [3]);
                var morphs = new Array(5);
                morphs[0] = dom.createMorphAt(dom.childAt(element35, [1]),0,0);
                morphs[1] = dom.createMorphAt(dom.childAt(element35, [3]),0,0);
                morphs[2] = dom.createMorphAt(element35,5,5);
                morphs[3] = dom.createMorphAt(dom.childAt(element36, [1]),0,0);
                morphs[4] = dom.createMorphAt(element36,3,3);
                return morphs;
              },
              statements: [
                ["inline","time-format",[["get","item.created",["loc",[null,[137,101],[137,113]]]],"DD. MM. YYYY"],[],["loc",[null,[137,87],[137,130]]]],
                ["inline","time-format",[["get","item.created",["loc",[null,[138,54],[138,66]]]],"HH:mm"],[],["loc",[null,[138,40],[138,76]]]],
                ["block","if",[["get","item.time",["loc",[null,[139,28],[139,37]]]]],[],0,null,["loc",[null,[139,22],[146,29]]]],
                ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[149,56],[149,72]]]]],["classNames","is-highlighted"],1,null,["loc",[null,[149,28],[150,130]]]],
                ["block","each",[["get","item.treatmentPatients",["loc",[null,[151,30],[151,52]]]]],[],2,null,["loc",[null,[151,22],[201,31]]]]
              ],
              locals: [],
              templates: [child0, child1, child2]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 133,
                  "column": 12
                },
                "end": {
                  "line": 206,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","show-treatment-tile",[["get","item",["loc",[null,[134,41],[134,45]]]],["get","selectedUsers",["loc",[null,[134,46],[134,59]]]],["get","changeTrigger",["loc",[null,[134,60],[134,73]]]]],[],["loc",[null,[134,20],[134,74]]]]],[],0,null,["loc",[null,[134,14],[205,21]]]]
            ],
            locals: ["item"],
            templates: [child0]
          };
        }());
        var child2 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 229,
                      "column": 18
                    },
                    "end": {
                      "line": 236,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","sub block mt5");
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2,"class","sub");
                  dom.setAttribute(el2,"style","display: inline");
                  var el3 = dom.createTextNode("Termin: ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("label");
                  dom.setAttribute(el2,"for","time");
                  dom.setAttribute(el2,"class","input-element");
                  var el3 = dom.createTextNode("\n                             ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("input");
                  dom.setAttribute(el3,"style","font-size: 14px");
                  dom.setAttribute(el3,"autocomplete","false");
                  dom.setAttribute(el3,"type","text");
                  dom.setAttribute(el3,"placeholder","");
                  dom.setAttribute(el3,"class","input date-picker__input time time-small");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element23 = dom.childAt(fragment, [1, 3, 1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createAttrMorph(element23, 'id');
                  morphs[1] = dom.createAttrMorph(element23, 'value');
                  morphs[2] = dom.createAttrMorph(element23, 'onclick');
                  return morphs;
                },
                statements: [
                  ["attribute","id",["concat",["timeTile_",["get","item.id",["loc",[null,[233,108],[233,115]]]]]]],
                  ["attribute","value",["concat",[["get","item.time",["loc",[null,[233,143],[233,152]]]]]]],
                  ["attribute","onclick",["subexpr","action",["openTime",["get","item",["loc",[null,[233,233],[233,237]]]]],[],["loc",[null,[233,213],[233,239]]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 239,
                      "column": 24
                    },
                    "end": {
                      "line": 240,
                      "column": 118
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.customer.firstname",["loc",[null,[240,64],[240,91]]]],
                  ["content","item.customer.lastname",["loc",[null,[240,92],[240,118]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 244,
                          "column": 24
                        },
                        "end": {
                          "line": 244,
                          "column": 256
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("i");
                      dom.setAttribute(el1,"class","animal-icon");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[244,115],[244,146]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[244,155],[244,191]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[244,200],[244,233]]]]],[],[]],"classNames","mr5"],["loc",[null,[244,83],[244,252]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 245,
                          "column": 24
                        },
                        "end": {
                          "line": 245,
                          "column": 151
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[245,94],[245,118]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[245,127],[245,149]]]]],[],[]]],["loc",[null,[245,75],[245,151]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child2 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 246,
                          "column": 24
                        },
                        "end": {
                          "line": 246,
                          "column": 192
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[246,63],[246,192]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child3 = (function() {
                  var child0 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 251,
                              "column": 30
                            },
                            "end": {
                              "line": 256,
                              "column": 44
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element21 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element21, 'title');
                          morphs[1] = dom.createAttrMorph(element21, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element21, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[252,46],[252,74]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[252,164],[252,188]]]]," !important;"]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[253,60],[253,91]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 256,
                              "column": 44
                            },
                            "end": {
                              "line": 261,
                              "column": 30
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element20 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element20, 'title');
                          morphs[1] = dom.createAttrMorph(element20, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element20, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[257,60],[257,88]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.doctor.selected",["loc",[null,[257,138],[257,165]]]],"selected",""],[],["loc",[null,[257,117],[257,181]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[257,217],[257,241]]]]]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[258,60],[258,91]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 250,
                            "column": 26
                          },
                          "end": {
                            "line": 262,
                            "column": 26
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[251,36],[251,63]]]]],[],0,1,["loc",[null,[251,30],[261,37]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 264,
                              "column": 30
                            },
                            "end": {
                              "line": 269,
                              "column": 30
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element19 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element19, 'title');
                          morphs[1] = dom.createAttrMorph(element19, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element19, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[265,46],[265,77]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[265,167],[265,194]]]]," !important;"]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[266,62],[266,96]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 269,
                              "column": 30
                            },
                            "end": {
                              "line": 274,
                              "column": 30
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element18 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element18, 'title');
                          morphs[1] = dom.createAttrMorph(element18, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element18, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[270,46],[270,77]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.assistant.selected",["loc",[null,[270,127],[270,157]]]],"selected",""],[],["loc",[null,[270,106],[270,173]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[270,209],[270,236]]]]]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[271,62],[271,96]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 263,
                            "column": 28
                          },
                          "end": {
                            "line": 275,
                            "column": 28
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[264,36],[264,66]]]]],[],0,1,["loc",[null,[264,30],[274,37]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 249,
                          "column": 24
                        },
                        "end": {
                          "line": 276,
                          "column": 24
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(2);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","patientInfo.doctor",["loc",[null,[250,32],[250,50]]]]],[],0,null,["loc",[null,[250,26],[262,33]]]],
                      ["block","if",[["get","patientInfo.assistant",["loc",[null,[263,34],[263,55]]]]],[],1,null,["loc",[null,[263,28],[275,35]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                var child4 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 279,
                          "column": 24
                        },
                        "end": {
                          "line": 281,
                          "column": 24
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                          ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-in-treatment",[],["size","32","color","#fff","showStroke",false,"content","Behandlung öffnen"],["loc",[null,[280,26],[280,117]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 242,
                        "column": 22
                      },
                      "end": {
                        "line": 283,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","flex-center mb5 mt5");
                    dom.setAttribute(el1,"style","flex-direction: row");
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n\n                          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-group");
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("                          ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n\n");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("                      ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element22 = dom.childAt(fragment, [1]);
                    var morphs = new Array(5);
                    morphs[0] = dom.createMorphAt(element22,1,1);
                    morphs[1] = dom.createMorphAt(element22,3,3);
                    morphs[2] = dom.createMorphAt(element22,5,5);
                    morphs[3] = dom.createMorphAt(dom.childAt(element22, [7]),1,1);
                    morphs[4] = dom.createMorphAt(element22,9,9);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","patientInfo.patient.category",["loc",[null,[244,30],[244,58]]]]],[],0,null,["loc",[null,[244,24],[244,263]]]],
                    ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[245,51],[245,73]]]]],[],1,null,["loc",[null,[245,24],[245,163]]]],
                    ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[246,30],[246,61]]]]],[],2,null,["loc",[null,[246,24],[246,199]]]],
                    ["block","if",[["subexpr","or",[["get","patientInfo.assistant",["loc",[null,[249,34],[249,55]]]],["get","patientInfo.doctor",["loc",[null,[249,56],[249,74]]]]],[],["loc",[null,[249,30],[249,75]]]]],[],3,null,["loc",[null,[249,24],[276,31]]]],
                    ["block","link-to",["practicemgmt.treatment-details",["get","patientInfo.id",["loc",[null,[279,68],[279,82]]]],["subexpr","query-params",[],["entry","true"],["loc",[null,[279,83],[279,110]]]]],["class","mr0 ml10 icon-button icon-button--default list-action-square"],4,null,["loc",[null,[279,24],[281,36]]]]
                  ],
                  locals: [],
                  templates: [child0, child1, child2, child3, child4]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 241,
                      "column": 20
                    },
                    "end": {
                      "line": 284,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","show-treatment-patient-tile",[["get","patientInfo",["loc",[null,[242,57],[242,68]]]],["get","selectedUsers",["loc",[null,[242,69],[242,82]]]],["get","changeTrigger",["loc",[null,[242,83],[242,96]]]]],[],["loc",[null,[242,28],[242,97]]]]],[],0,null,["loc",[null,[242,22],[283,29]]]]
                ],
                locals: ["patientInfo"],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 224,
                    "column": 14
                  },
                  "end": {
                    "line": 287,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__row");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24");
                dom.setAttribute(el2,"data-label","Check-In");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","sub");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" Uhr");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-16/24");
                dom.setAttribute(el2,"data-label","Kund:in");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element24 = dom.childAt(fragment, [1]);
                var element25 = dom.childAt(element24, [1]);
                var element26 = dom.childAt(element24, [3]);
                var morphs = new Array(5);
                morphs[0] = dom.createMorphAt(dom.childAt(element25, [1]),0,0);
                morphs[1] = dom.createMorphAt(dom.childAt(element25, [3]),0,0);
                morphs[2] = dom.createMorphAt(element25,5,5);
                morphs[3] = dom.createMorphAt(dom.childAt(element26, [1]),0,0);
                morphs[4] = dom.createMorphAt(element26,3,3);
                return morphs;
              },
              statements: [
                ["inline","time-format",[["get","item.created",["loc",[null,[227,97],[227,109]]]],"DD. MM. YYYY"],[],["loc",[null,[227,83],[227,126]]]],
                ["inline","time-format",[["get","item.created",["loc",[null,[228,50],[228,62]]]],"HH:mm"],[],["loc",[null,[228,36],[228,72]]]],
                ["block","if",[["get","item.time",["loc",[null,[229,24],[229,33]]]]],[],0,null,["loc",[null,[229,18],[236,25]]]],
                ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[239,52],[239,68]]]]],["classNames","is-highlighted"],1,null,["loc",[null,[239,24],[240,130]]]],
                ["block","each",[["get","item.treatmentPatients",["loc",[null,[241,28],[241,50]]]]],[],2,null,["loc",[null,[241,20],[284,29]]]]
              ],
              locals: [],
              templates: [child0, child1, child2]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 223,
                  "column": 12
                },
                "end": {
                  "line": 288,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","show-treatment-tile",[["get","item",["loc",[null,[224,41],[224,45]]]],["get","selectedUsers",["loc",[null,[224,46],[224,59]]]],["get","changeTrigger",["loc",[null,[224,60],[224,73]]]]],[],["loc",[null,[224,20],[224,74]]]]],[],0,null,["loc",[null,[224,14],[287,21]]]]
            ],
            locals: ["item"],
            templates: [child0]
          };
        }());
        var child3 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 311,
                      "column": 24
                    },
                    "end": {
                      "line": 312,
                      "column": 118
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.customer.firstname",["loc",[null,[312,64],[312,91]]]],
                  ["content","item.customer.lastname",["loc",[null,[312,92],[312,118]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 316,
                          "column": 24
                        },
                        "end": {
                          "line": 316,
                          "column": 256
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("i");
                      dom.setAttribute(el1,"class","animal-icon");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[316,115],[316,146]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[316,155],[316,191]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[316,200],[316,233]]]]],[],[]],"classNames","mr5"],["loc",[null,[316,83],[316,252]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 318,
                          "column": 0
                        },
                        "end": {
                          "line": 318,
                          "column": 127
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[318,70],[318,94]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[318,103],[318,125]]]]],[],[]]],["loc",[null,[318,51],[318,127]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child2 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 319,
                          "column": 24
                        },
                        "end": {
                          "line": 321,
                          "column": 24
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                          ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","block small");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["content","patientInfo.room.name",["loc",[null,[320,52],[320,77]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child3 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 323,
                          "column": 22
                        },
                        "end": {
                          "line": 323,
                          "column": 190
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[323,61],[323,190]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child4 = (function() {
                  var child0 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 328,
                              "column": 28
                            },
                            "end": {
                              "line": 333,
                              "column": 28
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element11 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element11, 'title');
                          morphs[1] = dom.createAttrMorph(element11, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element11, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[329,44],[329,72]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[329,162],[329,186]]]]," !important;"]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[330,60],[330,91]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 333,
                              "column": 28
                            },
                            "end": {
                              "line": 338,
                              "column": 28
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element10 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element10, 'title');
                          morphs[1] = dom.createAttrMorph(element10, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element10, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[334,44],[334,72]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.doctor.selected",["loc",[null,[334,122],[334,149]]]],"selected",""],[],["loc",[null,[334,101],[334,165]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[334,201],[334,225]]]]]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[335,60],[335,91]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 327,
                            "column": 26
                          },
                          "end": {
                            "line": 339,
                            "column": 26
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[328,34],[328,61]]]]],[],0,1,["loc",[null,[328,28],[338,35]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 341,
                              "column": 28
                            },
                            "end": {
                              "line": 346,
                              "column": 28
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element9 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element9, 'title');
                          morphs[1] = dom.createAttrMorph(element9, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element9, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[342,44],[342,75]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[342,165],[342,192]]]]," !important;"]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[343,62],[343,96]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 346,
                              "column": 28
                            },
                            "end": {
                              "line": 351,
                              "column": 28
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element8 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element8, 'title');
                          morphs[1] = dom.createAttrMorph(element8, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element8, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[347,44],[347,75]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.assistant.selected",["loc",[null,[347,125],[347,155]]]],"selected",""],[],["loc",[null,[347,104],[347,171]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[347,207],[347,234]]]]]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[348,62],[348,96]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 340,
                            "column": 26
                          },
                          "end": {
                            "line": 352,
                            "column": 26
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[341,34],[341,64]]]]],[],0,1,["loc",[null,[341,28],[351,35]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 325,
                          "column": 22
                        },
                        "end": {
                          "line": 353,
                          "column": 22
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(2);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","patientInfo.doctor",["loc",[null,[327,32],[327,50]]]]],[],0,null,["loc",[null,[327,26],[339,33]]]],
                      ["block","if",[["get","patientInfo.assistant",["loc",[null,[340,32],[340,53]]]]],[],1,null,["loc",[null,[340,26],[352,33]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 314,
                        "column": 20
                      },
                      "end": {
                        "line": 360,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","flex-center mb5 mt5");
                    dom.setAttribute(el1,"style","flex-direction: row");
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("                      ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-group");
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("                    ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("a");
                    dom.setAttribute(el2,"class","ml5 exp icon-button icon-button--default list-action-square");
                    var el3 = dom.createTextNode("\n                          ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("span");
                    var el4 = dom.createTextNode("Behandeln");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                        ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element12 = dom.childAt(fragment, [1]);
                    var element13 = dom.childAt(element12, [3]);
                    var element14 = dom.childAt(element12, [9]);
                    var morphs = new Array(7);
                    morphs[0] = dom.createMorphAt(element12,1,1);
                    morphs[1] = dom.createMorphAt(element13,1,1);
                    morphs[2] = dom.createMorphAt(element13,3,3);
                    morphs[3] = dom.createMorphAt(element12,5,5);
                    morphs[4] = dom.createMorphAt(dom.childAt(element12, [7]),1,1);
                    morphs[5] = dom.createElementMorph(element14);
                    morphs[6] = dom.createMorphAt(element14,1,1);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","patientInfo.patient.category",["loc",[null,[316,30],[316,58]]]]],[],0,null,["loc",[null,[316,24],[316,263]]]],
                    ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[318,27],[318,49]]]]],[],1,null,["loc",[null,[318,0],[318,139]]]],
                    ["block","if",[["get","patientInfo.room",["loc",[null,[319,30],[319,46]]]]],[],2,null,["loc",[null,[319,24],[321,31]]]],
                    ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[323,28],[323,59]]]]],[],3,null,["loc",[null,[323,22],[323,197]]]],
                    ["block","if",[["subexpr","or",[["get","patientInfo.assistant",["loc",[null,[325,32],[325,53]]]],["get","patientInfo.doctor",["loc",[null,[325,54],[325,72]]]]],[],["loc",[null,[325,28],[325,73]]]]],[],4,null,["loc",[null,[325,22],[353,29]]]],
                    ["element","action",["movePatientToTreatmentRoom",["get","patientInfo.id",["loc",[null,[356,131],[356,145]]]]],[],["loc",[null,[356,93],[356,147]]]],
                    ["inline","button-in-treatment",[],["size","40","color","#fff","showStroke",false,"content","In eine Behandlungs-Lokation setzen"],["loc",[null,[357,26],[357,135]]]]
                  ],
                  locals: [],
                  templates: [child0, child1, child2, child3, child4]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 313,
                      "column": 18
                    },
                    "end": {
                      "line": 361,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","show-treatment-patient-tile",[["get","patientInfo",["loc",[null,[314,55],[314,66]]]],["get","selectedUsers",["loc",[null,[314,67],[314,80]]]],["get","changeTrigger",["loc",[null,[314,81],[314,94]]]]],[],["loc",[null,[314,26],[314,95]]]]],[],0,null,["loc",[null,[314,20],[360,27]]]]
                ],
                locals: ["patientInfo"],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 304,
                    "column": 14
                  },
                  "end": {
                    "line": 364,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__row");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24");
                dom.setAttribute(el2,"data-label","Check-In");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","sub");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" Uhr");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-16/24");
                dom.setAttribute(el2,"data-label","Kund:in");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element15 = dom.childAt(fragment, [1]);
                var element16 = dom.childAt(element15, [1]);
                var element17 = dom.childAt(element15, [3]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(dom.childAt(element16, [1]),0,0);
                morphs[1] = dom.createMorphAt(dom.childAt(element16, [3]),0,0);
                morphs[2] = dom.createMorphAt(dom.childAt(element17, [1]),0,0);
                morphs[3] = dom.createMorphAt(element17,3,3);
                return morphs;
              },
              statements: [
                ["inline","time-format",[["get","item.created",["loc",[null,[307,97],[307,109]]]],"DD. MM. YYYY"],[],["loc",[null,[307,83],[307,126]]]],
                ["inline","time-format",[["get","item.created",["loc",[null,[308,50],[308,62]]]],"HH:mm"],[],["loc",[null,[308,36],[308,72]]]],
                ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[311,52],[311,68]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[311,24],[312,130]]]],
                ["block","each",[["get","item.treatmentPatients",["loc",[null,[313,26],[313,48]]]]],[],1,null,["loc",[null,[313,18],[361,27]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 303,
                  "column": 12
                },
                "end": {
                  "line": 365,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","show-treatment-tile",[["get","item",["loc",[null,[304,41],[304,45]]]],["get","selectedUsers",["loc",[null,[304,46],[304,59]]]],["get","changeTrigger",["loc",[null,[304,60],[304,73]]]]],[],["loc",[null,[304,20],[304,74]]]]],[],0,null,["loc",[null,[304,14],[364,21]]]]
            ],
            locals: ["item"],
            templates: [child0]
          };
        }());
        var child4 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 390,
                    "column": 24
                  },
                  "end": {
                    "line": 391,
                    "column": 118
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.customer.firstname",["loc",[null,[391,64],[391,91]]]],
                ["content","item.customer.lastname",["loc",[null,[391,92],[391,118]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 394,
                      "column": 24
                    },
                    "end": {
                      "line": 398,
                      "column": 85
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("i");
                  dom.setAttribute(el1,"class","animal-icon");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                  return morphs;
                },
                statements: [
                  ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[395,81],[395,112]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[396,71],[396,107]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[397,71],[397,104]]]]],[],[]],"classNames","mr5"],["loc",[null,[395,49],[398,81]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 399,
                      "column": 22
                    },
                    "end": {
                      "line": 400,
                      "column": 119
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[399,92],[399,116]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[400,95],[400,117]]]]],[],[]]],["loc",[null,[399,73],[400,119]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 401,
                      "column": 22
                    },
                    "end": {
                      "line": 403,
                      "column": 107
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[401,61],[403,107]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 392,
                    "column": 18
                  },
                  "end": {
                    "line": 405,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","flex-center");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element4 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(element4,1,1);
                morphs[1] = dom.createMorphAt(element4,3,3);
                morphs[2] = dom.createMorphAt(element4,5,5);
                return morphs;
              },
              statements: [
                ["block","if",[["get","patientInfo.patient.category",["loc",[null,[394,30],[394,58]]]]],[],0,null,["loc",[null,[394,24],[398,92]]]],
                ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[399,49],[399,71]]]]],[],1,null,["loc",[null,[399,22],[400,131]]]],
                ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[401,28],[401,59]]]]],[],2,null,["loc",[null,[401,22],[403,114]]]]
              ],
              locals: ["patientInfo"],
              templates: [child0, child1, child2]
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 409,
                      "column": 20
                    },
                    "end": {
                      "line": 414,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","exp icon-button icon-button--success list-action-square mr0 mb5 ml0 ");
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  return morphs;
                },
                statements: [
                  ["inline","button-ready4payment",[],["size","32","color","#fff","showStroke",false,"content","Übergabe an Ausgangsrechnung"],["loc",[null,[411,24],[412,87]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 421,
                        "column": 22
                      },
                      "end": {
                        "line": 423,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-in-treatment",[],["size","32","color","#fff","showStroke",false,"content","Behandlung öffnen"],["loc",[null,[422,24],[422,115]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 414,
                      "column": 20
                    },
                    "end": {
                      "line": 424,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","ember-view icon-button icon-button--success list-action-square mr0 mb5 ml5 ");
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element3 = dom.childAt(fragment, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createElementMorph(element3);
                  morphs[1] = dom.createMorphAt(element3,1,1);
                  morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["element","action",["openExportInvoicePanel",["get","patientInfo.id",["loc",[null,[416,94],[416,108]]]],["get","patientInfo.info.invoiceId",["loc",[null,[417,94],[417,120]]]]],[],["loc",[null,[415,109],[417,122]]]],
                  ["inline","button-ready4payment",[],["size","32","color","#fff","showStroke",false,"content","Übergabe an Ausgangsrechnung"],["loc",[null,[418,24],[419,87]]]],
                  ["block","link-to",["practicemgmt.treatment-details",["get","patientInfo.id",["loc",[null,[421,66],[421,80]]]],["subexpr","query-params",[],["entry","true"],["loc",[null,[421,81],[421,108]]]]],["class","mr0 icon-button icon-button--default list-action-square"],0,null,["loc",[null,[421,22],[423,34]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 408,
                    "column": 18
                  },
                  "end": {
                    "line": 425,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","inProgress",["loc",[null,[409,26],[409,36]]]]],[],0,1,["loc",[null,[409,20],[424,27]]]]
              ],
              locals: ["patientInfo"],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 382,
                  "column": 12
                },
                "end": {
                  "line": 428,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24");
              dom.setAttribute(el2,"data-label","Check-In");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","sub");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" Uhr");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell  l-11/24");
              dom.setAttribute(el2,"data-label","Kund:in");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--action l-5/24");
              dom.setAttribute(el2,"data-label","");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1]);
              var element6 = dom.childAt(element5, [1]);
              var element7 = dom.childAt(element5, [3]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element6, [3]),0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element7, [1]),0,0);
              morphs[3] = dom.createMorphAt(element7,3,3);
              morphs[4] = dom.createMorphAt(dom.childAt(element5, [5]),1,1);
              return morphs;
            },
            statements: [
              ["inline","time-format",[["get","item.created",["loc",[null,[385,97],[385,109]]]],"DD. MM. YYYY"],[],["loc",[null,[385,83],[386,113]]]],
              ["inline","time-format",[["get","item.created",["loc",[null,[387,50],[387,62]]]],"HH:mm"],[],["loc",[null,[387,36],[387,72]]]],
              ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[390,52],[390,68]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[390,24],[391,130]]]],
              ["block","each",[["get","item.treatmentPatients",["loc",[null,[392,26],[392,48]]]]],[],1,null,["loc",[null,[392,18],[405,27]]]],
              ["block","each",[["get","item.treatmentPatients",["loc",[null,[408,26],[408,48]]]]],[],2,null,["loc",[null,[408,18],[425,27]]]]
            ],
            locals: ["item"],
            templates: [child0, child1, child2]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 49,
                "column": 0
              },
              "end": {
                "line": 433,
                "column": 0
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col u-hide@phone l-24/24 l-12/24@tablet l-6/24@desk pr10 ");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","card treatment-card");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","d-flex align-items-center");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"style","font-size: 2.2rem !important;");
            dom.setAttribute(el4,"class","ml5 is-highlighted");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h3");
            dom.setAttribute(el4,"class","mb0 ml10");
            var el5 = dom.createTextNode("Demnächst");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table grid table--small");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__head");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-8/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Termin");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-16/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Kund:in");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__body no-scrollbar");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col u-hide@phone l-24/24 l-12/24@tablet l-6/24@desk pr10 ");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","card treatment-card");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","d-flex align-items-center");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"style","font-size: 2.2rem !important;");
            dom.setAttribute(el4,"class","ml5 is-highlighted");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h3");
            dom.setAttribute(el4,"class","mb0 ml10");
            var el5 = dom.createTextNode("Wartezimmer");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table grid table--small");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__head");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-8/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Check-In");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-16/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Kund:in");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__body no-scrollbar");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col u-hide@phone l-24/24 l-12/24@tablet l-6/24@desk pr10 ");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","card treatment-card");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","d-flex align-items-center");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"style","font-size: 2.2rem !important;");
            dom.setAttribute(el4,"class","ml5 is-highlighted");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h3");
            dom.setAttribute(el4,"class","mb0 ml10");
            var el5 = dom.createTextNode("In Behandlung");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table table--small grid");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__head");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-8/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Check-In");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-16/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Kund:in");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__body no-scrollbar");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","card treatment-card");
            dom.setAttribute(el2,"id","stationsCard");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","d-flex align-items-center");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"style","font-size: 2.2rem !important;");
            dom.setAttribute(el4,"class","ml5 is-highlighted");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h3");
            dom.setAttribute(el4,"class","mb0 ml10");
            var el5 = dom.createTextNode("Auf Station");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table table--small grid");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__head");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-8/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Check-In");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-16/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Kund:in");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__body no-scrollbar");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col u-hide@phone l-24/24 l-12/24@tablet l-6/24@desk ");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","card treatment-card");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","d-flex align-items-center");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"style","font-size: 2.2rem !important;");
            dom.setAttribute(el4,"class","ml5 is-highlighted");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h3");
            dom.setAttribute(el4,"class","mb0 ml10");
            var el5 = dom.createTextNode("Bezahlung");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table table--small grid");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__head");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-8/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Check-In");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-14/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Kund:in");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__body no-scrollbar");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element46 = dom.childAt(fragment, [1, 1]);
            var element47 = dom.childAt(fragment, [3, 1]);
            var element48 = dom.childAt(fragment, [5]);
            var element49 = dom.childAt(element48, [1]);
            var element50 = dom.childAt(element48, [3]);
            var element51 = dom.childAt(fragment, [7, 1]);
            var morphs = new Array(10);
            morphs[0] = dom.createMorphAt(dom.childAt(element46, [1, 1]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element46, [3, 3]),1,1);
            morphs[2] = dom.createMorphAt(dom.childAt(element47, [1, 1]),0,0);
            morphs[3] = dom.createMorphAt(dom.childAt(element47, [3, 3]),1,1);
            morphs[4] = dom.createMorphAt(dom.childAt(element49, [1, 1]),0,0);
            morphs[5] = dom.createMorphAt(dom.childAt(element49, [3, 3]),1,1);
            morphs[6] = dom.createMorphAt(dom.childAt(element50, [1, 1]),0,0);
            morphs[7] = dom.createMorphAt(dom.childAt(element50, [3, 3]),1,1);
            morphs[8] = dom.createMorphAt(dom.childAt(element51, [1, 1]),0,0);
            morphs[9] = dom.createMorphAt(dom.childAt(element51, [3, 3]),1,1);
            return morphs;
          },
          statements: [
            ["inline","get-size",[["get","upcomingItems",["loc",[null,[53,92],[53,105]]]]],[],["loc",[null,[53,81],[53,107]]]],
            ["block","each",[["get","upcomingItems",["loc",[null,[62,20],[62,33]]]]],[],0,null,["loc",[null,[62,12],[116,21]]]],
            ["inline","get-size",[["get","waitingroomItems",["loc",[null,[124,92],[124,108]]]]],[],["loc",[null,[124,81],[124,110]]]],
            ["block","each",[["get","waitingroomItems",["loc",[null,[133,20],[133,36]]]]],[],1,null,["loc",[null,[133,12],[206,21]]]],
            ["inline","get-size",[["get","treatmentItems",["loc",[null,[214,92],[214,106]]]]],[],["loc",[null,[214,81],[214,108]]]],
            ["block","each",[["get","treatmentItems",["loc",[null,[223,20],[223,34]]]]],[],2,null,["loc",[null,[223,12],[288,21]]]],
            ["inline","get-size",[["get","stationItems",["loc",[null,[294,92],[294,104]]]]],[],["loc",[null,[294,81],[294,106]]]],
            ["block","each",[["get","stationItems",["loc",[null,[303,20],[303,32]]]]],[],3,null,["loc",[null,[303,12],[365,21]]]],
            ["inline","get-size",[["get","paymentItems",["loc",[null,[373,92],[373,104]]]]],[],["loc",[null,[373,81],[373,106]]]],
            ["block","each",[["get","paymentItems",["loc",[null,[382,20],[382,32]]]]],[],4,null,["loc",[null,[382,12],[428,21]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 0
            },
            "end": {
              "line": 435,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","with-context-bar grid");
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element54 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element54,1,1);
          morphs[1] = dom.createMorphAt(element54,3,3);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[["get","mainView",["loc",[null,[20,14],[20,22]]]],"tiles"],[],["loc",[null,[20,10],[20,31]]]]],[],0,null,["loc",[null,[20,4],[47,11]]]],
          ["block","if",[["get","users",["loc",[null,[49,6],[49,11]]]]],[],1,null,["loc",[null,[49,0],[433,7]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 451,
                "column": 10
              },
              "end": {
                "line": 453,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element2, 'class');
            morphs[1] = dom.createElementMorph(element2);
            morphs[2] = dom.createMorphAt(element2,0,0);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","station.selected",["loc",[null,[452,65],[452,81]]]],"selected",""],[],["loc",[null,[452,44],[452,97]]]]]]],
            ["element","action",["toggleStation",["get","station",["loc",[null,[452,124],[452,131]]]]],[],["loc",[null,[452,99],[452,133]]]],
            ["content","station.name",["loc",[null,[452,134],[452,150]]]]
          ],
          locals: ["station"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 448,
              "column": 6
            },
            "end": {
              "line": 455,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","quick-filter mb0 col l-12/24");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","title");
          var el3 = dom.createTextNode("Stationen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
          return morphs;
        },
        statements: [
          ["block","each",[["get","stations",["loc",[null,[451,18],[451,26]]]]],[],0,null,["loc",[null,[451,10],[453,19]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 462,
                  "column": 16
                },
                "end": {
                  "line": 470,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","symbol-label");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(5);
              morphs[0] = dom.createAttrMorph(element1, 'title');
              morphs[1] = dom.createAttrMorph(element1, 'class');
              morphs[2] = dom.createAttrMorph(element1, 'style');
              morphs[3] = dom.createElementMorph(element1);
              morphs[4] = dom.createMorphAt(dom.childAt(element1, [1]),0,0);
              return morphs;
            },
            statements: [
              ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[463,32],[463,46]]]]]]],
              ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[464,65],[464,78]]]],"selected",""],[],["loc",[null,[464,44],[465,69]]]]," symbol symbol-circle symbol-40px"]]],
              ["attribute","style",["concat",["background-color: ",["get","user.color",["loc",[null,[466,50],[466,60]]]]," !important;"]]],
              ["element","action",["toggleResource",["get","user",["loc",[null,[463,76],[463,80]]]]],[],["loc",[null,[463,50],[463,82]]]],
              ["content","user.capitals",["loc",[null,[467,46],[467,63]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 474,
                    "column": 20
                  },
                  "end": {
                    "line": 478,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","symbol-label");
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("img");
                dom.setAttribute(el2,"src","assets/images/icons/notassigned.svg");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 478,
                    "column": 20
                  },
                  "end": {
                    "line": 481,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","symbol-label");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                return morphs;
              },
              statements: [
                ["content","user.capitals",["loc",[null,[479,48],[479,65]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 470,
                  "column": 16
                },
                "end": {
                  "line": 483,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element0, 'title');
              morphs[1] = dom.createAttrMorph(element0, 'class');
              morphs[2] = dom.createElementMorph(element0);
              morphs[3] = dom.createMorphAt(element0,1,1);
              return morphs;
            },
            statements: [
              ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[471,32],[471,46]]]]]]],
              ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[472,65],[472,78]]]],"selected",""],[],["loc",[null,[472,44],[473,69]]]]," symbol symbol-circle symbol-40px ",["get","user.color",["loc",[null,[473,105],[473,115]]]]]]],
              ["element","action",["toggleResource",["get","user",["loc",[null,[471,76],[471,80]]]]],[],["loc",[null,[471,50],[471,82]]]],
              ["block","if",[["subexpr","eq",[["get","user.id",["loc",[null,[474,30],[474,37]]]],"9998"],[],["loc",[null,[474,26],[474,45]]]]],[],0,1,["loc",[null,[474,20],[481,27]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 461,
                "column": 14
              },
              "end": {
                "line": 484,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","user.colorHex",["loc",[null,[462,22],[462,35]]]]],[],0,1,["loc",[null,[462,16],[483,23]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 460,
              "column": 12
            },
            "end": {
              "line": 485,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","user.active",["loc",[null,[461,20],[461,31]]]]],[],0,null,["loc",[null,[461,14],[484,21]]]]
        ],
        locals: ["user"],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 530,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        dom.setAttribute(el1,"class","context-bar grid");
        dom.setAttribute(el1,"id","treatmentOverviewNav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-14/24");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","btn-group");
        dom.setAttribute(el3,"role","group");
        dom.setAttribute(el3,"aria-label","Basic example");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4,"type","button");
        var el5 = dom.createTextNode("Kombinierte Ansicht");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4,"type","button");
        var el5 = dom.createTextNode(" Nur Kacheln");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4,"type","button");
        var el5 = dom.createTextNode("Nur Liste");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","buttons-box col l-10/24");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","with-icon button context primary");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/add-entry.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createTextNode("Neue Behandlung");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","treatmentOverview");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("section");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table-wrapper mb30 mt20 grid");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","quick-filter mb0 col l-12/24");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title");
        var el6 = dom.createTextNode("Filter");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Alle");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Wartezimmer");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Behandlung");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Bereit zum Bezahlen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Station");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","quick-filter mb0 mt10 col l-24/24");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title");
        var el6 = dom.createTextNode("Mitarbeiter:innen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","symbol-group symbol-hover flex-shrink-0 me-2");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element55 = dom.childAt(fragment, [0]);
        var element56 = dom.childAt(element55, [1, 1]);
        var element57 = dom.childAt(element56, [1]);
        var element58 = dom.childAt(element56, [3]);
        var element59 = dom.childAt(element56, [5]);
        var element60 = dom.childAt(element55, [3, 1]);
        var element61 = dom.childAt(fragment, [2]);
        var element62 = dom.childAt(element61, [3]);
        var element63 = dom.childAt(element62, [1]);
        var element64 = dom.childAt(element63, [1]);
        var element65 = dom.childAt(element64, [3]);
        var element66 = dom.childAt(element64, [5]);
        var element67 = dom.childAt(element64, [7]);
        var element68 = dom.childAt(element64, [9]);
        var element69 = dom.childAt(element64, [11]);
        var morphs = new Array(27);
        morphs[0] = dom.createAttrMorph(element57, 'class');
        morphs[1] = dom.createElementMorph(element57);
        morphs[2] = dom.createAttrMorph(element58, 'class');
        morphs[3] = dom.createElementMorph(element58);
        morphs[4] = dom.createAttrMorph(element59, 'class');
        morphs[5] = dom.createElementMorph(element59);
        morphs[6] = dom.createElementMorph(element60);
        morphs[7] = dom.createAttrMorph(element61, 'class');
        morphs[8] = dom.createMorphAt(element61,1,1);
        morphs[9] = dom.createAttrMorph(element62, 'class');
        morphs[10] = dom.createAttrMorph(element65, 'class');
        morphs[11] = dom.createElementMorph(element65);
        morphs[12] = dom.createAttrMorph(element66, 'class');
        morphs[13] = dom.createElementMorph(element66);
        morphs[14] = dom.createAttrMorph(element67, 'class');
        morphs[15] = dom.createElementMorph(element67);
        morphs[16] = dom.createAttrMorph(element68, 'class');
        morphs[17] = dom.createElementMorph(element68);
        morphs[18] = dom.createAttrMorph(element69, 'class');
        morphs[19] = dom.createElementMorph(element69);
        morphs[20] = dom.createMorphAt(element63,3,3);
        morphs[21] = dom.createMorphAt(dom.childAt(element63, [5, 3]),1,1);
        morphs[22] = dom.createMorphAt(element62,3,3);
        morphs[23] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[24] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[25] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[26] = dom.createMorphAt(fragment,10,10,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["btn button ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","mainView",["loc",[null,[4,106],[4,114]]]],"splitted"],[],["loc",[null,[4,102],[4,126]]]],"selected",""],[],["loc",[null,[4,81],[4,142]]]]]]],
        ["element","action",["changeView","splitted"],[],["loc",[null,[4,14],[4,48]]]],
        ["attribute","class",["concat",["btn button ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","mainView",["loc",[null,[5,103],[5,111]]]],"tiles"],[],["loc",[null,[5,99],[5,120]]]],"selected",""],[],["loc",[null,[5,78],[5,136]]]]]]],
        ["element","action",["changeView","tiles"],[],["loc",[null,[5,14],[5,45]]]],
        ["attribute","class",["concat",["btn button ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","mainView",["loc",[null,[6,102],[6,110]]]],"list"],[],["loc",[null,[6,98],[6,118]]]],"selected",""],[],["loc",[null,[6,77],[6,134]]]]]]],
        ["element","action",["changeView","list"],[],["loc",[null,[6,14],[6,44]]]],
        ["element","action",["selectCustomerForTreatment"],[],["loc",[null,[10,7],[10,47]]]],
        ["attribute","class",["concat",[["get","mainView",["loc",[null,[16,37],[16,45]]]]]]],
        ["block","if",[["get","showTopTiles",["loc",[null,[17,6],[17,18]]]]],[],0,null,["loc",[null,[17,0],[435,9]]]],
        ["attribute","class",["concat",["site-content card transaction-filter  ",["subexpr","css-bool-evaluator",[["get","showTopTiles",["loc",[null,[437,77],[437,89]]]],"","with-context-bar"],[],["loc",[null,[437,56],[437,113]]]]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showAll",["loc",[null,[442,61],[442,68]]]],"selected",""],[],["loc",[null,[442,40],[442,85]]]]]]],
        ["element","action",["toggleQuickfilter","all"],[],["loc",[null,[442,87],[442,123]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showWaiting",["loc",[null,[443,61],[443,72]]]],"selected",""],[],["loc",[null,[443,40],[443,88]]]]]]],
        ["element","action",["toggleQuickfilter","waiting"],[],["loc",[null,[443,90],[443,130]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showTreatment",["loc",[null,[444,61],[444,74]]]],"selected",""],[],["loc",[null,[444,40],[444,90]]]]]]],
        ["element","action",["toggleQuickfilter","treatment"],[],["loc",[null,[444,92],[444,134]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showPayment",["loc",[null,[445,61],[445,72]]]],"selected",""],[],["loc",[null,[445,40],[445,88]]]]]]],
        ["element","action",["toggleQuickfilter","payment"],[],["loc",[null,[445,90],[445,130]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showStation",["loc",[null,[446,61],[446,72]]]],"selected",""],[],["loc",[null,[446,40],[446,88]]]]]]],
        ["element","action",["toggleQuickfilter","station"],[],["loc",[null,[446,90],[446,130]]]],
        ["block","if",[["get","showStation",["loc",[null,[448,12],[448,23]]]]],[],1,null,["loc",[null,[448,6],[455,13]]]],
        ["block","each",[["get","users",["loc",[null,[460,20],[460,25]]]]],[],2,null,["loc",[null,[460,12],[485,21]]]],
        ["inline","treatment-patient-list",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[491,14],[491,19]]]]],[],[]],"type",["subexpr","@mut",[["get","type",["loc",[null,[492,13],[492,17]]]]],[],[]],"selectedUsers",["subexpr","@mut",[["get","selectedUsers",["loc",[null,[493,22],[493,35]]]]],[],[]],"filteredStations",["subexpr","@mut",[["get","stations",["loc",[null,[494,25],[494,33]]]]],[],[]],"changeTrigger",["subexpr","@mut",[["get","changeTrigger",["loc",[null,[495,22],[495,35]]]]],[],[]],"showAll",["subexpr","@mut",[["get","showAll",["loc",[null,[496,16],[496,23]]]]],[],[]],"showAdmission",["subexpr","@mut",[["get","showAdmission",["loc",[null,[497,22],[497,35]]]]],[],[]],"showWaiting",["subexpr","@mut",[["get","showWaiting",["loc",[null,[498,20],[498,31]]]]],[],[]],"showTreatment",["subexpr","@mut",[["get","showTreatment",["loc",[null,[499,22],[499,35]]]]],[],[]],"showPayment",["subexpr","@mut",[["get","showPayment",["loc",[null,[500,20],[500,31]]]]],[],[]],"showStation",["subexpr","@mut",[["get","showStation",["loc",[null,[501,20],[501,31]]]]],[],[]],"showStationOverride",["subexpr","is-test-mandant",[],[],["loc",[null,[502,28],[502,45]]]],"abort","abort","openTime","openTime","movePatientToWaitingRoom","movePatientToWaitingRoom","movePatientToTreatmentRoom","movePatientToTreatmentRoom","movePatientToStation","movePatientToStation","exportInvoice","openExportInvoicePanel","editTreatmentInfo","editTreatmentInfo"],["loc",[null,[490,6],[510,8]]]],
        ["inline","edit-treatment-infos-panel",[],["confirm","saveEditTreatmentInfo","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[513,67],[513,72]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[513,84],[513,94]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[513,103],[513,110]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","infoTreatmentPanel",["loc",[null,[513,126],[513,144]]]]],[],[]]],["loc",[null,[513,0],[513,146]]]],
        ["inline","start-treatment-panel",[],["confirm","selectCustomerConfirm","gotoCustomer","gotoCustomer","gotoPatient","gotoPatient","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[519,8],[519,13]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[520,10],[520,17]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[521,13],[521,23]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","startTreatmentPanel",["loc",[null,[522,17],[522,36]]]]],[],[]]],["loc",[null,[515,0],[522,38]]]],
        ["inline","export-invoice-panel",[],["exportInvoice","openExportInvoicePanel","confirm","exportInvoice","actionReceiver",["subexpr","@mut",[["get","exportInvoicePanel",["loc",[null,[527,17],[527,35]]]]],[],[]]],["loc",[null,[524,0],[527,37]]]],
        ["inline","select-app-time",[],["save","saveDate","actionReceiver",["subexpr","@mut",[["get","selectAppTime",["loc",[null,[528,49],[528,62]]]]],[],[]]],["loc",[null,[528,0],[528,64]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});