define('jason-frontend/components/simple-select', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    selectedValues: new Array(),
    loading: true,
    timerId: {},
    mode: 'single',
    prefix: '',
    actions: {
      doSearch: function doSearch() {
        var self = this;
        if (!self.get('filterName') || self.get('filterName') && self.get('filterName').length < 3) {
          this.set('values', this.get('originalValues'));
          return;
        }
        var newValues = new Array();
        this.get('originalValues').forEach(function (item) {
          if (item.name.toLowerCase().search(self.get('filterName').toLowerCase()) >= 0) {
            newValues.push(item);
          }
        });
        this.set('values', newValues);
      },
      select: function select(item) {
        this.sendAction('select', item, this.get('reference'));
        this.set('values', null);
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        this.sendAction('confirm', this.get('selectedValues'), this.get('reference'), this.get('provider'));
        $.magnificPopup.close();
      },
      load: function load(values, title, reference) {
        this.set('values', values);
        this.set('originalValues', values);
        this.set('title', title);
        this.set('reference', reference);
        this.set('filterName', '');

        setTimeout(function () {
          $('input[name=filterName]').focus();
        }, 500);
      }
    },
    watchSearch: (function () {
      var self = this;
      var type = 'defaultTimer';
      if (this.timerId[type]) {
        window.clearTimeout(this.timerId[type]);
      }
      this.timerId[type] = window.setTimeout(function () {
        self.send('doSearch');
      }, 500);
    }).observes('filterName')
  });

});