define('jason-frontend/models/product', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    individualName: DS['default'].attr('string'),
    individualDescription: DS['default'].attr('string'),
    atcCode: DS['default'].attr('string'),
    tags: DS['default'].attr(''),
    systemTags: DS['default'].attr(''),
    importDetails: DS['default'].attr(''),
    lastImport: DS['default'].attr(''),
    primarySupplierId: DS['default'].attr(''),
    atcCodeDescription: DS['default'].attr('string'),
    foreignId: DS['default'].attr('string'),
    rpaId: DS['default'].attr(''),
    gtin: DS['default'].attr(''),
    pzn: DS['default'].attr(''),
    description: DS['default'].attr('string'),
    purchasePrice: DS['default'].attr('number'),
    effectivePrice: DS['default'].attr('number'),
    purchaseRelevant: DS['default'].attr('boolean'),
    priceExVat: DS['default'].attr('number'),
    additionPercentage: DS['default'].attr('number'),
    additionalPercentage: DS['default'].attr('number'),
    jasonRecommendedPrice: DS['default'].attr('number'),
    jasonRecommendedAddition: DS['default'].attr('number'),
    jasonPrice: DS['default'].attr('boolean'),
    reorderPurchaseSupplierInfos: DS['default'].attr(''),
    orderNotice: DS['default'].attr(''),
    autosendReorder: DS['default'].attr(''),
    jasonPriceAuto: DS['default'].attr('boolean'),
    deleted: DS['default'].attr(''),
    favorite: DS['default'].attr('boolean'),
    quantity: DS['default'].attr('number'),
    editable: DS['default'].attr('boolean'),
    imported: DS['default'].attr('boolean'),
    ehapoActive: DS['default'].attr('boolean'),
    ehapoAutoBookingActive: DS['default'].attr(''),
    globalAutoBookingActive: DS['default'].attr(''),
    bestPrice: DS['default'].attr('boolean'),
    jason: DS['default'].attr('boolean'),
    applied: DS['default'].attr('boolean'),
    doseMethod: DS['default'].attr(''),
    doseInfoSpecial: DS['default'].attr(''),
    slopingInfo: DS['default'].attr('string'),
    individualSlopingInfo: DS['default'].attr('string'),
    tgd: DS['default'].attr('boolean'),
    hideEhapo: DS['default'].attr('boolean'),
    labCategory: DS['default'].attr('string'),
    autoDosingActive: DS['default'].attr(),
    referenceNumber: DS['default'].attr('number'),
    picture: DS['default'].belongsTo('media'),
    category: DS['default'].attr(''),
    supplier: DS['default'].belongsTo('product-supplier'),
    supplierFlat: DS['default'].attr('string'),
    licenceNumber: DS['default'].attr('string'),
    licenceNumberEu: DS['default'].attr(''),
    licenceNumberAges: DS['default'].attr(''),
    licenceNumberApo: DS['default'].attr(''),
    manufacturer: DS['default'].belongsTo('product-manufacturer'),
    distributor: DS['default'].belongsTo('product-distributor'),
    tax: DS['default'].belongsTo('tax'),
    unit: DS['default'].belongsTo('serviceunit', { async: true }),
    altUnit: DS['default'].attr(''),
    prices: DS['default'].hasMany('articlePrice'),
    partnerCategory: DS['default'].attr('string'),
    partnerSubcategory: DS['default'].attr('string'),
    info: DS['default'].attr('string'),
    partnerNumber: DS['default'].attr('string'),
    stockLevel: DS['default'].attr('number'),
    migrated: DS['default'].attr(),
    richterCase: DS['default'].attr(),
    stockLevels: DS['default'].attr(),
    stockDetails: DS['default'].attr(),
    labProfile: DS['default'].attr(''),
    equivalentDetails: DS['default'].attr(),
    quantitySelection: DS['default'].attr(),
    patientCategories: DS['default'].hasMany('patientCategory'),
    vetconnectTest: DS['default'].attr(''),
    type: DS['default'].attr(''),
    ean: DS['default'].attr('')
  });

});