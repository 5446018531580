define('jason-frontend/components/customer-payout-deposit-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      load: function load(ref) {
        var self = this;

        this.set('payoutValue', this.get('totalDeposit'));
        $.ajax({
          url: "/api/cashRegisters?hideInactive=true",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('cashRegisters', data.cashRegister);
          self.set('selectedCashRegister', data.cashRegister.get('firstObject'));
          if (API['default'].getPrimaryCashRegisterId()) {
            data.cashRegister.forEach(function (item) {
              if (item.id === API['default'].getPrimaryCashRegisterId()) {
                self.set('selectedCashRegister', item);
              }
            });
          }
        });
      },
      save: function save(invoice) {
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      payout: function payout(totalDeposit, customerId) {

        if (!this.get('selectedCashRegister')) {
          jason.notifiction.error('Fehler', 'Bitte wählen Sie eine Kassa');
          return;
        }

        if (this.get('payoutValue') > totalDeposit) {
          jason.notifiction.error('Fehler', 'Auszahlungsbetrag darf max. dem Guthaben entsprechen');
          return;
        }

        var data = {
          value: String(this.get('payoutValue')).replace(',', '.'),
          cashRegisterId: this.get('selectedCashRegister').id,
          customerId: customerId
        };

        this.sendAction('payout', data);
        $.magnificPopup.close();
      }
    }
  });

});