define('jason-frontend/templates/components/powersearch-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 8
            },
            "end": {
              "line": 13,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"src","assets/images/misc/loading.svg");
          dom.setAttribute(el1,"class","loading");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 26,
                  "column": 32
                },
                "end": {
                  "line": 26,
                  "column": 80
                }
              },
              "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(", ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["content","searchItem.street",["loc",[null,[26,57],[26,78]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 8
              },
              "end": {
                "line": 30,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","avatar");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("img");
            dom.setAttribute(el3,"src","assets/images/icons/powersearch/customer.svg");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","text");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("p");
            dom.setAttribute(el3,"class","top");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("p");
            dom.setAttribute(el3,"class","bottom");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/powersearch/enter.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element15 = dom.childAt(fragment, [1]);
            var element16 = dom.childAt(element15, [3]);
            var element17 = dom.childAt(element16, [1]);
            var element18 = dom.childAt(element16, [3]);
            var morphs = new Array(7);
            morphs[0] = dom.createAttrMorph(element15, 'class');
            morphs[1] = dom.createElementMorph(element15);
            morphs[2] = dom.createMorphAt(element17,0,0);
            morphs[3] = dom.createMorphAt(element17,2,2);
            morphs[4] = dom.createMorphAt(element18,0,0);
            morphs[5] = dom.createMorphAt(element18,1,1);
            morphs[6] = dom.createMorphAt(element18,3,3);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["d-flex align-items-center entry ",["subexpr","css-bool-evaluator",[["get","searchItem.selected",["loc",[null,[20,72],[20,91]]]],"selected",""],[],["loc",[null,[20,51],[20,107]]]]]]],
            ["element","action",["gotoCustomerEdit",["get","searchItem.id",["loc",[null,[19,43],[19,56]]]]],[],["loc",[null,[19,15],[19,58]]]],
            ["content","searchItem.firstname",["loc",[null,[25,29],[25,53]]]],
            ["content","searchItem.lastname",["loc",[null,[25,54],[25,77]]]],
            ["block","if",[["get","searchItem.street",["loc",[null,[26,38],[26,55]]]]],[],0,null,["loc",[null,[26,32],[26,87]]]],
            ["content","searchItem.postalCode",["loc",[null,[26,87],[26,112]]]],
            ["content","searchItem.town",["loc",[null,[26,113],[26,132]]]]
          ],
          locals: ["searchItem"],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 31,
                "column": 8
              },
              "end": {
                "line": 33,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","ml30");
            var el2 = dom.createTextNode("Bitte Suchbegriff eingeben");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 6
            },
            "end": {
              "line": 34,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","filteredItems",["loc",[null,[18,16],[18,29]]]]],[],0,null,["loc",[null,[18,8],[30,17]]]],
          ["block","unless",[["get","filteredItems",["loc",[null,[31,18],[31,31]]]]],[],1,null,["loc",[null,[31,8],[33,19]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 43,
                    "column": 32
                  },
                  "end": {
                    "line": 43,
                    "column": 90
                  }
                },
                "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" - ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["content","searchItem.category.name",["loc",[null,[43,59],[43,87]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 35,
                  "column": 8
                },
                "end": {
                  "line": 47,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","avatar");
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("img");
              dom.setAttribute(el3,"src","assets/images/icons/powersearch/animal.svg");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","text");
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("p");
              dom.setAttribute(el3,"class","top");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("p");
              dom.setAttribute(el3,"class","bottom");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/icons/powersearch/enter.svg");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element12 = dom.childAt(fragment, [1]);
              var element13 = dom.childAt(element12, [3]);
              var element14 = dom.childAt(element13, [3]);
              var morphs = new Array(6);
              morphs[0] = dom.createAttrMorph(element12, 'class');
              morphs[1] = dom.createElementMorph(element12);
              morphs[2] = dom.createMorphAt(dom.childAt(element13, [1]),0,0);
              morphs[3] = dom.createMorphAt(element14,0,0);
              morphs[4] = dom.createMorphAt(element14,1,1);
              morphs[5] = dom.createMorphAt(element14,3,3);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["d-flex align-items-center entry ",["subexpr","css-bool-evaluator",[["get","searchItem.selected",["loc",[null,[37,72],[37,91]]]],"selected",""],[],["loc",[null,[37,51],[37,107]]]]]]],
              ["element","action",["gotoPatientEdit",["get","searchItem.id",["loc",[null,[36,42],[36,55]]]]],[],["loc",[null,[36,15],[36,57]]]],
              ["content","searchItem.name",["loc",[null,[42,29],[42,48]]]],
              ["block","if",[["get","searchItem.category",["loc",[null,[43,38],[43,57]]]]],[],0,null,["loc",[null,[43,32],[43,97]]]],
              ["content","searchItem.customer.firstname",["loc",[null,[43,97],[43,130]]]],
              ["content","searchItem.customer.lastname",["loc",[null,[43,131],[43,163]]]]
            ],
            locals: ["searchItem"],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 48,
                  "column": 8
                },
                "end": {
                  "line": 50,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","ml30");
              var el2 = dom.createTextNode("Bitte Suchbegriff eingeben");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 6
              },
              "end": {
                "line": 51,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","each",[["get","filteredItems",["loc",[null,[35,16],[35,29]]]]],[],0,null,["loc",[null,[35,8],[47,17]]]],
            ["block","unless",[["get","filteredItems",["loc",[null,[48,18],[48,31]]]]],[],1,null,["loc",[null,[48,8],[50,19]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 56,
                      "column": 14
                    },
                    "end": {
                      "line": 58,
                      "column": 14
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("img");
                  dom.setAttribute(el1,"src","assets/images/icons/powersearch/service.svg");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 58,
                        "column": 14
                      },
                      "end": {
                        "line": 60,
                        "column": 14
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("img");
                    dom.setAttribute(el1,"src","assets/images/icons/powersearch/product.svg");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 60,
                          "column": 14
                        },
                        "end": {
                          "line": 62,
                          "column": 14
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("img");
                      dom.setAttribute(el1,"src","assets/images/icons/powersearch/package.svg");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 62,
                            "column": 14
                          },
                          "end": {
                            "line": 64,
                            "column": 14
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("img");
                        dom.setAttribute(el1,"src","assets/images/icons/powersearch/template.svg");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n              ");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() { return []; },
                      statements: [

                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 62,
                          "column": 14
                        },
                        "end": {
                          "line": 64,
                          "column": 14
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["subexpr","eq",[["get","searchItem.type",["loc",[null,[62,28],[62,43]]]],"template"],[],["loc",[null,[62,24],[62,55]]]]],[],0,null,["loc",[null,[62,14],[64,14]]]]
                    ],
                    locals: [],
                    templates: [child0]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 60,
                        "column": 14
                      },
                      "end": {
                        "line": 64,
                        "column": 14
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","eq",[["get","searchItem.type",["loc",[null,[60,28],[60,43]]]],"package"],[],["loc",[null,[60,24],[60,54]]]]],[],0,1,["loc",[null,[60,14],[64,14]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 58,
                      "column": 14
                    },
                    "end": {
                      "line": 64,
                      "column": 14
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","searchItem.type",["loc",[null,[58,28],[58,43]]]],"product"],[],["loc",[null,[58,24],[58,54]]]]],[],0,1,["loc",[null,[58,14],[64,14]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            var child2 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 68,
                      "column": 59
                    },
                    "end": {
                      "line": 68,
                      "column": 110
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode(" (Mehrere Varianten)");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child3 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 72,
                        "column": 16
                      },
                      "end": {
                        "line": 75,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["inline","format-currency",[],["value",["subexpr","get-inclusive-price",[["get","searchItem.priceExVat",["loc",[null,[74,49],[74,70]]]],["get","searchItem.tax.percentage",["loc",[null,[74,71],[74,96]]]]],[],["loc",[null,[74,28],[74,97]]]]],["loc",[null,[73,20],[74,99]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 75,
                          "column": 16
                        },
                        "end": {
                          "line": 78,
                          "column": 16
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                    ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["inline","format-currency",[],["value",["subexpr","get-inclusive-price",[["get","searchItem.priceExVat",["loc",[null,[77,49],[77,70]]]],["get","searchItem.tax.percentage",["loc",[null,[77,71],[77,96]]]],["get","searchItem.applied",["loc",[null,[77,97],[77,115]]]]],[],["loc",[null,[77,28],[77,116]]]]],["loc",[null,[76,20],[77,118]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 78,
                            "column": 16
                          },
                          "end": {
                            "line": 80,
                            "column": 16
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                    ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n                ");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                        return morphs;
                      },
                      statements: [
                        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","searchItem.total",["loc",[null,[79,44],[79,60]]]]],[],[]]],["loc",[null,[79,20],[79,62]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 78,
                          "column": 16
                        },
                        "end": {
                          "line": 80,
                          "column": 16
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["subexpr","eq",[["get","searchItem.type",["loc",[null,[78,30],[78,45]]]],"product"],[],["loc",[null,[78,26],[78,56]]]]],[],0,null,["loc",[null,[78,16],[80,16]]]]
                    ],
                    locals: [],
                    templates: [child0]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 75,
                        "column": 16
                      },
                      "end": {
                        "line": 80,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","eq",[["get","searchItem.type",["loc",[null,[75,30],[75,45]]]],"product"],[],["loc",[null,[75,26],[75,56]]]]],[],0,1,["loc",[null,[75,16],[80,16]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 71,
                      "column": 14
                    },
                    "end": {
                      "line": 81,
                      "column": 14
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","searchItem.type",["loc",[null,[72,26],[72,41]]]],"service"],[],["loc",[null,[72,22],[72,52]]]]],[],0,1,["loc",[null,[72,16],[80,23]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            var child4 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 84,
                      "column": 14
                    },
                    "end": {
                      "line": 91,
                      "column": 13
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","fav");
                  dom.setNamespace("http://www.w3.org/2000/svg");
                  var el2 = dom.createElement("svg");
                  dom.setAttribute(el2,"xmlns","http://www.w3.org/2000/svg");
                  dom.setAttribute(el2,"viewBox","0 0 32 32");
                  dom.setAttribute(el2,"width","15");
                  dom.setAttribute(el2,"height","15");
                  var el3 = dom.createTextNode("\n  ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("g");
                  dom.setAttribute(el3,"fill","none");
                  dom.setAttribute(el3,"fill-rule","evenodd");
                  dom.setAttribute(el3,"transform","translate(1 1)");
                  var el4 = dom.createTextNode("\n     ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("g");
                  dom.setAttribute(el4,"transform","translate(0,0) scale(0.06,0.06)");
                  dom.setAttribute(el4,"fill","#fff");
                  var el5 = dom.createTextNode("\n        ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("path");
                  dom.setAttribute(el5,"d","M255,489.6l-35.7-35.7C86.7,336.6,0,257.55,0,160.65C0,81.6,61.2,20.4,140.25,20.4c43.35,0,86.7,20.4,114.75,53.55\n			C283.05,40.8,326.4,20.4,369.75,20.4C448.8,20.4,510,81.6,510,160.65c0,96.9-86.7,175.95-219.3,293.25L255,489.6z");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n      ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n  ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 52,
                    "column": 8
                  },
                  "end": {
                    "line": 95,
                    "column": 8
                  }
                },
                "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","avatar");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","text");
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("p");
                dom.setAttribute(el3,"class","top");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("p");
                dom.setAttribute(el3,"class","bottom");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","priceLabel");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","favorite");
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("img");
                dom.setAttribute(el2,"src","assets/images/icons/powersearch/enter.svg");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element9 = dom.childAt(fragment, [1]);
                var element10 = dom.childAt(element9, [3]);
                var element11 = dom.childAt(element10, [3]);
                var morphs = new Array(8);
                morphs[0] = dom.createAttrMorph(element9, 'class');
                morphs[1] = dom.createElementMorph(element9);
                morphs[2] = dom.createMorphAt(dom.childAt(element9, [1]),1,1);
                morphs[3] = dom.createMorphAt(dom.childAt(element10, [1]),0,0);
                morphs[4] = dom.createMorphAt(element11,0,0);
                morphs[5] = dom.createMorphAt(element11,2,2);
                morphs[6] = dom.createMorphAt(dom.childAt(element9, [5]),1,1);
                morphs[7] = dom.createMorphAt(dom.childAt(element9, [7]),1,1);
                return morphs;
              },
              statements: [
                ["attribute","class",["concat",["d-flex align-items-center entry ",["get","searchItem.subType",["loc",[null,[54,53],[54,71]]]]," ",["subexpr","css-bool-evaluator",[["get","searchItem.selected",["loc",[null,[54,95],[54,114]]]],"selected",""],[],["loc",[null,[54,74],[54,130]]]]]]],
                ["element","action",["addArticleToTreatment",["get","searchItem",["loc",[null,[53,48],[53,58]]]]],[],["loc",[null,[53,15],[53,60]]]],
                ["block","if",[["subexpr","eq",[["get","searchItem.type",["loc",[null,[56,24],[56,39]]]],"service"],[],["loc",[null,[56,20],[56,50]]]]],[],0,1,["loc",[null,[56,14],[64,21]]]],
                ["content","searchItem.name",["loc",[null,[67,29],[67,48]]]],
                ["content","searchItem.description",["loc",[null,[68,32],[68,58]]]],
                ["block","if",[["subexpr","gt",[["get","item.pricesCount",["loc",[null,[68,69],[68,85]]]],0],[],["loc",[null,[68,65],[68,88]]]]],[],2,null,["loc",[null,[68,59],[68,117]]]],
                ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[71,20],[71,33]]]]],[],3,null,["loc",[null,[71,14],[81,21]]]],
                ["block","if",[["get","searchItem.favorite",["loc",[null,[84,20],[84,39]]]]],[],4,null,["loc",[null,[84,14],[91,20]]]]
              ],
              locals: ["searchItem"],
              templates: [child0, child1, child2, child3, child4]
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 96,
                    "column": 8
                  },
                  "end": {
                    "line": 98,
                    "column": 8
                  }
                },
                "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","ml30");
                var el2 = dom.createTextNode("Bitte ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("b");
                var el3 = dom.createTextNode("Suchbegriff");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" eingeben");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 51,
                  "column": 6
                },
                "end": {
                  "line": 99,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","each",[["get","filteredItems",["loc",[null,[52,16],[52,29]]]]],[],0,null,["loc",[null,[52,8],[95,17]]]],
              ["block","unless",[["get","filteredItems",["loc",[null,[96,18],[96,31]]]]],[],1,null,["loc",[null,[96,8],[98,19]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 108,
                        "column": 59
                      },
                      "end": {
                        "line": 108,
                        "column": 103
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createElement("b");
                    var el2 = dom.createTextNode("Verabreicht");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 111,
                        "column": 14
                      },
                      "end": {
                        "line": 114,
                        "column": 14
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["inline","format-currency",[],["value",["subexpr","get-inclusive-price",[["get","searchItem.price",["loc",[null,[113,45],[113,61]]]],["get","searchItem.tax",["loc",[null,[113,62],[113,76]]]],["get","searchItem.applied",["loc",[null,[113,77],[113,95]]]]],[],["loc",[null,[113,24],[113,96]]]]],["loc",[null,[112,16],[113,98]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child2 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 117,
                        "column": 14
                      },
                      "end": {
                        "line": 124,
                        "column": 27
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","fav");
                    dom.setNamespace("http://www.w3.org/2000/svg");
                    var el2 = dom.createElement("svg");
                    dom.setAttribute(el2,"xmlns","http://www.w3.org/2000/svg");
                    dom.setAttribute(el2,"viewBox","0 0 32 32");
                    dom.setAttribute(el2,"width","15");
                    dom.setAttribute(el2,"height","15");
                    var el3 = dom.createTextNode("\n                ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("g");
                    dom.setAttribute(el3,"fill","none");
                    dom.setAttribute(el3,"fill-rule","evenodd");
                    dom.setAttribute(el3,"transform","translate(1 1)");
                    var el4 = dom.createTextNode("\n                  ");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createElement("g");
                    dom.setAttribute(el4,"transform","translate(0,0) scale(0.06,0.06)");
                    dom.setAttribute(el4,"fill","#fff");
                    var el5 = dom.createTextNode("\n                    ");
                    dom.appendChild(el4, el5);
                    var el5 = dom.createElement("path");
                    dom.setAttribute(el5,"d","M255,489.6l-35.7-35.7C86.7,336.6,0,257.55,0,160.65C0,81.6,61.2,20.4,140.25,20.4c43.35,0,86.7,20.4,114.75,53.55\n			C283.05,40.8,326.4,20.4,369.75,20.4C448.8,20.4,510,81.6,510,160.65c0,96.9-86.7,175.95-219.3,293.25L255,489.6z");
                    dom.appendChild(el4, el5);
                    var el5 = dom.createTextNode("\n                  ");
                    dom.appendChild(el4, el5);
                    dom.appendChild(el3, el4);
                    var el4 = dom.createTextNode("\n                ");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n              ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 100,
                      "column": 8
                    },
                    "end": {
                      "line": 128,
                      "column": 8
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","avatar");
                  var el3 = dom.createTextNode("\n              ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("img");
                  dom.setAttribute(el3,"src","assets/images/icons/powersearch/product.svg");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","text");
                  var el3 = dom.createTextNode("\n              ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("p");
                  dom.setAttribute(el3,"class","top");
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n              ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("p");
                  dom.setAttribute(el3,"class","bottom");
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode(" ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","priceLabel");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","favorite");
                  var el3 = dom.createTextNode("\n              ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("img");
                  dom.setAttribute(el2,"src","assets/images/icons/powersearch/enter.svg");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n          ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element6 = dom.childAt(fragment, [1]);
                  var element7 = dom.childAt(element6, [3]);
                  var element8 = dom.childAt(element7, [3]);
                  var morphs = new Array(7);
                  morphs[0] = dom.createAttrMorph(element6, 'class');
                  morphs[1] = dom.createElementMorph(element6);
                  morphs[2] = dom.createMorphAt(dom.childAt(element7, [1]),0,0);
                  morphs[3] = dom.createMorphAt(element8,0,0);
                  morphs[4] = dom.createMorphAt(element8,2,2);
                  morphs[5] = dom.createMorphAt(dom.childAt(element6, [5]),1,1);
                  morphs[6] = dom.createMorphAt(dom.childAt(element6, [7]),1,1);
                  return morphs;
                },
                statements: [
                  ["attribute","class",["concat",["d-flex align-items-center entry ",["subexpr","css-bool-evaluator",[["get","searchItem.selected",["loc",[null,[102,72],[102,91]]]],"selected",""],[],["loc",[null,[102,51],[102,107]]]]]]],
                  ["element","action",["addProductToTreatment",["get","searchItem",["loc",[null,[101,48],[101,58]]]]],[],["loc",[null,[101,15],[101,60]]]],
                  ["content","searchItem.name",["loc",[null,[107,29],[107,48]]]],
                  ["content","searchItem.description",["loc",[null,[108,32],[108,58]]]],
                  ["block","if",[["get","searchItem.applied",["loc",[null,[108,65],[108,83]]]]],[],0,null,["loc",[null,[108,59],[108,110]]]],
                  ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[111,20],[111,33]]]]],[],1,null,["loc",[null,[111,14],[114,21]]]],
                  ["block","if",[["get","searchItem.favorite",["loc",[null,[117,20],[117,39]]]]],[],2,null,["loc",[null,[117,14],[124,34]]]]
                ],
                locals: ["searchItem"],
                templates: [child0, child1, child2]
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 129,
                      "column": 8
                    },
                    "end": {
                      "line": 131,
                      "column": 8
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","ml30");
                  var el2 = dom.createElement("b");
                  var el3 = dom.createTextNode("VPE");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(" auswählen");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 99,
                    "column": 6
                  },
                  "end": {
                    "line": 132,
                    "column": 6
                  }
                },
                "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","each",[["get","filteredItems",["loc",[null,[100,16],[100,29]]]]],[],0,null,["loc",[null,[100,8],[128,17]]]],
                ["block","unless",[["get","filteredItems",["loc",[null,[129,18],[129,31]]]]],[],1,null,["loc",[null,[129,8],[131,19]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 133,
                        "column": 8
                      },
                      "end": {
                        "line": 145,
                        "column": 8
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    var el2 = dom.createTextNode("\n            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","avatar");
                    var el3 = dom.createTextNode("\n              ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("img");
                    dom.setAttribute(el3,"src","assets/images/icons/powersearch/product.svg");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n            ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","text");
                    var el3 = dom.createTextNode("\n              ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("p");
                    dom.setAttribute(el3,"class","top");
                    var el4 = dom.createComment("");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n              ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("p");
                    dom.setAttribute(el3,"class","bottom");
                    var el4 = dom.createTextNode("-");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n            ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("img");
                    dom.setAttribute(el2,"src","assets/images/icons/powersearch/enter.svg");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n          ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element5 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createAttrMorph(element5, 'class');
                    morphs[1] = dom.createElementMorph(element5);
                    morphs[2] = dom.createMorphAt(dom.childAt(element5, [3, 1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["attribute","class",["concat",["d-flex align-items-center entry ",["subexpr","css-bool-evaluator",[["get","searchItem.selected",["loc",[null,[135,72],[135,91]]]],"selected",""],[],["loc",[null,[135,51],[135,107]]]]]]],
                    ["element","action",["gotoProductEdit",["get","searchItem.id",["loc",[null,[134,42],[134,55]]]]],[],["loc",[null,[134,15],[134,57]]]],
                    ["content","searchItem.nameSuggest",["loc",[null,[140,29],[140,55]]]]
                  ],
                  locals: ["searchItem"],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 146,
                        "column": 8
                      },
                      "end": {
                        "line": 148,
                        "column": 8
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml30");
                    var el2 = dom.createTextNode("Bitte Suchbegriff eingeben");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 132,
                      "column": 6
                    },
                    "end": {
                      "line": 149,
                      "column": 6
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","filteredItems",["loc",[null,[133,16],[133,29]]]]],[],0,null,["loc",[null,[133,8],[145,17]]]],
                  ["block","unless",[["get","filteredItems",["loc",[null,[146,18],[146,31]]]]],[],1,null,["loc",[null,[146,8],[148,19]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 150,
                          "column": 8
                        },
                        "end": {
                          "line": 162,
                          "column": 8
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                    },
                    arity: 1,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("          ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      var el2 = dom.createTextNode("\n            ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","avatar");
                      var el3 = dom.createTextNode("\n              ");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createElement("img");
                      dom.setAttribute(el3,"src","assets/images/icons/powersearch/service.svg");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n            ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n            ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","text");
                      var el3 = dom.createTextNode("\n              ");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createElement("p");
                      dom.setAttribute(el3,"class","top");
                      var el4 = dom.createComment("");
                      dom.appendChild(el3, el4);
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n              ");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createElement("p");
                      dom.setAttribute(el3,"class","bottom");
                      var el4 = dom.createTextNode("-");
                      dom.appendChild(el3, el4);
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n            ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n            ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("img");
                      dom.setAttribute(el2,"src","assets/images/icons/powersearch/enter.svg");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n          ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element4 = dom.childAt(fragment, [1]);
                      var morphs = new Array(3);
                      morphs[0] = dom.createAttrMorph(element4, 'class');
                      morphs[1] = dom.createElementMorph(element4);
                      morphs[2] = dom.createMorphAt(dom.childAt(element4, [3, 1]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["attribute","class",["concat",["d-flex align-items-center entry ",["subexpr","css-bool-evaluator",[["get","searchItem.selected",["loc",[null,[152,72],[152,91]]]],"selected",""],[],["loc",[null,[152,51],[152,107]]]]]]],
                      ["element","action",["gotoServiceEdit",["get","searchItem.id",["loc",[null,[151,42],[151,55]]]]],[],["loc",[null,[151,15],[151,57]]]],
                      ["content","searchItem.name",["loc",[null,[157,29],[157,48]]]]
                    ],
                    locals: ["searchItem"],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 163,
                          "column": 8
                        },
                        "end": {
                          "line": 165,
                          "column": 8
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("          ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","ml30");
                      var el2 = dom.createTextNode("Bitte Suchbegriff eingeben");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 149,
                        "column": 6
                      },
                      "end": {
                        "line": 166,
                        "column": 6
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","each",[["get","filteredItems",["loc",[null,[150,16],[150,29]]]]],[],0,null,["loc",[null,[150,8],[162,17]]]],
                    ["block","unless",[["get","filteredItems",["loc",[null,[163,18],[163,31]]]]],[],1,null,["loc",[null,[163,8],[165,19]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 167,
                            "column": 8
                          },
                          "end": {
                            "line": 179,
                            "column": 8
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                      },
                      arity: 1,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("          ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("div");
                        var el2 = dom.createTextNode("\n            ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("div");
                        dom.setAttribute(el2,"class","avatar");
                        var el3 = dom.createTextNode("\n              ");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createElement("img");
                        dom.setAttribute(el3,"src","assets/images/icons/powersearch/package.svg");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createTextNode("\n            ");
                        dom.appendChild(el2, el3);
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n            ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("div");
                        dom.setAttribute(el2,"class","text");
                        var el3 = dom.createTextNode("\n              ");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createElement("p");
                        dom.setAttribute(el3,"class","top");
                        var el4 = dom.createComment("");
                        dom.appendChild(el3, el4);
                        dom.appendChild(el2, el3);
                        var el3 = dom.createTextNode("\n              ");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createElement("p");
                        dom.setAttribute(el3,"class","bottom");
                        var el4 = dom.createTextNode("-");
                        dom.appendChild(el3, el4);
                        dom.appendChild(el2, el3);
                        var el3 = dom.createTextNode("\n            ");
                        dom.appendChild(el2, el3);
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n            ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("img");
                        dom.setAttribute(el2,"src","assets/images/icons/powersearch/enter.svg");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n          ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var element3 = dom.childAt(fragment, [1]);
                        var morphs = new Array(3);
                        morphs[0] = dom.createAttrMorph(element3, 'class');
                        morphs[1] = dom.createElementMorph(element3);
                        morphs[2] = dom.createMorphAt(dom.childAt(element3, [3, 1]),0,0);
                        return morphs;
                      },
                      statements: [
                        ["attribute","class",["concat",["d-flex align-items-center entry ",["subexpr","css-bool-evaluator",[["get","searchItem.selected",["loc",[null,[169,72],[169,91]]]],"selected",""],[],["loc",[null,[169,51],[169,107]]]]]]],
                        ["element","action",["gotoPackageEdit",["get","searchItem.id",["loc",[null,[168,42],[168,55]]]]],[],["loc",[null,[168,15],[168,57]]]],
                        ["content","searchItem.name",["loc",[null,[174,29],[174,48]]]]
                      ],
                      locals: ["searchItem"],
                      templates: []
                    };
                  }());
                  var child1 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 180,
                            "column": 8
                          },
                          "end": {
                            "line": 182,
                            "column": 8
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("          ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("span");
                        dom.setAttribute(el1,"class","ml30");
                        var el2 = dom.createTextNode("Bitte Suchbegriff eingeben");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() { return []; },
                      statements: [

                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 166,
                          "column": 6
                        },
                        "end": {
                          "line": 183,
                          "column": 6
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(2);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","each",[["get","filteredItems",["loc",[null,[167,16],[167,29]]]]],[],0,null,["loc",[null,[167,8],[179,17]]]],
                      ["block","unless",[["get","filteredItems",["loc",[null,[180,18],[180,31]]]]],[],1,null,["loc",[null,[180,8],[182,19]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                var child1 = (function() {
                  var child0 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 189,
                              "column": 14
                            },
                            "end": {
                              "line": 191,
                              "column": 14
                            }
                          },
                          "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("img");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element0 = dom.childAt(fragment, [1]);
                          var morphs = new Array(1);
                          morphs[0] = dom.createAttrMorph(element0, 'src');
                          return morphs;
                        },
                        statements: [
                          ["attribute","src",["concat",["assets/images/icons/powersearch/",["get","searchItem.icon",["loc",[null,[190,60],[190,75]]]],".svg"]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      var child0 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 192,
                                "column": 16
                              },
                              "end": {
                                "line": 192,
                                "column": 104
                              }
                            },
                            "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createElement("img");
                            dom.setAttribute(el1,"src","assets/images/icons/powersearch/help.svg");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes() { return []; },
                          statements: [

                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      var child1 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 193,
                                "column": 16
                              },
                              "end": {
                                "line": 193,
                                "column": 108
                              }
                            },
                            "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createElement("img");
                            dom.setAttribute(el1,"src","assets/images/icons/powersearch/search.svg");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes() { return []; },
                          statements: [

                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      var child2 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 194,
                                "column": 16
                              },
                              "end": {
                                "line": 194,
                                "column": 104
                              }
                            },
                            "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createElement("img");
                            dom.setAttribute(el1,"src","assets/images/icons/powersearch/link.svg");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes() { return []; },
                          statements: [

                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      var child3 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 195,
                                "column": 16
                              },
                              "end": {
                                "line": 195,
                                "column": 108
                              }
                            },
                            "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createElement("img");
                            dom.setAttribute(el1,"src","assets/images/icons/powersearch/wizard.svg");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes() { return []; },
                          statements: [

                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 191,
                              "column": 14
                            },
                            "end": {
                              "line": 196,
                              "column": 14
                            }
                          },
                          "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n                ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n                ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n                ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(4);
                          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                          morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
                          morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
                          return morphs;
                        },
                        statements: [
                          ["block","if",[["subexpr","eq",[["get","searchItem.type",["loc",[null,[192,26],[192,41]]]],"help"],[],["loc",[null,[192,22],[192,49]]]]],[],0,null,["loc",[null,[192,16],[192,111]]]],
                          ["block","if",[["subexpr","eq",[["get","searchItem.type",["loc",[null,[193,26],[193,41]]]],"search"],[],["loc",[null,[193,22],[193,51]]]]],[],1,null,["loc",[null,[193,16],[193,115]]]],
                          ["block","if",[["subexpr","eq",[["get","searchItem.type",["loc",[null,[194,26],[194,41]]]],"link"],[],["loc",[null,[194,22],[194,49]]]]],[],2,null,["loc",[null,[194,16],[194,111]]]],
                          ["block","if",[["subexpr","eq",[["get","searchItem.type",["loc",[null,[195,26],[195,41]]]],"wizard"],[],["loc",[null,[195,22],[195,51]]]]],[],3,null,["loc",[null,[195,16],[195,115]]]]
                        ],
                        locals: [],
                        templates: [child0, child1, child2, child3]
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 185,
                            "column": 8
                          },
                          "end": {
                            "line": 204,
                            "column": 8
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                      },
                      arity: 1,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("          ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("div");
                        var el2 = dom.createTextNode("\n            ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("div");
                        dom.setAttribute(el2,"class","avatar");
                        var el3 = dom.createTextNode("\n");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createComment("");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createTextNode("            ");
                        dom.appendChild(el2, el3);
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n            ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("div");
                        dom.setAttribute(el2,"class","text");
                        var el3 = dom.createTextNode("\n              ");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createElement("p");
                        dom.setAttribute(el3,"class","top");
                        var el4 = dom.createComment("");
                        dom.appendChild(el3, el4);
                        dom.appendChild(el2, el3);
                        var el3 = dom.createTextNode("\n              ");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createElement("p");
                        dom.setAttribute(el3,"class","bottom");
                        var el4 = dom.createComment("");
                        dom.appendChild(el3, el4);
                        dom.appendChild(el2, el3);
                        var el3 = dom.createTextNode("\n            ");
                        dom.appendChild(el2, el3);
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n            ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("img");
                        dom.setAttribute(el2,"src","assets/images/icons/powersearch/enter.svg");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n          ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var element1 = dom.childAt(fragment, [1]);
                        var element2 = dom.childAt(element1, [3]);
                        var morphs = new Array(5);
                        morphs[0] = dom.createAttrMorph(element1, 'class');
                        morphs[1] = dom.createElementMorph(element1);
                        morphs[2] = dom.createMorphAt(dom.childAt(element1, [1]),1,1);
                        morphs[3] = dom.createMorphAt(dom.childAt(element2, [1]),0,0);
                        morphs[4] = dom.createMorphAt(dom.childAt(element2, [3]),0,0);
                        return morphs;
                      },
                      statements: [
                        ["attribute","class",["concat",["d-flex align-items-center entry ",["subexpr","css-bool-evaluator",[["get","searchItem.selected",["loc",[null,[187,72],[187,91]]]],"selected",""],[],["loc",[null,[187,51],[187,107]]]]]]],
                        ["element","action",["trigger",["get","searchItem",["loc",[null,[186,34],[186,44]]]]],[],["loc",[null,[186,15],[186,46]]]],
                        ["block","if",[["get","searchItem.icon",["loc",[null,[189,20],[189,35]]]]],[],0,1,["loc",[null,[189,14],[196,21]]]],
                        ["content","searchItem.name",["loc",[null,[199,29],[199,48]]]],
                        ["content","searchItem.description",["loc",[null,[200,32],[200,58]]]]
                      ],
                      locals: ["searchItem"],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 183,
                          "column": 6
                        },
                        "end": {
                          "line": 205,
                          "column": 6
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("        ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","resultHeading");
                      var el2 = dom.createTextNode("Suchergebnisse");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("      ");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["block","each",[["get","filteredItems",["loc",[null,[185,16],[185,29]]]]],[],0,null,["loc",[null,[185,8],[204,17]]]]
                    ],
                    locals: [],
                    templates: [child0]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 166,
                        "column": 6
                      },
                      "end": {
                        "line": 205,
                        "column": 6
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","eq",[["get","subContext",["loc",[null,[166,20],[166,30]]]],"packageSearch"],[],["loc",[null,[166,16],[166,47]]]]],[],0,1,["loc",[null,[166,6],[205,6]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 149,
                      "column": 6
                    },
                    "end": {
                      "line": 205,
                      "column": 6
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","subContext",["loc",[null,[149,20],[149,30]]]],"serviceSearch"],[],["loc",[null,[149,16],[149,47]]]]],[],0,1,["loc",[null,[149,6],[205,6]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 132,
                    "column": 6
                  },
                  "end": {
                    "line": 205,
                    "column": 6
                  }
                },
                "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","subContext",["loc",[null,[132,20],[132,30]]]],"productSearch"],[],["loc",[null,[132,16],[132,47]]]]],[],0,1,["loc",[null,[132,6],[205,6]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 99,
                  "column": 6
                },
                "end": {
                  "line": 205,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","subContext",["loc",[null,[99,20],[99,30]]]],"treatmentAddProduct"],[],["loc",[null,[99,16],[99,53]]]]],[],0,1,["loc",[null,[99,6],[205,6]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 51,
                "column": 6
              },
              "end": {
                "line": 205,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["get","subContext",["loc",[null,[51,20],[51,30]]]],"treatmentAddPosition"],[],["loc",[null,[51,16],[51,54]]]]],[],0,1,["loc",[null,[51,6],[205,6]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 34,
              "column": 6
            },
            "end": {
              "line": 205,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[["get","subContext",["loc",[null,[34,20],[34,30]]]],"patientSearch"],[],["loc",[null,[34,16],[34,47]]]]],[],0,1,["loc",[null,[34,6],[205,6]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 214,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/powersearch-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-powersearch");
        dom.setAttribute(el1,"class","popup-basic popup-large admin-form mfp-with-anim mfp-hide small--heading");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel u-drop-shadow");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-heading");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","search--box");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"for","search");
        dom.setAttribute(el5,"class","search__box");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-body pl10 pr10 p25");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-footer");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","assets/images/icons/powersearch/cursor.svg");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Navigieren");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createTextNode("Auswählen ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","assets/images/icons/powersearch/enter.svg");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Verlassen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","assets/images/icons/powersearch/esc.svg");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element19 = dom.childAt(fragment, [0, 1]);
        var element20 = dom.childAt(element19, [1, 1]);
        var element21 = dom.childAt(element20, [1]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element21, 'src');
        morphs[1] = dom.createMorphAt(dom.childAt(element20, [3]),1,1);
        morphs[2] = dom.createMorphAt(element20,5,5);
        morphs[3] = dom.createMorphAt(dom.childAt(element19, [3]),1,1);
        return morphs;
      },
      statements: [
        ["attribute","src",["concat",["assets/images/icons/powersearch/",["get","searchIcon",["loc",[null,[6,52],[6,62]]]],".svg"]]],
        ["inline","input",[],["id","powerSearchInput","name","search","value",["subexpr","@mut",[["get","powerSearch",["loc",[null,[8,60],[8,71]]]]],[],[]],"class","gui-input input input--search search__input","placeholder",["subexpr","@mut",[["get","placeholder",["loc",[null,[9,82],[9,93]]]]],[],[]],"autocomplete","off"],["loc",[null,[8,10],[9,114]]]],
        ["block","if",[["get","loading",["loc",[null,[11,14],[11,21]]]]],[],0,null,["loc",[null,[11,8],[13,15]]]],
        ["block","if",[["subexpr","eq",[["get","subContext",["loc",[null,[17,16],[17,26]]]],"customerSearch"],[],["loc",[null,[17,12],[17,44]]]]],[],1,2,["loc",[null,[17,6],[205,13]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});