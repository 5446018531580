define('jason-frontend/routes/appointments/calendar', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api', 'jason-frontend/helpers/is-journal-enabled', 'moment'], function (exports, Ember, RouteMixin, API, is_journal_enabled, moment) {

  'use strict';

  var timer = null;

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    saveUserSettings: function saveUserSettings() {
      var self = this;
      clearTimeout(this.timer);
      this.timer = setTimeout(function () {
        self.saveUserSettingsIntern();
      }, 2000);
    },
    saveUserSettingsIntern: function saveUserSettingsIntern() {
      var controller = this.get('controller');

      var data = {
        showFilter: controller.get('showFilter'),
        viewId: controller.get('selectedView'),
        colorMode: controller.get('colorMode'),
        resourceIds: controller.get('selectedResources')
      };

      $.ajax({
        url: "/api/users/calSettings",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(data),
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data2) {}, function (data2) {});
    },
    updateUserSettings: function updateUserSettings() {
      var controller = this.get('controller');
      var self = this;
      $.ajax({
        url: "/api/users/calSettings",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        var settings = data.userCalSettings;
        controller.set('showFilter', settings.showFilter);
        if (settings.resourceIds) {
          controller.get('users').forEach(function (user) {
            if (settings.resourceIds.some(function (e) {
              return e + '' === user.id + '';
            })) {
              controller.get('selectedResources').push(user.id);
              Ember['default'].set(user, 'selected', true);
              controller.get('cal').addResource({
                id: user.id,
                title: user.get('capitals'),
                role: user.get('userRoleId')
              });
            }
          });
        }
        if (settings.viewId) {
          var viewId = settings.viewId;
          if (settings.resourceIds && settings.resourceIds.length > 0 && viewId.indexOf('resource') < 0) {
            viewId = 'resourceTime' + viewId.replace('time', '');
          }
          controller.get('cal').changeView(viewId);
          controller.set('selectedView', viewId.replace("resourceTime", "time"));
          if (viewId.indexOf('resource') >= 0) {
            controller.set('hideBgEvents', false);
          } else {
            controller.set('hideBgEvents', true);
          }
        }
        if (settings.colorMode) {
          if (settings.colorMode === 'user') {
            controller.set('colorMode', 'user');
            controller.set('colorModeUserEnabled', true);
          } else {
            controller.set('colorMode', 'category');
            controller.set('colorModeUserEnabled', false);
          }
        }
        $('.fc-day-grid').css('margin-top', $('.fc-head').height() - 1);
      }, function (data) {});
    },
    myResizeFunction: function myResizeFunction() {
      Ember['default'].$('.fc-toolbar.fc-header-toolbar').width(Ember['default'].$('#calendar').width());
      $('.fc-day-grid').css('margin-top', $('.fc-head').height() - 1);
    },
    scrollToStart: function scrollToStart() {
      setTimeout(function () {
        var scrollToElement = $('.fc-slats').find('tr[data-time*="06:30:00"]');
        var elementOffsetTop = scrollToElement && scrollToElement.length > 0 ? scrollToElement[0].offsetTop : 0;
        $('html, body').animate({
          scrollTop: elementOffsetTop
        }, 100);
      }, 500);
    },
    setupController: function setupController(controller, model, params) {
      var self = this;
      controller.set('doctors', this.store.find('user', { role: 'doctor', per_page: 100 }));
      controller.set('assistants', this.store.find('user', { role: 'assistant', per_page: 100 }));
      controller.set('users', this.store.find('user', { per_page: 100, addAll: false, addNotAssigned: true }));
      controller.set('rooms', this.store.find('treatment-room'));
      controller.set('stations', this.store.find('treatment-station'));
      controller.set('patientCategories', this.store.find('patient-appointment-category', { filterActive: true }));
      controller.set('resources', this.store.find('appointment-resource'));

      var defaultSlotDuration = API['default'].getCalDefaultSlotDuration();

      controller.set('showUserBadges', API['default'].getShowUserBadges());
      if (!defaultSlotDuration || isNaN(defaultSlotDuration) || defaultSlotDuration === '0') {
        defaultSlotDuration = 30;
      }

      defaultSlotDuration = '00:' + defaultSlotDuration + ':00';

      console.log(defaultSlotDuration);
      $.ajax({
        url: "/api/patientAppointmentCategories?filterActive=true&etiga=false",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('categories', data.patientAppointmentCategory);
      });
      $.ajax({
        url: "/api/patientAppointmentCategories?filterActive=true&etiga=true",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('etigaCategories', data.patientAppointmentCategory);
      });

      controller.set('showLoading', true);

      Ember['default'].$(window).on('resize', this.myResizeFunction);

      var self = this;
      setTimeout(function () {
        var calendarEl = document.getElementById('calendar');
        var calendar = new FullCalendar.Calendar(calendarEl, {
          plugins: ['interaction', 'dayGrid', 'timeGrid', 'resourceTimeGrid'],
          datesAboveResources: true,
          schedulerLicenseKey: '0878593577-fcs-1715064797',
          header: false,
          views: {
            timeGridFiveDays: {
              type: 'timeGridWeek',
              weekends: false
            },
            resourceTimeGridFiveDays: {
              type: 'resourceTimeGridWeek',
              weekends: false
            },
            month: {
              timeFormat: 'H:mm'
            }
          },
          defaultDate: moment['default']().format("YYYY-MM-DD"),
          locale: 'de',
          defaultView: API['default'].getCalWeekdays() === '7' ? 'timeGridWeek' : 'timeGridFiveDays',
          height: "auto",
          navLinks: true,
          nowIndicator: true,
          selectable: true,
          disableResizing: true,
          slotDuration: defaultSlotDuration,
          snapDuration: '00:15:00',
          scrollTime: '10:00:00',
          editable: true,
          selectMirror: true,
          columnHeaderText: function columnHeaderText(date) {
            var weekday = moment['default'](date).format('ddd');
            if (weekday === 'Mon') {
              weekday = 'MO';
            } else if (weekday === 'Tue') {
              weekday = 'DI';
            } else if (weekday === 'Wed') {
              weekday = 'MI';
            } else if (weekday === 'Thu') {
              weekday = 'DO';
            } else if (weekday === 'Fri') {
              weekday = 'FR';
            } else if (weekday === 'Sat') {
              weekday = 'SA';
            } else if (weekday === 'Sun') {
              weekday = 'SO';
            }
            return weekday + ', ' + moment['default'](date).format('DD.MM');
          },
          eventMouseEnter: function eventMouseEnter(info) {

            var appType = info.event.extendedProps.appType;
            var appTitle = 'Kundentermin';

            if (info.event.extendedProps.readOnly) {
              return;
            }

            if (!info.event.extendedProps.customer) {
              appTitle = 'Termin';
            }
            if (appType) {
              if (appType === 'user') {
                appTitle = "Mitarbeiter Termin";
              }
              if (appType === 'resource') {
                appTitle = "Dienstplan";
              }
              if (appType === 'task') {
                appTitle = "Aufgabe";
              }
            }

            var content = '<span class=\"head\">' + appTitle + '</span><span class="flex-top line"><img src="assets/images/icons/clock.svg"> <span class="time">' + info.event.extendedProps.head + ' Uhr</span></span>';

            if (info.event.extendedProps.category) {
              content += '<span class="flex-top line category"> <img src="assets/images/icons/empty.svg"><span class="category">' + info.event.extendedProps.category + '</span></span>';
            }

            if (info.event.extendedProps.customer) {
              content += '<span class="flex-top line customer"> <img src="assets/images/icons/customer-info.svg"><span>' + info.event.extendedProps.customer + '</span></span>';
            }
            if (info.event.extendedProps.patient) {
              content += '<span class="flex-top line patient">';
              if (info.event.extendedProps.patientCategory) {
                content += '<img src="assets/images/icons/patient-categories/' + info.event.extendedProps.patientCategory + '.svg"/>';
              } else {
                content += '<img src="assets/images/icons/empty.svg">';
              }
              content += '<span>' + info.event.extendedProps.patient + '</span></span>';
            }

            var regex = /(<([^>]+)>)/ig;

            if (info.event.extendedProps.description && info.event.extendedProps.description.replace(regex, "").length > 1) {
              content += '<span class="flex-top line"><img src="assets/images/icons/info.svg"><span>' + info.event.extendedProps.description + '</div></span></span>';
            }

            if (info.event.extendedProps.treatmentStatus) {
              var _status = '';
              var icon = 'treatment-white.svg';
              if (info.event.extendedProps.treatmentStatus === 'in_treatment') {
                _status = "Behandlung";
              } else if (info.event.extendedProps.treatmentStatus === 'in_waitingroom') {
                _status = "Wartezimmer";
                icon = 'waitingroom-white.svg';
              } else if (info.event.extendedProps.treatmentStatus === 'ready_for_payment') {
                _status = "Bezahlung";
                icon = 'ready_for_payment-white.svg';
              } else if (info.event.extendedProps.treatmentStatus === 'in_station') {
                _status = "Auf Station";
                icon = 'station-white.svg';
              } else if (info.event.extendedProps.treatmentStatus === 'closed') {
                _status = "Behandlung abgeschlossen";
                icon = 'check-white.svg';
              }
              if (_status) {
                content += '<span class="flex-top line"><div class="status-badge"><img src="assets/images/icons/' + icon + '"></div><span>' + _status + '</span></span>';
              }
            }

            if (info.event.extendedProps.appStatus) {
              var _status2 = info.event.extendedProps.appStatus;
              if (_status2 === 'booked') {
                content += '<span class="flex-top line"><div class="status-badge cal ' + _status2 + '"><img src="assets/images/icons/cal-white.svg"></div><span>Bestätigt</span></span>';
              }
            }

            $(info.el).tooltipster({
              'contentAsHTML': true,
              'side': 'right',
              'delay': 0,
              'timer': 0,
              'theme': 'elovet',
              'updateAnimation': null
            }).tooltipster('content', content).tooltipster('open');
          },
          eventMouseLeave: function eventMouseLeave(info) {},
          eventResize: function eventResize(arg) {
            var id = arg.event.id;
            var self = this;

            var data = {
              id: id,
              start: arg.event.start,
              end: arg.event.end
            };

            $.ajax({
              url: "/api/patientAppointments/move",
              method: "POST",
              contentType: "application/json",
              dataType: "json",
              data: JSON.stringify(data),
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function () {
              self.refetchEvents();
            }, function () {
              self.refetchEvents();
            });
          },
          eventDrop: function eventDrop(arg) {
            var id = arg.event.id;
            var self = this;

            var data = {
              id: id,
              start: arg.event.start,
              end: arg.event.end
            };

            if (arg.newResource) {
              data.newRole = arg.newResource.extendedProps.role;
              data.newResourceId = arg.newResource.id;
            }
            if (arg.oldResource) {
              data.oldRole = arg.oldResource.extendedProps.role;
              data.oldResourceId = arg.oldResource.id;
            }

            var classNames = arg.event.classNames;

            var target = 'patientAppointments';
            if (classNames.contains('task')) {
              target = 'tasks';
            }

            $.ajax({
              url: "/api/" + target + "/move",
              method: "POST",
              contentType: "application/json",
              dataType: "json",
              data: JSON.stringify(data),
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function () {
              self.refetchEvents();
            }, function () {
              self.refetchEvents();
            });
          },
          select: function select(arg) {

            controller.get('addAppointmentPanel').send('loadFromCal', arg);
            $.magnificPopup.open({
              removalDelay: 500,
              closeOnBgClick: false,
              items: {
                src: '#modal-add-appointment-panel'
              },
              callbacks: {
                beforeOpen: function beforeOpen() {
                  var Animation = "mfp-with-fade";
                  this.st.mainClass = Animation;
                }
              },
              midClick: false
            });
          },
          eventClick: function eventClick(calEvent, jsEvent, view) {
            var classNames = calEvent.event.classNames;
            var series = calEvent.event.extendedProps.seriesId;

            if (calEvent.event.extendedProps.readOnly) {
              return;
            }

            if (classNames.contains('task')) {
              $.ajax({
                url: "/api/tasks/" + calEvent.event.id,
                method: "GET",
                contentType: "application/json",
                dataType: "json",
                headers: {
                  'X-AUTH-TOKEN': API['default'].getToken()
                }
              }).then(function (data) {
                controller.get('showTaskPanel').send('load', data.task);
              });

              $.ajax({
                url: "/api/tasks/" + calEvent.event.id + "/history",
                method: "GET",
                contentType: "application/json",
                dataType: "json",
                headers: {
                  'X-AUTH-TOKEN': API['default'].getToken()
                }
              }).then(function (data) {
                controller.get('showTaskPanel').send('loadHistory', data.taskHistory);
              });

              $.magnificPopup.open({
                removalDelay: 500,
                closeOnBgClick: false,
                items: {
                  src: '#modal-show-task-panel'
                },
                callbacks: {
                  beforeOpen: function beforeOpen() {
                    var Animation = "mfp-with-fade";
                    this.st.mainClass = Animation;
                  }
                },
                midClick: true
              });
            } else {

              if (series) {
                $.magnificPopup.open({
                  removalDelay: 500,
                  closeOnBgClick: false,
                  items: {
                    src: '#modal-choose-appointment-edit-type-panel'
                  },
                  callbacks: {
                    beforeOpen: function beforeOpen() {
                      var Animation = "mfp-with-fade";
                      this.st.mainClass = Animation;
                    }
                  },
                  midClick: true
                });

                controller.get('chooseAppointmentEditTypePanel').send('load', calEvent.event.id);
              } else {
                self.editEvent(calEvent.event.id);
              }
            }
          },
          events: function events(info, callback, failureCallback) {
            var users = new Array();
            var resources = new Array();
            var types = new Array();
            var cats = new Array();
            var locations = new Array();
            var stations = new Array();

            $('input.checkboxUser:checked').each(function () {
              users.push($(this).val());
            });

            $('input.checkResource:checked').each(function () {
              resources.push($(this).val());
            });

            $('input.checkboxType:checked').each(function () {
              types.push($(this).val());
            });
            $('input.checkboxCat:checked').each(function () {
              cats.push($(this).val());
            });
            $('input.checkboxLocation:checked').each(function () {
              locations.push($(this).val());
            });
            $('input.checkboxStation:checked').each(function () {
              stations.push($(this).val());
            });

            var path = 'filterFrom=' + moment['default'](info.start).format('YYYY-MM-DD') + '&filterTo=' + moment['default'](info.end).format('YYYY-MM-DD') + '&colorMode=' + controller.get('colorMode');

            if (users) {
              path += '&filterUsers=' + users.join(',');
            }
            if (types) {
              path += '&filterTypes=' + types.join(',');
            }
            if (resources) {
              path += '&filterResources=' + resources.join(',');
            }
            if (cats) {
              path += '&filterCats=' + cats.join(',');
            }
            if (locations) {
              path += '&filterLocations=' + locations.join(',');
            }
            if (stations) {
              path += '&filterStations=' + stations.join(',');
            }

            $.ajax({
              url: 'api/appointments?' + path,
              type: 'GEt',
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              },
              error: function error() {
                jason.notifiction.error("Fehler", "Termine konnten leider nicht abgerufen werden");
              },
              success: function success(data) {
                callback(data);
              }
            });
          },
          loading: function loading(view) {
            if (view) {
              controller.set('showLoading', true);
            } else {
              controller.set('showLoading', false);
            }
          }
        });

        controller.set('cal', calendar);
        calendar.render();

        controller.set('showLoading', false);

        $.ajax({
          url: "/api/profile",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('profile', data.profile);
          API['default'].setCalCategory(data.profile.calCategory);
          setTimeout(function () {
            self.refreshCal();
          }, 200);
        }, function (data) {});

        $('#sidebar').css('min-height', $('#calendarWrapper').height() + 42 + 'px');
        self.myResizeFunction();
        self.scrollToStart();
        self.updateDate();
        self.updateUserSettings();
        self.updateTooltips();
      }, 1000);
    },
    refreshCal: function refreshCal() {
      this.get('controller').get('cal').refetchEvents();
      this.myResizeFunction();
    },
    adjustRefresh: function adjustRefresh(checkboxName, prefix, referer) {
      if (referer === 'all') {
        if ($('#' + prefix + '-9999').prop('checked')) {
          $('input.' + checkboxName).each(function (index, val) {
            if (val.id !== prefix + '-9999') {
              $(this).prop('checked', false);
            }
          });
        }
      } else {
        var atLeastOne = false;
        $('input.' + checkboxName + ':checked').each(function (index, val) {
          atLeastOne = true;
        });
        if (atLeastOne) {
          $('#' + prefix + '-9999').prop('checked', false);
        }
      }
    },
    editEvent: function editEvent(id, series) {
      var controller = this.get('controller');

      var url = "/api/patientAppointments/" + id;
      if (series) {
        url = "/api/patientAppointments/" + id + "/series";
      }
      var self = this;
      $.ajax({
        url: url,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {

        var appStatus = data.patientAppointment.appStatus;

        var msgId = data.patientAppointment.messageId;

        if (appStatus === 'requested' && msgId && msgId > 0) {

          $.ajax({
            url: "/api/msgs/" + msgId,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (msg) {
            controller.get('appRequestConfirmPanel').send('load', data.patientAppointment, msg.msg);

            setTimeout(function () {
              $.magnificPopup.open({
                removalDelay: 500,
                closeOnBgClick: false,
                items: {
                  src: '#modal-app-request-confirm-panel'
                },
                callbacks: {
                  beforeOpen: function beforeOpen() {
                    var Animation = "mfp-with-fade";
                    this.st.mainClass = Animation;
                  }
                },
                midClick: true
              });
            }, 500);
          });
        } else {
          controller.get('addAppointmentPanel').send('loadEdit', data.patientAppointment, self.store.find('patient-appointment-category'), series);

          setTimeout(function () {
            $.magnificPopup.open({
              removalDelay: 500,
              closeOnBgClick: false,
              items: {
                src: '#modal-add-appointment-panel'
              },
              callbacks: {
                beforeOpen: function beforeOpen() {
                  var Animation = "mfp-with-fade";
                  this.st.mainClass = Animation;
                }
              },
              midClick: false
            });
          }, 500);
        }
      });
    },
    deleteAppointment: function deleteAppointment(app, editSeries, msg) {
      var inform = arguments.length <= 3 || arguments[3] === undefined ? true : arguments[3];

      if (!editSeries) {
        editSeries = false;
      }

      var data = {
        message: msg,
        inform: inform
      };

      var self = this;

      if (msg && msg.length > 0) {
        $.ajax({
          url: "/api/patientAppointments/" + app.id + "/sendAppDenialMessage",
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          $.ajax({
            url: "/api/patientAppointments/" + app.id + "?editSeries=" + editSeries,
            method: "DELETE",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function () {
            jason.notifiction.notifiy('Termin', 'erfolgreich gelöscht');
            self.refreshCal();
          }, function () {});
        }, function () {});
      } else {
        $.ajax({
          url: "/api/patientAppointments/" + app.id + "?editSeries=" + editSeries,
          method: "DELETE",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Termin', 'erfolgreich gelöscht');
          self.refreshCal();
        }, function () {});
      }
    },
    getWeekNumber: function getWeekNumber(date) {
      var momentDate = moment['default'](date);
      return momentDate.isoWeek().toString();
    },
    updateDate: function updateDate() {
      this.get('controller').set('currentDateFormatted', this.get('controller').get('cal').view.title);
      var date = this.get('controller').get('cal').getDate();
      date.setHours(4);
      this.get('controller').set('weekNumber', this.getWeekNumber(date));
    },
    actions: {
      toggleSidebar: function toggleSidebar() {
        var controller = this.get('controller');
        if (controller.get('sidebarActive') === true) {
          controller.set('sidebarActive', false);
        } else {
          controller.set('sidebarActive', true);
          setTimeout(function () {
            $('aside.side-bar div.scrollable').css('max-height', $(window).height() - 100);
          }, 100);
        }
        setTimeout(function () {
          controller.get('cal').updateSize();
          $('#calendarWrapper thead.fc-head').css('width', $('#calendar').width());
          $('#calendarWrapper .fc-widget-content .fc-day-grid').css('width', $('#calendar').width() - 3);
        }, 100);
      },
      changeView: function changeView(id) {
        var saveSettings = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];

        var controller = this.get('controller');

        var counter = controller.get('selectedResources').length;

        if (counter > 0) {
          var to = 'resourceTimeGridFiveDays';
          if (id.indexOf('Week') > 0) {
            to = 'resourceTimeGridWeek';
          }
          if (id.indexOf('FiveDays') > 0) {
            to = 'resourceTimeGridFiveDays';
          }
          if (id === 'timeGrid') {
            to = 'resourceTimeGrid';
          }
          controller.get('cal').changeView(to);
        } else {
          controller.get('cal').changeView(id);
        }
        this.updateDate();
        if (saveSettings) {
          this.saveUserSettings();
        }
      },
      toggleResourceNone: function toggleResourceNone() {
        var controller = this.get('controller');

        if (controller.get('selectedResources').contains(-1)) {
          controller.get('selectedResources').splice(controller.get('selectedResources').indexOf(-1), 1);
          // Ember.set(user, 'selected', false);
          this.get('controller').get('cal').getResourceById(-1).remove();
        } else {
          controller.get('selectedResources').push(-1);
          // Ember.set(user, 'selected', true);
          this.get('controller').get('cal').addResource({
            id: -1,
            title: 'n/a'
          });
        }
      },
      toggleResource: function toggleResource(user) {
        var controller = this.get('controller');
        if (controller.get('selectedResources').contains(user.id)) {
          controller.get('selectedResources').splice(controller.get('selectedResources').indexOf(user.id), 1);
          Ember['default'].set(user, 'selected', false);
          this.get('controller').get('cal').getResourceById(user.id).remove();
        } else {
          controller.get('selectedResources').push(user.id);
          Ember['default'].set(user, 'selected', true);
          this.get('controller').get('cal').addResource({
            id: user.id,
            title: user.get('capitals'),
            role: user.get('userRoleId')
          });
        }
        var counter = controller.get('selectedResources').length;

        var oldView = this.get('controller').get('cal').view.type;
        if (counter > 0) {
          controller.set('hideBgEvents', false);
          var to = 'resourceTimeGridFiveDays';
          if (oldView.indexOf('Week') > 0) {
            to = 'resourceTimeGridWeek';
          }
          if (oldView.indexOf('FiveDays') > 0) {
            to = 'resourceTimeGridFiveDays';
          }
          if (oldView === 'timeGrid') {
            to = 'resourceTimeGrid';
          }
          if (oldView === 'resourceTimeGrid') {
            to = 'resourceTimeGrid';
          }
          this.get('controller').get('cal').changeView(to);
        } else {
          controller.set('hideBgEvents', true);
          var to = 'week';
          if (oldView.indexOf('Week') > 0) {
            to = 'timeGridWeek';
          }
          if (oldView.indexOf('FiveDays') > 0) {
            to = 'timeGridFiveDays';
          }
          if (oldView === 'resourceTimeGrid') {
            to = 'timeGrid';
          }
          this.get('controller').get('cal').changeView(to);
        }
        setTimeout(function () {
          $('#calendarWrapper thead.fc-head').css('width', $('#calendar').width());
          $('#calendarWrapper .fc-widget-content .fc-day-grid').css('width', $('#calendar').width() - 3);
          $('.fc-day-grid').css('margin-top', $('.fc-head').height() - 1);
        }, 100);

        this.saveUserSettings();
      },
      toggleColorMode: function toggleColorMode() {
        var save = arguments.length <= 0 || arguments[0] === undefined ? true : arguments[0];

        var controller = this.get('controller');
        if (controller.get('colorMode') === 'category') {
          controller.set('colorMode', 'user');
          controller.set('colorModeUserEnabled', true);
        } else {
          controller.set('colorMode', 'category');
          controller.set('colorModeUserEnabled', false);
        }
        this.refreshCal();
        if (save) {
          this.saveUserSettings();
        }
      },
      toggleFilter: function toggleFilter() {
        var controller = this.get('controller');
        if (controller.get('showFilter')) {
          controller.set('showFilter', false);
        } else {
          controller.set('showFilter', true);
        }
        this.saveUserSettings();
      },
      today: function today() {
        this.get('controller').get('cal').today();
        this.updateDate();
      },
      next: function next() {
        this.get('controller').get('cal').next();
        this.updateDate();
      },
      prev: function prev() {
        this.get('controller').get('cal').prev();
        this.updateDate();
      },
      openMiniCalendar: function openMiniCalendar() {
        $('#miniCalendarTrigger').click();
      },
      gotoDate: function gotoDate($date) {
        var date = moment['default']($date).add(4, 'hour').toDate();
        if (this.get('controller').get('cal')) {
          this.get('controller').get('cal').gotoDate(date);
          this.updateDate();
        }
      },
      editEvent: function editEvent(id, type) {
        this.editEvent(id, type);
      },
      ical: function ical() {
        var self = this;

        var users = new Array();
        var resources = new Array();
        var types = new Array();
        var cats = new Array();
        var locations = new Array();
        var stations = new Array();

        $('input.checkboxUser:checked').each(function () {
          users.push($(this).val());
        });

        $('input.checkResource:checked').each(function () {
          resources.push($(this).val());
        });

        $('input.checkboxType:checked').each(function () {
          types.push($(this).val());
        });
        $('input.checkboxCat:checked').each(function () {
          cats.push($(this).val());
        });
        $('input.checkboxLocation:checked').each(function () {
          locations.push($(this).val());
        });
        $('input.checkboxStation:checked').each(function () {
          stations.push($(this).val());
        });

        var data = {
          users: users.join(','),
          types: types.join(','),
          resources: resources.join(','),
          cats: cats.join(','),
          locations: locations.join(','),
          stations: stations.join(',')
        };

        $.ajax({
          url: "/api/appointments/ical",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('icalLinkPanel').send('load', data.responseText);
        }, function (data) {
          self.get('controller').get('icalLinkPanel').send('load', data.responseText);
        });

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-ical-link-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      confirmAppRequest: function confirmAppRequest(msg, refData, dateDetails) {
        var self = this;
        var controller = this.get('controller');

        if (dateDetails) {
          refData.dateOverride = dateDetails.date, refData.timeFromOverride = dateDetails.dateFrom, refData.timeToOverride = dateDetails.dateTo;
        }

        $.ajax({
          url: "/api/patientAppointments/" + msg.id + "/appConfirm",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(refData),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.refreshCal();
          jason.notifiction.notifiy('eltiga App Termin', 'bestätigt');
        }, function () {
          self.refreshCal();
          jason.notifiction.notifiy('eltiga App Termin', 'bestätigt');
        });
      },
      abortAppRequest: function abortAppRequest(msg, refData) {
        var self = this;
        var controller = this.get('controller');
        $.ajax({
          url: "/api/patientAppointments/" + msg.id + "/appAbort",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(refData),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.refreshCal();
          jason.notifiction.notifiy('eltiga App Termin', 'abgesagt');
        }, function () {
          self.refreshCal();
          jason.notifiction.notifiy('eltiga App Termin', 'abgesagt');
        });
      },
      gotoTreatment: function gotoTreatment(id) {
        this.transitionTo('practicemgmt.treatment-details', id);
      },
      selectCustomerConfirm: function selectCustomerConfirm(customerId, data, appointment, time, directTreatment, app) {
        var self = this,
            treatment = {
          customer: {
            id: customerId
          }
        };

        treatment.selectedPatients = data;
        treatment.appointment = appointment;
        treatment.time = time;
        if (app) {
          treatment.appointmentId = app.id;
        }

        $.ajax({
          url: "/api/treatments",
          method: "PUT",
          data: JSON.stringify(treatment),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {
          if (API['default'].isStayInCalendar()) {
            jason.notifiction.notifiy('Patient', 'erfolgreich aufgenommen');
            self.refreshCal();
          } else {
            if (directTreatment) {
              self.transitionTo('practicemgmt.treatment-details', id, { queryParams: { entry: "true" } });
            } else {
              self.transitionTo('practicemgmt.admission');
            }
          }
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
      },
      reloadPatients: function reloadPatients(customerId) {
        var self = this;
        $.ajax({
          url: "/api/patients/forcustomer/" + customerId + "?filterDead=true",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('addAppointmentPanel').send('loadPatients', data.patient);
        });
      },
      triggerRefreshUser: function triggerRefreshUser(referer) {
        this.adjustRefresh('checkboxUser', 'user', referer);
        this.refreshCal();
      },
      triggerRefreshCat: function triggerRefreshCat(referer) {
        this.adjustRefresh('checkboxCat', 'cat', referer);
        this.refreshCal();
      },
      triggerRefreshStation: function triggerRefreshStation(referer) {
        this.adjustRefresh('checkboxStation', 'station', referer);
        this.refreshCal();
      },
      triggerRefreshLocation: function triggerRefreshLocation(referer) {
        this.adjustRefresh('checkboxLocation', 'room', referer);
        this.refreshCal();
      },
      triggerRefresh: function triggerRefresh() {
        this.refreshCal();
      },
      toggleMe: function toggleMe() {
        var controller = this.get('controller');
        if (controller.get('onlyMine') === true) {
          controller.set('onlyMine', false);

          $('input.checkboxUser').parent('div.upper').removeClass('hidden');
          $('input.checkboxUser').prop('checked', false);
          $('input#user-9999').prop('checked', true);
        } else {
          controller.set('onlyMine', true);
          $('input.checkboxUser').prop('checked', false);
          $('input.checkboxUser').parent('div.upper').addClass('hidden');
          $('input#user-' + API['default'].getUserId()).prop('checked', true);
          $('input#user-' + API['default'].getUserId()).parent('div.upper').removeClass('hidden');
        }
        this.refreshCal();
      },
      toggleUsers: function toggleUsers() {
        var controller = this.get('controller');
        if (controller.get('usersExpanded') === true) {
          controller.set('usersExpanded', false);
        } else {
          controller.set('usersExpanded', true);
        }
      },
      toggleResources: function toggleResources() {
        var controller = this.get('controller');
        if (controller.get('resourcesExpanded') === true) {
          controller.set('resourcesExpanded', false);
        } else {
          controller.set('resourcesExpanded', true);
        }
      },
      toggleStations: function toggleStations() {
        var controller = this.get('controller');
        if (controller.get('stationsExpanded') === true) {
          controller.set('stationsExpanded', false);
        } else {
          controller.set('stationsExpanded', true);
        }
      },
      toggleCats: function toggleCats() {
        var controller = this.get('controller');
        if (controller.get('catsExpanded') === true) {
          controller.set('catsExpanded', false);
        } else {
          controller.set('catsExpanded', true);
        }
      },
      toggleRooms: function toggleRooms() {
        var controller = this.get('controller');
        if (controller.get('roomsExpanded') === true) {
          controller.set('roomsExpanded', false);
        } else {
          controller.set('roomsExpanded', true);
        }
      },
      deleteAppointment: function deleteAppointment(app, editSeries, msg) {
        var inform = arguments.length <= 3 || arguments[3] === undefined ? true : arguments[3];

        this.deleteAppointment(app, editSeries, msg, inform);
      },
      deleteAppointmentCheckEtiga: function deleteAppointmentCheckEtiga(app, editSeries) {
        var self = this;
        if (app.appStatus === 'booked') {
          setTimeout(function () {

            self.get('controller').get('appDenialPanel').send('load', app);

            $.magnificPopup.open({
              removalDelay: 500,
              closeOnBgClick: false,
              items: {
                src: '#modal-app-denial-panel'
              },
              callbacks: {
                beforeOpen: function beforeOpen() {
                  var Animation = "mfp-with-fade";
                  this.st.mainClass = Animation;
                }
              },
              midClick: true
            });
          }, 1000);
        } else {
          this.deleteAppointment(app, editSeries, null);
        }
      },
      editTask: function editTask(data) {
        var self = this;
        $.ajax({
          url: "/api/tasks",
          method: "PUT",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Aufgabe', 'erfolgreich gespeichert');
          self.refreshCal();
        });
      },
      createAppointment: function createAppointment(data) {
        var self = this;
        $.ajax({
          url: "/api/patientAppointments",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Eingaben', 'erfolgreich gespeichert');
          self.refreshCal();
        }, function () {});
      },
      gotoPatient: function gotoPatient(id) {
        this.transitionTo('patients.edit', id, {
          queryParams: {
            selectTab: is_journal_enabled.isJournalEnabled() ? 'activities' : 'correspondance'
          }
        });
      },
      gotoPatientEtiga: function gotoPatientEtiga(id) {
        this.transitionTo('patients.etiga', id);
      },
      linkTo: function linkTo(to, id) {
        this.transitionTo(to, id);
      },
      finishTaskSend: function finishTaskSend(taskData) {
        var self = this,
            controller = this.get('controller');

        var action = taskData.finishTaskAction;

        $.ajax({
          url: "/api/tasks/finish",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(taskData),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Aufgabe', 'erledigt');
          if (action === 'notbillable') {
            setTimeout(function () {
              controller.send('editTreatment', data, taskData.patient, taskData.task);
            }, 500);
          }
          if (action === 'billable') {
            self.transitionTo('practicemgmt.treatment-details', data, { queryParams: { entry: "true" } });
          }
        }, function (data) {});
      },
      startTask: function startTask(taskData) {
        var task = {
          id: taskData.id
        };

        $.ajax({
          url: "/api/tasks/start",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(task),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Aufgabe', 'gestartet');
        });
      },
      addReply: function addReply(data) {
        var controller = this.get('controller');
        var self = this;
        $.ajax({
          url: "/api/messageReplies",
          method: "PUT",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Antwort', 'verschickt');
        }, function () {
          jason.notifiction.notifiy('Antwort', 'verschickt');
          $.ajax({
            url: "/api/tasks/" + data.taskId + "/history",
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            controller.get('showTaskPanel').send('loadHistory', data.taskHistory);
          });
        });
      },
      createNotification: function createNotification(data) {
        var self = this,
            controller = this.get('controller');
        $.ajax({
          url: "/api/msgs",
          method: "PUT",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Nachricht', 'verschickt');
        });
      }
    },
    updateTooltips: function updateTooltips() {
      $('.tooltipstered').tooltipster({
        theme: 'tooltipster-punk',
        delay: 0,
        side: 'top',
        touchDevices: false
      });
    }
  });

});