define('jason-frontend/templates/components/param-bar', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 28
            },
            "end": {
              "line": 3,
              "column": 123
            }
          },
          "moduleName": "jason-frontend/templates/components/param-bar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","barRed");
          dom.setAttribute(el1,"style","width: 100%");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 28
            },
            "end": {
              "line": 4,
              "column": 125
            }
          },
          "moduleName": "jason-frontend/templates/components/param-bar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","barGreen");
          dom.setAttribute(el1,"style","width: 100%");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 64
            },
            "end": {
              "line": 5,
              "column": 159
            }
          },
          "moduleName": "jason-frontend/templates/components/param-bar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","barRed");
          dom.setAttribute(el1,"style","width: 100%");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 4
            },
            "end": {
              "line": 9,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/param-bar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","thresholdBarBorder");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'style');
          return morphs;
        },
        statements: [
          ["attribute","style",["concat",["left: ",["subexpr","css-bool-evaluator",[["subexpr","gt",[["get","param.max",["loc",[null,[8,76],[8,85]]]],0],[],["loc",[null,[8,72],[8,88]]]],["get","param.positionIn",["loc",[null,[8,89],[8,105]]]],"35"],[],["loc",[null,[8,51],[8,112]]]],"px;top:1px;"]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 30
            },
            "end": {
              "line": 15,
              "column": 80
            }
          },
          "moduleName": "jason-frontend/templates/components/param-bar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("span");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
          return morphs;
        },
        statements: [
          ["content","param.min",["loc",[null,[15,60],[15,73]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 80
            },
            "end": {
              "line": 15,
              "column": 146
            }
          },
          "moduleName": "jason-frontend/templates/components/param-bar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"style","margin-left: 12px");
          var el2 = dom.createTextNode("Keine Referenzwerte");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 36
            },
            "end": {
              "line": 16,
              "column": 73
            }
          },
          "moduleName": "jason-frontend/templates/components/param-bar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["content","param.max",["loc",[null,[16,60],[16,73]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 19,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/param-bar.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","thresholdBar");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","thresholdBar");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","thresholdBar");
        dom.setAttribute(el3,"style","position: absolute; right:0");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","thresholdBorderBar");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","thresholdLabelBar");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","firstLabel");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","thresholdLabel");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","thresholdLabel");
        var el4 = dom.createElement("span");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [3]);
        var morphs = new Array(8);
        morphs[0] = dom.createAttrMorph(element2, 'class');
        morphs[1] = dom.createAttrMorph(element2, 'data-reduction-sum');
        morphs[2] = dom.createMorphAt(dom.childAt(element2, [1]),0,0);
        morphs[3] = dom.createMorphAt(dom.childAt(element2, [3]),0,0);
        morphs[4] = dom.createMorphAt(dom.childAt(element2, [5]),0,0);
        morphs[5] = dom.createMorphAt(dom.childAt(element2, [7]),1,1);
        morphs[6] = dom.createMorphAt(dom.childAt(element3, [3]),0,0);
        morphs[7] = dom.createMorphAt(dom.childAt(element3, [5, 0]),0,0);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["cashbackBar ",["get","cssClass",["loc",[null,[2,26],[2,34]]]]]]],
        ["attribute","data-reduction-sum",["concat",[["get","reductionSum",["loc",[null,[2,60],[2,72]]]]]]],
        ["block","if",[["subexpr","and",[["subexpr","gt",[["get","param.value",["loc",[null,[3,43],[3,54]]]],0],[],["loc",[null,[3,39],[3,57]]]],["subexpr","gt",[["get","param.max",["loc",[null,[3,62],[3,71]]]],0],[],["loc",[null,[3,58],[3,74]]]]],[],["loc",[null,[3,34],[3,75]]]]],[],0,null,["loc",[null,[3,28],[3,130]]]],
        ["block","if",[["subexpr","and",[["subexpr","gt",[["get","param.value",["loc",[null,[4,43],[4,54]]]],0],[],["loc",[null,[4,39],[4,57]]]],["subexpr","gt",[["get","param.max",["loc",[null,[4,62],[4,71]]]],0],[],["loc",[null,[4,58],[4,74]]]]],[],["loc",[null,[4,34],[4,75]]]]],[],1,null,["loc",[null,[4,28],[4,132]]]],
        ["block","if",[["subexpr","and",[["subexpr","gt",[["get","param.value",["loc",[null,[5,79],[5,90]]]],0],[],["loc",[null,[5,75],[5,93]]]],["subexpr","gt",[["get","param.max",["loc",[null,[5,98],[5,107]]]],0],[],["loc",[null,[5,94],[5,110]]]]],[],["loc",[null,[5,70],[5,111]]]]],[],2,null,["loc",[null,[5,64],[5,166]]]],
        ["block","if",[["subexpr","gt",[["get","param.value",["loc",[null,[7,14],[7,25]]]],0],[],["loc",[null,[7,10],[7,28]]]]],[],3,null,["loc",[null,[7,4],[9,11]]]],
        ["block","if",[["subexpr","gt",[["get","param.max",["loc",[null,[15,40],[15,49]]]],0],[],["loc",[null,[15,36],[15,52]]]]],[],4,5,["loc",[null,[15,30],[15,153]]]],
        ["block","if",[["subexpr","gt",[["get","param.max",["loc",[null,[16,46],[16,55]]]],0],[],["loc",[null,[16,42],[16,58]]]]],[],6,null,["loc",[null,[16,36],[16,80]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6]
    };
  }()));

});