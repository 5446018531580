define('jason-frontend/models/invoice', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    code: DS['default'].attr('string'),
    paymentStatus: DS['default'].attr('string'),
    orderStatus: DS['default'].attr('string'),
    created: DS['default'].attr('date'),
    entries: DS['default'].hasMany('orderEntry'),
    hasEntries: DS['default'].attr('boolean'),
    invoicePatients: DS['default'].hasMany('invoicePatient'),
    payments: DS['default'].hasMany('paymentAction'),
    patients: DS['default'].hasMany('patient'),
    taxEntries: DS['default'].hasMany('taxEntry'),
    customer: DS['default'].belongsTo('customer'),
    user: DS['default'].belongsTo('user'),
    total: DS['default'].attr('number'),
    warning: DS['default'].attr('boolean'),
    notice: DS['default'].attr('string'),
    cancellationNotice: DS['default'].attr('string'),
    treatmentNotice: DS['default'].attr(''),
    payedTotal: DS['default'].attr('number'),
    totalWithoutTax: DS['default'].attr('number'),
    absoluteDiscount: DS['default'].attr('boolean'),
    discount: DS['default'].attr('number'),
    discountValue: DS['default'].attr('number'),
    deposit: DS['default'].attr('boolean'),
    cancelAble: DS['default'].attr('boolean'),
    doctor: DS['default'].belongsTo('user'),
    statusChangeUser: DS['default'].belongsTo('user'),
    statusChanged: DS['default'].attr('date'),
    doctorId: DS['default'].attr('number'),
    assistant: DS['default'].belongsTo('user'),
    assistantId: DS['default'].attr('number'),
    accountingInfo: DS['default'].attr('string'),
    paymentAgreement: DS['default'].attr('string'),
    lastStatusChanged: DS['default'].attr('date'),
    nextStatusChange: DS['default'].attr('date'),
    nextStatusChangeInput: DS['default'].attr('string'),
    nextStatusInput: DS['default'].attr('string'),
    referenceInvoice: DS['default'].belongsTo('invoice'),
    references: DS['default'].hasMany('invoice-reference'),
    warningStopped: DS['default'].attr('boolean'),
    warningInfo: DS['default'].attr('string'),
    warningAddText: DS['default'].attr('string'),
    warningEnabled: DS['default'].attr('boolean'),
    fullyBooked: DS['default'].attr('boolean'),
    persistedInvoice: DS['default'].attr('')
  });

});