define('jason-frontend/routes/cash/in', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    perPage: 10,
    queryParams: {
      filterCode: {
        refreshModel: true
      }
    },
    model: function model(params) {
      params.filterPaymentStatus = 'partpaid,notpaid';
      params.filterStatus = 'to_be_verified';
      params.legacy = 'true';
      return this.findPaged('invoice', params);
    },
    setupController: function setupController(controller, model, params) {
      this._super(controller, model);
      var self = this;

      if (!API['default'].hasPermission('c_payments')) {
        this.transitionTo('home');
      }

      controller.set('bankingAccountList', this.store.find('bankingAccount'));
      controller.set('styleStepOne', '');
      controller.set('styleStepTwo', 'hidden');
      controller.set('styleStepThree', 'hidden');
      controller.set('styleStepConfirmation', 'hidden');
      controller.set('selectedInvoiceId', null);
      controller.set('submitButtonEnabled', true);
      controller.set('showBankTransferPanel', false);
      controller.set('selectedInvoice', null);
      controller.set('transaction', null);
      controller.set('notice', null);
      controller.set('amount', null);
      controller.set('maxPaymentAmount', null);
      controller.set('documentDate', null);
      controller.set('referenceNumber', null);
      controller.set('referenceText', null);
      controller.set('recipient', null);
      controller.set('referer', null);
      controller.set('purpose', null);
      controller.set('recipientText', null);
      controller.set('recipientNumber', null);
      controller.set('selectedPaymentType', 'cash');
      controller.set('balanceSum', null);
      controller.set('depositSum', null);

      $.ajax({
        url: "/api/cashRegisters?hideInactive=true",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('cashRegisterList', data.cashRegister);
        controller.set('selectedCashRegister', data.cashRegister.get('firstObject'));
        if (API['default'].getPrimaryCashRegisterId()) {
          data.cashRegister.forEach(function (item) {
            if (item.id == API['default'].getPrimaryCashRegisterId()) {
              controller.set('selectedCashRegister', item);
            }
          });
        }
      });

      var invoiceId = params.params['cash.in'].id;
      if (invoiceId > 0) {
        controller.set('styleStepOne', 'hidden');
        controller.set('selectedInvoiceId', invoiceId);

        this.store.fetch('invoice', invoiceId).then(function (data) {
          controller.set("invoice", data);
          if (data.get('customer') && data.get('customer').get('id') > 0) {
            controller.set('customerDeposit', self.store.fetch('customer', data.get('customer').get('id')));

            $.ajax({
              url: "/api/customerDeposits/" + data.get('customer').get('id'),
              method: "GET",
              contentType: "application/json",
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function (data) {
              controller.set('balanceSum', data.meta.sum);
              controller.set('depositSum', data.meta.depositSum);
            });
          }
        });

        controller.set('invoice', this.store.find('invoice', invoiceId));
        controller.set('styleStepThree', '');
      } else {
        controller.set('invoicesList', this.store.find('invoice', {
          filterPaymentStatus: "partpaid,notpaid",
          filterStatus: "to_be_verified"
        }));
      }

      $.ajax({
        url: "/api/cashdesk/cashdeskSettings",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('terminalActivated', data.cashDeskSettings.terminalActivated);
        controller.set('externalCashRegister', data.cashDeskSettings.externalCashRegister);
      }, function (data) {});
    },
    actions: {
      download: function download(url) {
        window.open(url);
      },
      print: function print(transactionGroup) {
        $.ajax({
          url: "/api/cashdesk/print/" + transactionGroup.get('id'),
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Belegdruck durchgeführt', '');
        }, function () {
          jason.notifiction.systemError('Fehlercode 101', 'Belegdruck konnte nicht durchgeführt werden');
        });
      },
      showSlip: function showSlip() {
        this.get('controller').get('pdfPreview').send('show');
      },
      selectInvoice: function selectInvoice(invoice) {
        var controller = this.get('controller'),
            self = this;

        controller.set('styleStepOne', 'hidden');
        controller.set('selectedInvoiceId', invoice.id);
        controller.set('balanceSum', null);
        controller.set('depositSum', null);

        this.store.fetch('invoice', invoice.id).then(function (data) {
          controller.set("invoice", data);
          if (data.get('customer') && data.get('customer').get('id') > 0) {
            controller.set('customerDeposit', self.store.fetch('customer', data.get('customer').get('id')));

            $.ajax({
              url: "/api/customerDeposits/" + data.get('customer').get('id'),
              method: "GET",
              contentType: "application/json",
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function (data) {
              controller.set('balanceSum', data.meta.sum);
              controller.set('depositSum', data.meta.depositSum);
            });
          }
        });

        controller.set('styleStepThree', '');
      },
      selectAmount: function selectAmount() {
        var controller = this.get('controller');
        controller.set('styleStepOne', 'hidden');
        controller.set('styleStepTwo', 'hidden');
        controller.set('styleStepThree', '');
        controller.set('styleStepFour', 'hidden');
        controller.set('styleStepFive', 'hidden');
      },
      toSelectType: function toSelectType() {
        this.transitionTo('invoices.open');
      },
      toSelectConfirmationWithExternalCashRegister: function toSelectConfirmationWithExternalCashRegister(given) {
        var controller = this.get('controller');
        var invoiceId = controller.get('invoice').id;

        controller.set('submitButtonEnabled', false);

        var payload = {
          invoiceId: invoiceId,
          payment: given,
          type: 'in'
        };

        controller.set('styleStepPayment', 'hidden');
        controller.set('showLoading', true);
        controller.set('styleStepConfirmation', '');

        var self = this;

        $.ajax({
          url: "/api/cashdesk/extern",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(payload),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('invoices.show', invoiceId);
          controller.set('showLoading', false);
          return data;
        }, function (error) {
          controller.set('showLoading', false);
          jason.notifiction.error('Unbekannter Fehler', '');
        });
      },
      toSelectConfirmation: function toSelectConfirmation(paymentItems) {
        var controller = this.get('controller'),
            selectedCashRegister = controller.get('selectedCashRegister'),
            selectedType = 'in';

        if (!paymentItems || paymentItems && paymentItems.length == 0 || paymentItems && paymentItems[0].given < 0.01) {
          jason.notifiction.error('Betrag fehlt', 'Bitte geben Sie einen Mindestbetrag von € 0,01 ein');
          return false;
        }

        controller.set('submitButtonEnabled', false);
        controller.set('styleStepThree', 'hidden');
        controller.set('styleStepConfirmation', '');
        controller.set('showLoading', true);

        if (selectedCashRegister) {
          selectedCashRegister = selectedCashRegister.id;
        }

        var payload = {
          invoiceId: controller.get('selectedInvoiceId'),
          selectedCashRegister: selectedCashRegister,
          type: selectedType,
          payments: paymentItems
        };

        var self = this;

        $.ajax({
          url: "/api/cashdesk/in",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(payload),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {

          controller.set('currentTransactionId', data);

          self.store.fetch('transaction-group', controller.get('currentTransactionId')).then(function (data2) {
            controller.set('transactionGroup', data2);
            controller.set('transactionDownloadUrl', '/api/salesSlip/' + data2.get('token'));
          });

          jason.notifiction.notifiy('Buchung erfolgreich', '');
          controller.set('submitButtonEnabled', true);

          controller.set('showLoading', false);
          return data;
        }, function () {
          jason.notifiction.error('Buchungsvorgang', 'fehlgeschlagen');
          controller.set('submitButtonEnabled', true);
          controller.set('transactionGroup', null);
          controller.set('showLoading', false);
        });
      },
      reset: function reset() {}
    }
  });

});