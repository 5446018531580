define('jason-frontend/templates/appointments/calendar', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 0
              },
              "end": {
                "line": 11,
                "column": 0
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  background-color: ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(";\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","user.color",["loc",[null,[10,20],[10,34]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 0
              },
              "end": {
                "line": 13,
                "column": 0
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  background-color: #b5b5c3;\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 2
              },
              "end": {
                "line": 36,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    background-color: ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(";\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","user.color",["loc",[null,[35,22],[35,36]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 36,
                "column": 2
              },
              "end": {
                "line": 38,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    background-color: #b5b5c3;\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 48,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("th.fc-resource-cell[data-resource-id=\"");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\"]::after {\n  content: '");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("';\n  transition: all 0.3s ease;\n  display: inline-block;\n  border-radius: 50%;\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("  color: white;\n  width: 25px;\n  height: 25px;\n  font-size: 12px;\n  line-height: 25px;\n  text-align: center;\n  font-weight: bold;\n  position: absolute;\n  left:50%;\n  top:50%;\n  transform: translate(-50%, -50%);\n}\n.showUserBadges .fc-event.user-");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("::after {\n  content: '");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("';\n  bottom: -4px;\n  right: -4px;\n  position: absolute;\n  margin-left: -10px;\n  display: inline-block;\n  border-radius: 50%;\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("  color: white;\n  width: 22px;\n  height: 22px;\n  font-size: 10px;\n  text-align: center;\n  font-weight: bold;\n  line-height: 22px;\n  z-index: 99;\n}\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
          morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
          morphs[4] = dom.createMorphAt(fragment,9,9,contextualElement);
          morphs[5] = dom.createMorphAt(fragment,11,11,contextualElement);
          return morphs;
        },
        statements: [
          ["content","user.id",["loc",[null,[4,38],[4,49]]]],
          ["content","user.capitals",["loc",[null,[5,12],[5,29]]]],
          ["block","if",[["get","user.colorHex",["loc",[null,[9,6],[9,19]]]]],[],0,1,["loc",[null,[9,0],[13,7]]]],
          ["content","user.id",["loc",[null,[26,31],[26,42]]]],
          ["content","user.capitals",["loc",[null,[27,12],[27,29]]]],
          ["block","if",[["get","user.colorHex",["loc",[null,[34,8],[34,21]]]]],[],2,3,["loc",[null,[34,2],[38,9]]]]
        ],
        locals: ["user"],
        templates: [child0, child1, child2, child3]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 107,
                "column": 16
              },
              "end": {
                "line": 115,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","symbol-label");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element24 = dom.childAt(fragment, [1]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element24, 'title');
            morphs[1] = dom.createAttrMorph(element24, 'class');
            morphs[2] = dom.createAttrMorph(element24, 'style');
            morphs[3] = dom.createElementMorph(element24);
            morphs[4] = dom.createMorphAt(dom.childAt(element24, [1]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[108,32],[108,46]]]]]]],
            ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[109,65],[109,78]]]],"selected",""],[],["loc",[null,[109,44],[110,69]]]]," symbol symbol-circle symbol-40px"]]],
            ["attribute","style",["concat",["background-color: ",["get","user.color",["loc",[null,[111,50],[111,60]]]]," !important;"]]],
            ["element","action",["toggleResource",["get","user",["loc",[null,[108,76],[108,80]]]]],[],["loc",[null,[108,50],[108,82]]]],
            ["content","user.capitals",["loc",[null,[112,46],[112,63]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 115,
                "column": 16
              },
              "end": {
                "line": 122,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","symbol-label");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element23 = dom.childAt(fragment, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element23, 'title');
            morphs[1] = dom.createAttrMorph(element23, 'class');
            morphs[2] = dom.createElementMorph(element23);
            morphs[3] = dom.createMorphAt(dom.childAt(element23, [1]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[116,32],[116,46]]]]]]],
            ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[117,65],[117,78]]]],"selected",""],[],["loc",[null,[117,44],[118,69]]]]," symbol symbol-circle symbol-40px ",["get","user.color",["loc",[null,[118,105],[118,115]]]]]]],
            ["element","action",["toggleResource",["get","user",["loc",[null,[116,76],[116,80]]]]],[],["loc",[null,[116,50],[116,82]]]],
            ["content","user.capitals",["loc",[null,[119,46],[119,63]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 105,
              "column": 12
            },
            "end": {
              "line": 124,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","user.colorHex",["loc",[null,[107,22],[107,35]]]]],[],0,1,["loc",[null,[107,16],[122,23]]]]
        ],
        locals: ["user"],
        templates: [child0, child1]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 151,
              "column": 8
            },
            "end": {
              "line": 155,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","app");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-app");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"checked","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 155,
              "column": 8
            },
            "end": {
              "line": 159,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","app");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-app");
          dom.setAttribute(el1,"type","checkbox");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 163,
              "column": 8
            },
            "end": {
              "line": 167,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","user");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-appUser");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"checked","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 167,
              "column": 8
            },
            "end": {
              "line": 171,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","user");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-appUser");
          dom.setAttribute(el1,"type","checkbox");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 176,
              "column": 10
            },
            "end": {
              "line": 180,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","etiga");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-appEtiga");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"checked","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 180,
              "column": 10
            },
            "end": {
              "line": 184,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","etiga");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-appEtiga");
          dom.setAttribute(el1,"type","checkbox");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 189,
              "column": 8
            },
            "end": {
              "line": 193,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","task");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-task");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"checked","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child9 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 193,
              "column": 8
            },
            "end": {
              "line": 197,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","task");
          dom.setAttribute(el1,"class","checkboxType");
          dom.setAttribute(el1,"id","type-task");
          dom.setAttribute(el1,"type","checkbox");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child10 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 209,
                "column": 10
              },
              "end": {
                "line": 215,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("style");
            var el2 = dom.createTextNode("\n              label[for=cat-");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("]::before {\n                background-color: ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(";\n             }\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element20 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element20,1,1);
            morphs[1] = dom.createMorphAt(element20,3,3);
            return morphs;
          },
          statements: [
            ["content","cat.id",["loc",[null,[211,28],[211,38]]]],
            ["content","cat.color",["loc",[null,[212,34],[212,47]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 217,
                "column": 12
              },
              "end": {
                "line": 221,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxCat");
            dom.setAttribute(el1,"type","checkbox");
            dom.setAttribute(el1,"checked","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element19 = dom.childAt(fragment, [1]);
            if (this.cachedFragment) { dom.repairClonedNode(element19,[],true); }
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element19, 'value');
            morphs[1] = dom.createAttrMorph(element19, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","cat.id",["loc",[null,[218,30],[218,36]]]]]]],
            ["attribute","id",["concat",["cat-",["get","cat.id",["loc",[null,[219,31],[219,37]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 221,
                "column": 12
              },
              "end": {
                "line": 225,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxCat");
            dom.setAttribute(el1,"type","checkbox");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element18 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element18, 'value');
            morphs[1] = dom.createAttrMorph(element18, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","cat.id",["loc",[null,[222,30],[222,36]]]]]]],
            ["attribute","id",["concat",["cat-",["get","cat.id",["loc",[null,[223,31],[223,37]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 226,
                "column": 41
              },
              "end": {
                "line": 226,
                "column": 173
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" App Termin");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [
            ["inline","button-etiga-small",[],["content","","classNames","pt2 inline-icon","size","16","width",28],["loc",[null,[226,81],[226,162]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 226,
                "column": 173
              },
              "end": {
                "line": 226,
                "column": 193
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","cat.name",["loc",[null,[226,181],[226,193]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 208,
              "column": 8
            },
            "end": {
              "line": 228,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element21 = dom.childAt(fragment, [2]);
          var element22 = dom.childAt(element21, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createAttrMorph(element21, 'class');
          morphs[2] = dom.createElementMorph(element21);
          morphs[3] = dom.createMorphAt(element21,1,1);
          morphs[4] = dom.createAttrMorph(element22, 'for');
          morphs[5] = dom.createMorphAt(element22,1,1);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["block","if",[["get","cat.colorHex",["loc",[null,[209,16],[209,28]]]]],[],0,null,["loc",[null,[209,10],[215,17]]]],
          ["attribute","class",["concat",["upper checkbox-custom checkbox-primary mb5 color-pick ",["get","cat.color",["loc",[null,[216,78],[216,87]]]]]]],
          ["element","action",["triggerRefresh"],["on","change","preventDefault",false],["loc",[null,[216,91],[216,151]]]],
          ["block","if",[["subexpr","is-cat-selected2",[["get","profile.cats",["loc",[null,[217,36],[217,48]]]],["get","cat.fullId",["loc",[null,[217,49],[217,59]]]]],[],["loc",[null,[217,18],[217,60]]]]],[],1,2,["loc",[null,[217,12],[225,19]]]],
          ["attribute","for",["concat",["cat-",["get","cat.id",["loc",[null,[226,30],[226,36]]]]]]],
          ["block","if",[["subexpr","eq",[["get","cat.name",["loc",[null,[226,51],[226,59]]]],"etiga App Termin"],[],["loc",[null,[226,47],[226,79]]]]],[],3,4,["loc",[null,[226,41],[226,200]]]]
        ],
        locals: ["cat"],
        templates: [child0, child1, child2, child3, child4]
      };
    }());
    var child11 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 239,
              "column": 10
            },
            "end": {
              "line": 243,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","9999");
          dom.setAttribute(el1,"class","checkboxUser");
          dom.setAttribute(el1,"id","user-9999");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"checked","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child12 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 243,
              "column": 10
            },
            "end": {
              "line": 247,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","9999");
          dom.setAttribute(el1,"class","checkboxUser");
          dom.setAttribute(el1,"id","user-9999");
          dom.setAttribute(el1,"type","checkbox");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child13 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 251,
                "column": 10
              },
              "end": {
                "line": 257,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("style");
            var el2 = dom.createTextNode("\n                label[for=user-");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("]::before {\n                background-color: ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(";\n              }\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element15 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element15,1,1);
            morphs[1] = dom.createMorphAt(element15,3,3);
            return morphs;
          },
          statements: [
            ["content","user.id",["loc",[null,[253,31],[253,42]]]],
            ["content","user.color",["loc",[null,[254,34],[254,48]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 259,
                "column": 12
              },
              "end": {
                "line": 263,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxUser");
            dom.setAttribute(el1,"type","checkbox");
            dom.setAttribute(el1,"checked","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element14 = dom.childAt(fragment, [1]);
            if (this.cachedFragment) { dom.repairClonedNode(element14,[],true); }
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element14, 'value');
            morphs[1] = dom.createAttrMorph(element14, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","user.id",["loc",[null,[260,30],[260,37]]]]]]],
            ["attribute","id",["concat",["user-",["get","user.id",["loc",[null,[261,32],[261,39]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 263,
                "column": 12
              },
              "end": {
                "line": 267,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxUser");
            dom.setAttribute(el1,"type","checkbox");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element13 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element13, 'value');
            morphs[1] = dom.createAttrMorph(element13, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","user.id",["loc",[null,[264,30],[264,37]]]]]]],
            ["attribute","id",["concat",["user-",["get","user.id",["loc",[null,[265,32],[265,39]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 250,
              "column": 8
            },
            "end": {
              "line": 270,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element16 = dom.childAt(fragment, [2]);
          var element17 = dom.childAt(element16, [3]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createAttrMorph(element16, 'class');
          morphs[2] = dom.createElementMorph(element16);
          morphs[3] = dom.createMorphAt(element16,1,1);
          morphs[4] = dom.createAttrMorph(element17, 'for');
          morphs[5] = dom.createMorphAt(element17,0,0);
          morphs[6] = dom.createMorphAt(element17,2,2);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["block","if",[["get","user.colorHex",["loc",[null,[251,16],[251,29]]]]],[],0,null,["loc",[null,[251,10],[257,17]]]],
          ["attribute","class",["concat",["upper checkbox-custom checkbox-primary mb5 color-pick ",["get","user.color",["loc",[null,[258,78],[258,88]]]]]]],
          ["element","action",["triggerRefreshUser",""],["on","change","preventDefault",false],["loc",[null,[258,92],[258,159]]]],
          ["block","if",[["subexpr","is-cat-selected2",[["get","profile.users",["loc",[null,[259,36],[259,49]]]],["get","user.fullId",["loc",[null,[259,50],[259,61]]]]],[],["loc",[null,[259,18],[259,62]]]]],[],1,2,["loc",[null,[259,12],[267,19]]]],
          ["attribute","for",["concat",["user-",["get","user.id",["loc",[null,[268,31],[268,38]]]]]]],
          ["content","user.firstname",["loc",[null,[268,43],[268,61]]]],
          ["content","user.lastname",["loc",[null,[268,62],[268,79]]]]
        ],
        locals: ["user"],
        templates: [child0, child1, child2]
      };
    }());
    var child14 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 286,
                "column": 10
              },
              "end": {
                "line": 293,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","upper checkbox-custom checkbox-primary mb5");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2,"class","checkResource");
            dom.setAttribute(el2,"type","checkbox");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1]);
            var element9 = dom.childAt(element8, [1]);
            var element10 = dom.childAt(element8, [3]);
            var morphs = new Array(5);
            morphs[0] = dom.createElementMorph(element8);
            morphs[1] = dom.createAttrMorph(element9, 'value');
            morphs[2] = dom.createAttrMorph(element9, 'id');
            morphs[3] = dom.createAttrMorph(element10, 'for');
            morphs[4] = dom.createMorphAt(element10,0,0);
            return morphs;
          },
          statements: [
            ["element","action",["triggerRefreshResource",""],["on","change","preventDefault",false],["loc",[null,[287,68],[287,139]]]],
            ["attribute","value",["concat",[["get","resource.id",["loc",[null,[288,30],[288,41]]]]]]],
            ["attribute","id",["concat",["resource-",["get","resource.id",["loc",[null,[289,36],[289,47]]]]]]],
            ["attribute","for",["concat",["resource-",["get","resource.id",["loc",[null,[291,37],[291,48]]]]]]],
            ["content","resource.name",["loc",[null,[291,52],[291,69]]]]
          ],
          locals: ["resource"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 273,
              "column": 6
            },
            "end": {
              "line": 295,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","header");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h3");
          dom.setAttribute(el2,"class","mb20 float-left mt20 ml20");
          var el3 = dom.createTextNode("Resourcen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","upper checkbox-custom checkbox-primary mb5");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("input");
          dom.setAttribute(el3,"value","9999");
          dom.setAttribute(el3,"class","checkResource");
          dom.setAttribute(el3,"id","resource-9999");
          dom.setAttribute(el3,"type","checkbox");
          dom.setAttribute(el3,"checked","");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","resource-9999");
          var el4 = dom.createTextNode("Alle");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element11 = dom.childAt(fragment, [3]);
          var element12 = dom.childAt(element11, [1]);
          if (this.cachedFragment) { dom.repairClonedNode(dom.childAt(element12, [1]),[],true); }
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
          morphs[1] = dom.createAttrMorph(element11, 'class');
          morphs[2] = dom.createElementMorph(element12);
          morphs[3] = dom.createMorphAt(element11,3,3);
          return morphs;
        },
        statements: [
          ["inline","button-toggle-all-decent",[],["size",20,"strokeWidth",1,"classNames"," mt20 float-right topToggle","active",["subexpr","@mut",[["get","resourcesExpanded",["loc",[null,[277,107],[277,124]]]]],[],[]],"toggleAll","toggleResources","content","ein/ausblenden"],["loc",[null,[277,10],[277,179]]]],
          ["attribute","class",["concat",["clear-left users ",["subexpr","css-bool-evaluator",[["get","resourcesExpanded",["loc",[null,[279,58],[279,75]]]],"","hidden"],[],["loc",[null,[279,37],[279,89]]]]]]],
          ["element","action",["triggerRefreshResource","all"],["on","change","preventDefault",false],["loc",[null,[280,66],[280,140]]]],
          ["block","each",[["get","resources",["loc",[null,[286,18],[286,27]]]]],[],0,null,["loc",[null,[286,10],[293,19]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child15 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 302,
              "column": 10
            },
            "end": {
              "line": 306,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","9999");
          dom.setAttribute(el1,"class","checkboxLocation");
          dom.setAttribute(el1,"id","room-9999");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"checked","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child16 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 306,
              "column": 10
            },
            "end": {
              "line": 310,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","9999");
          dom.setAttribute(el1,"class","checkboxLocation");
          dom.setAttribute(el1,"id","room-9999");
          dom.setAttribute(el1,"type","checkbox");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child17 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 315,
                "column": 12
              },
              "end": {
                "line": 319,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxLocation");
            dom.setAttribute(el1,"type","checkbox");
            dom.setAttribute(el1,"checked","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            if (this.cachedFragment) { dom.repairClonedNode(element5,[],true); }
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element5, 'value');
            morphs[1] = dom.createAttrMorph(element5, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","room.id",["loc",[null,[316,30],[316,37]]]]]]],
            ["attribute","id",["concat",["room-",["get","room.id",["loc",[null,[317,32],[317,39]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 319,
                "column": 12
              },
              "end": {
                "line": 323,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxLocation");
            dom.setAttribute(el1,"type","checkbox");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element4, 'value');
            morphs[1] = dom.createAttrMorph(element4, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","room.id",["loc",[null,[320,30],[320,37]]]]]]],
            ["attribute","id",["concat",["room-",["get","room.id",["loc",[null,[321,32],[321,39]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 313,
              "column": 8
            },
            "end": {
              "line": 326,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","upper checkbox-custom checkbox-primary mb5");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var element7 = dom.childAt(element6, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createElementMorph(element6);
          morphs[1] = dom.createMorphAt(element6,1,1);
          morphs[2] = dom.createAttrMorph(element7, 'for');
          morphs[3] = dom.createMorphAt(element7,0,0);
          return morphs;
        },
        statements: [
          ["element","action",["triggerRefreshLocation",""],["on","change","preventDefault",false],["loc",[null,[314,66],[314,137]]]],
          ["block","if",[["subexpr","is-cat-selected2",[["get","profile.rooms",["loc",[null,[315,36],[315,49]]]],["get","room.fullId",["loc",[null,[315,50],[315,61]]]]],[],["loc",[null,[315,18],[315,62]]]]],[],0,1,["loc",[null,[315,12],[323,19]]]],
          ["attribute","for",["concat",["room-",["get","room.id",["loc",[null,[324,31],[324,38]]]]]]],
          ["content","room.name",["loc",[null,[324,42],[324,55]]]]
        ],
        locals: ["room"],
        templates: [child0, child1]
      };
    }());
    var child18 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 335,
              "column": 10
            },
            "end": {
              "line": 339,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","9999");
          dom.setAttribute(el1,"class","checkboxStation");
          dom.setAttribute(el1,"id","station-9999");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"checked","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child19 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 339,
              "column": 10
            },
            "end": {
              "line": 343,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"value","9999");
          dom.setAttribute(el1,"class","checkboxStation");
          dom.setAttribute(el1,"id","station-9999");
          dom.setAttribute(el1,"type","checkbox");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child20 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 349,
                "column": 12
              },
              "end": {
                "line": 353,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxStation");
            dom.setAttribute(el1,"type","checkbox");
            dom.setAttribute(el1,"checked","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            if (this.cachedFragment) { dom.repairClonedNode(element1,[],true); }
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element1, 'value');
            morphs[1] = dom.createAttrMorph(element1, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","station.id",["loc",[null,[350,30],[350,40]]]]]]],
            ["attribute","id",["concat",["station-",["get","station.id",["loc",[null,[351,35],[351,45]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 353,
                "column": 12
              },
              "end": {
                "line": 357,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"class","checkboxStation");
            dom.setAttribute(el1,"type","checkbox");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element0, 'value');
            morphs[1] = dom.createAttrMorph(element0, 'id');
            return morphs;
          },
          statements: [
            ["attribute","value",["concat",[["get","station.id",["loc",[null,[354,30],[354,40]]]]]]],
            ["attribute","id",["concat",["station-",["get","station.id",["loc",[null,[355,35],[355,45]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 347,
              "column": 8
            },
            "end": {
              "line": 360,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","upper checkbox-custom checkbox-primary mb5");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createElementMorph(element2);
          morphs[1] = dom.createMorphAt(element2,1,1);
          morphs[2] = dom.createAttrMorph(element3, 'for');
          morphs[3] = dom.createMorphAt(element3,0,0);
          return morphs;
        },
        statements: [
          ["element","action",["triggerRefreshStation",""],["on","change","preventDefault",false],["loc",[null,[348,66],[348,136]]]],
          ["block","if",[["subexpr","is-cat-selected2",[["get","profile.stations",["loc",[null,[349,36],[349,52]]]],["get","station.fullId",["loc",[null,[349,53],[349,67]]]]],[],["loc",[null,[349,18],[349,68]]]]],[],0,1,["loc",[null,[349,12],[357,19]]]],
          ["attribute","for",["concat",["station-",["get","station.id",["loc",[null,[358,34],[358,44]]]]]]],
          ["content","station.name",["loc",[null,[358,48],[358,64]]]]
        ],
        locals: ["station"],
        templates: [child0, child1]
      };
    }());
    var child21 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 365,
              "column": 2
            },
            "end": {
              "line": 368,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("aside");
          dom.setAttribute(el1,"class","side-bar-hidden");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"type","button");
          dom.setAttribute(el2,"class","mfp-close");
          dom.setAttribute(el2,"id","showSidebar");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]),0,0);
          return morphs;
        },
        statements: [
          ["inline","button-toggle-sidebar",[],["size",34,"color","#fff","toggle","toggleSidebar","strokeWidth",1,"classNames","","active",false,"content","Ein/Ausblenden"],["loc",[null,[366,61],[366,196]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 479,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/appointments/calendar.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("style");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"id","calendarWrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","fc fc-ltr fc-unthemed");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"id","calendarStickyArea");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","d-flex align-items-center mb20");
        dom.setAttribute(el4,"id","calendarDateToolbar");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("button");
        dom.setAttribute(el6,"type","button");
        dom.setAttribute(el6,"class","mr10 btn btn-light text-center");
        var el7 = dom.createTextNode("Heute\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("button");
        dom.setAttribute(el6,"style","position: relative");
        dom.setAttribute(el6,"type","button");
        dom.setAttribute(el6,"class","mr10 btn btn-light text-center");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","assets/images/icons/icon-calendar.svg");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","btn-group");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("button");
        dom.setAttribute(el7,"type","button");
        dom.setAttribute(el7,"class","btn btn-icon btn-light text-center");
        var el8 = dom.createElement("img");
        dom.setAttribute(el8,"src","assets/images/icons/icon-prev-dark.svg");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("button");
        dom.setAttribute(el7,"type","button");
        dom.setAttribute(el7,"class","btn btn-icon ml5 btn-light text-center");
        var el8 = dom.createElement("img");
        dom.setAttribute(el8,"src","assets/images/icons/icon-next-dark.svg");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","text-center");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","current-date fs-2 fw-bold");
        var el7 = dom.createTextNode(" ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","ml5 fs-7 text-gray-500 fw-bold");
        var el8 = dom.createTextNode("KW ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n         ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","d-flex justify-content-end");
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","flex-center mr20");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","mr5 text-center");
        dom.setAttribute(el7,"style","font-size: 13px;line-height: 13px");
        var el8 = dom.createTextNode("Farbe");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("Terminart");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","header-toggle");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","colorMode");
        dom.setAttribute(el8,"class","switch neutral");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","ml5 text-center");
        dom.setAttribute(el7,"style","font-size: 13px;line-height: 13px");
        var el8 = dom.createTextNode("Farbe");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("Mitarbeiter:in");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","u-mb0 field select mr10");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("button");
        dom.setAttribute(el6,"type","button");
        dom.setAttribute(el6,"class","btn btn-icon btn-light text-center");
        var el7 = dom.createTextNode("Ansicht\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"id","calendarFilterToolbar");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","headline");
        var el7 = dom.createTextNode("Geteilte Ansicht je Mitarbeiter:in");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","symbol-group symbol-hover flex-shrink-0 me-2");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"id","calendar");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"id","tooltipTest");
        dom.setAttribute(el2,"class","tooltip hidden");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","head");
        var el4 = dom.createTextNode("Details");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","flex-top line");
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/clock.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","time");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","flex-top line category");
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/empty.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","category");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","flex-top line customer");
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/customer-info.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","flex-top line");
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/info.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","info");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","flex-top line appStatus");
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/empty.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","appStatus");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("aside");
        dom.setAttribute(el1,"id","calendarSidebar");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2,"type","button");
        dom.setAttribute(el2,"class","mfp-close");
        var el3 = dom.createTextNode("X");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","box scrollable");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        dom.setAttribute(el4,"class","mb20 mt20");
        var el5 = dom.createTextNode("Typ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","upper checkbox-custom checkbox-primary mb5 type-pick calApp");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","type-app");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","calApp");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Kund:innen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","upper checkbox-custom checkbox-primary mb5 type-pick calAppMa");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","type-appUser");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","calAppMa");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Mitarbeiter:innen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","upper checkbox-custom checkbox-primary mb5 type-pick calAppEtiga");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","type-appEtiga");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","calAppEtiga mr0");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Terminanfragen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","upper checkbox-custom checkbox-primary mb5 type-pick calTask");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","type-task");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","calTask");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Aufgaben");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        dom.setAttribute(el4,"class","mb20 float-left ml20 mt20");
        var el5 = dom.createTextNode("Terminarten");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        dom.setAttribute(el4,"class","mb20 float-left mt20 ml20");
        var el5 = dom.createTextNode("Personen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","upper checkbox-custom checkbox-primary mb5");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"for","user-9999");
        var el6 = dom.createTextNode("Alle");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        dom.setAttribute(el4,"class","mb20 float-left mt20 ml20");
        var el5 = dom.createTextNode("Lokationen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","upper checkbox-custom checkbox-primary mb5");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"for","room-9999");
        var el6 = dom.createTextNode("Alle");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        dom.setAttribute(el4,"class","mb20 float-left mt20 ml20");
        var el5 = dom.createTextNode("Stationen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","upper checkbox-custom checkbox-primary mb5");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"for","station-9999");
        var el6 = dom.createTextNode("Alle");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n    $( window ).resize(function() {\n      $('aside.side-bar ').css('min-height', $(window).height()-100);\n      $('aside.side-bar div.scrollable').css('max-height', $(window).height()-100);\n      $('#calendarWrapper thead.fc-head').css('width', $('#calendar').width());\n      $('#calendarWrapper .fc-widget-content .fc-day-grid').css('width', $('#calendar').width()-3);\n    });\n    $('aside.side-bar').css('min-height', $(window).height() - 150);\n    $('aside.side-bar div.scrollable').css('max-height', $(window).height() - 150);\n    $('#calendarWrapper .fc-widget-content .fc-day-grid').css('width', $('#calendar').width()-3);\n\n    $('#miniCalendarTrigger').daterangepicker({\n      \"singleDatePicker\": true,\n      \"showISOWeekNumbers\": true,\n      \"locale\": {\n        \"separator\": \" - \",\n        \"applyLabel\": \"Anwenden\",\n        \"cancelLabel\": \"Abbrechen\",\n        \"fromLabel\": \"Von\",\n        \"toLabel\": \"Bis\",\n        \"customRangeLabel\": \"Benutzerdefiniert\",\n        \"daysOfWeek\": [\n          \"So\",\n          \"Mo\",\n          \"Di\",\n          \"Mi\",\n          \"Do\",\n          \"Fr\",\n          \"Sa\"\n        ],\n        \"monthNames\": [\n          \"Jänner\",\n          \"Februar\",\n          \"März\",\n          \"April\",\n          \"Mai\",\n          \"Juni\",\n          \"Juli\",\n          \"August\",\n          \"September\",\n          \"Oktober\",\n          \"November\",\n          \"Dezember\"\n        ],\n        \"firstDay\": 1\n      },\n      // \"autoApply\": true,\n    });\n    $('#miniCalendarTrigger').on('apply.daterangepicker', function(ev, picker) {\n     picker.show();\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element25 = dom.childAt(fragment, [2]);
        var element26 = dom.childAt(element25, [1, 1]);
        var element27 = dom.childAt(element26, [1]);
        var element28 = dom.childAt(element27, [1]);
        var element29 = dom.childAt(element28, [1]);
        var element30 = dom.childAt(element28, [3]);
        var element31 = dom.childAt(element28, [7]);
        var element32 = dom.childAt(element31, [1]);
        var element33 = dom.childAt(element31, [3]);
        var element34 = dom.childAt(element27, [3, 1]);
        var element35 = dom.childAt(element27, [5]);
        var element36 = dom.childAt(element35, [1, 3]);
        var element37 = dom.childAt(element35, [5]);
        var element38 = dom.childAt(element26, [3]);
        var element39 = dom.childAt(fragment, [4]);
        var element40 = dom.childAt(element39, [1]);
        var element41 = dom.childAt(element39, [3]);
        var element42 = dom.childAt(element41, [1]);
        var element43 = dom.childAt(element41, [3]);
        var element44 = dom.childAt(element41, [7]);
        var element45 = dom.childAt(element41, [9]);
        var element46 = dom.childAt(element41, [12]);
        var element47 = dom.childAt(element41, [15]);
        var element48 = dom.childAt(element41, [19]);
        var element49 = dom.childAt(element41, [23]);
        var element50 = dom.childAt(element49, [1]);
        var element51 = dom.childAt(element41, [29]);
        var element52 = dom.childAt(element51, [1]);
        var element53 = dom.childAt(element41, [33]);
        var element54 = dom.childAt(element53, [1]);
        var morphs = new Array(58);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
        morphs[1] = dom.createAttrMorph(element25, 'class');
        morphs[2] = dom.createAttrMorph(element26, 'class');
        morphs[3] = dom.createElementMorph(element29);
        morphs[4] = dom.createElementMorph(element30);
        morphs[5] = dom.createMorphAt(element28,5,5);
        morphs[6] = dom.createElementMorph(element32);
        morphs[7] = dom.createElementMorph(element33);
        morphs[8] = dom.createMorphAt(element34,1,1);
        morphs[9] = dom.createMorphAt(dom.childAt(element34, [2]),1,1);
        morphs[10] = dom.createElementMorph(element36);
        morphs[11] = dom.createMorphAt(element36,1,1);
        morphs[12] = dom.createMorphAt(dom.childAt(element35, [3]),1,1);
        morphs[13] = dom.createElementMorph(element37);
        morphs[14] = dom.createAttrMorph(element38, 'class');
        morphs[15] = dom.createMorphAt(dom.childAt(element38, [1, 3]),1,1);
        morphs[16] = dom.createAttrMorph(element39, 'class');
        morphs[17] = dom.createElementMorph(element40);
        morphs[18] = dom.createElementMorph(element42);
        morphs[19] = dom.createMorphAt(element42,0,0);
        morphs[20] = dom.createElementMorph(element43);
        morphs[21] = dom.createMorphAt(element43,0,0);
        morphs[22] = dom.createElementMorph(element44);
        morphs[23] = dom.createMorphAt(element44,1,1);
        morphs[24] = dom.createElementMorph(element45);
        morphs[25] = dom.createMorphAt(element45,1,1);
        morphs[26] = dom.createElementMorph(element46);
        morphs[27] = dom.createMorphAt(element46,1,1);
        morphs[28] = dom.createMorphAt(dom.childAt(element46, [3]),2,2);
        morphs[29] = dom.createElementMorph(element47);
        morphs[30] = dom.createMorphAt(element47,1,1);
        morphs[31] = dom.createMorphAt(dom.childAt(element41, [17]),3,3);
        morphs[32] = dom.createAttrMorph(element48, 'class');
        morphs[33] = dom.createMorphAt(element48,1,1);
        morphs[34] = dom.createMorphAt(dom.childAt(element41, [21]),3,3);
        morphs[35] = dom.createAttrMorph(element49, 'class');
        morphs[36] = dom.createElementMorph(element50);
        morphs[37] = dom.createMorphAt(element50,1,1);
        morphs[38] = dom.createMorphAt(element49,3,3);
        morphs[39] = dom.createMorphAt(element41,25,25);
        morphs[40] = dom.createMorphAt(dom.childAt(element41, [27]),3,3);
        morphs[41] = dom.createAttrMorph(element51, 'class');
        morphs[42] = dom.createElementMorph(element52);
        morphs[43] = dom.createMorphAt(element52,1,1);
        morphs[44] = dom.createMorphAt(element51,3,3);
        morphs[45] = dom.createMorphAt(dom.childAt(element41, [31]),3,3);
        morphs[46] = dom.createAttrMorph(element53, 'class');
        morphs[47] = dom.createElementMorph(element54);
        morphs[48] = dom.createMorphAt(element54,1,1);
        morphs[49] = dom.createMorphAt(element53,3,3);
        morphs[50] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[51] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[52] = dom.createMorphAt(fragment,10,10,contextualElement);
        morphs[53] = dom.createMorphAt(fragment,12,12,contextualElement);
        morphs[54] = dom.createMorphAt(fragment,14,14,contextualElement);
        morphs[55] = dom.createMorphAt(fragment,16,16,contextualElement);
        morphs[56] = dom.createMorphAt(fragment,18,18,contextualElement);
        morphs[57] = dom.createMorphAt(fragment,20,20,contextualElement);
        return morphs;
      },
      statements: [
        ["block","each",[["get","users",["loc",[null,[3,8],[3,13]]]]],[],0,null,["loc",[null,[3,0],[48,9]]]],
        ["attribute","class",["concat",[["subexpr","css-bool-evaluator",[["get","hideBgEvents",["loc",[null,[51,58],[51,70]]]],"hideBgEvents",""],[],["loc",[null,[51,37],[51,90]]]]," ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","showUserBadges",["loc",[null,[51,116],[51,130]]]],"true"],[],["loc",[null,[51,112],[51,138]]]],"showUserBadges",""],[],["loc",[null,[51,91],[51,160]]]]," ",["subexpr","css-bool-evaluator",[["get","showFilter",["loc",[null,[51,182],[51,192]]]],"withFilter",""],[],["loc",[null,[51,161],[51,210]]]]," col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[51,243],[51,256]]]]],[],["loc",[null,[51,238],[51,257]]]],"24","19"],[],["loc",[null,[51,217],[51,269]]]],"/24@desk l-24/24@phone l-24/24@tablet ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[51,328],[51,341]]]],"sidebar-active",""],[],["loc",[null,[51,307],[51,363]]]]," card site-content fadeIn"]]],
        ["attribute","class",["concat",["col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[53,72],[53,85]]]]],[],["loc",[null,[53,67],[53,86]]]],"24","20"],[],["loc",[null,[53,46],[53,98]]]],"/24@desk l-24/24@phone l-24/24@tablet"]]],
        ["element","action",["today"],[],["loc",[null,[56,18],[56,36]]]],
        ["element","action",["openMiniCalendar"],[],["loc",[null,[58,98],[58,127]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","switchToDate",["loc",[null,[61,36],[61,48]]]]],[],[]],"id","miniCalendarTrigger"],["loc",[null,[61,10],[61,75]]]],
        ["element","action",["prev"],[],["loc",[null,[63,34],[63,51]]]],
        ["element","action",["next"],[],["loc",[null,[65,34],[65,51]]]],
        ["content","currentDateFormatted",["loc",[null,[70,51],[70,75]]]],
        ["content","weekNumber",["loc",[null,[71,54],[71,68]]]],
        ["element","action",["toggleColorMode"],[],["loc",[null,[78,39],[78,67]]]],
        ["inline","input",[],["type","checkbox","id","colorMode","classNames","switch","name","colorMode","checked",["subexpr","@mut",[["get","colorModeUserEnabled",["loc",[null,[79,98],[79,118]]]]],[],[]]],["loc",[null,[79,14],[79,120]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","views",["loc",[null,[87,22],[87,27]]]]],[],[]],"value",["subexpr","@mut",[["get","selectedView",["loc",[null,[88,20],[88,32]]]]],[],[]],"optionLabelPath","name","optionValuePath","id","searchEnabled",false,"allowClear",false,"cssClass","input--dropdown custom-calendar"],["loc",[null,[86,12],[94,14]]]],
        ["element","action",["toggleFilter"],[],["loc",[null,[97,32],[97,57]]]],
        ["attribute","class",["concat",[["subexpr","css-bool-evaluator",[["get","showFilter",["loc",[null,[101,39],[101,49]]]],"","hidden"],[],["loc",[null,[101,18],[101,63]]]]," mb10"]]],
        ["block","each",[["get","users",["loc",[null,[105,20],[105,25]]]]],[],1,null,["loc",[null,[105,12],[124,21]]]],
        ["attribute","class",["concat",[["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[141,38],[141,51]]]],"","hidden"],[],["loc",[null,[141,17],[141,65]]]]," side-bar card col l-5/24@desk l-24/24@phone l-24/24@tablet"]]],
        ["element","action",["toggleSidebar",true],[],["loc",[null,[142,42],[142,73]]]],
        ["element","action",["toggleMe"],[],["loc",[null,[144,18],[144,39]]]],
        ["inline","button-toggle-me",[],["size",40,"strokeWidth",1,"classNames","toggleMe","active",["subexpr","@mut",[["get","onlyMine",["loc",[null,[144,111],[144,119]]]]],[],[]],"content",["subexpr","css-bool-evaluator",[["get","onlyMine",["loc",[null,[144,148],[144,156]]]],"Persönliche Ansicht","Praxis Ansicht"],[],["loc",[null,[144,128],[144,196]]]]],["loc",[null,[144,41],[144,198]]]],
        ["element","action",["ical"],[],["loc",[null,[145,18],[145,35]]]],
        ["inline","button-ical",[],["size",32,"classNames","ical"],["loc",[null,[145,37],[145,78]]]],
        ["element","action",["triggerRefresh"],["on","change","preventDefault",false],["loc",[null,[150,79],[150,139]]]],
        ["block","if",[["subexpr","is-cat-selected2",[["get","profile.types",["loc",[null,[151,32],[151,45]]]],"type-app"],[],["loc",[null,[151,14],[151,57]]]]],[],2,3,["loc",[null,[151,8],[159,15]]]],
        ["element","action",["triggerRefresh"],["on","change","preventDefault",false],["loc",[null,[162,81],[162,141]]]],
        ["block","if",[["subexpr","is-cat-selected2",[["get","profile.types",["loc",[null,[163,32],[163,45]]]],"type-appUser"],[],["loc",[null,[163,14],[163,61]]]]],[],4,5,["loc",[null,[163,8],[171,15]]]],
        ["element","action",["triggerRefresh"],["on","change","preventDefault",false],["loc",[null,[175,86],[175,146]]]],
        ["block","if",[["subexpr","is-cat-selected2",[["get","profile.types",["loc",[null,[176,34],[176,47]]]],"type-appEtiga"],[],["loc",[null,[176,16],[176,64]]]]],[],6,7,["loc",[null,[176,10],[184,17]]]],
        ["inline","button-etiga-small",[],["content","","classNames","inline-icon","size","16","width",28],["loc",[null,[185,75],[185,152]]]],
        ["element","action",["triggerRefresh"],["on","change","preventDefault",false],["loc",[null,[188,80],[188,140]]]],
        ["block","if",[["subexpr","is-cat-selected2",[["get","profile.types",["loc",[null,[189,32],[189,45]]]],"type-task"],[],["loc",[null,[189,14],[189,58]]]]],[],8,9,["loc",[null,[189,8],[197,15]]]],
        ["inline","button-toggle-all-decent",[],["size",20,"strokeWidth",1,"classNames","mt20 float-right topToggle","active",["subexpr","@mut",[["get","catsExpanded",["loc",[null,[204,104],[204,116]]]]],[],[]],"toggleAll","toggleCats","content","ein/ausblenden"],["loc",[null,[204,8],[204,166]]]],
        ["attribute","class",["concat",["clear-left users ",["subexpr","css-bool-evaluator",[["get","catsExpanded",["loc",[null,[207,56],[207,68]]]],"","hidden"],[],["loc",[null,[207,35],[207,82]]]]]]],
        ["block","each",[["get","categories",["loc",[null,[208,16],[208,26]]]]],[],10,null,["loc",[null,[208,8],[228,17]]]],
        ["inline","button-toggle-all-decent",[],["size",20,"strokeWidth",1,"classNames","mt20 float-right topToggle","active",["subexpr","@mut",[["get","usersExpanded",["loc",[null,[233,104],[233,117]]]]],[],[]],"toggleAll","toggleUsers","content","ein/ausblenden"],["loc",[null,[233,8],[233,168]]]],
        ["attribute","class",["concat",["clear-left users ",["subexpr","css-bool-evaluator",[["get","usersExpanded",["loc",[null,[236,56],[236,69]]]],"","hidden"],[],["loc",[null,[236,35],[236,83]]]]]]],
        ["element","action",["triggerRefreshUser","all"],["on","change","preventDefault",false],["loc",[null,[238,64],[238,134]]]],
        ["block","if",[["subexpr","is-cat-selected2",[["get","profile.users",["loc",[null,[239,34],[239,47]]]],"user-9999"],[],["loc",[null,[239,16],[239,60]]]]],[],11,12,["loc",[null,[239,10],[247,17]]]],
        ["block","each",[["get","users",["loc",[null,[250,16],[250,21]]]]],[],13,null,["loc",[null,[250,8],[270,17]]]],
        ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[273,12],[273,20]]]]],[],14,null,["loc",[null,[273,6],[295,13]]]],
        ["inline","button-toggle-all-decent",[],["size",20,"strokeWidth",1,"classNames"," mt20 float-right topToggle","active",["subexpr","@mut",[["get","roomsExpanded",["loc",[null,[298,105],[298,118]]]]],[],[]],"toggleAll","toggleRooms","content","ein/ausblenden"],["loc",[null,[298,8],[298,169]]]],
        ["attribute","class",["concat",["clear-left users ",["subexpr","css-bool-evaluator",[["get","roomsExpanded",["loc",[null,[300,56],[300,69]]]],"","hidden"],[],["loc",[null,[300,35],[300,83]]]]]]],
        ["element","action",["triggerRefreshLocation","all"],["on","change","preventDefault",false],["loc",[null,[301,64],[301,138]]]],
        ["block","if",[["subexpr","is-cat-selected2",[["get","profile.rooms",["loc",[null,[302,34],[302,47]]]],"room-9999"],[],["loc",[null,[302,16],[302,60]]]]],[],15,16,["loc",[null,[302,10],[310,17]]]],
        ["block","each",[["get","rooms",["loc",[null,[313,16],[313,21]]]]],[],17,null,["loc",[null,[313,8],[326,17]]]],
        ["inline","button-toggle-all-decent",[],["size",20,"strokeWidth",1,"classNames"," mt20 float-right topToggle","active",["subexpr","@mut",[["get","stationsExpanded",["loc",[null,[331,105],[331,121]]]]],[],[]],"toggleAll","toggleStations","content","ein/ausblenden"],["loc",[null,[331,8],[331,175]]]],
        ["attribute","class",["concat",["clear-left users ",["subexpr","css-bool-evaluator",[["get","stationsExpanded",["loc",[null,[333,56],[333,72]]]],"","hidden"],[],["loc",[null,[333,35],[333,86]]]]]]],
        ["element","action",["triggerRefreshStation","all"],["on","change","preventDefault",false],["loc",[null,[334,64],[334,137]]]],
        ["block","if",[["subexpr","is-cat-selected2",[["get","profile.stations",["loc",[null,[335,34],[335,50]]]],"station-9999"],[],["loc",[null,[335,16],[335,66]]]]],[],18,19,["loc",[null,[335,10],[343,17]]]],
        ["block","each",[["get","stations",["loc",[null,[347,16],[347,24]]]]],[],20,null,["loc",[null,[347,8],[360,17]]]],
        ["block","unless",[["get","sidebarActive",["loc",[null,[365,12],[365,25]]]]],[],21,null,["loc",[null,[365,2],[368,13]]]],
        ["inline","show-task-panel",[],["actionReceiver",["subexpr","@mut",[["get","showTaskPanel",["loc",[null,[370,33],[370,46]]]]],[],[]],"goto","goto","startTask","startTask","linkTo","linkTo","create","createNotification","addReply","addReply","finishTask","finishTaskSend","addMessage","addMessage","changeOwner","changeOwner"],["loc",[null,[370,0],[378,45]]]],
        ["inline","add-appointment-panel",[],["categories",["subexpr","@mut",[["get","patientCategories",["loc",[null,[380,35],[380,52]]]]],[],[]],"etigaCategories",["subexpr","@mut",[["get","etigaCategories",["loc",[null,[381,40],[381,55]]]]],[],[]],"users",["subexpr","@mut",[["get","users",["loc",[null,[382,30],[382,35]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[383,32],[383,39]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[384,35],[384,45]]]]],[],[]],"resources",["subexpr","@mut",[["get","resources",["loc",[null,[385,34],[385,43]]]]],[],[]],"rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[386,30],[386,35]]]]],[],[]],"users",["subexpr","@mut",[["get","users",["loc",[null,[387,30],[387,35]]]]],[],[]],"stations",["subexpr","@mut",[["get","stations",["loc",[null,[388,33],[388,41]]]]],[],[]],"showDelete",true,"showSeries",true,"supportUserApps",true,"showAdmission",true,"gotoPatient","gotoPatient","gotoEtigaMaster","gotoPatientEtiga","reloadPatients","reloadPatients","startAdmission","admissionPatient","gotoTreatment","gotoTreatment","create","createAppointment","delete","deleteAppointmentCheckEtiga","actionReceiver",["subexpr","@mut",[["get","addAppointmentPanel",["loc",[null,[400,39],[400,58]]]]],[],[]]],["loc",[null,[380,0],[400,60]]]],
        ["inline","start-treatment-panel",[],["confirm","selectCustomerConfirm","gotoCustomer","gotoCustomer","gotoPatient","gotoPatient","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[406,8],[406,13]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[407,10],[407,17]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[408,13],[408,23]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","startTreatmentPanel",["loc",[null,[409,17],[409,36]]]]],[],[]]],["loc",[null,[402,0],[409,38]]]],
        ["inline","app-request-confirm-panel",[],["confirm","confirmAppRequest","gotoPatient","gotoPatient","gotoPatientEtiga","gotoPatientEtiga","gotoCustomer","gotoCustomer","abort","abortAppRequest","actionReceiver",["subexpr","@mut",[["get","appRequestConfirmPanel",["loc",[null,[414,95],[414,117]]]]],[],[]]],["loc",[null,[411,0],[414,119]]]],
        ["inline","app-denial-panel",[],["confirm","deleteAppointment","gotoPatient","gotoPatient","gotoPatientEtiga","gotoPatientEtiga","gotoCustomer","gotoCustomer","actionReceiver",["subexpr","@mut",[["get","appDenialPanel",["loc",[null,[420,43],[420,57]]]]],[],[]]],["loc",[null,[416,0],[420,59]]]],
        ["inline","ical-link-panel",[],["actionReceiver",["subexpr","@mut",[["get","icalLinkPanel",["loc",[null,[422,33],[422,46]]]]],[],[]]],["loc",[null,[422,0],[422,48]]]],
        ["inline","choose-appointment-edit-type-panel",[],["editEvent","editEvent","actionReceiver",["subexpr","@mut",[["get","chooseAppointmentEditTypePanel",["loc",[null,[423,74],[423,104]]]]],[],[]]],["loc",[null,[423,0],[423,106]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18, child19, child20, child21]
    };
  }()));

});