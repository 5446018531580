define('jason-frontend/helpers/show-treatment-patient', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.showTreatmentPatient = showTreatmentPatient;

  function showTreatmentPatient(params) {
    var tpState = params[0],
        showAll = params[1],
        showAdmission = params[2],
        showWaiting = params[3],
        showTreatment = params[4],
        showPayment = params[5],
        showStation = params[6],
        stationsOverride = params[7],
        filteredStation = params[8],
        patientInfo = params[9],
        selectedUsers = params[10];

    var show = false;

    var userShow = true;
    if (selectedUsers && selectedUsers.length) {
      var doctor = selectedUsers.some(function (u) {
        return patientInfo.get('doctor') && u === patientInfo.get('doctor').id;
      });
      var assistent = selectedUsers.some(function (u) {
        return patientInfo.get('assistant') && u === patientInfo.get('assistant').id;
      });
      var notAssigned = selectedUsers.some(function (u) {
        return parseInt(u) === 9998 && patientInfo.get('assistant') === null && patientInfo.get('doctor') === null;
      });
      userShow = assistent || doctor || notAssigned;
    }

    if (showAll && (stationsOverride || tpState !== 'in_station')) {
      return userShow;
    }

    if (showStation) {
      if (filteredStation && filteredStation.length && filteredStation.some(function (s) {
        return s.get('selected') === true;
      })) {
        return filteredStation.some(function (s) {
          return userShow && s.get('selected') === true && patientInfo.get('station') && patientInfo.get('station').id === s.id;
        });
      }
    }

    return userShow && (showAdmission && tpState === 'in_admission' || showWaiting && tpState === 'in_waitingroom' || showTreatment && tpState === 'in_treatment' || showPayment && tpState === 'ready_for_payment' || showStation && tpState === 'in_station');
  }

  exports['default'] = Ember['default'].Helper.helper(showTreatmentPatient);

});