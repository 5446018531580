define('jason-frontend/controllers/products/edit', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Bearbeiten",
    breadCrumbPath: "products.edit",
    queryParams: ['referer', 'refererInvoiceId', 'refererPatientId', 'selectTab'],
    referer: null,
    showTherapies: true,
    showDiagnoses: true,
    showSymptoms: true,
    showReport: true,
    sidebarActive: false,
    showAnamnese: true,
    refererInvoiceId: null,
    refererPatientId: null,
    selectTab: 'masterdata',
    save: function save(referer, refererInvoiceId, refererPatientId, check) {

      var allPrices = new Array(),
          valid = true,
          validUnit = true,
          unitsFound = new Array(this.get('model').get('unit').get('id')),
          refLine = $('div#default-row'),
          indexCount = 0;

      $('div.price-line').each(function () {
        var line = $(this),
            price = line.find('input.product-price').val().replace(",", "."),
            unit = line.find('select.product-unit').val(),
            quantity = line.find('input.product-quantity-input').val().replace(",", "."),
            applied = line.find('input.product-applied-input').is(':checked'),
            tgd = line.find('input.product-tgd-input').is(':checked'),
            priceAddition = line.find('input.product-price-addition-input').val().replace(",", "."),
            purchasePrice = line.find('.purchase-price').attr('data-purchase-price'),
            favButton = line.find('a.btn-blub'),
            id = line.data('id'),
            isFav = false,
            equivalentAmount = null,
            equivalentDivident = null;

        if (line.find('input.product-equivalentAmount-input').val()) {
          equivalentAmount = line.find('input.product-equivalentAmount-input').val().replace(",", ".");
        }
        if (line.find('input.product-equivalentDivident-input').val()) {
          equivalentDivident = line.find('input.product-equivalentDivident-input').val().replace(",", ".");
        }

        if (id > 0 || line.hasClass('cloned')) {

          if (favButton.length) {
            isFav = favButton.data('selected');
          }

          if (unitsFound.contains(unit)) {
            validUnit = false;
          }

          if (quantity) {
            quantity = quantity.replace(',', '.');
          }
          if (price) {
            price = price.replace(',', '.');
          }
          if (priceAddition) {
            priceAddition = priceAddition.replace(',', '.');
          }

          unitsFound.push(unit);

          if (parseFloat(price) < 0.01) {}

          if (quantity == 0) {
            valid = false;
          }

          if (isNaN(parseFloat(price)) && (isNaN(parseFloat(priceAddition)) || parseFloat(priceAddition) < 0.01)) {
            valid = false;
          }

          allPrices[indexCount] = {
            id: id,
            price: price,
            favorite: isFav,
            applied: applied,
            purchasePrice: purchasePrice,
            tgd: tgd,
            quantity: quantity,
            equivalentAmount: equivalentAmount,
            equivalentDivident: equivalentDivident,
            overrides: id,
            priceAddition: priceAddition,
            unit: { id: unit, name: '' }
          };
          indexCount++;
        }
      });

      if (!valid) {
        jason.notifiction.error('Eingaben prüfen', 'Bitte überprüfen Sie Ihre Eingaben: VPE-Menge, VK-Brutto oder Aufschlag müssen angegeben werden');
        return;
      }

      var valid = true;
      //check if there is a conversion entry without settings
      $('div.conversionEntry').each(function (val) {
        if (!$(this).hasClass('hidden') && !$(this).hasClass('read-only')) {
          var firstValid = $.isNumeric($(this).find('input.reference-equivalentDivident').val());
          var secondValid = $.isNumeric($(this).find('input.reference-equivalentAmount').val());
          if (!firstValid || !secondValid) {
            valid = false;
          }
        }
      });

      if (!valid) {
        jason.notifiction.error('Eingaben prüfen', 'Bitte geben Sie alle Einheitsequivalente an');
        return;
      }

      var self = this;

      setTimeout(function () {
        var model = self.get('model');
        var price = model.get('priceExVat');

        var patientCategory = new Array();
        $('form#productEdit #patient-category-panel input[type=checkbox]:checked').each(function () {
          patientCategory.push({
            id: $(this).val()
          });
        });

        var product = {};
        product.id = model.id;
        product.name = model.get('name');
        product.licenceNumber = model.get('licenceNumber');
        product.doseMethod = model.get('doseMethod');
        product.doseInfoSpecial = model.get('doseInfoSpecial');
        product.individualName = model.get('individualName');
        product.individualSlopingInfo = model.get('individualSlopingInfo');
        product.individualDescription = model.get('individualDescription');
        product.tags = model.get('tags');

        var validPriceRow = false;
        var validQuantity = false;
        if ($('input.product-quantity-input', refLine).val()) {
          product.quantity = $('input.product-quantity-input', refLine).val().replace(",", ".");
          validQuantity = true;
        }
        if ($('input.product-price', refLine).val()) {
          product.priceExVat = $('input.product-price', refLine).val().replace(",", ".");
          validPriceRow = true;
        }
        if ($('input.product-purchase-price', refLine).val()) {
          product.purchasePrice = $('input.product-purchase-price', refLine).val().replace(",", ".");
        }
        if ($('a.btn-blub', refLine)) {
          product.favorite = $('a.btn-blub', refLine).data('selected') == true;
        }

        if ($('input.product-price-addition-input', refLine).val()) {
          var priceAdditionInput = $('input.product-price-addition-input', refLine).val().replace(",", ".");
          product.additionalPercentage = priceAdditionInput;
        }

        if (model.get('labProfile')) {
          product.labProfile = { id: model.get('labProfile').id };
        }

        product.applied = $('input.product-applied-input', refLine).is(':checked');
        product.tgd = $('input.product-tgd-input', refLine).is(':checked');
        product.ehapoActive = $('input#productEhapo').is(':checked');
        product.ehapoAutoBookingActive = $('input#productAutoBooking').is(':checked');

        product.description = model.get('description');

        if (model.get('category')) {
          product.category = { id: model.get('category').id, name: model.get('category').name };
        }

        if (model.get('manufacturer')) {
          product.manufacturer = { id: model.get('manufacturer').id, name: model.get('manufacturer').get('name') };
        }
        if (model.get('distributor')) {
          product.distributor = { id: model.get('distributor').id, name: model.get('distributor').get('name') };
        }
        product.tax = { id: model.get('tax').id, name: model.get('tax').get('name') };
        product.unit = { id: model.get('unit').get('id'), name: model.get('unit').get('name') };
        product.prices = allPrices;
        product.referenceNumber = model.get('referenceNumber');
        product.patientCategories = patientCategory;

        var sleep = 0;
        if (!product.quantity || !product.quantity.length || Number(product.quantity) === 0) {
          jason.notifiction.errorLong('Achtung', 'VPE muss größer 0 sein');
          return;
        }
        if (!validQuantity || !validPriceRow) {
          jason.notifiction.errorLong('Fehler 102', 'Produkt konnte nicht gespeichert werden, bitte aktualisieren Sie die Seite');
          return;
        }
        if (Number(product.priceExVat) === 0) {
          jason.notifiction.warning('Achtung', 'Artikelpreis wurde auf 0 Euro gesetzt');
          sleep = 3500;
        }

        setTimeout(function () {
          $.ajax({
            url: "/api/products/" + model.id,
            method: "PUT",
            data: JSON.stringify(product),
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function () {
            if (referer === 'sale') {
              jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
              //self.transitionTo('sale.products');
            } else if (referer === 'add-product') {
                jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
                // self.transitionTo('cash.add_product', refererInvoiceId, refererPatientId);
              } else {
                  jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
                  // self.transitionTo('products.index');
                }
          }, function (error) {
            if (error.responseJSON.exception === "java.lang.IllegalStateException") {
              jason.notifiction.errorLong('Fehler', 'Verkaufseinheit kann nicht entfernt werden, da sie noch in Verwendung ist');
            }
            if (error.responseJSON.exception === "java.lang.IllegalArgumentException") {
              jason.notifiction.errorLong('Abbruch', 'Mindestens ein in Verwendung befindlicher Lagerstand verwendet die von Ihnen geänderte Verkaufseinheit');
            }
            if (error.responseJSON.error === "Internal Server Error") {
              jason.notifiction.errorLong('Fehler 101', 'Leider konnte das Produkt nicht gespeichert werden');
            }
          });
        }, sleep);
      }, 1000);
    },
    actions: {
      openBarcodeSelection: function openBarcodeSelection(entryId) {
        this.get('productSlopingSelectBarcodePanel').send('load', entryId);
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-product-sloping-select-barcode-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      addSloping: function addSloping() {
        this.get('editSlopingPanel').send('load', null, this.store.find('patient-category'), this.store.find('serviceunit'));

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-sloping-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      editSloping: function editSloping(line) {
        this.get('editSlopingPanel').send('load', line, this.store.find('patient-category'), this.store.find('serviceunit'));

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-sloping-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      saveCheck: function saveCheck(referer, refererInvoiceId, refererPatientId) {
        this.save(referer, refererInvoiceId, refererPatientId, true);
      }
    }
  });

});