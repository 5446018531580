define('jason-frontend/routes/invoices/banktransfers', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    perPage: 10,
    model: function model(params) {},
    setupController: function setupController(controller, model, params) {
      controller.set('model', model);
      var queryCustomerId = params.queryParams.customerId;

      if (queryCustomerId > 0) {
        this.store.find('customer', queryCustomerId).then(function (customer) {
          controller.set('filterCustomer', customer);
        });
      } else {
        controller.set('filterCustomer', null);
      }

      this.applyFilter();
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          filterCustomer = controller.get('filterCustomer'),
          filterCode = controller.get('filterCode'),
          sortBy = controller.get('sortBy'),
          sortDir = controller.get('sortDir'),
          dateRange = controller.get('pickDate'),
          dateFromTo = dateRange.split('-');

      var from = moment(dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
          to = moment(dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

      controller.set('to', to);
      controller.set('from', from);

      controller.set('showLoading', true);

      this.findPaged('transaction', {
        filterFrom: from,
        filterTo: to,
        filterPaymentBanktransfer: true,
        showOnlyBankTransfer: true,
        filterCode: filterCode
      }).then(function (transactions) {
        var meta = transactions.meta;
        controller.set('sum', meta.sum);
        controller.set('model', transactions);
        controller.set('showLoading', false);
      });
    },
    actions: {
      sortable: function sortable(attr) {
        if (this.get('controller').get('sortBy') === attr) {
          if (this.get('controller').get('sortDir') === 'asc') {
            this.get('controller').set('sortDir', 'desc');
          } else {
            this.get('controller').set('sortDir', 'asc');
          }
        } else {
          this.get('controller').set('sortBy', attr);
        }
        this.applyFilter();
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      queryCustomer: function queryCustomer(query, deferred) {
        this.store.find('customer', { searchTerm: query.term }).then(deferred.resolve, deferred.reject);
      }
    }
  });

});