define('jason-frontend/components/stable-edit-form', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    store: Ember['default'].inject.service(),
    actions: {
      queryCustomer: function queryCustomer(query, deferred) {
        this.get('store').find('customer', { filterName: query.term }).then(deferred.resolve, deferred.reject);
      }
    }
  });

});