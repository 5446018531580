define('jason-frontend/routes/stables/create', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      return new Object();
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
    },
    actions: {
      create: function create() {
        var self = this;

        var controller = this.get('controller'),
            model = controller.get('model');

        if (model.owner) {
          Ember['default'].set(model, 'owner', {
            id: model.owner.id
          });
        }

        $.ajax({
          url: "/api/stables/0",
          method: "POST",
          data: JSON.stringify(model),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('stables.index');
        }, function (data) {
          self.transitionTo('stables.index');
        });
      }
    }
  });

});