define('jason-frontend/models/article', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    nameSuggest: DS['default'].attr(''),
    type: DS['default'].attr('string'),
    subType: DS['default'].attr(''),
    gotNumber: DS['default'].attr(''),
    gotNumberPrefix: DS['default'].attr(''),
    description: DS['default'].attr('string'),
    unit: DS['default'].belongsTo('serviceunit'),
    priceExVat: DS['default'].attr('number'),
    entryId: DS['default'].attr('number'),
    notReturnable: DS['default'].attr('boolean'),
    slopingInfo: DS['default'].attr('string'),
    supplierOverride: DS['default'].attr('string'),
    lab: DS['default'].attr('boolean'),
    privateLab: DS['default'].attr('boolean'),
    xray: DS['default'].attr('boolean'),
    labCategory: DS['default'].attr('string'),
    labCategoryString: DS['default'].attr('string'),
    picture: DS['default'].belongsTo('media'),
    category: DS['default'].belongsTo('product-category'),
    supplier: DS['default'].belongsTo('product-supplier'),
    partnerCategory: DS['default'].attr('string'),
    partnerSubcategory: DS['default'].attr('string'),
    referenceNumber: DS['default'].attr('string'),
    partnerNumber: DS['default'].attr('string'),
    labProfile: DS['default'].attr(''),
    vetconnectTest: DS['default'].attr(''),
    zoetisTest: DS['default'].attr('')
  });

});