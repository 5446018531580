define('jason-frontend/components/cancel-invoice-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    type: null,
    actions: {
      open: function open(id) {
        this.set('id', id);
      },
      confirm: function confirm() {
        this.sendAction('cancel', this.get('id'), this.get('reason'));
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    }
  });

});