define('jason-frontend/routes/invoices/editbanktransfer', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.fetch('transaction', params.id);
    },
    setupController: function setupController(controller, model, params) {
      this._super(controller, model);

      controller.set('group', this.store.find('transaction-group', model.get('transactionGroupId')));

      this.store.find('bankingAccount').then(function (data) {
        controller.set('bankingAccountList', data);
        if (data.content[0]) {
          controller.set('selectedBankingAccount', data.content[0].id);
        }
      });

      if (model.get('documentDate')) {
        model.set('createdInput', moment(model.get('documentDate')).format('DD. MM. YYYY'));
      }
      if (model.get('withdrawalDate')) {
        model.set('withdrawalDateInput', moment(model.get('withdrawalDate')).format('DD. MM. YYYY'));
      }
    },
    actions: {
      triggerSave: function triggerSave() {
        var controller = this.get('controller'),
            model = controller.get('model');

        var data = {
          id: model.id,
          withdrawalName: model.get('withdrawalName'),
          withdrawalBankname: model.get('withdrawalBankname'),
          withdrawalIban: model.get('withdrawalIban'),
          reason: model.get('reason'),
          referenceNumber: model.get('referenceNumber'),
          recipientReferenceNumber: model.get('recipientReferenceNumber')
        };

        if (model.get('createdInput')) {
          data.documentDate = moment(model.get('createdInput'), 'DD. MM. YYYY').toDate();
        }
        if (model.get('bankAccount')) {
          data.bankAccount = {
            id: model.get('bankAccount').id
          };
        }
        if (model.get('withdrawalDateInput')) {
          data.withdrawalDate = moment(model.get('withdrawalDateInput'), 'DD. MM. YYYY').toDate();
        }

        $.ajax({
          url: "/api/transactions",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Eingaben', 'erfolgreich gespeichert');
        }, function (error) {});
      }
    }
  });

});